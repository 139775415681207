var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icSearch",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 30 30",
        "icon-color": _vm.iconColor,
        "stroke-width": 0,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M20.635 22.483A9.962 9.962 0 0 1 14 25 10 10 0 0 1 4 15 10 10 0 0 1 14 5a10 10 0 0 1 10 10 9.961 9.961 0 0 1-2.517 6.634l4.305 4.305-.848.849ZM5.2 15a8.81 8.81 0 0 0 8.8 8.8 8.81 8.81 0 0 0 8.8-8.8A8.81 8.81 0 0 0 14 6.2 8.81 8.81 0 0 0 5.2 15Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }