// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mobile-wrap[data-v-3f20484b] {
  height: 250px;
  overflow: auto;
}
.mobile-wrap[data-v-3f20484b] {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.mobile-wrap[data-v-3f20484b]::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.mobile-item[data-v-3f20484b] {
  width: 320px;
  overflow: none;
  text-align: left;
}
.mobile-ttl[data-v-3f20484b] {
  color: var(--txt-gray);
}
.mobile-ttl.is_active[data-v-3f20484b] {
  color: #fff;
}
.edit_item[data-v-3f20484b] {
  display: block;
}
.edit_item .item_body[data-v-3f20484b] {
  padding-top: 2rem;
}
.edit_item .ttl_area .ttl + p[data-v-3f20484b] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.edit_item.is_active .item_head[data-v-3f20484b]:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--main-color);
  pointer-events: none;
}
/* .edit_item.is_active .item_txt:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid var(--main-color);
  pointer-events: none;
} */
.controller_group[data-v-3f20484b] {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.controller_group .slide_nav_area .btn[data-v-3f20484b] {
  position: absolute;
  margin-top: -2.5rem;
}
.controller_group .slide_nav_area .btn.prev[data-v-3f20484b] {
  left: 2rem;
}
.controller_group .slide_nav_area .btn.next[data-v-3f20484b] {
  right: 2rem;
}
@media screen and (max-width: 600px) {
.playlist_sec .slide_nav_area .btn[data-v-3f20484b] {
    display: none !important;
}
.edit_list[data-v-3f20484b] {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.edit_list .edit_node[data-v-3f20484b] {
    width: 28rem;
}
.swiper-container[data-v-3f20484b] {
    width: 100% !important;
}
.swiper-wrapper[data-v-3f20484b] {
    width: 50% !important;
}
.swiper-slide[data-v-3f20484b] {
    text-align: center !important;
    width: auto !important;
}
.thumb_area img[data-v-3f20484b] {
    height: 170px;
    width: 250px;
}
.my-gallery figure[data-v-3f20484b] {
    margin: 0px;
}
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
.playlist_wrap[data-v-3f20484b] {
    width: 100%;
}
.edit_list[data-v-3f20484b] {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.playlist_sec .slide_nav_area .btn[data-v-3f20484b] {
    display: none !important;
}
.edit_item .ttl_area .ttl[data-v-3f20484b] {
    font-size: 1.4rem;
}
.edit_item .ttl_area p[data-v-3f20484b] {
    font-size: 1.2rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
