var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "drops_page detail_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Drops Detail")]),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.previewType,
              expression: "!previewType",
            },
          ],
          staticClass: "overview_sec",
        },
        [
          _c("div", {
            staticClass: "bg_el",
            style: `background-image: url(${_vm.artworkBackground})`,
          }),
          _c("div", { staticClass: "set_inner" }, [
            _c("header", { staticClass: "sec_head" }, [
              _c("div", { staticClass: "thumb_area" }, [
                _vm.artworkInfo.mimeType === "image/jpeg"
                  ? _c("img", {
                      attrs: {
                        srcset: `${_vm.source} 2x, ${_vm.source}`,
                        src: _vm.source,
                        width: "890",
                        height: "500",
                        alt: _vm.artworkAlt ? _vm.artworkAlt : "",
                      },
                    })
                  : _c(
                      "video",
                      {
                        staticStyle: { width: "'auto'" },
                        attrs: {
                          loop: "",
                          autoplay: "",
                          muted: "",
                          playsinline: "",
                          src: _vm.source,
                        },
                        domProps: { muted: true },
                      },
                      [
                        _vm._v(
                          " Sorry, your browser doesn't support embedded videos. "
                        ),
                      ]
                    ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn type_ic btn_like",
                  class: {
                    is_active: _vm.likedList.indexOf(_vm.artworkId) !== -1,
                  },
                  attrs: { type: "button" },
                },
                [
                  _vm.likedList.indexOf(_vm.artworkId)
                    ? _c("IcLike", {
                        ref: "icLike",
                        attrs: {
                          status: _vm.likedList.indexOf(_vm.artworkId),
                          "icon-color": "#fff",
                          likedList: _vm.likedList,
                        },
                        on: {
                          "update:likedList": function ($event) {
                            _vm.likedList = $event
                          },
                          "update:liked-list": function ($event) {
                            _vm.likedList = $event
                          },
                        },
                      })
                    : _c("IcLike", {
                        ref: "icLike",
                        attrs: {
                          "icon-color": "#ff521d",
                          likedList: _vm.likedList,
                        },
                        on: {
                          "update:likedList": function ($event) {
                            _vm.likedList = $event
                          },
                          "update:liked-list": function ($event) {
                            _vm.likedList = $event
                          },
                        },
                      }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "sec_body" }, [
              _c("div", { staticClass: "ttl_wrap" }, [
                _c("div", { staticClass: "ttl_area" }, [
                  _c("h3", { staticClass: "ttl" }, [
                    _vm._v(_vm._s(_vm.artworkInfo.title)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "profile_info is_art" },
                  [
                    _vm.artistAvatarInfo && _vm.artistAvatarInfo.length
                      ? _c("span", {
                          staticClass: "profile",
                          style: `background-image: url(${_vm.artistAvatarInfo[0].resUrl})`,
                        })
                      : _c("IcProfile", { staticClass: "profile" }),
                    _c("IcRing", { staticClass: "ic_ring" }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.artworkInfo.artistName)),
                    ]),
                  ],
                  1
                ),
                _c("p", { staticClass: "edit" }, [
                  _vm._v(_vm._s(_vm.artworkInfo.totalEdition) + " Editions"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "buy_wrap float_el" },
                [
                  _vm.artworkInfo.dropsDivision == "live"
                    ? _c("CountDown", {
                        attrs: { "date-time": _vm.artworkInfo.dropsEndDate },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "buy_group" }, [
                    _c("div", { staticClass: "label_list_group" }, [
                      _c("dl", { staticClass: "label_list" }, [
                        _c("dt", [_vm._v("Price")]),
                        _c("dd", {}, [
                          _c("p", { staticClass: "price" }, [
                            _c("b", [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm._f("setNum")(_vm.artworkInfo.price)
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("dl", { staticClass: "label_list" }, [
                        _vm._m(0),
                        _c("dd", {}, [
                          _c(
                            "p",
                            { staticClass: "price" },
                            [
                              [
                                _c("b", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm._f("setNum")(
                                          _vm.artworkInfo.price * 0.008
                                        )
                                      )
                                  ),
                                ]),
                              ],
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                    !_vm.$isMobile()
                      ? _c("button", { staticClass: "btn w_f strong h_l" }, [
                          _vm._v(" " + _vm._s(_vm.getDropStatus) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "desc_wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "desc_group more_ui",
                    class: { is_more: _vm.showMoreDesc },
                  },
                  [
                    _c("h4", { staticClass: "ttl type_underL" }, [
                      _vm._v("Description"),
                    ]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(_vm._s(_vm.artworkInfo.artworkDesc)),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn type_txt has_arw dv_mob toggle_desc",
                        on: {
                          click: function ($event) {
                            _vm.showMoreDesc = !_vm.showMoreDesc
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.showMoreDesc ? "Close" : "More") +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "tag_group" }, [
                  _c("h4", { staticClass: "ttl type_underL" }, [_vm._v("Tag")]),
                  _c(
                    "ul",
                    { staticClass: "tag_list clearFix" },
                    _vm._l(_vm.artworkInfo.keywords, function (tag, i) {
                      return _c(
                        "li",
                        { key: "tag_" + i, staticClass: "tag_node" },
                        [
                          _c("button", { staticClass: "tag_item" }, [
                            _c("span", [_vm._v(_vm._s(tag))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "set_inner" }, [
        _c("section", { staticClass: "detail_sec detail_info_sec" }, [
          !_vm.previewType
            ? _c("header", { ref: "tabs", staticClass: "sec_head" }, [
                _c("div", { staticClass: "tab_wrap" }, [
                  _c(
                    "div",
                    { staticClass: "tab_list", attrs: { role: "tablist" } },
                    _vm._l(_vm.infoTabData, function (tab) {
                      return _c(
                        "div",
                        {
                          key: tab.id,
                          staticClass: "tab_node",
                          attrs: { role: "presentation" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "tab_item",
                              class: { is_active: _vm.currentTab === tab.id },
                              attrs: {
                                id: "tab_" + tab.id,
                                role: "tab",
                                "aria-controls": tab.id + "_panel",
                                "aria-selected":
                                  tab.id === _vm.currentTab ? true : false,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setTab(tab.id)
                                },
                              },
                            },
                            [
                              tab.icon === "time"
                                ? _c("IcTime", {
                                    attrs: { "stroke-color": "#fff" },
                                  })
                                : _vm._e(),
                              tab.icon === "items"
                                ? _c("IcItems", {
                                    attrs: { "stroke-color": "#fff" },
                                  })
                                : _vm._e(),
                              tab.icon === "eye"
                                ? _c("IcEye", {
                                    attrs: { "stroke-color": "#fff" },
                                  })
                                : _vm._e(),
                              tab.icon === "video"
                                ? _c("IcVideo", {
                                    attrs: { "stroke-color": "#fff" },
                                  })
                                : _vm._e(),
                              _c("span", [_vm._v(_vm._s(tab.name))]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "sec_body tab_panel",
              attrs: {
                id: _vm.currentTab + "_panel",
                role: "tabpanel",
                "aria-labelledby": "tab_" + _vm.currentTab,
              },
            },
            [
              _vm.currentTab === "status"
                ? [
                    _c("div", { staticClass: "detail_info_wrap" }, [
                      _c("div", { staticClass: "detail_info_inner" }, [
                        _c("header", { staticClass: "detail_info_head taR" }, [
                          _c(
                            "p",
                            { staticClass: "info_txt refresh" },
                            [
                              _c("IcRefreshS", {
                                attrs: { "stroke-color": "var(--txt-gray-2)" },
                              }),
                              _c("span", [_vm._v("Refresh in 2 minutes")]),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "detail_info_body" }, [
                          _c(
                            "ul",
                            { staticClass: "status_list" },
                            _vm._l(_vm.statusInfos, function (status, i) {
                              return _c(
                                "li",
                                {
                                  key: "status_" + i,
                                  staticClass: "status_node",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "status_item",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "profile_info",
                                          class: {
                                            is_art:
                                              status.artistYn === "artist",
                                          },
                                        },
                                        [
                                          status.buyerAvatarInfo.length > 0
                                            ? _c("span", {
                                                staticClass: "profile",
                                                style: `background-image: url(${status.buyerAvatarInfo[0].resUrl})`,
                                              })
                                            : _c("IcProfile", {
                                                staticClass: "profile",
                                                attrs: {
                                                  width: 50,
                                                  height: 50,
                                                  "stroke-width": ".7",
                                                },
                                              }),
                                          status.artistYn === "artist"
                                            ? _c("IcRing", {
                                                staticClass: "ic_ring",
                                                attrs: {
                                                  width: 50,
                                                  height: 50,
                                                  "small-ring": 14.8,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "txt_info" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(_vm._s(status.buyerName)),
                                        ]),
                                        _c("p", { staticClass: "date" }, [
                                          _vm._v(_vm._s(status.buyDate)),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "price_info" }, [
                                        _c("b", { staticClass: "price" }, [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm._f("setNum")(
                                                  status.buyPrice
                                                )
                                              )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm.currentTab === "editions"
                ? [
                    _c("div", { staticClass: "list_wrap detail_info_wrap" }, [
                      _c(
                        "header",
                        { staticClass: "list_head detail_info_head" },
                        [
                          _c("p", { staticClass: "count" }, [
                            _c("span", { staticClass: "c_point" }, [
                              _vm._v(_vm._s(_vm.artworkInfo.totalEdition)),
                            ]),
                            _vm._v(" Editions "),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "list_body detail_info_body" }, [
                        _c(
                          "ul",
                          { staticClass: "grid grid_10 thumb_list ed_list" },
                          _vm._l(_vm.editionItems, function (item, i) {
                            return _c(
                              "li",
                              {
                                key: "edit_" + i,
                                staticClass: "col col_3 thumb_node",
                              },
                              [
                                _c("div", { staticClass: "thumb_item" }, [
                                  _c("div", { staticClass: "thumb_area" }, [
                                    item.mimeType === "video/mp4"
                                      ? _c("img", {
                                          attrs: {
                                            srcset: `${item.videoThumbInfos[0].resUrl} 2x, ${item.videoThumbInfos[1].resUrl}`,
                                            src: `${item.videoThumbInfos[1].resUrl}`,
                                            alt: "thumbnail",
                                          },
                                        })
                                      : _c("img", {
                                          attrs: {
                                            srcset: `${item.imageInfos[0].resUrl} 2x, ${item.imageInfos[1].resUrl}`,
                                            src: `${item.imageInfos[1].resUrl}`,
                                            alt: "thumbnail",
                                          },
                                        }),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "ic_area type_blockchains" },
                                    [_c("IcEth")],
                                    1
                                  ),
                                  _c("p", { staticClass: "edit" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          `${i + 1} of ${
                                            _vm.artworkInfo.totalEdition
                                          }`
                                        )
                                      ),
                                    ]),
                                    _vm._v(" Editions "),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]
                : _vm.currentTab === "docent"
                ? [
                    _vm.docentThumbItems.length
                      ? _c(
                          "div",
                          [
                            _c("DocentThumbSlide", {
                              attrs: { "item-data": _vm.docentThumbItems },
                              on: { setCurrent: _vm.setDocentCurrent },
                            }),
                            _vm.currentDocent.item
                              ? _c(
                                  "div",
                                  { staticClass: "viewer_wrap type_img" },
                                  [
                                    _c("div", { staticClass: "img_area" }, [
                                      _vm.currentDocent.item.thumbInfos[0]
                                        .resUrl !== undefined
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.currentDocent.item
                                                .thumbInfos[0].resUrl,
                                              width: 1220,
                                              alt: "currentDocent",
                                            },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: _vm.docentInfo.imageInfos[0]
                                                .resUrl,
                                              width: 1220,
                                              alt: "currentDocent",
                                            },
                                          }),
                                    ]),
                                    _c("div", { staticClass: "desc_area" }, [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.currentDocent.item.content)
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "guide_ui no_data" }, [
                          _c("div", { staticClass: "img_area" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  media: "(max-width: 600px)",
                                  type: "image/png",
                                  srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                                  alt: "illust_nosearch",
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/img_illust_nosearch.png"),
                                  alt: "No Result",
                                },
                              }),
                            ]),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v("No Docent"),
                          ]),
                        ]),
                  ]
                : _vm.currentTab === "film"
                ? [
                    _vm.makingInfos.resUrl
                      ? _c("div", [
                          _c("div", { staticClass: "viewer_wrap type_video" }, [
                            _c(
                              "div",
                              {
                                staticClass: "video_area",
                                staticStyle: { "text-align": "center" },
                              },
                              [
                                _c(
                                  "video",
                                  {
                                    ref: "video",
                                    attrs: { id: "makingFilm" },
                                    on: {
                                      ended: function ($event) {
                                        _vm.isPlay = false
                                      },
                                    },
                                  },
                                  [
                                    _c("source", {
                                      attrs: {
                                        src: _vm.makingInfos.resUrl,
                                        type: "video/mp4",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "controls_area type_start" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn type_ic player",
                                    on: { click: _vm.makingfilmPlayPause },
                                  },
                                  [
                                    !_vm.isPlay
                                      ? _c("IcPlayL", {
                                          staticClass: "ic_play",
                                        })
                                      : _c("IcPauseL", {
                                          staticClass: "ic_pause",
                                        }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "guide_ui no_data" }, [
                          _c("div", { staticClass: "img_area" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  media: "(max-width: 600px)",
                                  type: "image/png",
                                  srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/img_illust_nosearch.png"),
                                  alt: "No Result",
                                },
                              }),
                            ]),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v("No Making Film"),
                          ]),
                        ]),
                  ]
                : [
                    _c("div", { staticClass: "guide_ui no_data" }, [
                      _c("div", { staticClass: "img_area" }, [
                        _c("picture", [
                          _c("source", {
                            attrs: {
                              media: "(max-width: 600px)",
                              type: "image/png",
                              srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/img_illust_nosearch.png"),
                              alt: "No Result",
                            },
                          }),
                        ]),
                      ]),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v("The data is not available."),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("Estimated "),
      _c("br", { staticClass: "dv_mob" }),
      _vm._v("Tax"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }