var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "drops_page curation_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Drops")]),
      _c("div", { staticClass: "set_inner" }, [
        _c("section", { staticClass: "rep_sec" }, [
          _c("div", { staticClass: "img_wrap" }, [
            _c("div", { staticClass: "thumb_area" }, [
              _vm.mainDropImage
                ? _c("img", {
                    attrs: {
                      src: _vm.mainDropImage,
                      width: 1440,
                      alt: _vm.detailInfo.title,
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/img/bg_skeletonDropsCuration.png"),
                      width: 1440,
                      alt: "bg_skeletonDropsCuration",
                    },
                  }),
            ]),
          ]),
          _c("div", { staticClass: "info_wrap" }, [
            _c(
              "div",
              {
                staticClass: "ttl_area profile_info is_art",
                on: {
                  click: function ($event) {
                    return _vm.goArtistProfile()
                  },
                },
              },
              [
                _vm.avatarInfo && _vm.avatarInfo.length
                  ? _c("span", {
                      staticClass: "profile",
                      style: `background-image: url(${_vm.detailInfo.avatarInfo[0].resUrl})`,
                    })
                  : _c("IcProfile", { staticClass: "profile" }),
                _c("IcRing", {
                  staticClass: "ic_ring",
                  attrs: { width: 50, height: 50, "small-ring": 14.97 },
                }),
                _c("h3", { staticClass: "ttl name" }, [
                  _vm._v(_vm._s(_vm.detailInfo.artistName)),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "grid grid_30 desc_info_group" }, [
              _c("div", { staticClass: "col col_6 artist_info" }, [
                _c("p", { staticClass: "desc" }, [
                  _vm._v(_vm._s(_vm.detailInfo.artistDescription)),
                ]),
              ]),
              _vm.detailInfo.title && _vm.detailInfo.artworkDescription
                ? _c("div", { staticClass: "col col_6 drops_info" }, [
                    _c("h4", { staticClass: "ttl" }, [
                      _vm._v(_vm._s(_vm.detailInfo.title)),
                    ]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(_vm._s(_vm.detailInfo.artworkDescription)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("section", { staticClass: "list_sec live_sec" }, [
          _c("header", { staticClass: "sec_head" }, [
            _c("div", { staticClass: "ttl_area" }, [
              _vm.detailInfo.dropsDivision == "live"
                ? _c("h2", { staticClass: "ttl" }, [
                    _c("b", [_vm._v("Live")]),
                    _vm._v(" Drops "),
                  ])
                : _vm.detailInfo.dropsDivision == "upcoming"
                ? _c("h2", { staticClass: "ttl" }, [
                    _c("b", [_vm._v("Upcoming")]),
                    _vm._v(" Drops "),
                  ])
                : _c("h2", { staticClass: "ttl" }, [
                    _c("b", [_vm._v("Ended")]),
                    _vm._v(" Drops"),
                  ]),
            ]),
          ]),
          _c("div", { staticClass: "sec_body" }, [
            _c("div", { staticClass: "live_list_wrap" }, [
              _c(
                "ul",
                { staticClass: "live_list" },
                _vm._l(_vm.dropDetailInfos, function (item) {
                  return _c("li", { key: item.id, staticClass: "live_node" }, [
                    _c(
                      "div",
                      {
                        staticClass: "live_item clearFix",
                        attrs: {
                          "aria-describedby":
                            "Capture of Artwork for the " + item.title,
                        },
                      },
                      [
                        _c("div", { staticClass: "item_head" }, [
                          _c("div", { staticClass: "thumb_area" }, [
                            _c("button", [
                              item.mimeType === "video/mp4"
                                ? _c("img", {
                                    attrs: {
                                      src: item.videoThumbInfos[1].resUrl,
                                      width: 598,
                                      alt:
                                        "Capture of Artwork for the " +
                                        item.title,
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: item.imageInfos[1].resUrl,
                                      width: 598,
                                      alt:
                                        "Capture of Artwork for the " +
                                        item.title,
                                    },
                                  }),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "item_body clearFix" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ttl_area",
                              class: {
                                liveDrop:
                                  _vm.detailInfo.dropsDivision == "live",
                              },
                              attrs: { role: "text", id: item.artworkId },
                            },
                            [
                              _c("h3", { staticClass: "ttl" }, [
                                _c("b", [_vm._v(_vm._s(item.title))]),
                              ]),
                              _c("p", { staticClass: "edit" }, [
                                _c(
                                  "span",
                                  { class: { "text-indent": _vm.editionHide } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.editionHide
                                            ? ""
                                            : "Edition of " + item.totalEdition
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                item.soldedCount > 0 &&
                                _vm.detailInfo.dropsDivision == "ended"
                                  ? _c("span", [
                                      _vm._v(
                                        " | " +
                                          _vm._s(item.soldedCount) +
                                          " Sold"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("p", { staticClass: "desc" }, [
                                _vm._v(_vm._s(item.artworkDescription)),
                              ]),
                            ]
                          ),
                          _vm.detailInfo.dropsDivision == "upcoming"
                            ? _c("div", { staticClass: "time_cover" }, [
                                _c(
                                  "div",
                                  { staticClass: "btn_area upcomingDate" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.setUpcomingDateStr(
                                            _vm.dropsId,
                                            item.upcomingStartDate
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "time_cover" },
                                [
                                  _c("CountDown", {
                                    attrs: {
                                      state: item.soldOutYn,
                                      "date-time": item.dropsEndDate,
                                      "drops-division":
                                        _vm.detailInfo.dropsDivision,
                                      "solded-count": item.soldedCount,
                                    },
                                  }),
                                  item.soldedCount > 0 ||
                                  _vm.detailInfo.dropsDivision == "live"
                                    ? _c("div", { staticClass: "btn_area" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn w_s h_l strong",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goDetails(
                                                  item.artworkId,
                                                  _vm.detailInfo.dropsDivision,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" View Details ")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }