var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_collection_page mode_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [
        _vm._v("MY Collections View Mode"),
      ]),
      _vm.collections
        ? _c("div", { staticClass: "set_inner" }, [
            _vm.collections && _vm.collections.length > 0
              ? _c(
                  "section",
                  { staticClass: "collection_list_sec" },
                  [
                    _c("div", { staticClass: "collection_list_sec_head" }, [
                      _c("div", { staticClass: "ttl_group" }, [
                        _c("div", { staticClass: "ttl_area" }, [
                          _c("h2", { staticClass: "ttl" }, [
                            _vm._v("My Collections"),
                          ]),
                          _c("p", { attrs: { role: "status" } }, [
                            _vm._v(
                              "Total Collections " +
                                _vm._s(_vm.collectionsCount)
                            ),
                          ]),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "btn type_line_w w_l h_l mode",
                            attrs: { href: "/collections" },
                          },
                          [_c("ArwPrevs"), _c("span", [_vm._v("Back")])],
                          1
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.collections, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.artworkId,
                          staticClass: "collection_list_sec_body",
                          class: { is_mode: _vm.showButtons },
                        },
                        [
                          item.collectionInfos &&
                          item.collectionInfos.length > 0
                            ? _c("div", { staticClass: "ttl_area" })
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "grid grid_15 ib edit_list" },
                            [
                              _vm._l(
                                item.collectionInfos,
                                function (card, index) {
                                  return [
                                    item.isViewAll ||
                                    index < 8 ||
                                    _vm.showButtons
                                      ? _c(
                                          "li",
                                          {
                                            key: card.id,
                                            staticClass: "col col_3 edit_node",
                                          },
                                          [
                                            _c("CollectionsCard", {
                                              attrs: {
                                                itemData: card,
                                                "show-badge": true,
                                                showButtons: _vm.showButtons,
                                                isAllChecked: _vm.isAllChecked,
                                              },
                                              on: {
                                                setChecked: _vm.setChkItem,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          !_vm.showButtons &&
                          item.collectionInfos &&
                          item.collectionInfos.length > 8
                            ? _c("div", { staticClass: "view_btn_area" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "view_btn",
                                    class: { is_active: item.isViewAll },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewBtn(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.isViewAll ? "Fold" : "View All"
                                        ) +
                                        " "
                                    ),
                                    _c("ArwSelect", {
                                      attrs: {
                                        "aria-hidden": "true",
                                        "stroke-color": "var(--disabled-colr)",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "btn_group",
                        class: { mob_style: _vm.showButtons },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "btn_area" },
                          [
                            _vm.showButtons
                              ? _c("div", { staticClass: "input_area check" }, [
                                  _c("div", { staticClass: "input_box" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "allCheckbox" } },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.isAllChecked,
                                              expression: "isAllChecked",
                                            },
                                          ],
                                          attrs: {
                                            id: "allCheckbox",
                                            type: "checkbox",
                                            label: "isAllChecked",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.isAllChecked
                                            )
                                              ? _vm._i(_vm.isAllChecked, null) >
                                                -1
                                              : _vm.isAllChecked,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a = _vm.isAllChecked,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.isAllChecked =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.isAllChecked = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.isAllChecked = $$c
                                                }
                                              },
                                              _vm.allSelect,
                                            ],
                                          },
                                        }),
                                        _c("span", [_vm._v("All")]),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "txt_selected",
                                        attrs: { role: "status" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedCollections.length
                                          ) + " selected"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.showButtons
                              ? [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn w_xs h_l strong",
                                      on: { click: _vm.play },
                                    },
                                    [_vm._v("Play")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn w_xs h_l basic",
                                      on: { click: _vm.download },
                                    },
                                    [_vm._v(" Download ")]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "play dv_mob",
                                      on: { click: _vm.play },
                                    },
                                    [_c("IcPlayS")],
                                    1
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "download dv_mob",
                                      on: { click: _vm.download },
                                    },
                                    [_c("IcMobDownload")],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  2
                )
              : _c("section", { staticClass: "collection_list_sec" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "collection_list_sec_body" }, [
                    _c("div", { staticClass: "guide_ui no_data" }, [
                      _c("div", { staticClass: "img_area" }, [
                        _c("picture", [
                          _c("source", {
                            attrs: {
                              media: "(max-width: 600px)",
                              type: "image/png",
                              srcset: `${require("@/assets/img/img_mob_illust_nodata_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nodata_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nodata.png")}`,
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/img_illust_nodata.png"),
                              alt: "nodata",
                            },
                          }),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.userName) +
                            " doesn’t own Any Artworks yet!"
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn w_xl h_l strong",
                          on: {
                            click: function ($event) {
                              return _vm.goMarketPlaceItemList()
                            },
                          },
                        },
                        [_vm._v(" Go to Marketplace ")]
                      ),
                    ]),
                  ]),
                ]),
          ])
        : _vm._e(),
      _c("ErrorModal"),
      _c("LoadingModal", {
        attrs: { isLoading: _vm.isLoading, message: "Wait for Downloads..." },
      }),
      _vm.isShowDetailView
        ? _c("DetailViewer", {
            attrs: {
              collections: _vm.selectedCollections,
              currentIndex: 0,
              isFullScreen: true,
              singleVideoLoop: _vm.selectedCollections.length == 1,
            },
            on: { hide: _vm.hide },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "collection_list_sec_head" }, [
      _c("div", { staticClass: "ttl_group" }, [
        _c("div", { staticClass: "ttl_area" }, [
          _c("h2", { staticClass: "ttl" }, [_vm._v("My Collections")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }