var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edition_slide_wrap type_img" },
    [
      _c(
        "swiper",
        {
          ref: "itemSwiper",
          staticClass: "edit_list",
          class: _vm.addClass,
          attrs: { options: _vm.slideOpt },
        },
        _vm._l(_vm.itemData, function (item) {
          return _c(
            "swiper-slide",
            { key: item.artworkId, staticClass: "edit_node" },
            [
              _c("div", { staticClass: "edit_item" }, [
                _c("div", { staticClass: "item_head" }, [
                  _c("div", { staticClass: "thumb_area" }, [
                    _c("img", {
                      attrs: {
                        src: `${item.rsrcUrl}`,
                        width: 598,
                        alt: "Capture of Artwork for the " + item.title,
                      },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "control_area pagination_area type_dot" }),
      _c("div", { staticClass: "control_area slide_nav_area" }, [
        _c("button", { staticClass: "btn type_ic prev" }, [_c("ArwPrev")], 1),
        _c("button", { staticClass: "btn type_ic next" }, [_c("ArwNext")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }