// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../assets/css/detail.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.clickable[data-v-21284533] {
  cursor: pointer;
}
.textUnderLine[data-v-21284533] {
  text-decoration: underline;
}
.item[data-v-21284533] {
  position: relative;
}
.item .ic_area[data-v-21284533] {
  position: absolute;
  z-index: 10;
}
.item .ic_area[data-v-21284533] {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.item .ic_area.type_video[data-v-21284533] {
  right: 7px;
  top: 7px;
}
.isDeactive[data-v-21284533] {
  opacity: 0.2;
  pointer-events: none;
}
/* edition list in marketplace detail page at PC, space increase between editionId and owner name td - request by hyLee(Pm) */
.tbl_area .tbl .own[data-v-21284533] {
  padding-left: 40px;
}
.profile_info[data-v-21284533] {
  cursor: default;
}
.ethName[data-v-21284533] {
  cursor: default;
}
.tbl.isLoading[data-v-21284533] {
  display: flex;
  flex-flow: column;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
