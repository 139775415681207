var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcLink",
        "icon-label": _vm.iconLabel,
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        attrs: {
          "stroke-linejoin": "round",
          d: "m12.858 15.954-3.633 3.632a3.4 3.4 0 0 1-4.81 0 3.4 3.4 0 0 1 0-4.81l3.848-3.848a3.4 3.4 0 0 1 4.81 0l.42.419",
          "data-name": "패스 607",
        },
      }),
      _c("path", {
        attrs: {
          "stroke-linejoin": "round",
          d: "m11.144 8.047 3.632-3.632a3.4 3.4 0 0 1 4.811 0 3.4 3.4 0 0 1 0 4.81l-3.848 3.849a3.4 3.4 0 0 1-4.811 0l-.415-.416",
          "data-name": "패스 608",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }