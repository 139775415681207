// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*-------------------------------------------------------------------
    ## icon
-------------------------------------------------------------------*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform ease-out 0.3s;
}
.drawer-enter,
.drawer-leave-to {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
.drop-enter-active {
  transition: ease-out 0.3s;
}

.drop-leave-active {
  transition: cubic-bezier(0, 1, 0.5, 1) 0.3s;
}
.drop-enter-to,
.drop-leave {
  max-height: calc((2rem + 3rem) * 5);
}
.drop-enter,
.drop-leave-to {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
