var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcDownload",
        "icon-label": _vm.iconLabel,
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [
      _c("path", { attrs: { "data-name": "Path 195", d: "M20.5 20h-17" } }),
      _c("path", { attrs: { "data-name": "Path 197", d: "M12.001 15V4" } }),
      _c("path", {
        attrs: { "data-name": "Path 2367", d: "m6.5 10 5.5 5 5.5-5" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }