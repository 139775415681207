var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icExpand",
        "icon-label": _vm.iconLabel,
        width: 14,
        height: 12.449,
        viewBox: "0 0 14 12.449",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("g", { attrs: { "data-name": "그룹 3597" } }, [
        _c("path", {
          attrs: { d: "m2 6.6 5 5 5-5", "data-name": "패스 3702" },
        }),
        _c("path", { attrs: { d: "M0 .6h14", "data-name": "패스 3703" } }),
        _c("path", { attrs: { d: "M7 .6v10.383", "data-name": "패스 3704" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }