var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "footer_page terms_page", attrs: { id: "contents" } },
    [
      _c("div", { staticClass: "set_inner" }, [
        _c("div", { staticClass: "page_ttl_area" }, [
          _c("h1", { staticClass: "page_ttl" }, [
            _vm._v(_vm._s(_vm.termsTitle)),
          ]),
        ]),
        _vm.isLoading
          ? _c("div", [_c("LoadingSpinner")], 1)
          : _c("div", [
              _c("section", { staticClass: "footer_info_sec" }, [
                _c("div", { staticClass: "footer_info_sec_head" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.termsContent) },
                  }),
                ]),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }