// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/ic/ic_info.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img_area[data-v-01935112] {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot[data-v-01935112] {
  margin-top: 4.5rem;
}
.desc[data-v-01935112] {
  font-weight: 300;
}
.drop_ui[data-v-01935112] {
  position: relative;
  display: flex;
  margin-top: 2.8rem;
}
.drop_btn[data-v-01935112] {
  width: 100%;
  padding-right: 0px !important;
  background: #2e2e2e;
}
.drop_btn.is_border[data-v-01935112] {
  border: 1px solid #e31f1f;
}
.drop_ui .drop_btn[data-v-01935112]::-moz-placeholder {
  color: #6c6c6c !important;
}
.drop_ui .drop_btn[data-v-01935112]::placeholder {
  color: #6c6c6c !important;
}
/* .drop_btn.is_pc {
  min-width: 300px !important;
  width: 300px !important;
  min-width: 0 !important;
  text-align: left;
} */
.inputbox[data-v-01935112] {
  outline: 0 !important;
}
.drop_btn.is_pc.inputbox[data-v-01935112] {
  width: 215px;
  min-width: 215px;
  margin-right: 10px;
}
.drop_btn.inputbox[data-v-01935112]::-moz-placeholder {
  color: var(--txt-gray-2);
}
.drop_btn.inputbox[data-v-01935112]::placeholder {
  color: var(--txt-gray-2);
}
.drop_btn.has_arw[data-v-01935112] {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.drop_box.is_pc[data-v-01935112] {
  letter-spacing: -1px;
  width: 335px !important;
}
.input_area.check label[data-v-01935112] {
  text-align: left;
  padding-left: 0px !important;
}
.drop_op_list[data-v-01935112] {
  position: absolute;
  top: unset !important;
  bottom: unset !important;
}
.is_scroll[data-v-01935112] {
  top: unset !important;
  height: 115px !important;
  overflow-y: auto;
}
.is_scroll.is_edit[data-v-01935112] {
  height: 98px !important;
}
.is_scroll.is_edit.is_pc[data-v-01935112] {
  height: 113px !important;
}
.error_msg[data-v-01935112] {
  position: absolute;
  left: 0;
  bottom: -2.2rem;
  float: none;
  color: #e31f1f;
  font-size: 1.3rem;
}
.price_area[data-v-01935112] {
  width: 100%;
  margin: 3rem 0;
  padding: 2rem;
  border: 1px solid #535353;
}
.price_area .tit[data-v-01935112] {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 300;
}
.price_area .tit[data-v-01935112]::before {
  content: '';
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
  background-size: contain;
}
.price_box[data-v-01935112] {
  width: 100%;
  margin-top: 1rem;
}
.price_box p[data-v-01935112] {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 3.5rem;
}
.price_box p + p[data-v-01935112] {
  margin-top: 0.8rem;
}
.price_box .desc[data-v-01935112] {
  text-align: left;
  color: #aaa;
  font-size: 1.4rem;
}
.gray_tit[data-v-01935112] {
  color: var(--txt-gray-2);
  margin-right: 10px;
}
.price_tit[data-v-01935112] {
  font-size: 2rem;
  font-weight: 600;
}
.price_box b[data-v-01935112] {
  display: inline-block;
  font-size: 2rem;
  font-weight: 300;
}
.price_box b .unit[data-v-01935112] {
  padding: 0 0.3rem 0 0.1rem;
}
.price_box b .dollars[data-v-01935112] {
  font-size: 1.4rem;
  font-weight: 200;
}
.inner[data-v-01935112] {
  margin-bottom: 10px;
}
.drop_ui .drop_op_list [role='option'][data-v-01935112] {
  padding: 5px 14px !important;
}
.drop_ui.is_pc[data-v-01935112] {
  text-align: left;
}
@media screen and (max-width: 767px) {
.drop_ui[data-v-01935112] {
    flex-direction: column;
}
.drop_ui.select .input_box[data-v-01935112] {
    margin-top: 1rem;
}
.desc[data-v-01935112] {
    font-size: 1.4rem;
}
.price_box p[data-v-01935112] {
    padding: 0;
}
.drop_btn.is_pc.inputbox[data-v-01935112] {
    width: 100%;
    margin-right: 0;
}
.gray_tit[data-v-01935112] {
    font-size: 1.4rem;
}
.error_msg[data-v-01935112] {
    position: relative;
    left: unset;
    bottom: unset;
    margin-top: 0.5rem;
    text-align: left;
}
.zipcode_group .drop_ui[data-v-01935112] {
    margin-top: 0 !important;
}
.modal .modal_body + .modal_foot[data-v-01935112] {
    margin-top: 3rem;
}
.modal_foot .btn_area[data-v-01935112] {
    display: flex;
}
.modal_foot .btn_area button[data-v-01935112] {
    flex: 1;
    min-width: auto;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
