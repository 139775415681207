import { render, staticRenderFns } from "./MarketplaceDetailView.vue?vue&type=template&id=21284533&scoped=true"
import script from "./MarketplaceDetailView.vue?vue&type=script&lang=js"
export * from "./MarketplaceDetailView.vue?vue&type=script&lang=js"
import style0 from "./MarketplaceDetailView.vue?vue&type=style&index=0&id=21284533&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21284533",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data001/jenkins/home/lgartlab/workspace/lgart-dev-pcapp/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21284533')) {
      api.createRecord('21284533', component.options)
    } else {
      api.reload('21284533', component.options)
    }
    module.hot.accept("./MarketplaceDetailView.vue?vue&type=template&id=21284533&scoped=true", function () {
      api.rerender('21284533', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketplace/MarketplaceDetailView.vue"
export default component.exports