var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icHedera",
        "icon-label": _vm.iconLabel,
        width: 34,
        height: 37,
        viewBox: "0 0 34 37",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_3893",
            "data-name": "그룹 3893",
            transform: "translate(0 0)",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "패스_3811",
              "data-name": "패스 3811",
              d: "M33,9.249a7.458,7.458,0,0,0-2.734-2.741l-7.783-4.5L20.74,1a7.478,7.478,0,0,0-7.48,0L3.735,6.509c-.173.1-.34.206-.5.321l-.044.032A7.485,7.485,0,0,0,0,12.991V24.007a7.515,7.515,0,0,0,.771,3.313c.068.144.148.288.227.428a7.448,7.448,0,0,0,.844,1.183q.159.185.331.357a7.356,7.356,0,0,0,1.136.937c.136.092.279.18.423.263L13.26,36a7.48,7.48,0,0,0,7.48,0L25.5,33.243l4.764-2.754c.019-.01.035-.023.054-.034q.088-.053.169-.106a7.43,7.43,0,0,0,2.334-2.308c.084-.132.165-.265.239-.4A7.49,7.49,0,0,0,34,24.009V12.993A7.5,7.5,0,0,0,33,9.249Z",
              transform: "translate(0 0.001)",
              fill: "#191919",
              opacity: "0.4",
            },
          }),
          _c("path", {
            attrs: {
              id: "패스_3812",
              "data-name": "패스 3812",
              d: "M27,31H25.125V26.375h-8.25V31H15V16h1.875v4.515h8.25V16H27ZM16.963,24.643h8.249v-2.39H16.963Z",
              transform: "translate(-4 -5)",
              fill: "#fff",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }