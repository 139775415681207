var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit_item type_card" }, [
    _c("div", { staticClass: "item_head" }, [
      _c(
        "div",
        {
          staticClass: "thumb_area",
          attrs: { "aria-hidden": "true", tabindex: "-1" },
          on: { click: _vm.goArtworkDetail },
        },
        [
          _vm.source.small
            ? _c("img", {
                attrs: {
                  src: _vm.source.small,
                  width: 480,
                  alt: _vm.itemData.artworkId ? _vm.itemData.artworkId : "",
                },
              })
            : _c("img", {
                attrs: {
                  src: require("@/assets/img/img_illust_nodata.png"),
                  alt: "nodata",
                },
              }),
        ]
      ),
      _c(
        "div",
        { staticClass: "ic_area type_blockchains" },
        [_vm.itemData.bcNwTp == "10" ? _c("IcHedera") : _c("IcEth")],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn type_ic btn_like",
          class: {
            is_active: _vm.likeStatus,
          },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.likeThis(_vm.itemData)
            },
          },
        },
        [
          !_vm.likeStatus
            ? _c("IcLike", {
                ref: "icLike",
                attrs: { status: !_vm.likeStatus, "icon-color": "#fff" },
              })
            : _c("IcLike", {
                ref: "icLike",
                attrs: { "icon-color": "#ff521d" },
              }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "item_body" }, [
      _c("div", {
        staticClass: "link",
        attrs: { "aria-describedby": _vm.itemData.artworkId },
        on: { click: _vm.goArtworkDetail },
      }),
      _c("div", { staticClass: "basic_info_group" }, [
        _c(
          "button",
          {
            staticClass: "profile_info is_art",
            on: {
              click: function ($event) {
                return _vm.goArtistProfile(_vm.itemData.mbrNo)
              },
            },
          },
          [
            _vm.itemData.artistAvatarInfo &&
            _vm.itemData.artistAvatarInfo.length
              ? _c("span", {
                  staticClass: "profile",
                  style: `background-image: url(${_vm.itemData.artistAvatarInfo[0].resUrl})`,
                })
              : _c("IcProfile", {
                  staticClass: "profile",
                  attrs: { width: 32, height: 32, "stroke-width": 1.1 },
                }),
            _c("IcRing", { staticClass: "ic_ring" }),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.itemData.artistName)),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "ttl_area" }, [
          _c("h3", { staticClass: "ttl", attrs: { id: _vm.itemData.id } }, [
            _vm._v(_vm._s(_vm.itemData.title)),
          ]),
          _c("p", { staticClass: "edit" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.itemData.totalEdition > 1
                    ? `${_vm.itemData.totalEdition} Editions`
                    : `${_vm.itemData.totalEdition} Edition`
                ) +
                " "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }