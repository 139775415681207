var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icWarn",
        "icon-label": _vm.iconLabel,
        width: 60,
        height: 60,
        viewBox: "0 0 60 60",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        staticClass: "st0",
        attrs: { d: "m30 9.5 24 41H6l24-41zM29.7 24.5v14" },
      }),
      _c("path", {
        attrs: {
          d: "M31.3 43c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5",
          fill: _vm.strokeColor,
          "stroke-width": "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }