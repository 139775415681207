var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icRemove",
        "icon-label": _vm.iconLabel,
        width: 40,
        height: 40,
        x: "0",
        y: "0",
        viewBox: "0 0 40 40",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        staticClass: "st2",
        attrs: {
          "stroke-width": "0",
          fill: _vm.strokeColor,
          d: "M1063.2 677.2v15.6h13.6v-15.6h-13.6m-1.2-1.2h16v18h-16v-18z",
          transform: "translate(-1050 -664)",
        },
      }),
      _c("path", {
        staticClass: "st2",
        attrs: {
          "stroke-width": "0",
          fill: _vm.strokeColor,
          d: "M1060 676h20v1.2h-20V676z",
          transform: "translate(-1050 -664)",
        },
      }),
      _c("path", {
        staticClass: "st3",
        attrs: {
          d: "M1066.5 680v10M1070 680v10M1073.5 680v10",
          transform: "translate(-1050 -664)",
        },
      }),
      _c("path", { attrs: { d: "M17.6 9.6h4.8" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }