var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "footer_page help_page", attrs: { id: "contents" } },
    [
      _c("div", { staticClass: "set_inner" }, [
        _vm._m(0),
        _c(
          "section",
          { staticClass: "board_sec" },
          [
            _c("div", { staticClass: "search_wrap" }, [
              _c("div", { staticClass: "input_area search" }, [
                _c("div", { staticClass: "input_box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    ref: "searchRef",
                    attrs: {
                      type: "search",
                      placeholder: "Search help articles…",
                      label: "Search",
                      title: "Search",
                    },
                    domProps: { value: _vm.search },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn_search",
                      on: { click: _vm.handleSearch },
                    },
                    [_c("IcSearch", { staticClass: "ic_search" })],
                    1
                  ),
                  _vm.search !== ""
                    ? _c(
                        "button",
                        {
                          staticClass: "btn type_ic remove_word",
                          attrs: { type: "button" },
                          on: { click: _vm.clear },
                        },
                        [
                          _c("IcCloseS", {
                            attrs: {
                              iconLabel: "Clear search field",
                              "stroke-color": "#fff",
                              width: 20,
                              height: 20,
                              "stroke-width": 1,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm.faqInfos && _vm.resultIndex === 1
              ? [
                  _c("p", { staticClass: "result_num" }, [
                    _vm._v(
                      " " + _vm._s(_vm.faqInfos.length) + " results for “"
                    ),
                    _c("em", [_vm._v(_vm._s(_vm.keyword))]),
                    _vm._v("” in all categories "),
                  ]),
                  _c(
                    "ul",
                    _vm._l(_vm.faqInfos, function (item, index) {
                      return _c("li", { key: index }, [
                        _c(
                          "div",
                          { staticClass: "board_item result" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: `helpCenterDetail`,
                                    query: {
                                      num: item.num,
                                      cateNo: item.cateNo,
                                    },
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "item_head" }, [
                                  _c(
                                    "span",
                                    [
                                      _vm._v("Help Center "),
                                      _c("ArwNextS", {
                                        attrs: {
                                          "stroke-color": "var(--txt-gray)",
                                        },
                                      }),
                                      _vm._v(_vm._s(item.cateName)),
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(_vm._s(item.date))]),
                                ]),
                                _c("div", { staticClass: "item_body" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item_info_area",
                                      attrs: {
                                        id: "help_" + item.id,
                                        role: "text",
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "ttl" }, [
                                        _vm._v(_vm._s(item.title)),
                                      ]),
                                      _c("p", { staticClass: "desc" }, [
                                        _vm._v(
                                          " " + _vm._s(item.content) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "btn_area" }, [
                                  _c("button", [_c("ArwNext")], 1),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              : _vm._e(),
            _vm.resultIndex === 2
              ? [
                  _c("p", { staticClass: "result_num" }, [
                    _vm._v(" 0 results for “"),
                    _c("em", [_vm._v(_vm._s(_vm.keyword))]),
                    _vm._v("” in all categories "),
                  ]),
                  _c("div", { staticClass: "guide_ui no_data" }, [
                    _c("div", { staticClass: "img_area" }, [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(max-width: 600px)",
                            type: "image/png",
                            srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                          },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/img_illust_nosearch.png"),
                            alt: "No Result",
                          },
                        }),
                      ]),
                    ]),
                    _vm._m(1),
                    _c(
                      "a",
                      {
                        staticClass: "btn w_l h_l strong",
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/help")
                          },
                        },
                      },
                      [_vm._v(" Go to Help Center ")]
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page_ttl_area" }, [
      _c("h1", { staticClass: "page_ttl" }, [_vm._v("Help Center")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" We couldn’t find any match for “Search word.”"),
      _c("br"),
      _vm._v(" Try another keyword. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }