var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner_wrap" },
    [
      _c(
        "swiper",
        {
          ref: "mvSwiper",
          staticClass: "banner_list",
          class: _vm.addClass,
          attrs: { options: _vm.slideOpt },
        },
        _vm._l(_vm.itemData, function (banner, i) {
          return _c(
            "swiper-slide",
            { key: "banner" + i, staticClass: "banner_node" },
            [
              banner.type === "banner"
                ? _c(
                    "button",
                    {
                      staticClass: "banner_item",
                      class: { is_mobile: _vm.$isMobile() },
                      staticStyle: { width: "100%", "text-align": "left" },
                      style: `background-image: url(${banner.resourceInfo.resUrl})`,
                      attrs: { title: banner.eventTitle },
                      on: {
                        click: function ($event) {
                          return _vm.accessToBanner(banner)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "set_inner" }, [
                        _c("div", { staticClass: "txt_area" }, [
                          banner.eventTitle
                            ? _c("h2", { staticClass: "ttl" }, [
                                _vm._v(" " + _vm._s(banner.eventTitle) + " "),
                              ])
                            : _vm._e(),
                          banner.desc
                            ? _c("p", { staticClass: "desc" }, [
                                _vm._v(_vm._s(banner.desc)),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : banner.mimeType === "image/jpeg"
                ? _c(
                    "button",
                    {
                      staticClass: "banner_item",
                      staticStyle: { width: "100%", "text-align": "left" },
                      style: `background-image: url(${_vm.checkImageType(
                        banner.imageInfos
                      )})`,
                      attrs: { title: banner.title },
                      on: {
                        click: function ($event) {
                          return _vm.goCuration(banner.dropsId)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "set_inner" },
                        [
                          banner.type == "live"
                            ? _c("CountDown", {
                                attrs: { "date-time": banner.dropsEndDate },
                              })
                            : _c("div", { staticClass: "txt_area" }, [
                                _c("p", [_vm._v("Upcoming Drops")]),
                                _c("h4", { staticClass: "upcomingDate" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.setUpcomingDateStr(
                                          banner.dropsId,
                                          banner.dropsStartDate
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                          _c("div", { staticClass: "txt_area" }, [
                            _c("h2", { staticClass: "ttl" }, [
                              _vm._v(_vm._s(banner.artistName)),
                            ]),
                            _c("p", { staticClass: "desc" }, [
                              _vm._v(_vm._s(banner.title)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "banner_item",
                      staticStyle: { width: "100%", "text-align": "left" },
                      style: `background-image: url(${banner.videoThumbInfos[0].resUrl})`,
                      attrs: { title: banner.title },
                      on: {
                        click: function ($event) {
                          return _vm.goCuration(banner.dropsId)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "set_inner" },
                        [
                          banner.type == "live"
                            ? _c("CountDown", {
                                attrs: { "date-time": banner.dropsEndDate },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "txt_area" }, [
                            _c("h2", { staticClass: "ttl" }, [
                              _vm._v(_vm._s(banner.artistName)),
                            ]),
                            _c("p", { staticClass: "desc" }, [
                              _vm._v(_vm._s(banner.title)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
            ]
          )
        }),
        1
      ),
      _c("div", { staticClass: "set_inner" }, [
        _c("div", { staticClass: "controller_group" }, [
          _c("div", { staticClass: "control_area pagination_area type_dot" }),
          _vm.isPlay
            ? _c(
                "button",
                {
                  staticClass: "btn type_ic play",
                  on: { click: _vm.playPause },
                },
                [_c("IcPauseS")],
                1
              )
            : _vm._e(),
          !_vm.isPlay
            ? _c(
                "button",
                {
                  staticClass: "btn type_ic play",
                  on: { click: _vm.playPause },
                },
                [_c("IcPlayS")],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "control_area slide_nav_area" }, [
            _c(
              "button",
              { staticClass: "btn type_ic prev" },
              [_c("ArwPrevS")],
              1
            ),
            _c(
              "button",
              { staticClass: "btn type_ic next" },
              [_c("ArwNextS")],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }