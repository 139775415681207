var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCamera",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        strokeColor: _vm.strokeColor,
        "stroke-width": "1.2",
      },
    },
    [
      _c("path", { attrs: { d: "M7.5 11.3h2v.2h-2v-.2z" } }),
      _c("path", {
        attrs: {
          d: "M18 6.4h-6.2l-1.1 2H4.6v14.9h20.8V8.4h-6.2l-1.2-2z",
          "stroke-linejoin": "round",
        },
      }),
      _c("circle", { attrs: { cx: "15.1", cy: "15.9", r: "4.1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }