var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showEthProgress } },
    [
      _c("div", { staticClass: "modal", attrs: { role: "dialog" } }, [
        _vm._m(0),
        _c("div", { staticClass: "modal_body" }, [
          _c("div", { staticClass: "img_area" }, [_c("LoadingSpinner")], 1),
          _c(
            "p",
            { staticClass: "desc" },
            [
              _vm._v(" Your Purchase is securely complete!"),
              _c("br"),
              _vm._v("This may take some time due to network speed."),
              _c("br"),
              _c("br"),
              _vm._v(" You can check your purchase status in"),
              _c("br"),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: "/mylist/activities" } },
                [_vm._v("My Account : Activities")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "modal_foot" }, [
          _c("div", { staticClass: "btn_area" }, [
            _c(
              "button",
              { staticClass: "btn w_s h_m strong", on: { click: _vm.close } },
              [_vm._v("Close")]
            ),
          ]),
        ]),
        _c(
          "button",
          { staticClass: "btn type_ic close_modal", on: { click: _vm.close } },
          [_c("IcCloseM")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl" }, [_vm._v("Purchase In Progress")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }