var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icItems",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-width": 1.2,
        "stroke-color": _vm.strokeColor,
      },
    },
    [
      _c("path", {
        attrs: { "data-name": "Rectangle 3707", d: "M5.5 9.5h19v14h-19z" },
      }),
      _c("path", { attrs: { "data-name": "Path 161", d: "M8 5.5h14" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }