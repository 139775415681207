// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width: 1200px) {
.ic-info[data-v-36456117] {
    margin-top: 1px;
    margin-left: 35px;
}
}
.is_active[data-v-36456117]:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--main-color);
  pointer-events: none;
}
/* .swiper .swiper-slide {
  height: auto;
} */
.swiper-slide img[data-v-36456117] {
  width: auto;
  /* height: 200px; */
  aspect-ratio: 16/9;
  -o-object-fit: contain;
     object-fit: contain;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
