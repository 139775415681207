var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcDownload",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [_c("path", { attrs: { d: "m7 13.3 8 8 8-8M15 21.3v-17M26 25.7H4" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }