// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img_wrap .btn_area[data-v-64a2cb94] {
  margin-top: 2.4rem;
}
.img_wrap .btn_area .btn i[data-v-64a2cb94] {
  margin-right: 1rem;
  vertical-align: -6px;
}
.input_area.textarea[data-v-64a2cb94] {
  margin-top: 4rem;
}
.input_area.textarea textarea[data-v-64a2cb94] {
  min-height: 14rem;
}
.modal .modal_body + .modal_foot[data-v-64a2cb94] {
  margin-top: 2.4rem;
}
.modal_foot .btn i[data-v-64a2cb94] {
  margin-right: 1rem;
  vertical-align: -6px;
}
@media screen and (max-width: 767px) {
.input_area.textarea .input_box[data-v-64a2cb94] {
    width: 100%;
}
.modal .btn.w_xl[data-v-64a2cb94] {
    min-width: 18rem;
}
.img_wrap .btn_area[data-v-64a2cb94],
  .modal .modal_body + .modal_foot[data-v-64a2cb94] {
    margin-top: 2rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
