var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "footer_page notice_page", attrs: { id: "contents" } },
    [
      _c("div", { staticClass: "set_inner" }, [
        _vm._m(0),
        _c(
          "section",
          { staticClass: "board_sec" },
          [
            _c(
              "ul",
              _vm._l(_vm.noticeInfos, function (item) {
                return _c("li", { key: item.num }, [
                  _c(
                    "div",
                    { staticClass: "board_item" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            "aria-describedby": "board" + item.num,
                            to: {
                              name: "noticeDetail",
                              params: { item: item },
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "item_head" }, [
                            _c("span", [_vm._v(_vm._s(item.num))]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("UsaFormat")(
                                    _vm._f("GmtToTz")(item.writeDate)
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "item_body" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item_info_area",
                                attrs: { id: "board" + item.num, role: "text" },
                              },
                              [
                                _c("p", { staticClass: "ttl" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("p", { staticClass: "desc" }, [
                                  _vm._v(
                                    " " + _vm._s(item.contentSummary) + " "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "btn_area only_pc" }, [
                            _c("button", [_c("ArwNext")], 1),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
            _c("Pagination", {
              attrs: {
                totalCount: _vm.totalCount,
                paginationRange: _vm.maxViewCount,
                pageSize: "10",
              },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page_ttl_area" }, [
      _c("h1", { staticClass: "page_ttl" }, [_vm._v("Notice Board")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }