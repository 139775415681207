var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_list_page favorites_page", attrs: { id: "contents" } },
    [
      _c(
        "div",
        { staticClass: "set_inner clearFix" },
        [
          _c("h1", { staticClass: "page_ttl only_pc" }, [_vm._v("My Account")]),
          _c("h1", { staticClass: "page_ttl dv_tab" }, [_vm._v("Favorites")]),
          _c("AccountMenu"),
          _vm.favoriteInfos
            ? _c("div", { staticClass: "min_inner" }, [
                _c("section", { staticClass: "favorites_sec" }, [
                  _c("div", { staticClass: "favorites_sec_head" }, [
                    _c("h2", { staticClass: "ttl" }, [
                      _c("em", [_vm._v(_vm._s(_vm.totalCount))]),
                      _vm._v(" Collections "),
                    ]),
                  ]),
                  _vm.totalCount > 0
                    ? _c("div", { staticClass: "favorites_sec_body" }, [
                        _c(
                          "ul",
                          { staticClass: "grid grid_15 ib edit_list" },
                          _vm._l(_vm.favoriteInfos, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                staticClass: "col col_4 edit_node",
                              },
                              [
                                _c("FavoritesItem", {
                                  attrs: { itemData: item },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ])
                    : _c("div", { staticClass: "favorites_sec_body" }, [
                        _c("div", { staticClass: "guide_ui no_data" }, [
                          _c("div", { staticClass: "img_area" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  media: "(max-width: 600px)",
                                  type: "image/png",
                                  srcset: `${require("@/assets/img/img_mob_illust_nofavorite_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nofavorite_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nofavorite.png")}`,
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/img_illust_nofavorite.png"),
                                  alt: "",
                                },
                              }),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v("You don’t have any favorite artworks yet!"),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn w_xl h_l strong",
                              on: {
                                click: function ($event) {
                                  return _vm.goMarketPlaceItemList()
                                },
                              },
                            },
                            [_vm._v(" Go to Marketplace ")]
                          ),
                        ]),
                      ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }