var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "sub_header", attrs: { id: "subHeader" } },
    [
      _c("article", { staticClass: "page_sec" }, [
        _c("div", { staticClass: "page_head" }, [
          _c("h1", { staticClass: "page_ttl" }, [_vm._v(_vm._s(_vm.setTitle))]),
          _c(
            "button",
            { staticClass: "btn type_ic close_page", on: { click: _vm.close } },
            [_c("IcCloseS")],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }