var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icFootTwitter",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "그룹_3881",
            "data-name": "그룹 3881",
            transform: "translate(-1753.998 -114)",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "twitter_5968958",
              d: "M25.088,8.655,32.533,0H30.769L24.3,7.515,19.14,0H13.185l7.808,11.364L13.185,20.44h1.764L21.776,12.5,27.23,20.44h5.955l-8.1-11.785Zm-2.417,2.809-.791-1.132-6.295-9H18.3l5.08,7.267.791,1.132,6.6,9.445H28.06l-5.389-7.707Z",
              transform: "translate(1745.813 119)",
              fill: "#aaa",
            },
          }),
          _c("rect", {
            attrs: {
              id: "사각형_4492",
              "data-name": "사각형 4492",
              width: "30",
              height: "30",
              transform: "translate(1753.998 114)",
              fill: "none",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }