var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showQrModal } },
    [
      _c(
        "div",
        {
          staticClass: "modal qrModal",
          attrs: {
            id: _vm.modalId,
            role: "dialog",
            "aria-labelledby": _vm.modalId + "_title",
            "aria-describedby": _vm.modalId + "_desc",
          },
        },
        [
          _c("div", { staticClass: "modal_head" }, [
            _c(
              "h3",
              {
                staticClass: "modal_ttl",
                attrs: { id: _vm.modalId + "_title" },
              },
              [_vm._v(" " + _vm._s(_vm.modalData.title) + " ")]
            ),
            _c("p", { attrs: { id: _vm.modalId + "_desc" } }, [
              _vm._v(_vm._s(_vm.modalData.desc)),
            ]),
          ]),
          _c("div", { staticClass: "modal_body" }, [
            _c("div", { staticClass: "qr_group" }, [
              _c("div", { staticClass: "img_area" }, [
                _c(
                  "div",
                  { staticClass: "qr-code" },
                  [
                    _c("qrcode-vue", {
                      attrs: {
                        value: _vm.qrcodeUrl,
                        size: _vm.qrcodeSize,
                        level: "L",
                        background: "#fff",
                        margin: "10",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "modal_foot" }, [
            this.modalData.title === "Purchase"
              ? _c("p", { staticClass: "txt box_txt" }, [
                  _vm._v(
                    " After the purchase is complete, You can find the purchased artwork in "
                  ),
                  _c("span", [_vm._v("[My Collections]")]),
                  _vm._v(" or "),
                  _c(
                    "span",
                    [
                      _vm._v("[My Account] "),
                      _c("ArwNextS", { attrs: { "stroke-width": 1.2 } }),
                      _vm._v(" [Profile]"),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "button",
            {
              staticClass: "btn type_ic close_modal",
              on: { click: _vm.close },
            },
            [_c("IcCloseM")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }