var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icVideo",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        strokeColor: _vm.strokeColor,
        "stroke-width": "1.2",
      },
    },
    [
      _c("path", {
        attrs: { d: "m20.4 18.4 6 2.6V9.6l-6 2.8V8.6H3.6v13.8h16.8v-4z" },
      }),
      _c("circle", {
        attrs: {
          cx: "7.5",
          cy: "12.5",
          r: "1.5",
          fill: _vm.strokeColor,
          "stroke-width": "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }