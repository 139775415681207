var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "activities_item_list_wrap" }, [
    _c(
      "ul",
      { staticClass: "thumb_list activities_item_list" },
      _vm._l(_vm.itemData, function (item) {
        return _c("li", { key: item.id, staticClass: "thumb_node list_note" }, [
          _c("div", { staticClass: "thumb_item type_list" }, [
            _c("div", { staticClass: "card_frame" }, [
              _c("div", { staticClass: "card_head" }, [
                _c("div", { staticClass: "thumb_area" }, [
                  item.mimeType === "image/jpeg"
                    ? _c("img", {
                        attrs: {
                          srcset: `${item.imageInfos[0].resUrl} 768w, ${item.imageInfos[1].resUrl}`,
                          src: item.imageInfos[0].resUrl,
                          width: 262,
                          alt: item.title ? item.title : "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          srcset: `${item.videoThumbInfos[0].resUrl} 768w, 
                      ${item.videoThumbInfos[1].resUrl}`,
                          src: item.videoThumbInfos[0].resUrl,
                          width: 279,
                          alt: item.title ? item.title : "",
                        },
                      }),
                  item.type === "video"
                    ? _c(
                        "span",
                        { staticClass: "ic_area type_video" },
                        [_c("IcVideo")],
                        1
                      )
                    : _vm._e(),
                  item.reject
                    ? _c("div", { staticClass: "reject_area" }, [
                        _c("p", [_vm._v("Reject")]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "card_body" }, [
                _c(
                  "div",
                  { staticClass: "artist_info_group profile_info is_art" },
                  [
                    item.artistImg
                      ? _c("span", {
                          staticClass: "profile",
                          style: `background-image: url('${item.artistImg}')`,
                        })
                      : _c("IcProfile", {
                          staticClass: "profile",
                          attrs: { width: 24, height: 24 },
                        }),
                    _c("IcRing", { staticClass: "ic_ring" }),
                    _c(
                      "router-link",
                      { attrs: { to: `/mylist/artist/${item.artistMbrNo}` } },
                      [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.artistName)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "ttl_area" }, [
                  _c("h2", { staticClass: "ttl" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ]),
                _c("div", { staticClass: "contract_info_group" }, [
                  _c("span", [_vm._v("Contract Address")]),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.goContract(item.bcNwTp, item.contractAddr)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.addrEllipsis(item.contractAddr)) + " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "info_area" }, [
                  _c("div", { staticClass: "blockchains_info_group" }, [
                    _c(
                      "p",
                      { staticClass: "blockchains" },
                      [
                        _vm.window.width > 1024
                          ? [
                              item.bcNwTp == "10"
                                ? _c("IcHederaS", {
                                    staticStyle: {
                                      width: "2.2rem",
                                      height: "2.4rem",
                                    },
                                  })
                                : _c("IcEthS", {
                                    staticStyle: {
                                      width: "2.2rem",
                                      height: "2.4rem",
                                    },
                                  }),
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    item.bcNwTp == "10" ? "Hedera" : "Ethereum"
                                  )
                                ),
                              ]),
                            ]
                          : [
                              item.bcNwTp == "10"
                                ? _c("IcHedera")
                                : _c("IcEth"),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "owner_info_group" }, [
                    item.totalEdition > 1
                      ? _c("p", { staticClass: "editions" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.editionId) +
                              " of " +
                              _vm._s(item.totalEdition) +
                              " Editions "
                          ),
                        ])
                      : _c("p", { staticClass: "editions" }, [
                          _vm._v("1 Edition"),
                        ]),
                    _c("dl", [
                      _c("dt", [_vm._v("Owned by")]),
                      _c("dd", { staticClass: "profile_info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.ownerName)),
                        ]),
                      ]),
                    ]),
                    _c("p", { staticClass: "time" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("UsaFormat")(
                              _vm._f("GmtToTz")(item.createDate)
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card_foot" }, [
                _c("div", { staticClass: "sale_info_group" }, [
                  _c("dl", [
                    item.activityType == 20
                      ? _c("dt", { class: "type_1" }, [
                          _vm._v(" " + _vm._s("Purchased") + " "),
                        ])
                      : item.activityType == 30
                      ? _c("dt", { class: "type_2" }, [
                          _vm._v(" " + _vm._s("Sold") + " "),
                        ])
                      : item.activityType == 70
                      ? _c("dt", { class: "type_2" }, [
                          _vm._v(" " + _vm._s("Restored") + " "),
                        ])
                      : item.activityType == 60
                      ? _c("dt", { class: "type_2" }, [
                          _vm._v(" " + _vm._s("Transferred") + " "),
                        ])
                      : _vm._e(),
                    _c("dd", { staticClass: "price" }, [
                      item.bcNwTp == "10"
                        ? _c("b", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("setNum")(item.price)) +
                                " USDC "
                            ),
                          ])
                        : _c("b", [
                            _vm._v(_vm._s(item.price)),
                            _c("span", { staticClass: "unit" }, [_vm._v("Ξ")]),
                            _c("span", { staticClass: "dollars" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm._f("crtpyAbbreviate")(
                                      item.priceToEthUsd
                                    )
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }