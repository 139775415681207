// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.upcomingDate[data-v-6fd5ad31] {
  font-size: 2.3rem;
  margin-bottom: 30px;
}
.est[data-v-6fd5ad31] {
  font-size: 1.5rem;
}
.banner_item[data-v-6fd5ad31] {
  cursor: pointer;
}
.banner_item.is_mobile[data-v-6fd5ad31]:before {
  background: none !important;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
