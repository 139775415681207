var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showUsdc } },
    [
      _c(
        "div",
        {
          ref: "InsufficientBalance",
          staticClass: "modal",
          attrs: {
            id: "InsufficientBalance",
            role: "dialog",
            "aria-labelledby": "InsufficientBalance_title",
            "aria-describedby": "InsufficientBalance_desc",
          },
        },
        [
          _c("div", { staticClass: "modal_head" }, [
            _c(
              "h3",
              {
                staticClass: "modal_ttl",
                attrs: { id: "InsufficientBalance_title" },
              },
              [_vm._v(" " + _vm._s(_vm.modalData.title) + " ")]
            ),
          ]),
          _c("div", { staticClass: "modal_body" }, [
            _c("div", { staticClass: "img_area" }, [
              _c("picture", [
                _c("img", {
                  attrs: {
                    srcset: `${require("@/assets/img/illust_Insufficient_balance.png")} 4x, ${require("@/assets/img/illust_Insufficient_balance.png")} 3x, ${require("@/assets/img/illust_Insufficient_balance.png")}`,
                    src: require("@/assets/img/illust_Insufficient_balance.png"),
                    width: "164",
                    alt: "illust_Insufficient_balance",
                  },
                }),
              ]),
            ]),
            _c(
              "p",
              {
                staticClass: "desc",
                attrs: { id: "InsufficientBalance_desc" },
              },
              [_vm._v(" " + _vm._s(_vm.modalData.desc) + " ")]
            ),
          ]),
          _c("div", { staticClass: "modal_foot" }, [
            _c("div", { staticClass: "btn_area" }, [
              _c(
                "button",
                {
                  staticClass: "btn w_s h_m strong",
                  on: { click: _vm.openApp },
                },
                [_vm._v(" " + _vm._s(_vm.modalData.button) + " ")]
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn type_ic close_modal",
              on: { click: _vm.close },
            },
            [_c("IcCloseM")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }