var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "main_page", attrs: { id: "contents" } }, [
    _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Main")]),
    _c(
      "section",
      { staticClass: "main_visual_sec" },
      [
        _vm.bannerData.length > 0
          ? _c("BannerSlide", {
              staticClass: "main_visual_wrap",
              attrs: {
                "slide-opt": _vm.mainVisualOpt,
                itemData: _vm.bannerData,
                "add-class": "mv_banner_list",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "set_inner" }, [
      _vm.liveInfos && _vm.liveInfos.length
        ? _c("section", { staticClass: "list_sec drops_sec" }, [
            _vm.openService
              ? _c("header", { staticClass: "sec_head" }, [_vm._m(0)])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "sec_body clearFix" },
              [
                _c("div", { staticClass: "info_wrap" }, [
                  _c("div", { staticClass: "profile_info" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.liveInfos[0].artistName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "ttl_area" }, [
                    _c("h3", { staticClass: "ttl" }, [
                      _vm._v(_vm._s(_vm.liveInfos[0].title)),
                    ]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(_vm._s(_vm.liveInfos[0].artworkDescription)),
                    ]),
                  ]),
                  _vm.openService
                    ? _c("div", { staticClass: "btn_area" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn w_s h_l strong",
                            on: { click: _vm.goCuration },
                          },
                          [_vm._v(" View More ")]
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("EditionImgSlide", {
                  attrs: {
                    "item-data": _vm.liveDropsThumbs,
                    "slide-opt": _vm.liveDropSlidOpt,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("section", { staticClass: "list_sec activity_sec" }, [
        _c(
          "header",
          { staticClass: "sec_head" },
          [
            _vm._m(1),
            _c(
              "router-link",
              {
                staticClass: "btn h_m type_line float_el",
                attrs: { to: "marketplace" },
              },
              [_c("b", [_vm._v("More")])]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "sec_body" }, [
          _c("div", { staticClass: "market_item_wrap activity_item_wrap" }, [
            _c(
              "ul",
              { staticClass: "grid grid_15 edit_list" },
              [
                _vm._l(_vm.recentActivityItems, function (item, i) {
                  return [
                    i < 5
                      ? _c(
                          "li",
                          { key: item.id, staticClass: "col edit_node" },
                          [
                            _c("MarketItem", {
                              attrs: {
                                "item-data": item,
                                "item-index": i,
                                "show-badge": false,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "list_sec market_sec" }, [
        _c("header", { staticClass: "sec_head" }, [
          _vm._m(2),
          _c(
            "button",
            {
              staticClass: "btn h_m type_line float_el",
              attrs: { to: "marketplace" },
              on: { click: _vm.goMarketplace },
            },
            [_c("b", [_vm._v("More")])]
          ),
        ]),
        _c("div", { staticClass: "sec_body" }, [
          _c("div", { staticClass: "market_item_wrap" }, [
            _c(
              "ul",
              { staticClass: "grid grid_15 ib edit_list" },
              [
                _vm._l(_vm.hotInMarketplaceItems, function (item, i) {
                  return [
                    i < 4
                      ? _c(
                          "li",
                          { key: item.id, staticClass: "col col_3 edit_node" },
                          [
                            _c("MarketItem", {
                              attrs: {
                                "item-data": item,
                                "item-index": i,
                                "show-badge": false,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "list_sec keyword_sec noBottomM" }, [
      _c("div", { staticClass: "set_inner" }, [
        _vm._m(3),
        _c("div", { staticClass: "sec_body" }, [
          _c(
            "ul",
            { staticClass: "tag_list type_big clearFix" },
            _vm._l(_vm.mosKeyword, function (tag, i) {
              return _c("li", { key: "tag_" + i, staticClass: "tag_node" }, [
                _c(
                  "button",
                  {
                    staticClass: "tag_item",
                    on: {
                      click: function ($event) {
                        return _vm.goTagSearch(tag.keywordName)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(tag.keywordName))])]
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ttl_area" }, [
      _c("h2", { staticClass: "ttl" }, [
        _c("b", [_vm._v("Live")]),
        _vm._v(" Drops"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ttl_area" }, [
      _c("h2", { staticClass: "ttl" }, [
        _c("b", [_vm._v("Recent")]),
        _vm._v(" Activities"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ttl_area" }, [
      _c("h2", { staticClass: "ttl" }, [
        _c("b", [_vm._v("Trending")]),
        _vm._v(" in Marketplace"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "sec_head" }, [
      _c("div", { staticClass: "ttl_area" }, [
        _c("h2", { staticClass: "ttl" }, [
          _c("b", [_vm._v("Most")]),
          _vm._v(" Searched Keywords"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }