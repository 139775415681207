// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal_wrap[data-v-b36646b2] {
  overflow: hidden;
}
.modal .modal_body + .modal_foot[data-v-b36646b2] {
  margin-top: 34px;
}
.input_box[data-v-b36646b2] {
  width: 100%;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
