var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icProfile",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 20 20",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [
      _c("circle", {
        staticClass: "st0",
        attrs: { cx: "10", cy: "10", r: "9.4" },
      }),
      _c("path", {
        staticClass: "st0",
        attrs: { d: "M17.2 16.2c-4-4-10.4-4-14.4 0" },
      }),
      _c("circle", {
        staticClass: "st0",
        attrs: { cx: "10.1", cy: "7.6", r: "2.9" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }