var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icRing",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 32 32",
        "icon-color": "none",
        "stroke-color": "none",
        "stroke-width": 0,
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "linearGradient",
        {
          attrs: {
            id: _vm.uniqueId,
            gradientUnits: "userSpaceOnUse",
            x1: "-645.009",
            y1: "389.4",
            x2: "-644.224",
            y2: "388.499",
            gradientTransform: "matrix(32 0 0 -32 20646 12465)",
          },
        },
        [
          _c("stop", { attrs: { offset: "0", "stop-color": "#6BE5D1" } }),
          _c("stop", { attrs: { offset: "1", "stop-color": "#00A7FF" } }),
        ],
        1
      ),
      _c("circle", {
        attrs: {
          cx: "16",
          cy: "16",
          r: "16",
          fill: "url(#" + _vm.uniqueId + ")",
        },
      }),
      _c("circle", {
        attrs: { cx: "16", cy: "16", r: _vm.smallRing, fill: "#1f1f1f" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }