var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icFilter",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": _vm.iconColor,
        "stroke-width": 0,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "m13.2 24.1 3.6-1.8v-7.4l7.7-9.6h-19l7.7 9.6v9.2M12 26V15.2L3 4h24l-9 11.2V23l-6 3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }