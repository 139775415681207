// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.purchase_page[data-v-2abd03e9] {
}
.purchase_sec[data-v-2abd03e9] {
  display: flex;
  min-height: calc(var(--min) * 1px);
  align-items: center;
}
.purchase_sec .btn_area[data-v-2abd03e9] {
  margin-top: 70px;
}
@media screen and (max-width: 600px) {
.purchase_sec .btn_area[data-v-2abd03e9] {
    display: inline-flex;
    margin-top: 5rem;
}
.purchase_sec .btn_area .btn[data-v-2abd03e9] {
    flex: 1;
    min-width: 0;
    max-width: 18rem;
}
}
@media screen and (max-width: 450px) {
.purchase_sec .btn_area[data-v-2abd03e9] {
    display: flex;
    justify-content: center;
}
.purchase_sec .btn_area .btn[data-v-2abd03e9] {
    min-width: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
