var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    {
      staticClass: "my_list_page notification_page",
      attrs: { id: "contents" },
    },
    [
      _c(
        "div",
        { staticClass: "set_inner clearFix" },
        [
          _c("h1", { staticClass: "page_ttl only_pc" }, [_vm._v("My Account")]),
          _c("h1", { staticClass: "page_ttl dv_tab" }, [_vm._v("Settings")]),
          _c("AccountMenu"),
          _c("div", { staticClass: "min_inner" }, [
            _c("section", { staticClass: "notification_sec" }, [
              _vm._m(0),
              _c("div", { staticClass: "notification_sec_body" }, [
                _c("ul", { staticClass: "subscribe_list" }, [
                  _c("li", [
                    _c("div", { staticClass: "subscribe_group" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "input_area switch" }, [
                        _c("div", { staticClass: "input_box" }, [
                          _c("label", { attrs: { for: "chk_reminderCheck" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reminderYn,
                                  expression: "reminderYn",
                                },
                              ],
                              attrs: {
                                id: "chk_reminderCheck",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.reminderYn)
                                  ? _vm._i(_vm.reminderYn, null) > -1
                                  : _vm.reminderYn,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.reminderYn,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.reminderYn = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.reminderYn = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.reminderYn = $$c
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "subscribe_group" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "input_area switch" }, [
                        _c("div", { staticClass: "input_box" }, [
                          _c(
                            "label",
                            { attrs: { for: "chk_statusChangeCheck" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.statusChangeYn,
                                    expression: "statusChangeYn",
                                  },
                                ],
                                attrs: {
                                  id: "chk_statusChangeCheck",
                                  type: "checkbox",
                                  label: "statusChangeCheck",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.statusChangeYn)
                                    ? _vm._i(_vm.statusChangeYn, null) > -1
                                    : _vm.statusChangeYn,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.statusChangeYn,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.statusChangeYn = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.statusChangeYn = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.statusChangeYn = $$c
                                    }
                                  },
                                },
                              }),
                              _c("span"),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_area" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn w_xl h_l strong",
                      on: { click: _vm.changeSettings },
                    },
                    [_vm._v(" Save Changes ")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "delete_account" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/mylist/delete" } },
                      [
                        _vm._v(" Delete Account "),
                        _c("ArwNextS", {
                          attrs: { "stroke-color": "var(--txt-gray)" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notification_sec_head" }, [
      _c("div", { staticClass: "ttl_area" }, [
        _c("h2", { staticClass: "ttl" }, [_vm._v("Notification Settings")]),
        _c("p", [
          _vm._v(
            " LG Art Lab may send you emails regarding important notifications about your account and activities. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info_area" }, [
      _c("p", { staticClass: "subscribe" }, [_vm._v("Reminder emails")]),
      _c("p", [
        _vm._v(
          " Get email notifications when the upcoming drops you have chosen goes live. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info_area" }, [
      _c("p", { staticClass: "subscribe" }, [_vm._v("Status Change emails")]),
      _c("p", [
        _vm._v(
          " Get email notifications for status changes on the artworks you have. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }