var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "footer_page terms_page", attrs: { id: "contents" } },
    [
      _c("div", { staticClass: "set_inner" }, [
        _vm._m(0),
        _vm.isLoading ? _c("div", [_c("LoadingSpinner")], 1) : _vm._e(),
        _vm._m(1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page_ttl_area" }, [
      _c("h1", { staticClass: "page_ttl" }, [_vm._v("About Us")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("section", { staticClass: "board_sec" }, [
        _c("div", { staticClass: "board_sec_head" }, [
          _c("p", { staticClass: "ttl" }, [_vm._v("About LG Art Lab")]),
        ]),
        _c(
          "div",
          {
            staticClass: "board_sec_body",
            staticStyle: {
              border: "0px",
              "padding-bottom": "2rem",
              "line-height": "3.6rem",
            },
          },
          [
            _c("span", [
              _vm._v(
                "LG Art Lab is LG Electronics’ dynamic NFT marketplace curating a diverse collection of digital art accessible through LG Smart TVs and online. "
              ),
            ]),
            _c("br"),
            _c("span", [
              _vm._v(
                "LG Art Lab invites art enthusiasts to collect and trade NFTs of digital artworks using blockchain transactions. "
              ),
            ]),
            _c("br"),
            _c("span", [
              _vm._v(
                "Integrated across multiple devices LG Art Lab enhances accessibility and allows art lovers to enjoy their collection on LG OLED, "
              ),
            ]),
            _c("br"),
            _c("span", [
              _vm._v(
                " the preferred digital canvas. This approach empowers art enthusiasts to securely collect and trade NFTs ensuring transparent verification of each artwork’s creation and ownership. "
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "board_sec_head" }, [
          _c("p", { staticClass: "ttl" }, [_vm._v("Our Approach")]),
          _c(
            "p",
            {
              staticStyle: {
                color: "#ff521d",
                "font-size": "3.2rem",
                "font-weight": "bold",
              },
            },
            [
              _vm._v(
                ' "We display valuable arts that transform living rooms into digital art museums" '
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "board_sec_body", staticStyle: { border: "0px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "20px",
                  "font-weight": "bold",
                  "margin-left": "100px",
                },
              },
              [_vm._v("1. The Art Gallery You Can Fully Enjoy from Home")]
            ),
            _c("br"),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "150px",
                  "margin-right": "150px",
                  "line-height": "3.6rem",
                },
              },
              [
                _c("span", [
                  _vm._v(
                    "We transform houses into a live digital art museum. "
                  ),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "We provide an immersive experience to appreciate art work like a personal exhibition. "
                  ),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "We continue to present the artworks as intended preserving the artist’s vision and quality of the piece on LG Smart TVs. "
                  ),
                ]),
              ]
            ),
            _c("br"),
            _c("br"),
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "20px",
                  "font-weight": "bold",
                  "margin-left": "100px",
                },
              },
              [_vm._v("2. Embrace the Ethos of Display Valuable Arts")]
            ),
            _c("br"),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "150px",
                  "margin-right": "150px",
                  "line-height": "3.6rem",
                },
              },
              [
                _c("span", [
                  _vm._v(
                    "We prioritize a collaborative approach with artists, ensuring their voices are central. "
                  ),
                ]),
                _c("br"),
                _c("span", [
                  _vm._v(
                    "We aim to provide an integrated experience that connects virtual to reality and linking artists and collectors through both online and offline events. We dream this will enhance the relationship between creators and collectors - artists can reach global audiences more easily, and our LG TV customers can experience art n more interactive ways. "
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }