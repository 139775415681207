var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icMember",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 61",
          d: "M25.972 25.279a15.778 15.778 0 0 0-22.28 0",
        },
      }),
      _c("circle", {
        attrs: {
          "data-name": "Ellipse 22",
          cx: "5.863",
          cy: "5.863",
          transform: "translate(8.822 4.721)",
          r: "5.863",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }