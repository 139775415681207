var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_list_page delete_page", attrs: { id: "contents" } },
    [
      _c(
        "div",
        { staticClass: "set_inner clearFix" },
        [
          _c("h1", { staticClass: "page_ttl only_pc" }, [_vm._v("My Account")]),
          _c("h1", { staticClass: "page_ttl dv_tab" }, [_vm._v("Settings")]),
          _c("AccountMenu"),
          _c("div", { staticClass: "min_inner" }, [
            _c("section", { staticClass: "delete_sec" }, [
              _vm._m(0),
              _c("div", { staticClass: "delete_sec_body" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "btn_area" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn basic w_xl h_l dv_mob",
                        attrs: { to: "/mylist/notification" },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn strong w_xl h_l",
                        on: {
                          click: function ($event) {
                            return _vm.checkAccount()
                          },
                        },
                      },
                      [_vm._v(" Delete Account ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "delete_sec_head" }, [
      _c("div", { staticClass: "ttl_area" }, [
        _c("h2", { staticClass: "ttl" }, [_vm._v("Delete Account")]),
        _c("p", [
          _vm._v(
            " If you want to permanently delete your LG Art Lab account, press the button below. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "permanent_box" }, [
      _c("h3", [
        _vm._v(
          " When you delete your account, the account will be permanently removed. "
        ),
      ]),
      _c("p", [_vm._v("When you delete your LG Art Lab account,")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            " You won’t be able to retrieve the information of your activities within LG Art Lab. "
          ),
        ]),
        _c("li", [
          _vm._v(
            " You and other members won’t be able to reuse and restore the username again. "
          ),
        ]),
        _c("li", [
          _vm._v(
            " You can find the NFT Artworks you’ve purchased from LG Art Lab in the Wallypto wallet, but won’t be able to resell them on LG Art Lab even after rejoining it. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }