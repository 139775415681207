var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcFaceBook",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        iconColor: _vm.iconColor,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M19.6 11.5h-3.2V9.4c-.1-.5.3-.9.7-1H19.5V5h-3.1c-2.1-.2-4 1.4-4.2 3.6v2.9h-2V15h2v10h4.2V15h2.8l.4-3.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }