var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_list_page my_user_page", attrs: { id: "contents" } },
    [
      _c(
        "div",
        { staticClass: "set_inner clearFix" },
        [
          _c("h1", { staticClass: "page_ttl" }, [_vm._v("My Account")]),
          _c("AccountMenu", {
            class: { is_show: _vm.window.width <= 1024 },
            attrs: { window: _vm.window.width },
          }),
          _vm.userInfo
            ? _c("div", { staticClass: "min_inner" }, [
                _c("section", { staticClass: "user_sec" }, [
                  _c("div", { staticClass: "user_sec_body" }, [
                    _vm.userInfo.ownrCount > 0
                      ? _c("div", { staticClass: "data_length_area" }, [
                          _c("h2", { staticClass: "ttl" }, [
                            _c("em", [_vm._v(_vm._s(_vm.userInfo.ownrCount))]),
                            _vm._v(" Owned "),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.userInfo.ownrCount > 0
                      ? _c(
                          "ul",
                          { staticClass: "grid grid_15 ib edit_list" },
                          _vm._l(_vm.ownedEditions, function (item, i) {
                            return _c(
                              "li",
                              {
                                key: item + i,
                                staticClass: "col col_4 edit_node",
                              },
                              [
                                _c("ItemCardEdit", {
                                  key: _vm.componentListKey + i,
                                  attrs: {
                                    mbrNo: _vm.mbrNo,
                                    userType: _vm.userType,
                                    itemData: item,
                                    itemIndex: i,
                                    viewType: "profile",
                                    hideOwner: true,
                                  },
                                  on: {
                                    open: _vm.open,
                                    onAppChangeSelling: _vm.onAppChangeSelling,
                                    onSetCloseFocusCard:
                                      _vm.onSetCloseFocusCard,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c(
                          "div",
                          { staticClass: "guide_ui no_data" },
                          [
                            _c("div", { staticClass: "img_area" }, [
                              _c("picture", [
                                _c("source", {
                                  attrs: {
                                    media: "(max-width: 600px)",
                                    type: "image/png",
                                    srcset: `${require("@/assets/img/img_mob_illust_nodata_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nodata_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nodata.png")}`,
                                  },
                                }),
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/img_illust_nodata.png"),
                                    alt: "nodata",
                                  },
                                }),
                              ]),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.userName) +
                                  " doesn’t own any artworks yet!"
                              ),
                            ]),
                            _c(
                              "router-link",
                              { attrs: { to: "/marketplace" } },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn w_xl h_l strong",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goMarketPlaceItemList()
                                      },
                                    },
                                  },
                                  [_vm._v(" Go to Marketplace ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.showQrModal
        ? _c("QrModal", {
            attrs: {
              showQrModal: _vm.showQrModal,
              "modal-data": _vm.changeSelling,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm.showPriceModal
        ? _c("SetPriceModal", {
            attrs: {
              showPriceModal: _vm.showPriceModal,
              editionId: _vm.editionId,
              artworkId: _vm.artworkId,
              "modal-size": "m",
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }