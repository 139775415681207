var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "arwNext",
        "icon-label": _vm.iconLabel,
        width: 40,
        height: 40,
        viewBox: "0 0 40 40",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 2598",
          d: "m14.674 7.999 12 11.429-12 12.572",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }