var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showWallet } },
    [
      _c("div", { staticClass: "modal", attrs: { role: "dialog" } }, [
        _c("div", { staticClass: "modal_head" }, [
          _c("h3", { staticClass: "modal_ttl" }, [_vm._v(_vm._s(_vm.subject))]),
        ]),
        _c("div", { staticClass: "modal_body" }, [
          _c("div", { staticClass: "img_area" }, [
            _c("picture", [
              _c("img", {
                attrs: {
                  srcset: `${require("@/assets/img/img_modal_illust_connectwallet_l.png")} 4x, ${require("@/assets/img/img_modal_illust_connectwallet_m.png")} 3x, ${require("@/assets/img/img_modal_illust_connectwallet.png")}`,
                  src: require("@/assets/img/img_modal_illust_connectwallet.png"),
                  width: "164",
                  alt: "illust_connectwallet",
                },
              }),
            ]),
          ]),
          _c("p", { staticClass: "desc" }, [
            _vm._v(" " + _vm._s(_vm.content) + " "),
          ]),
        ]),
        _c("div", { staticClass: "modal_foot" }, [
          _c("div", { staticClass: "btn_area" }, [
            _c(
              "button",
              {
                staticClass: "btn w_s h_m strong",
                class: {
                  is_loading: _vm.isLoading,
                },
                on: { click: _vm.connectWallet },
              },
              [_vm._v(" " + _vm._s(_vm.btnTxt) + " ")]
            ),
          ]),
        ]),
        _c(
          "button",
          { staticClass: "btn type_ic close_modal", on: { click: _vm.close } },
          [_c("IcCloseM")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }