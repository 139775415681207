import { render, staticRenderFns } from "./AboutUsView.vue?vue&type=template&id=268e22ea&scoped=true"
import script from "./AboutUsView.vue?vue&type=script&lang=js"
export * from "./AboutUsView.vue?vue&type=script&lang=js"
import style0 from "./AboutUsView.vue?vue&type=style&index=0&id=268e22ea&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268e22ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data001/jenkins/home/lgartlab/workspace/lgart-dev-pcapp/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('268e22ea')) {
      api.createRecord('268e22ea', component.options)
    } else {
      api.reload('268e22ea', component.options)
    }
    module.hot.accept("./AboutUsView.vue?vue&type=template&id=268e22ea&scoped=true", function () {
      api.rerender('268e22ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/etc/AboutUsView.vue"
export default component.exports