var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user_item type_card" }, [
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.goArtistProfile(_vm.itemData.mbrNo)
          },
        },
      },
      [
        _c("div", { staticClass: "item_head" }, [
          _c(
            "div",
            {
              staticClass: "thumb_area",
              attrs: { "aria-hidden": "true", tabindex: "-1" },
            },
            [
              _vm.itemData.repInfo && _vm.itemData.repInfo.length
                ? _c("img", {
                    attrs: {
                      src: _vm.itemData.repInfo[0].resUrl,
                      width: 337,
                      height: 189,
                      alt: "artist bacgkround image",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: `${require("/src/assets/img/img_mob_illust_nodata.png")}`,
                      width: 337,
                      height: 189,
                      alt: "nodata",
                    },
                  }),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "item_body", class: { is_pc: !_vm.$isMobile() } },
          [
            _c("div", { staticClass: "basic_info_group" }, [
              _c(
                "div",
                {
                  staticClass: "profile_info is_art",
                  attrs: { tabindex: "0" },
                },
                [
                  _vm.itemData.avatarInfo && _vm.itemData.avatarInfo.length
                    ? _c(
                        "div",
                        {
                          staticClass: "profile",
                          style: `background-image: url(${_vm.itemData.avatarInfo[0].resUrl})`,
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is_blind",
                              attrs: { "data-v-3015e6ff": "" },
                            },
                            [_vm._v("Artist profile picture")]
                          ),
                        ]
                      )
                    : _c("IcProfile", {
                        staticClass: "profile",
                        attrs: {
                          width: 110,
                          height: 110,
                          "stroke-width": 0.8,
                          iconLabel: "No artist profile",
                        },
                      }),
                  _c("IcRing", {
                    staticClass: "ic_ring",
                    attrs: { width: 110, height: 110, "small-ring": 14.8 },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "ttl_area" }, [
                _c("h3", { staticClass: "ttl" }, [
                  _c("b", [_vm._v(_vm._s(_vm.itemData.userName))]),
                ]),
                _c("p", { staticClass: "desc" }, [
                  _vm._v(_vm._s(_vm.itemData.about)),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }