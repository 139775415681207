// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../assets/css/customGlobal.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.family_account_group[data-v-6c206afe] {
  padding-left: 14.5rem;
}
.profile_info[data-v-6c206afe] {
  display: inline-flex;
  z-index: 11;
  cursor: pointer;
  align-items: center;
  vertical-align: top;
}
.profile_layer .follow_group .followers dl dt[data-v-6c206afe],
.profile_layer .follow_group .following dl dt[data-v-6c206afe] {
  font-size: 1.6rem;
  font-weight: 300;
  padding-left: 0;
}
.profile_info_group[data-v-6c206afe] {
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;
}
.profile_layer .followers dl[data-v-6c206afe],
.profile_layer .following dl[data-v-6c206afe] {
  text-align: left;
}
.profile_layer .followers[data-v-6c206afe] {
  min-width: 12rem;
  display: inline-block;
  position: relative;
  font-size: 0;
  text-align: left;
}
.profile_layer .ttl_area[data-v-6c206afe] {
  display: flex;
  justify-content: space-between;
  max-width: 28rem;
}
.profile_layer .ttl[data-v-6c206afe] {
  font-size: 1.8rem !important;
  font-weight: 300 !important;
}
.profile_layer .following[data-v-6c206afe] {
  min-width: 12rem;
  display: inline-block;
  position: relative;
  font-size: 0;
  text-align: left;
}
.profile_layer .followers[data-v-6c206afe]:before {
  content: none;
}
.profile_layer .following[data-v-6c206afe]:before {
  display: block;
  content: '';
  position: absolute;
  left: -2.8rem;
  top: 3px;
  bottom: 3px;
  z-index: 1;
  width: 1px;
  background: var(--line-color);
}
.my_artist_page .profile_layer .btn_area i[data-v-6c206afe] {
  margin-right: 1rem;
  vertical-align: -0.8rem;
}
.my_artist_page .artist_sec_head .profile_group[data-v-6c206afe] {
  flex-flow: column;
  align-items: baseline !important;
}
.profile_layer[data-v-6c206afe] {
  display: none;
  position: absolute;
  top: 5rem;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem solid var(--disabled-colr);
  background: #000;
  box-sizing: border-box;
}
.follow_group[data-v-6c206afe] {
  padding-left: 14.5rem;
  margin-top: 2.3rem;
  font-size: 0;
}
.profile_layer .follow_group[data-v-6c206afe] {
  padding-left: 0;
  margin-top: 2.3rem;
  font-size: 0;
}
.btn_area[data-v-6c206afe] {
  margin-top: 1.8rem;
}
.artist_sec_head .family_account_group[data-v-6c206afe] {
  margin-top: 2.5rem;
}
.artist_sec_head .family_account_group .account_length_area p[data-v-6c206afe] {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray);
}
.artist_sec_head .family_account_group .account_length_area p em[data-v-6c206afe] {
  color: var(--main-color);
}
.artist_sec_head .family_account_group .profile_info[data-v-6c206afe] {
  display: flex;
}
.artist_sec_head .family_account_group .profile_info .profile[data-v-6c206afe] {
  width: 4rem;
  height: 4rem;
  box-sizing: content-box;
}
.artist_sec_head .family_account_group .profile_info li + li[data-v-6c206afe] {
  margin-left: -0.7rem;
}
.artist_sec_head .family_account_group .profile_info li + li .profile[data-v-6c206afe] {
  /* border: 0.2rem solid #000; */
}
.artist_sec_head .family_account_group .profile_info li[data-v-6c206afe] {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
.artist_sec_head .family_account_group .profile_info li:hover .profile_layer[data-v-6c206afe] {
  display: block;
}
@media screen and (max-width: 1024px) {
.artist_sec_head .family_account_group .profile_info li:hover .profile_layer[data-v-6c206afe] {
    display: none;
}
}
@media screen and (max-width: 767px) {
.follow_group[data-v-6c206afe] {
    padding-left: 0;
}
.artist_sec_head .family_account_group .account_length_area p[data-v-6c206afe] {
    font-weight: 200;
}
.artist_sec_head .family_account_group .profile_info li[data-v-6c206afe] {
    margin-top: 1.4rem;
}
.artist_sec_head .family_account_group .profile_info .profile[data-v-6c206afe] {
    width: 4rem;
    height: 4rem;
}
.family_account_group[data-v-6c206afe] {
    margin-top: 3.5rem;
    padding-left: 0;
}
}
.desc[data-v-6c206afe] {
  white-space: pre-line !important;
}
.ttl_area.zpcd[data-v-6c206afe] {
  text-align: center;
  word-break: break-all;
  margin: 30px 0px 10px 0px;
}
.zpcd_title[data-v-6c206afe] {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.zpcd_info[data-v-6c206afe] {
  font-weight: 600;
  font-size: 2.2rem;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
