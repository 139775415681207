// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes loading-00ea9db1 {
0% {
    transform: translateX(0);
}
50%,
  100% {
    transform: translateX(460px);
}
}
.skeleton-item[data-v-00ea9db1] {
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  margin-top: 3px;
}
.skeleton-info[data-v-00ea9db1] {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}
.skeleton-name[data-v-00ea9db1] {
  width: 75%;
  height: 18px;
  background: #363535;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
.skeleton-name[data-v-00ea9db1]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #363535, rgb(69, 67, 67), #363535);
  animation: loading-00ea9db1 2s infinite linear;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
