var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      {
        class: { nothead: _vm.$route.meta.isHeaderHide },
        style: `--min: ${_vm.minH}; --nf: ${_vm.noneFoot};`,
        attrs: { id: "wrapper" },
        on: {
          click: _vm.handleClick,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            )
              return null
            return _vm.handleClick.apply(null, arguments)
          },
        },
      },
      [
        !_vm.$route.meta.isHeaderHide
          ? _c("HeaderBase", {
              ref: "header",
              class: _vm.pageClass,
              on: { openSignin: _vm.openSignin },
            })
          : _vm._e(),
        _vm.subHead
          ? _c("SubHeaderBase", {
              ref: "subHeader",
              class: _vm.pageClass,
              attrs: { setTitle: _vm.title },
              on: { openSignin: _vm.openSignin },
            })
          : _vm._e(),
        _c("div", { attrs: { id: "container" } }, [_vm._t("default")], 2),
        !_vm.$route.meta.isFooterHide
          ? _c("FooterBase", { ref: "footer", class: _vm.pageClass })
          : _vm._e(),
        _c("AlertModal", { ref: "alert", attrs: { alertData: _vm.alertData } }),
        _c("TutorialModal", { ref: "tutorial" }),
        _vm.showWallet && _vm.$isMobile()
          ? _c("ConnectWalletModal", {
              attrs: {
                showWallet: _vm.showWallet,
                walletTokenExpired: _vm.walletTokenExpired,
                walletChange: _vm.walletChange,
                walletInfo: _vm.walletInfo,
              },
              on: { close: _vm.closeWalletConnect },
            })
          : _vm._e(),
        _vm.showNotice
          ? _c("noticeModal", {
              attrs: { showNotice: _vm.showNotice },
              on: {
                "update:showNotice": function ($event) {
                  _vm.showNotice = $event
                },
                "update:show-notice": function ($event) {
                  _vm.showNotice = $event
                },
                closeNotice: _vm.closeNotice,
              },
            })
          : _vm._e(),
        _vm.commonModal
          ? _c("commonModal", {
              attrs: { commonModal: _vm.commonModal },
              on: { closeCommon: _vm.closeCommon },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "go_main" } }, [
      _c("a", { attrs: { href: "#container" } }, [_vm._v("Skip to content")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }