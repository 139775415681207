var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcInstagram",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "stroke-width": "1.2",
        "icon-color": _vm.iconColor,
      },
    },
    [
      _c("path", {
        staticStyle: { "stroke-miterlimit": "10" },
        attrs: {
          d: "M10 5h10c2.8 0 5 2.2 5 5v10c0 2.8-2.2 5-5 5H10c-2.8 0-5-2.2-5-5V10c0-2.8 2.2-5 5-5z",
          fill: "none",
          stroke: _vm.iconColor,
        },
      }),
      _c("path", {
        staticStyle: { "stroke-miterlimit": "10" },
        attrs: {
          d: "M14.9 10.5h.2c2.4 0 4.4 2 4.4 4.4v.2c0 2.4-2 4.4-4.4 4.4h-.2c-2.4 0-4.4-2-4.4-4.4v-.2c0-2.4 2-4.4 4.4-4.4z",
          fill: "none",
          stroke: _vm.iconColor,
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.3 8.9c0 .7-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2.5-1.2 1.2-1.2 1.2.6 1.2 1.2",
          "stroke-width": "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }