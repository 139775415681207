var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showWallet } },
    [
      _c(
        "div",
        {
          staticClass: "modal qrModal",
          class: _vm.modalSize,
          attrs: { role: "dialog" },
        },
        [
          _c("div", { staticClass: "modal_head" }, [
            _c("h3", { staticClass: "modal_ttl" }, [
              _vm._v(" " + _vm._s(_vm.modalData.title) + " "),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.modalData.desc))]),
          ]),
          _c("div", { staticClass: "modal_body" }, [
            _c("div", { staticClass: "qr_group" }, [
              _c("div", { staticClass: "img_area" }, [
                _c(
                  "div",
                  { staticClass: "qr-code" },
                  [
                    _c("qrcode-vue", {
                      attrs: {
                        value: _vm.qrcodeUrl,
                        size: _vm.qrcodeSize,
                        level: "L",
                        background: "#fff",
                        margin: "10",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.modalData.title === "Connect Your Wallet"
            ? _c("div", { staticClass: "modal_foot" }, [
                _c("p", { staticClass: "txt box_txt" }, [
                  _vm._v(
                    " If you want to connect wallet later, you can do so in "
                  ),
                  _c(
                    "span",
                    [
                      _vm._v("My Account "),
                      _c("ArwNextS", { attrs: { "stroke-width": 1.2 } }),
                      _vm._v(" My Wallet."),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btn_area", on: { click: _vm.close } },
                  [
                    _c("router-link", { attrs: { to: "/help" } }, [
                      _c("button", { staticClass: "btn strong w_xl h_m" }, [
                        _vm._v("Learn more about Wallet"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn type_ic close_modal",
              on: { click: _vm.close },
            },
            [_c("IcCloseM")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }