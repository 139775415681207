// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.purchase_sec[data-v-6e502980] {
  display: flex;
  min-height: calc(var(--min) * 1px);
  align-items: center;
}
.purchase_sec .btn_area[data-v-6e502980] {
  margin-top: 70px;
}
.progress_sec[data-v-6e502980] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #000;
}
.progress_area progress[data-v-6e502980] {
  height: 0.6rem;
}
.progress_sec .set_inner[data-v-6e502980],
.progress_sec .guide_ui[data-v-6e502980] {
  height: 100%;
}
.progress_sec .guide_ui[data-v-6e502980] {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.add_help_msg_box[data-v-6e502980] {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.add_help_inner[data-v-6e502980] {
  background: #252525;
  width: 650px;
  padding: 30px;
}
.add_help_head h3[data-v-6e502980] {
  font-size: 2.7rem;
  margin-bottom: 0.5rem;
}
.add_help_head p[data-v-6e502980] {
  text-align: left;
  font-size: 1.8rem;
}
.add_help_content[data-v-6e502980] {
  text-align: left;
}
.add_help_content_item[data-v-6e502980] {
  margin-top: 3rem;
}
.add_help_content_item h4[data-v-6e502980] {
  font-weight: 300;
  color: #b9b9b9;
}
.add_help_content_item .add_help_copy[data-v-6e502980] {
  display: flex;
  margin-top: 0.5rem;
}
.add_help_content_item .add_help_copy p[data-v-6e502980] {
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.6rem;
}
.add_help_content_item .add_help_copy .btn_copy[data-v-6e502980] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px !important;
  height: 25px !important;
  margin-left: 0.5rem;
  margin-top: -0.2rem;
}
.add_help_content_item .add_help_copy .btn_copy i[data-v-6e502980] {
  width: 1.7rem !important;
  height: 1.7rem !important;
}
@media screen and (max-width: 600px) {
.purchase_sec .btn_area[data-v-6e502980] {
    display: flex;
    margin-top: 5rem;
    width: 100%;
}
.purchase_sec .btn_area .btn[data-v-6e502980] {
    flex: 1;
    min-width: 0;
    /* max-width: 18rem; */
    padding: 0 1rem;
    width: 50%;
}
.add_help_inner[data-v-6e502980] {
    width: 100%;
}
.add_help_head h3[data-v-6e502980] {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}
.add_help_head p[data-v-6e502980] {
    text-align: left;
    font-size: 1.4rem;
}
}
@media screen and (max-width: 450px) {
.purchase_sec .btn_area .btn[data-v-6e502980] {
    flex: 1;
    min-width: 0;
    max-width: 50%;
    padding: 0 1rem;
}
.purchase_sec .btn_area[data-v-6e502980] {
    display: flex;
    justify-content: center;
    width: 100%;
}
.purchase_sec .btn_area .btn[data-v-6e502980] {
    min-width: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
