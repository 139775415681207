// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../assets/css/detail.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../assets/css/customGlobal.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.thumb_item[data-v-c81949b6] {
  position: relative;
  cursor: pointer;
}
.thumb_item .ic_area[data-v-c81949b6] {
  position: absolute;
  z-index: 10;
}
/* .thumb_item .ic_area {
  width: 40px;
  height: 40px;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.thumb_item .ic_area.type_video {
  right: 7px;
  top: 7px;
} */
.thumb_item .ic_area.type_blockchains[data-v-c81949b6] {
  width: 34px;
  height: 37px;
  padding: 0;
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  box-sizing: border-box;
  border-radius: 0;
  right: 10px;
  top: 10px;
}
.isDeactive[data-v-c81949b6] {
  background-color: #333;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
.thumb_item .ic_area.type_blockchains[data-v-c81949b6] {
    top: 7px;
    right: 7px;
    width: 2.8rem !important;
    height: 3rem !important;
}
.thumb_item .ic_area.type_blockchains i[data-v-c81949b6] {
    width: 100% !important;
    height: 100% !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
