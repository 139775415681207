var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcEmail",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("g", { attrs: { transform: "translate(-1054 -670)" } }, [
        _c("path", {
          attrs: {
            d: "m1065.241 688.888-8.012-4.659 19.807-7.136-7.265 19.678Z",
            "data-name": "패스 3715",
          },
        }),
        _c("path", {
          attrs: {
            d: "m1065.364 688.778 10.77-10.77",
            "data-name": "패스 3716",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }