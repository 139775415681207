var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCalendar",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        staticClass: "st1",
        attrs: {
          d: "M5.6 7.6h18.8v16.8H5.6V7.6zM10.6 9.5v-4M19.4 9.5v-4M5 12.4h20",
        },
      }),
      _c("path", {
        staticClass: "st2",
        attrs: {
          d: "M10 14.8h2V16h-2v-1.2zM10 17.8h2V19h-2v-1.2zM10 20.8h2V22h-2v-1.2zM14 14.8h2V16h-2v-1.2zM14 17.8h2V19h-2v-1.2zM14 20.8h2V22h-2v-1.2zM18 14.8h2V16h-2v-1.2zM18 17.8h2V19h-2v-1.2zM18 20.8h2V22h-2v-1.2z",
          fill: _vm.strokeColor,
          "stroke-width": "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }