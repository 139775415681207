var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "error_page", attrs: { id: "contents" } }, [
    _c("h1", { staticClass: "page_ttl is_blind" }, [
      _vm._v("networkerror page"),
    ]),
    _c("article", { staticClass: "error_sec" }, [
      _c("div", { staticClass: "guide_ui error" }, [
        _c("div", { staticClass: "img_area" }, [
          _c("picture", [
            _c("source", {
              attrs: {
                media: "(max-width: 600px)",
                type: "image/png",
                srcset: `${require("@/assets/img/img_mob_illust_network_l.png")} 4x, ${require("@/assets/img/img_mob_illust_network_m.png")} 3x, ${require("@/assets/img/img_mob_illust_network.png")}`,
              },
            }),
            _c("img", {
              attrs: {
                src: require("@/assets/img/img_illust_network.png"),
                alt: "img_illust_network",
              },
            }),
          ]),
        ]),
        _c("h2", [_vm._v("Sorry,")]),
        _c("p", [
          _vm._v(
            " The Internet connection is unstable. Try agiain later or reload the page. "
          ),
        ]),
        _c("div", { staticClass: "btn_area" }, [
          _c(
            "button",
            {
              staticClass: "btn w_l h_l basic",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(" Try again ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn w_l h_l strong",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/")
                },
              },
            },
            [_vm._v(" Go to Home ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }