// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
	파일정의 : layout
	작성날짜 : 2021-02-01 장병주
	참고사항 : 이 파일은 layout 만 정의한다.
	분류순서 : 기본정책 > 유틸리티 > 사용자정의(컬러, 폰트, 보더, 간격 등)
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------
    ## Layout
-------------------------------------------------------------------*/
html,
body {
  width: 100%;
}
html.overHidden {
  overflow: hidden;
}
html.overHidden.dv_ios {
  position: fixed;
  height: 100%;
  overflow: hidden;
}
html.overHidden body {
  height: 100%;
}

body {
  background: #000;
  font-family: 'Be Vietnam Pro', Arial, sans-serif;
  font-size: 1.3rem;
}

#go_main {
  position: relative;
  width: 100%;
}

#go_main a {
  position: absolute;
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  color: var(--txt-gray-2);
  font-weight: 900;
}

#go_main a:focus,
#go_main a:active,
#go_main a:hover {
  position: relative;
  top: 0;
}

#wrapper {
  position: relative;
  min-width: 1400px;
}

#contents {
  overflow: hidden;
  min-height: calc(var(--min) * 1px);
  box-sizing: border-box;
}
.set_inner {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
.min_inner {
  max-width: 1080px;
  margin-left: auto;
}
/*------------------------------------------------------
    ## header
-------------------------------------------------------*/
#header {
  position: relative;
}
#header .gnb,
#footer .fnb {
  padding: 0 80px;
}
.gnb > div,
.gnb > nav {
  float: left;
}
.gnb .logo_wrap {
  padding: 22px 0 24px;
  margin-right: 34px;
}
.gnb .logo_wrap a {
  display: block;
}
.gnb .menu_wrap {
  padding: 18px 0 20px;
}
.gnb .menu_list .menu_node:not(:first-child) {
  margin-left: 30px;
}
.gnb .menu_list .menu_item {
  padding: 0 10px;
  font-size: 2rem;
  font-weight: 200;
  line-height: 3.2rem;
}
.gnb .menu_list .menu_item span {
  position: relative;
}
.gnb .main_menu_wrap .menu_item.is_active span {
  font-weight: 500;
}
.gnb .main_menu_wrap .menu_item.is_active span:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 2px;
  margin-top: 3px;
  background: var(--main-color);
}
.gnb .sub_menu_wrap {
  position: relative;
  float: right;
  margin-right: -1px;
}
.gnb .sub_menu_wrap .menu_node:not(:first-child) {
  margin-left: 26px;
}
.gnb .sub_menu_wrap .menu_item {
  padding: 0;
}
.gnb .sub_menu_wrap .menu_item.type_line {
  padding: 0 17px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: -0.8rem;
  font-size: 1.8rem;
  line-height: 3.6rem;
}
.dep2_menu_wrap {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
  padding: 12px 15px;
  margin-right: -1.5rem;
  background: rgba(0, 0, 0, 0.85);
  border: 1px solid var(--main-color);
}
.dep2_menu_wrap .ttl_area {
  position: relative;
  padding-right: 3rem;
  margin-bottom: 1rem;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;  
}
.dep2_menu_wrap .ttl_area .ttl {
  font-size: 1.6rem;
}
.dep2_menu_wrap > .btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.dep2_menu_wrap .menu_list .menu_node {
  float: none;
}
.gnb .sub_menu_wrap .dep2_menu_wrap .menu_node:not(:first-child) {
  margin-left: 0;
  color: var(--txt-gray-2);
}
.dep2_menu_wrap .menu_list .menu_item {
  font-size: 1.4rem;
}
.alarm_menu_wrap {
  width: 33rem;
  padding-bottom: 0.5rem;
}
.my_account_menu_wrap {
  width: 23rem;
}
.my_account_menu_wrap .menu_list .menu_node:last-child {
  margin-top: 1rem;
  margin-bottom: -0.7rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--line-color);
}
.alarm_menu_wrap:before,
.my_account_menu_wrap:before,
.alarm_menu_wrap:after,
.my_account_menu_wrap:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
}
.alarm_menu_wrap:before,
.my_account_menu_wrap:before {
  margin-right: -0.2rem;
  border: 6px solid transparent;
  border-bottom: 8px solid var(--main-color);
}
.alarm_menu_wrap:after,
.my_account_menu_wrap:after {
  border: 4px solid transparent;
  border-bottom: 6px solid #000;
}
.my_account_menu_wrap:before,
.my_account_menu_wrap:after {
  right: 2.3rem;
}
.alarm_menu_wrap:before,
.alarm_menu_wrap:after {
  right: 8.1rem;
}
.alarm_menu_wrap .has_scroll_y {
  max-height: 560px;
  margin-right: -0.3rem;
}
.alarm_list {
  padding-right: 1.3rem;
}
.alarm_node:not(:last-child) {
  border-bottom: 1px solid var(--line-color);
}
.alarm_node:first-child .alarm_item {
  padding-top: 1rem;
}
.alarm_node:last-child .alarm_item {
  padding-bottom: 1.5rem;
}
.alarm_item {
  display: block;
  padding: 1.8rem 0;
  font-size: 1.2rem;
}
.alarm_item .cate {
  font-size: 1.2rem;
  font-weight: 300;
}
.alarm_item .msg {
  display: -webkit-box;
  overflow: hidden;
  margin: 0.8rem 0;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray);
  line-height: 1.428;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.alarm_item .msg b {
  color: #fff;
}
.alarm_item .date {
  color: var(--txt-gray-2);
}
#notiModal .alarm_list {
  padding-right: 0;
}
#notiModal .alarm_list .alarm_item {
  padding: 2rem;
}
#notiModal .alarm_node:last-child {
  border-bottom: 1px solid var(--line-color);
}
#notiModal .alarm_item .msg {
  margin: 0.9rem 0;
}
#notiModal .alarm_item .cate,
#notiModal .alarm_item .date {
  font-weight: 300;
}
.gnb .mob_menu_wrap {
  float: right;
  margin-right: -0.2rem;
}
.gnb .mob_menu_wrap .menu_list .menu_item {
  padding: 0;
  line-height: 2.4rem;
}
.gnb .mob_menu_wrap .menu_list .menu_node:not(:first-child) {
  margin-left: 1.5rem;
}
.btn_control_menu {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
}
/*------------------------------------------------------
    ## footer
-------------------------------------------------------*/
#footer {
  position: relative;
  padding: 35px 0 38px;
  background: #141414;
  border-top: 1px solid #2e2e2e;
}
.fnb .fnb_head > div,
.fnb .fnb_head > nav {
  float: left;
}
.fnb .fnb_head .menu_wrap {
  display: flex;
  float: right;
  padding-top: 0.3rem;
}
.fnb .menu_list {
  margin-left: -38px;
}
.fnb .menu_list .menu_node {
  position: relative;
  padding: 0 28px;
}
.fnb .menu_list .menu_node::before {
  display: block;
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 16px;
  margin-top: -8px;
  background: #fff;
  opacity: 0.16;
}
.fnb .menu_list .menu_item {
  padding: 0 10px;
  font-size: 1.6rem;
}
.fnb .menu_list.sns_menu {
  margin-top: -1px;
  margin-left: 24px;
  margin-right: -10px;
}
.fnb .menu_list.sns_menu .menu_node {
  padding: 0;
}
.fnb .menu_list.sns_menu .menu_node::before {
  display: none;
}
.fnb .fnb_body {
  margin-top: 23px;
}
.fnb .info_wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.4rem;
  line-height: 1.37;
  color: #fff;
}
.fnb .info_wrap p {
  opacity: 0.5;
  line-height: 1.375;
}
.fnb .info_wrap .copy {
  text-align: right;
}
.float_btn_area {
  position: fixed;
  bottom: 20px;
  right :20px;
  pointer-events: none;
}
.float_btn_area .btn {
  position: relative;
  width: 60px;
  height: 60px;
  padding-top: 2px;
  opacity: 0;
  font-size: 1.4rem;
  font-weight: 300;
  text-transform: uppercase;
  pointer-events: auto;
  transform: translate3d(0, 70px, 0);
  transition: ease-out 0.4s;
}
.float_btn_area.is_active .btn {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.float_btn_area .btn_gotop:before {
  display: inline-block;
  content: '';
  width: 18px;
  height: 18px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  box-sizing: border-box;
  transform: rotate(45deg) translate(4px, 4px);
}
.float_btn_area.fix_bottom:not(.dev_mob) {
  position: absolute;
  bottom: 100%;
  margin-bottom: 20px;
}

.anb {
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  z-index: 600;
  max-width: 40rem;
  width: 100%;
  background: #000;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid var(--line-color);
}
.anb > .mob_nav > ul {
  padding-top: 1.5rem;
  border-top: 1px solid var(--line-color);
}
.anb > .mob_nav > ul:not(:first-of-type) {
  margin-top: 1.5rem;
}
.anb .menu_list .menu_node {
  float: none;
}
.anb .mob_nav > .menu_item,
.anb .menu_list .menu_item {
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  font-weight: 300;
}
.anb .menu_list .menu_item.has_sub.is_active .ic {
  transform: rotate(180deg);
}
.anb .mob_nav > .menu_item {
  display: block;
  width: 100%;
  padding: 2.9rem 2rem;
  text-align: left;
}
.anb .mob_nav > .menu_item > .ic {
  margin-right: 0.9rem;
  vertical-align: -0.7rem;
}
.anb .mob_nav > .menu_item.btn_signout {
  margin-top: 1.5rem;
  border-top: 1px solid var(--line-color);
}
.anb .menu_list .menu_item > .ic {
  margin-left: 1.2rem;
}
.anb .dep2_menu_list {
  padding-left: 2rem;
  margin-bottom: 1.5rem;
}
.anb .dep2_menu_list .menu_item {
  color: var(--disabled-colr);
}
.anb .menu_item.is_current_active span {
  position: relative;
  color: #fff;
}
.anb .menu_item.is_current_active span:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 100%;
  height: 0.2rem;
  background: var(--main-color);
}
.anb .profile_info {
  padding: 1.1rem 2rem;
}
.anb .profile_info .profile {
  width: 3.6rem;
  height: 3.6rem;
}
.anb .profile_info .name {
  font-size: 1.8rem;
  font-weight: 300;
}
/*------------------------------------------------------
    ## lnb
-------------------------------------------------------*/

/*------------------------------------------------------
    ## section
-------------------------------------------------------*/

.list_sec {
  margin-bottom: 147px;
}
.list_sec:first-child {
  padding-top: 60px;
}
.list_sec .sec_head {
  position: relative;
  margin-bottom: 30px;
}
.list_sec .sec_head .float_el {
  position: absolute;
  top: 0;
  right: 0;
}
.list_sec .sec_head .float_el.btn {
  min-width: 8rem;
}
.search_sec {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 500;
  width: 100%;
  padding: 60px 0 20px;
  background: #000;
  text-align: center;
}
.search_wrap {
  position: relative;
  max-width: 960px;
  margin: auto;
  text-align: left;
}
.search_wrap .input_area .input_box {
  width: 100%;
  font-size: 1.8rem;
}
.auto_word_frame {
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: -10px;
  font-size: 1.8rem;
}
.search_sec .word_list {
  margin-bottom: -1rem;
}
.search_sec .word_node {
  margin-bottom: 1rem;
}
.search_sec .close_ui {
  margin-top: 50px;
}

/* account_menu*/

.my_account_menu {
  float: left;
  width: 32rem;
  padding-top: 5rem;
}

.my_account_head {
  padding-bottom: 3.7rem;
  border-bottom: 0.1rem solid var(--line-color);
}

.my_account_head .profile_info {
  display: block;
}

.my_account_head .profile_info .profile {
  display: block;
  width: 11rem;
  height: 11rem;
  flex-shrink: 0;
  margin-right: 0;
  border-radius: 50%;
  background-size: cover;
}

.my_account_head .ttl_area {
  display: flex;
  justify-content: space-between;
  max-width: 28rem;
  margin-top: 1.8rem;
}
.my_account_head .ttl_area .edit_btn {
  flex-shrink: 0;
  margin-left: 1.5rem;
}
.my_account_head .ttl_area .edit_btn i {
  vertical-align: middle;
}

.my_account_head .ttl {
  display: -webkit-box;
  overflow: hidden;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.my_account_head .follow_group {
  margin-top: 2.3rem;
  font-size: 0;
}

.my_account_head .following,
.my_account_head .followers {
  display: inline-block;
  position: relative;
  min-width: 14rem;
  font-size: 0;
  text-align: left;
}
.my_account_head .following:before {
  display: block;
  content: '';
  position: absolute;
  left: -34px;
  top: 3px;
  bottom: 3px;
  z-index: 1;
  width: 1px;
  background: var(--line-color);
}

.my_account_head .follow_group ul {
  display: inline-block;
  text-align: left;
}

.my_account_head .follow_group ul li {
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
}

.my_account_head .follow_group ul button {
  text-align:left;
}

.my_account_head .follow_group ul span {
  display:block;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}

.my_account_head .family_account_group {
  margin-top: 2.5rem;
}

.my_account_head .family_account_group .account_length_area p {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray);
}

.my_account_head .family_account_group .account_length_area p em {
  color: var(--main-color);
}

.my_account_head .family_account_group .profile_info {
  display: flex;
}

.my_account_head .family_account_group .profile_info .profile {
  width: 3.2rem;
  height: 3.2rem;
  box-sizing: content-box;
}

.my_account_head .family_account_group .profile_info li + li {
  margin-left: -0.7rem;
}

.my_account_head .family_account_group .profile_info li + li .profile {
  border: 0.2rem solid #000;
}

.my_account_body {
  padding-top: 4.7rem;
}

.my_account_body ul li a {
  position: relative;
  padding-bottom: 0.6rem;
  font-size: 2rem;
  font-weight: 200;
  color: #fff;
}
.my_account_body ul li a.is_active {
  font-weight: 500;
}

.my_account_body ul li a.is_active:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  right: 0;
  height: 0.2rem;
  background: var(--main-color);
}

.my_account_body ul li + li {
  margin-top: 3rem;
}

.my_account_head .family_account_group .profile_info li {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.my_account_head .family_account_group .profile_info li:hover .profile_layer {
  display: block;
}

.my_account_menu .profile_layer {
  display: none;
  position: absolute;
  top: 5rem;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem solid var(--disabled-colr);
  background: #000000;
  box-sizing: border-box;
}

.my_account_menu .profile_layer .profile_info_group {
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;
}

.my_account_menu .profile_layer .profile_info_group .profile {
  width: 5rem;
  height: 5rem;
}

.my_account_menu .profile_layer .profile_info_group .ttl_area {
  margin: 0 0 0 1rem;
}

.my_account_menu .profile_layer .profile_info_group .ttl {
  font-size: 1.8rem;
  font-weight: 300;
}

.my_account_menu .profile_layer .follow_group .following,
.my_account_menu .profile_layer .follow_group .followers {
  min-width: 12rem;
}
.my_account_menu .profile_layer .follow_group .following:before {
  left: -2.8rem;
}
.my_account_menu .profile_layer .follow_group .followers dl dt,
.my_account_menu .profile_layer .follow_group .following dl dt {
  font-size: 1.6rem;
  font-weight: 300;
  padding-left: 0;
}

.my_account_menu .profile_layer .follow_group dl dd {
  margin-top: 0.5rem;
}

.my_account_menu .profile_layer .btn_area {
  margin-top: 1.8rem;
}

.my_account_menu .profile_layer .btn_area i {
  margin-right: 1rem;
  vertical-align: -0.8rem;
}

.my_account_menu.my_artist {
  padding-top: 0;
}
#searchModal .modal_body {
  padding-top: 2rem;
}
#searchModal .modal_body .input_area.search input {
  height: 1.8rem;
}
#searchModal .input_area .input_box .remove_word {
  width: 2.4rem;
  height: 2.4rem;
  background: #5a5a5a;
}
#searchModal .input_area .input_box .remove_word g {
  stroke-width: 2;
}
#searchModal .auto_word_frame {
  max-height: calc(100vh - 22rem);
}
#searchModal .auto_word_frame .word_list {
  margin-bottom: -1.2rem;
}
#searchModal .auto_word_frame .word_node {
  margin-bottom: 1.2rem;
}
.sub_header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 6rem;
  background: #000;
}
.sub_header:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 1px;

  background: var(--line-color);
}
.sub_header .page_head {
  padding: 0 3rem;
  font-size: 2.4rem;
  line-height: 6rem;
}
.sub_header .page_head .close_page {
  position: absolute;
  right: 2.7rem;
  top: 1.5rem;
  width: 3rem;
  height: 3rem;
  padding: 0.3rem;
  line-height: 3rem;
}
.sub_header .page_head .close_page g {
  stroke-width: 2;
  stroke: #fff;
}
@media screen and (max-width: 1024px) {
  #go_main {
    display: none;
  }

  #wrapper {
    min-width: 0;
    margin: auto;
    padding-top: 6rem;
  }
  .nothead {
    padding-top: 0rem !important;
  }
  .set_inner {
    padding: 0 4rem;
    box-sizing: border-box;
  }
  #header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 400;
    width: 100%;
    background: #000;
  }
  #header .gnb,
  #footer .fnb {
    padding: 0 3rem;
  }
  .gnb .menu_wrap {
    padding: 17px 0 19px;
  }
  .gnb .logo_wrap {
    padding: 1.9rem 0 1.7rem;
  }
  .gnb .logo_area .ic,
  .fnb .fnb_head .logo_area .ic {
    width: 12.3rem !important;
    height: 2rem !important;
  }
  #footer {
    padding: 3.7rem 0;
  }
  .fnb .fnb_head .info_wrap {
    margin-top: 1rem;
  }
  .fnb .info_wrap p {
    line-height: 1.428;
  }
  .fnb .fnb_head .menu_wrap {
    display: block;
    float: left;
    width: 100%;
    margin-top: 2.5rem;
  }
  .fnb .fnb_head .menu_list {
    margin: 0;
  }
  .fnb .fnb_head .logo_area {
    margin-bottom: 1rem;
  }
  .fnb .menu_list .menu_node {
    float: none;
    padding: 0;
    margin-bottom: 1.2rem;
  }
  .fnb .menu_list .menu_node::before {
    display: none;
  }
  .fnb .menu_list .menu_item {
    padding: 0;
    font-size: 1.4rem;
  }
  .fnb .menu_list.sns_menu {
    margin-top: 20px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .fnb .menu_list.sns_menu .menu_node {
    float: left;
    margin-bottom: 0;
  }
  .fnb .menu_list.sns_menu .menu_item {
    padding: 0 10px;
  }
  .fnb .info_wrap .copy {
    font-size: 1.2rem;
  }
  .float_btn_area {
    right: 2rem;
    margin-right: 0;
  }
  .float_btn_area .btn {
    width: 4rem;
    height: 4rem;
    padding: 0;
    border-radius: 50%;
    font-size: 0;
  }
  .float_btn_area .btn_gotop:before {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.3rem;
    border-width: 0.1rem;
  }
  #footer.detail_page {
    padding-bottom: 8.7rem;
  }
  .detail_page .float_btn_area {
    bottom: 7rem;
  }

  .my_account_menu {
    display: none;
  }

  .my_account_menu.is_show {
    float: none;
    width: 100%;
  }

  .my_account_head .profile_group .profile_info .profile {
    margin: 0 auto;
  }

  .my_account_head .ttl_area {
    margin: 1.8rem auto 0;
  }

  .my_account_head .follow_group {
    text-align: center;
  }

  .my_account_head .follow_group .followers dl dt {
    padding-left: 0;
  }

  .my_account_head .family_account_group .profile_info li:hover .profile_layer {
    display: none;
  }
  #header.search_page,
  #header.help_detail_page,
  #header.notice_detail_page,
  #footer.search_page,
  #footer.help_detail_page,
  #footer.notice_detail_page {
    display: none;
  }
  .sub_header.search_page,
  .sub_header.help_detail_page,
  .sub_header.notice_detail_page {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .sub_header .page_head {
    padding: 0 2rem;
  }
  .sub_header .page_head .close_page {
    right: 1.7rem;
  }
  .my_account_menu {
    padding-top: 3rem;
  }

  .my_account_head .profile_group .profile_info .profile {
    width: 10rem;
    height: 10rem;
  }

  .my_account_head .follow_group .following:before {
    display: inline-block;
    content: '';
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    width: 0.1rem;
    height: 5rem;
    background: #444;
    vertical-align: bottom;
  }

  .my_account_head {
    padding-bottom: 4rem;
  }

  .my_account_head .ttl_area {
    margin: 2.3rem auto 0;
  }

  .my_account_head .follow_group .followers dl dt,
  .my_account_head .follow_group .following dl dt {
    font-size: 2.2rem;
  }

  .my_account_head .family_account_group .account_length_area p {
    font-weight: 200;
  }
  .my_account_head .family_account_group .profile_info li {
    margin-top: 1.4rem;
  }
  .my_account_head .family_account_group .profile_info .profile {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .set_inner {
    padding: 0 2rem;
    box-sizing: border-box;
  }
  .list_sec {
    margin-bottom: 7.7rem;
  }
  .list_sec:first-child {
    padding-top: 3rem;
  }
  .list_sec .sec_head {
    margin-bottom: 2.8rem;
  }
  #header .gnb,
  #footer .fnb {
    padding: 0 2rem;
  }
  .anb {
    max-width: 100%;
  }
  #footer {
    padding: 3.7rem 0;
  }
  .fnb .fnb_head .info_wrap {
    margin-top: 0.7rem;
  }
  .fnb .info_wrap p {
    line-height: 1.428;
  }
  .fnb .fnb_head .menu_wrap {
    float: left;
    width: 100%;
    margin-top: 2.5rem;
  }
  .fnb .menu_list .menu_node {
    float: none;
    padding: 0;
    margin-bottom: 1.2rem;
  }
  .fnb .menu_list .menu_node:not(:first-child):before {
    display: none;
  }
  .fnb .menu_list .menu_item {
    padding: 0;
    font-size: 1.4rem;
  }
  .fnb .info_wrap .copy {
    font-size: 1.2rem;
  }
  .float_btn_area {
    right: 2rem;
    margin-right: 0;
  }
  .float_btn_area .btn {
    width: 4rem;
    height: 4rem;
    padding: 0;
    border-radius: 50%;
    font-size: 0;
  }
  .float_btn_area .btn_gotop:before {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.3rem;
    border-width: 0.1rem;
  }
  #footer.detail_page {
    padding-bottom: 8.7rem;
  }
  .detail_page .float_btn_area {
    bottom: 7rem;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
