var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icZoomIn",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [
      _c("path", {
        staticClass: "cls-2",
        attrs: {
          id: "패스_2729",
          "data-name": "패스 2729",
          d: "M-816.607,8978H-825v8.394",
          transform: "translate(826 -8977.001)",
        },
      }),
      _c("path", {
        staticClass: "cls-2",
        attrs: {
          id: "패스_3739",
          "data-name": "패스 3739",
          d: "M8.393,7.394H0V-1",
          transform: "translate(1 15.6)",
        },
      }),
      _c("path", {
        staticClass: "cls-2",
        attrs: {
          id: "패스_3738",
          "data-name": "패스 3738",
          d: "M8.393,7.394H0V-1",
          transform: "translate(22.993 8.394) rotate(180)",
        },
      }),
      _c("path", {
        staticClass: "cls-2",
        attrs: {
          id: "패스_3740",
          "data-name": "패스 3740",
          d: "M8.393,0H0V8.394",
          transform: "translate(22.992 22.994) rotate(180)",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }