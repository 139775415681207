var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "IcDownload",
        "icon-label": _vm.iconLabel,
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [
      _c("path", {
        attrs: { fill: "#fff", stroke: "none", d: "M12 4h1v13h-1z" },
      }),
      _c("path", {
        attrs: { fill: "#fff", stroke: "none", d: "M20.5 20v1h-16v-1z" },
      }),
      _c("path", { attrs: { fill: "none", d: "m7.504 12 5 5 5-5" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }