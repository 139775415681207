var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    {
      staticClass: "footer_page notice_detail_page",
      attrs: { id: "contents" },
    },
    [
      _c("div", { staticClass: "set_inner" }, [
        _vm._m(0),
        _vm.noticeDetails
          ? _c("section", { staticClass: "board_sec" }, [
              _c("div", { staticClass: "board_sec_head" }, [
                _c("h2", { staticClass: "ttl" }, [
                  _vm._v(_vm._s(_vm.noticeDetails.title)),
                ]),
                _c("span", { staticClass: "date" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("UsaFormat")(
                        _vm._f("GmtToTz")(_vm.noticeDetails.writeDate)
                      )
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "board_sec_body" }, [
                _c("pre", { staticClass: "detail" }, [
                  _vm._v(_vm._s(_vm.noticeDetails.content)),
                ]),
              ]),
              _c("div", { staticClass: "board_sec_foot" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.back()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "btn strong w_s h_l" }, [
                      _vm._v("Back to List"),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page_ttl_area" }, [
      _c("h1", { staticClass: "page_ttl only_pc" }, [_vm._v("Notice Board")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }