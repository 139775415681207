var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edition_slide_wrap type_item" },
    [
      _c(
        "swiper",
        {
          ref: "itemSwiper",
          staticClass: "edit_list",
          class: _vm.addClass,
          attrs: { options: _vm.slideOpt },
        },
        _vm._l(_vm.itemData, function (item) {
          return _c(
            "swiper-slide",
            { key: item.dropsId, staticClass: "edit_node" },
            [
              _c("DropsItem", {
                attrs: { "item-data": item, "show-chip": true },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c("div", { staticClass: "control_area pagination_area type_dot" }),
      _c("div", { staticClass: "control_area slide_nav_area" }, [
        _c("button", { staticClass: "btn type_ic prev" }, [_c("ArwPrev")], 1),
        _c("button", { staticClass: "btn type_ic next" }, [_c("ArwNext")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }