var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list_wrap",
      class: { is_hover: _vm.isHover },
      staticStyle: { cursor: "pointer" },
    },
    [
      _c(
        "div",
        { staticClass: "swiper_container list" },
        [
          _c(
            "div",
            { staticClass: "list_head" },
            [
              _c("div", { staticClass: "ttl_area" }, [
                _c("h2", { staticClass: "ttl", attrs: { role: "status" } }, [
                  _c("em", [_vm._v(_vm._s(_vm.num))]),
                  _vm._v(" " + _vm._s(_vm.setTitle) + " "),
                ]),
              ]),
              _vm.window.width > 1024
                ? _c(
                    "router-link",
                    {
                      staticClass: "btn type_line_w h_l w_l mode",
                      attrs: { to: "/collections/mode" },
                    },
                    [_vm._v(" Collection Mode"), _c("ArwNextS")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "swiper",
            {
              ref: "subSlide",
              staticClass: "list_body",
              attrs: { options: _vm.subVisualOpt },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("swiperClick")
                },
              },
            },
            _vm._l(_vm.itemData, function (item, index) {
              return _c(
                "swiper-slide",
                { key: index, staticClass: "edit_node" },
                [
                  _c("div", { staticClass: "collection_wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "edit_item type_card",
                        class: { is_active: _vm.currentIndex === index },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.setCurrent(item, index)
                          },
                          mouseleave: _vm.removeHover,
                        },
                      },
                      [
                        _c("div", { staticClass: "item_head" }, [
                          item.videoThumbInfos || item.imageInfos
                            ? _c("img", {
                                attrs: {
                                  src:
                                    item.mimeType === "image/jpeg" &&
                                    item.imageInfos
                                      ? item.imageInfos[0].resUrl
                                      : item.videoThumbInfos[0].resUrl,
                                  width: 498,
                                  alt: item.title
                                    ? "Capture of Artwork for the " + item.title
                                    : "",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "ic_area type_blockchains" },
                            [
                              item.bcNwTp == "10"
                                ? _c("IcHedera")
                                : _c("IcEth"),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "item_body" }, [
                          _c("div", { staticClass: "basic_info_group" }, [
                            item.otherMaplYn == "Y"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "profile_info",
                                    attrs: { tabindex: "0" },
                                  },
                                  [
                                    item.otherMaplLogoUrl
                                      ? _c("span", {
                                          staticClass: "profile",
                                          style: `background-image: url(${item.otherMaplLogoUrl})`,
                                        })
                                      : _c("IcProfile", {
                                          staticClass: "profile",
                                        }),
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.otherPlatform)),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "profile_info",
                                    attrs: { tabindex: "0" },
                                  },
                                  [
                                    item.avatarRsrcUrl
                                      ? _c("span", {
                                          staticClass: "profile",
                                          style: `background-image: url(${item.avatarRsrcUrl})`,
                                        })
                                      : _c("IcProfile", {
                                          staticClass: "profile",
                                        }),
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.name
                                            ? item.name
                                            : item.artistName
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                            _c(
                              "div",
                              {
                                staticClass: "ttl_area",
                                attrs: { role: "text" },
                              },
                              [
                                _c("h3", { staticClass: "ttl" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                item.listType === "M"
                                  ? _c("p", { staticClass: "edit" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.editionId) +
                                          " of " +
                                          _vm._s(item.totalCount) +
                                          " " +
                                          _vm._s(
                                            item.totalEdition > 1
                                              ? "Editions"
                                              : "Edition"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.totalCount) +
                                          " " +
                                          _vm._s(
                                            item.totalEdition > 1
                                              ? "Editions"
                                              : "Edition"
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            1
          ),
          _vm.dimmable
            ? _c("div", { class: { dim_el: _vm.isHover } })
            : _vm._e(),
          _vm.window.width > 1024
            ? _c("div", { staticClass: "control_area slide_nav_area" }, [
                _c(
                  "button",
                  { staticClass: "btn type_ic prev" },
                  [_c("ArwPrev")],
                  1
                ),
                _c(
                  "button",
                  { staticClass: "btn type_ic next" },
                  [_c("ArwNext")],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }