var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icTime",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("circle", {
        staticClass: "st1",
        attrs: { cx: "15", cy: "15", r: "10.4" },
      }),
      _c("path", { staticClass: "st1", attrs: { d: "M15.2 8.1v6.7l3.6 3.7" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }