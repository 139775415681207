var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icSignOut",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("path", {
        attrs: { "data-name": "Path 2369", d: "M10.208 4H4v16h6.208" },
      }),
      _c("path", {
        attrs: {
          "data-name": "Path 2370",
          d: "m15.091 8.097 3.848 3.848H8v1.552h10.94l-3.848 3.848 1.1 1.1 5.812-5.736L16.188 7Z",
          fill: _vm.strokeColor,
          "stroke-width": "0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }