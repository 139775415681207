var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "arwPagePrev",
        "icon-label": _vm.iconLabel,
        width: 5.043,
        height: 9.664,
        viewBox: "0 0 5.043 9.664",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "none",
          d: "m4.669.332-4 4.5 4 4.5",
          "data-name": "패스 3750",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }