var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "modal_wrap type_full",
          class: { is_show: _vm.show },
          on: { click: _vm.dimEvt },
        },
        [
          _c(
            "div",
            {
              ref: "Tutorial",
              staticClass: "modal",
              class: _vm.modalSize,
              attrs: {
                id: "Tutorial",
                role: "dialog",
                "aria-labelledby": "tutorial_title",
              },
            },
            [
              _c("div", { staticClass: "modal_head" }, [
                _c(
                  "h3",
                  { staticClass: "modal_ttl", attrs: { id: "tutorial_title" } },
                  [
                    _vm._v(" Getting started on "),
                    _c("Logo", {
                      staticClass: "ic_logo",
                      attrs: { width: "352", height: "56" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "modal_body" }, [
                _c("div", { staticClass: "set_inner" }, [
                  _c("section", { staticClass: "info_sec sign_info" }, [
                    _vm._m(0),
                    _vm._m(1),
                    _c("div", { staticClass: "img_group" }, [
                      _c("div", { staticClass: "img_area" }, [
                        _c("figure", [
                          _c("figcaption", [_vm._v("PC")]),
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                media: "(max-width: 600px)",
                                type: "image/png",
                                srcset: `${require("@/assets/img/img_tutorial_pc_sign_m_3x.png")} 3x, ${require("@/assets/img/img_tutorial_pc_sign_m_2x.png")} 2x, ${require("@/assets/img/img_tutorial_pc_sign_m.png")}`,
                              },
                            }),
                            _c("source", {
                              attrs: {
                                type: "image/png",
                                srcset: `${require("@/assets/img/img_tutorial_pc_sign_2x.png")} 2x, ${require("@/assets/img/img_tutorial_pc_sign.png")}`,
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/img_tutorial_pc_sign.png"),
                                width: "646",
                                height: "360",
                                alt: "PC sign in Guide",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "img_area" }, [
                        _c("figure", [
                          _c("figcaption", [_vm._v("Mobile")]),
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                media: "(max-width: 600px)",
                                type: "image/png",
                                srcset: `${require("@/assets/img/img_tutorial_mob_sign_m_3x.png")} 3x, ${require("@/assets/img/img_tutorial_mob_sign_m_2x.png")} 2x, ${require("@/assets/img/img_tutorial_mob_sign_m.png")}`,
                              },
                            }),
                            _c("source", {
                              attrs: {
                                type: "image/png",
                                srcset: `${require("@/assets/img/img_tutorial_mob_sign_2x.png")} 2x, ${require("@/assets/img/img_tutorial_mob_sign.png")}`,
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/img_tutorial_mob_sign.png"),
                                width: "640",
                                height: "360",
                                alt: "Mobile sign in Guide",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("hr"),
                    _c("p", [
                      _vm._v(
                        " LG Account page will show up after selecting Sign In button. If you already have an LG Account, sign in to your LG Account. "
                      ),
                    ]),
                    _vm._m(2),
                    _c("div", { staticClass: "img_area" }, [
                      _c("figure", [
                        _c("picture", [
                          _c("source", {
                            attrs: {
                              media: "(max-width: 600px)",
                              type: "image/png",
                              srcset: `${require("@/assets/img/img_tutorial_account_m_3x.png")} 3x, ${require("@/assets/img/img_tutorial_account_m_2x.png")} 2x, ${require("@/assets/img/img_tutorial_account_m.png")}`,
                            },
                          }),
                          _c("source", {
                            attrs: {
                              type: "image/png",
                              srcset: `${require("@/assets/img/img_tutorial_account_2x.png")} 2x, ${require("@/assets/img/img_tutorial_account.png")}`,
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/img_tutorial_account.png"),
                              width: "1320",
                              height: "495",
                              alt: "USD Coin Guide image",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("section", { staticClass: "info_sec wallet_info" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "info_group" }, [
                      _c("p", [
                        _vm._v(
                          " You’ll need to download a mobile wallet app that supports collecting NFT Art on Hedera Blockchain platform. We recommend downloading Wallypto App. Once you’ve downloaded Wallypto, follow the in-app instructions to set up your wallet. Make sure you buy with USDC-Coin in Wallypto. "
                        ),
                      ]),
                      _c("div", { staticClass: "img_area" }, [
                        _c("figure", [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                media: "(max-width: 600px)",
                                type: "image/png",
                                srcset: `${require("@/assets/img/img_tutorial_coin_m_3x.png")} 3x, ${require("@/assets/img/img_tutorial_coin_m_2x.png")} 2x, ${require("@/assets/img/img_tutorial_coin_m.png")}`,
                              },
                            }),
                            _c("source", {
                              attrs: {
                                type: "image/png",
                                srcset: `${require("@/assets/img/img_tutorial_coin_2x.png")} 2x, ${require("@/assets/img/img_tutorial_coin.png")}`,
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/img_tutorial_coin.png"),
                                width: "660",
                                height: "400",
                                alt: "My wallet connection guide image",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("hr"),
                    _vm._m(4),
                    _c("div", { staticClass: "img_area" }, [
                      _c("figure", [
                        _c("picture", [
                          _c("source", {
                            attrs: {
                              media: "(max-width: 600px)",
                              type: "image/png",
                              srcset: `${require("@/assets/img/img_tutorial_wallet_m_3x.png")} 3x, ${require("@/assets/img/img_tutorial_wallet_m_2x.png")} 2x, ${require("@/assets/img/img_tutorial_wallet_m.png")}`,
                            },
                          }),
                          _c("source", {
                            attrs: {
                              type: "image/png",
                              srcset: `${require("@/assets/img/img_tutorial_wallet_2x.png")} 3x, ${require("@/assets/img/img_tutorial_wallet.png")}`,
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/img_tutorial_wallet.png"),
                              width: "1057",
                              height: "1345",
                              alt: "LG account registration guide image",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "modal_foot" }, [
                _c("div", { staticClass: "btn_area taC" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn strong w_xl h_l",
                      on: { click: _vm.close },
                    },
                    [_vm._v(" Go to LG Art Lab ")]
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn type_ic close_modal",
                  on: { click: _vm.close },
                },
                [_c("IcCloseM", { staticClass: "ic_close" })],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "sec_ttl" }, [
      _vm._v(" How To Set Up Your "),
      _c("b", [_vm._v("LG Art Lab Collector Account")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " First, create an LG Account if you do not have one. Select the Sign In button on the upper right corner. "
      ),
      _c("br"),
      _vm._v(
        " You can also create your LG Account through LG Art Lab website www.lgartlab.com "
      ),
      _c("br"),
      _vm._v(
        " using both PC and mobile (for mobile, select the three line button and select the Sign In menu) "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "c_point" }, [
      _vm._v(" Tip: If you add your Zip code, State, and City by entering "),
      _c("br"),
      _vm._v(" Account Management > Edit Account Information "),
      _c("br"),
      _vm._v(" before collecting art, the process can go quick. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "sec_ttl" }, [
      _vm._v(" How To Set Up Your "),
      _c("b", [_vm._v("Account With Wallet Connect")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " Once you have set your Wallypto wallet account, select Profile image icon which is located on the upper right corner. (same location as Sign In button) "
      ),
      _c("br"),
      _vm._v(
        " In there you can check your LG Art Lab account information including your wallet. Go to My Wallet sub-menu and select Connect Wallet button. "
      ),
      _c("br"),
      _vm._v(
        " Scan the QR Code on mobile wallet. A notification from your wallet will pop up and ask you to sign. Once you select sign, your account will be fully set up. "
      ),
      _c("br"),
      _vm._v(
        " Your wallet will popup and ask you to sign anytime you log into your account, or initiate any transactions, such as buying or selling an NFT. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }