// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.guide_ui .img_area[data-v-0d6aa5c8] {
  margin-bottom: -1rem;
}
input[data-v-0d6aa5c8]:focus::-webkit-input-placeholder,
textarea[data-v-0d6aa5c8]:focus::-webkit-input-placeholder {
  color: transparent;
}
input[data-v-0d6aa5c8]:focus::-moz-placeholder,
textarea[data-v-0d6aa5c8]:focus::-moz-placeholder {
  color: transparent;
}
input[data-v-0d6aa5c8]:focus:-ms-input-placeholder,
textarea[data-v-0d6aa5c8]:focus:-ms-input-placeholder {
  color: transparent;
}
@media screen and (max-width: 600px) {
.guide_ui .img_area[data-v-0d6aa5c8] {
    margin-bottom: 0;
}
}
textarea[data-v-0d6aa5c8]:focus,
input[data-v-0d6aa5c8]:focus {
  outline: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
