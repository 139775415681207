// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/be-vietnam-pro-v8-latin-200.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/be-vietnam-pro-v8-latin-200.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/be-vietnam-pro-v8-latin-200.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/be-vietnam-pro-v8-latin-200.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/be-vietnam-pro-v8-latin-200.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/be-vietnam-pro-v8-latin-300.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/be-vietnam-pro-v8-latin-300.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/be-vietnam-pro-v8-latin-300.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../fonts/be-vietnam-pro-v8-latin-300.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../fonts/be-vietnam-pro-v8-latin-300.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("../fonts/be-vietnam-pro-v8-latin-regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("../fonts/be-vietnam-pro-v8-latin-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("../fonts/be-vietnam-pro-v8-latin-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("../fonts/be-vietnam-pro-v8-latin-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("../fonts/be-vietnam-pro-v8-latin-regular.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_15___ = new URL("../fonts/be-vietnam-pro-v8-latin-500.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_16___ = new URL("../fonts/be-vietnam-pro-v8-latin-500.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_17___ = new URL("../fonts/be-vietnam-pro-v8-latin-500.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_18___ = new URL("../fonts/be-vietnam-pro-v8-latin-500.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_19___ = new URL("../fonts/be-vietnam-pro-v8-latin-500.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_20___ = new URL("../fonts/be-vietnam-pro-v8-latin-600.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_21___ = new URL("../fonts/be-vietnam-pro-v8-latin-600.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_22___ = new URL("../fonts/be-vietnam-pro-v8-latin-600.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_23___ = new URL("../fonts/be-vietnam-pro-v8-latin-600.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_24___ = new URL("../fonts/be-vietnam-pro-v8-latin-600.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_25___ = new URL("../fonts/be-vietnam-pro-v8-latin-700.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_26___ = new URL("../fonts/be-vietnam-pro-v8-latin-700.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_27___ = new URL("../fonts/be-vietnam-pro-v8-latin-700.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_28___ = new URL("../fonts/be-vietnam-pro-v8-latin-700.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_29___ = new URL("../fonts/be-vietnam-pro-v8-latin-700.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#BeVietnamPro" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___, { hash: "#BeVietnamPro" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___, { hash: "#BeVietnamPro" });
var ___CSS_LOADER_URL_REPLACEMENT_18___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
var ___CSS_LOADER_URL_REPLACEMENT_19___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_20___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_16___);
var ___CSS_LOADER_URL_REPLACEMENT_21___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_17___);
var ___CSS_LOADER_URL_REPLACEMENT_22___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_18___);
var ___CSS_LOADER_URL_REPLACEMENT_23___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_19___, { hash: "#BeVietnamPro" });
var ___CSS_LOADER_URL_REPLACEMENT_24___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_20___);
var ___CSS_LOADER_URL_REPLACEMENT_25___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_20___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_26___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_21___);
var ___CSS_LOADER_URL_REPLACEMENT_27___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_22___);
var ___CSS_LOADER_URL_REPLACEMENT_28___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_23___);
var ___CSS_LOADER_URL_REPLACEMENT_29___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_24___, { hash: "#BeVietnamPro" });
var ___CSS_LOADER_URL_REPLACEMENT_30___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_25___);
var ___CSS_LOADER_URL_REPLACEMENT_31___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_25___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_32___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_26___);
var ___CSS_LOADER_URL_REPLACEMENT_33___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_27___);
var ___CSS_LOADER_URL_REPLACEMENT_34___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_28___);
var ___CSS_LOADER_URL_REPLACEMENT_35___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_29___, { hash: "#BeVietnamPro" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* be-vietnam-pro-200 - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 200;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* IE9 Compat Modes */
  src: local(''), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
    /* IE6-IE8 */ url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
    /* Super Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
    /* Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype'),
    /* Safari, Android, iOS */ url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('svg'); /* Legacy iOS */
}
/* be-vietnam-pro-300 - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}); /* IE9 Compat Modes */
  src: local(''), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('embedded-opentype'),
    /* IE6-IE8 */ url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('woff2'),
    /* Super Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format('woff'),
    /* Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format('truetype'),
    /* Safari, Android, iOS */ url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format('svg'); /* Legacy iOS */
}
/* be-vietnam-pro-regular - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_12___}); /* IE9 Compat Modes */
  src: local(''), url(${___CSS_LOADER_URL_REPLACEMENT_13___}) format('embedded-opentype'),
    /* IE6-IE8 */ url(${___CSS_LOADER_URL_REPLACEMENT_14___}) format('woff2'),
    /* Super Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_15___}) format('woff'),
    /* Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_16___}) format('truetype'),
    /* Safari, Android, iOS */ url(${___CSS_LOADER_URL_REPLACEMENT_17___}) format('svg'); /* Legacy iOS */
}
/* be-vietnam-pro-500 - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_18___}); /* IE9 Compat Modes */
  src: local(''), url(${___CSS_LOADER_URL_REPLACEMENT_19___}) format('embedded-opentype'),
    /* IE6-IE8 */ url(${___CSS_LOADER_URL_REPLACEMENT_20___}) format('woff2'),
    /* Super Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_21___}) format('woff'),
    /* Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_22___}) format('truetype'),
    /* Safari, Android, iOS */ url(${___CSS_LOADER_URL_REPLACEMENT_23___}) format('svg'); /* Legacy iOS */
}
/* be-vietnam-pro-600 - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_24___}); /* IE9 Compat Modes */
  src: local(''), url(${___CSS_LOADER_URL_REPLACEMENT_25___}) format('embedded-opentype'),
    /* IE6-IE8 */ url(${___CSS_LOADER_URL_REPLACEMENT_26___}) format('woff2'),
    /* Super Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_27___}) format('woff'),
    /* Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_28___}) format('truetype'),
    /* Safari, Android, iOS */ url(${___CSS_LOADER_URL_REPLACEMENT_29___}) format('svg'); /* Legacy iOS */
}
/* be-vietnam-pro-700 - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_30___}); /* IE9 Compat Modes */
  src: local(''), url(${___CSS_LOADER_URL_REPLACEMENT_31___}) format('embedded-opentype'),
    /* IE6-IE8 */ url(${___CSS_LOADER_URL_REPLACEMENT_32___}) format('woff2'),
    /* Super Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_33___}) format('woff'),
    /* Modern Browsers */ url(${___CSS_LOADER_URL_REPLACEMENT_34___}) format('truetype'),
    /* Safari, Android, iOS */ url(${___CSS_LOADER_URL_REPLACEMENT_35___}) format('svg'); /* Legacy iOS */
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
