// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
	파일정의 : Detail page 스타일
	작성날짜 : 2022-04-01 장병주
	참고사항 : Detail page 스타일 정의
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/
.detail_page .overview_sec {
  position: relative;
  margin-bottom: 50px;
}
.detail_page .overview_sec .bg_el {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 681px;
  opacity: 0.5;
  background-position: center;
  background-size: cover;
}
.detail_page .overview_sec .bg_el:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  pointer-events: none;
}
.detail_page .overview_sec .sec_head {
  position: relative;
  max-width: 890px;
  margin: 0 auto 60px;
  padding-top: 70px;
}
.detail_page .overview_sec .sec_body {
  position: relative;
}
.detail_page .overview_sec .ttl_wrap,
.detail_page .overview_sec .desc_wrap {
  max-width: 720px;
}
.detail_page .overview_sec .ttl_wrap {
  font-size: 1.8rem;
  color: var(--txt-gray-2);
}
.detail_page .overview_sec .ttl_wrap .ttl_area,
.detail_page .overview_sec .ttl_wrap .profile_info {
  margin-bottom: 20px;
}
.detail_page .overview_sec .ttl_wrap .ttl {
  margin-bottom: 5px;
  font-size: 4.6rem;
  font-weight: 500;
  line-height: 1.3;
  color: #fff;
}
.detail_page .overview_sec .ttl_wrap .profile_info .name {
  font-size: 1.8rem;
  color: #fff;
}
.detail_page .overview_sec .float_el {
  position: absolute;
  right: 0;
  top: 12px;
}
.detail_page .overview_sec .buy_wrap {
  width: 510px;
  border: 1px solid var(--line-color);
  box-sizing: border-box;
}
.detail_page .overview_sec .buy_wrap .time_group {
  border-bottom: 1px solid var(--line-color);
}
.detail_page .overview_sec .buy_wrap .time_group > ul li {
  width: 25%;
  padding: 13px 20px;
}
.detail_page .overview_sec .buy_wrap .time_group .num {
  font-size: 2.8rem;
}
.detail_page .overview_sec .buy_wrap .time_group .txt {
  margin: 0;
}
.detail_page .overview_sec .buy_wrap .time_group > ul li:not(:first-child):before {
  top: 0;
  bottom: 0;
  background: var(--line-color);
  opacity: 1;
}
.detail_page .overview_sec .buy_wrap .buy_group {
  padding: 15px 29px;
  font-size: 1.8rem;
}
.detail_page .overview_sec .buy_wrap .buy_group .price b {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 300;
}
.detail_page .overview_sec .buy_wrap .buy_group .price b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.detail_page .overview_sec .buy_wrap .buy_group .price b .dollars{
  font-size: 1.4rem;
  font-weight: 200;
}
.detail_page .overview_sec .buy_wrap .buy_group small {
  display: block;
  margin-top: 2px;
  font-size: 1.4rem;
  color: var(--txt-gray-2);
}
.detail_page .overview_sec .buy_wrap .buy_group > .btn {
  margin: 15px 0;
}
.overview_sec .buy_wrap .label_list dt {
  min-width: 190px;
}
.detail_page .overview_sec .desc_wrap {
  margin-top: 90px;
  font-size: 1.6rem;
}
.detail_page .overview_sec .desc_wrap > div:not(:last-child) {
  margin-bottom: 57px;
}
.detail_page .overview_sec .desc_wrap .ttl {
  font-weight: 300;
}
.detail_page .detail_info_sec .sec_head {
  position: relative;
  min-height: 70px;
}
.detail_page .detail_info_sec .sec_head .tab_wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.detail_page .detail_info_sec .sec_head.is_fix .tab_wrap {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100;
  max-width: 1440px;
  margin-left: -720px;
  background: #000;
}
.detail_page .detail_info_sec .sec_body {
  position: relative;
  margin: 80px 0 145px;
}
.detail_page .detail_info_sec .sec_body:after {
  display: block;
  content: '';
  clear: both;
}
.detail_page .detail_info_sec .sec_body .detail_info_head {
  width: 100%;
  margin-bottom: 20px;
}
.detail_page .detail_info_sec .sec_body .detail_info_head .status_list_head{
  display: table;
  width: 100%;
  padding: 10px 30px;
  font-size: 1.6rem;
  background-color: var(--box-bg);
}
.detail_page .detail_info_sec .sec_body .detail_info_head .status_list_head li{
  display: table-cell;
  vertical-align: middle;
}
.detail_page .detail_info_sec .sec_body .detail_info_head .status_list_head .history_info{
  width: 40%;
}
.detail_page .detail_info_sec .sec_body .detail_info_head .status_list_head .date_info{
  width: 25%;

  text-align:center;
}
.detail_page .detail_info_sec .sec_body .detail_info_head .status_list_head .market_info{
  width: 15%;

  text-align:center;
}
.detail_page .detail_info_sec .sec_body .detail_info_head .status_list_head .price_info{
  width: 20%;
  text-align:center;
}
.detail_page .detail_info_sec .detail_info_head .info_txt {
  line-height: 24px;
  color: var(--txt-gray-2);
}
.detail_page .detail_info_sec .detail_info_head .info_txt .ic {
  margin-right: 4px;
  vertical-align: -5px;
}
.detail_info_sec .sec_body .thumb_nav_wrap {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  margin-right: -1230px;
  padding-right: 1260px;
  box-sizing: border-box;
}
.detail_info_sec .sec_body .thumb_nav_wrap .control_group {
  position: relative;
  padding: 20px 0;
}
.detail_info_sec .sec_body .thumb_nav_wrap .control_group {
}
.detail_info_sec .sec_body .thumb_nav_wrap .control_group .slide_nav_area {
  display: flex;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  justify-content: space-between;
  pointer-events: none;
}
.detail_info_sec .thumb_nav_wrap .slide_nav_area .btn {
  transform: rotate(90deg);
  pointer-events: auto;
}
.detail_info_sec .thumb_nav_wrap .slide_nav_area .btn.prev {
  margin-left: -0.5rem;
}
.detail_info_sec .thumb_nav_wrap .slide_nav_area .btn.next {
  margin-right: -0.5rem;
}
.detail_info_sec .thumb_nav_wrap .slide_nav_area .btn:disabled {
  opacity: 0.42;
  background: transparent !important;
}
.detail_info_sec .sec_body .thumb_nav_wrap .has_scroll {
  height: 645px;
  overflow: hidden;
}
.detail_info_sec .sec_body .viewer_wrap {
  z-index: 2;
}
.detail_info_sec .sec_body .viewer_wrap.type_img {
  float: right;
  max-width: 1230px;
}
.detail_info_sec .sec_body .viewer_wrap .desc_area {
  margin-top: 25px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.detail_info_wrap .detail_info_inner {
  position: relative;
  max-width: 100%;
  margin: auto;
}

/* marketplace 디테일 */
.detail_page.market_page .status_item {
  position: relative;
  display: table;
  width: 100%;
  padding: 30px;
  text-align: left;
  font-size: 1.6rem;
  color: var(--txt-gray-2);
  box-sizing: border-box;
}
.detail_page.market_page .status_item > div {
  display: table-cell;
  vertical-align: middle;
}
.detail_page.market_page .status_item .date_info {
  width: 25%;

  text-align:center;
  white-space: nowrap;
}
.detail_page.market_page .status_item .history_info {
  width: 40%;
  text-align: left;
  font-size: 1.8rem;
}
.detail_page.market_page .status_item .history_info em {
  color: #fff;
}
.detail_page.market_page .status_item .market_info{
  width: 15%;

  text-align: center;
}
.detail_page.market_page .status_item .price_info {
  width: 20%;
  padding-left: 2rem;
  text-align: right;
  color: #fff;
}
.detail_page.market_page .status_item .price_info .price b {
  font-weight: 300;
  font-size: 1.8rem;
}
.detail_page.market_page .status_item .price_info .price b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.detail_page.market_page .status_item .price_info .price b .dollars{
  font-size: 1.4rem;
  font-weight: 200;
}
.detail_page.market_page .status_item .btn_area {
  width: 160px;
  flex-shrink: 0;
}
.detail_page.market_page .status_item .btn_area .btn {
  margin-top: -10px;
  margin-bottom: -10px;
}
.detail_page.market_page .buy_group .label_list dt {
  width: 190px;
  box-sizing: border-box;
}
.detail_page.market_page .buy_group .label_list dd {
  position: relative;
}
.detail_page.market_page .buy_group .btn_copy {
  position: absolute;
  right: 0;
  margin-top: -2px;
  margin-left: 16px;
}
.detail_page.market_page .buy_group .input_area .input_box {
  width: 100%;
}

/* drops 디테일 */
.detail_page.drops_page .status_item {
  position: relative;
  width: 100%;
  font-size: 1.6rem;
  color: var(--txt-gray-2);
  box-sizing: border-box;
}
.detail_page.drops_page .status_item .user_info {
  display: flex;
  align-items: center;
  width: 40%;
}
.detail_page.drops_page .status_item .date_info {
  width: 25%;

  text-align: center;
}
.detail_page.drops_page .status_item .market_info {
  width: 15%;

  text-align: center;
}
.detail_page.drops_page .status_item .price_info {
  width: 20%;
  text-align: right;
  color: #fff;
}
.detail_page .buy_group .label_list .profile_info {
  margin: -0.3rem 0;
}
.tbl_area .tbl th,
.tbl_area .tbl td {
  text-align: right;
}
.tbl_area .tbl .item,
.tbl_area .tbl .own {
  text-align: left;
}
.tbl_area .tbl .item,
.tbl_area .tbl .time {
  width: 220px;
}
.tbl_area .tbl .time span{color:#aaa;}
.tbl_area .tbl .price b,
.tbl_area .tbl .current b {
  font-weight: 300;
}
.tbl_area .tbl .price b .unit,
.tbl_area .tbl .current b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.tbl_area .tbl .price b .dollars,
.tbl_area .tbl .current b .dollars{
  font-size: 1.4rem;
  font-weight: 200;
}
.tbl_area .tbl .own {
  width: 300px;
}
.tbl_area .tbl tbody td.item {
  white-space: nowrap;
  padding-left: 0;
  font-weight: 300;
  color: var(--txt-gray-2);
}
.tbl_area .tbl tbody td.item b {
  color: #fff;
}
.tbl_area .tbl tbody td.own,
.tbl_area .tbl tbody td.price,
.tbl_area .tbl tbody td.current {
  font-size: 1.8rem;
}
.tbl_area .tbl tbody td.own b {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.tbl_area .tbl tbody td.item > div {
  display: inline-block;
  vertical-align: middle;
}
.tbl_area .tbl tbody td.item .thumb_area {
  height: 100px;
  overflow: hidden;
  margin-right: 22px;
}
.tbl_area .tbl tbody td.item .thumb_area img {
  -o-object-position: center center;
     object-position: center center;
}
.label_list .drop_ui.select .drop_btn {
  width: 100%;
  padding: 10px 14px;
}

/* s: DEV 221207 추가  */
.ttl_wrap .drop_ui.select,
.ttl_wrap .input_area .input_box,
.ttl_wrap .drop_ui.select .drop_btn {
  width: 100%;
}
.ttl_wrap .drop_ui.select {
  margin-top: 1.7rem;
}
.ttl_wrap .drop_ui.select .drop_btn {
  padding-right: 10rem !important;
}
.drop_ui.select .badge {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 3.8rem;
  height: 2.4rem;
  padding: 0 1.4rem;
  margin-top: -1.2rem;
  border: 1px solid;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: #9058dd;
}
.ttl_wrap .drop_ui .drop_op_list [role='option'] {
  position: relative;
  padding-right: 10rem;
}
.market_page.detail_page .ttl_wrap .drop_ui.select .drop_op_list {
  top: 100%;
  bottom: auto;
}
/* e: DEV 221207 추가  */
@media screen and (max-width: 1024px) {
.detail_page .overview_sec .float_el {
    position: static;
    width: 100%;
    margin-top: 7rem;
}
.detail_page .overview_sec .ttl_wrap,
  .detail_page .overview_sec .desc_wrap {
    max-width: 100%;
}
.detail_page .detail_info_sec .sec_head.is_fix .tab_wrap {
    border-top: 1px solid var(--line-color);
    top: 6rem;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0 40px;
}
.detail_page .detail_info_sec .sec_head .tab_list .tab_item {
    font-size: 1.7rem;
}
.detail_info_sec .sec_body .thumb_nav_wrap,
  .detail_info_sec .sec_body .viewer_wrap.type_img {
    float: none;
    padding: 0;
}
.detail_info_sec .sec_body .thumb_nav_wrap .has_scroll {
    height: auto;
}
.detail_info_sec .sec_body .thumb_nav_wrap .control_group {
    display: none;
}
.detail_info_sec .sec_body .thumb_nav_wrap {
    float: none;
    width: auto;
    margin-left: -4rem;
    margin-right: -4rem;
    padding: 0;
}
.detail_info_sec .sec_body .viewer_wrap .desc_area {
    opacity: 0.7;
}
.thumb_nav_list {
    display: inline-flex;
    padding: 0 4rem;
}
.thumb_nav_list .nav_node {
    min-width: 14.5rem;
    margin-bottom: 3rem;
}
.thumb_nav_list .nav_node:not(:first-child) {
    margin-left: 2rem;
}
.thumb_nav_list .nav_item {
    max-width: 14.5rem;
}
.detail_page.market_page .status_item .history_info {
    width: 40%;
}
.detail_page .detail_info_sec .sec_body .detail_info_body .tbl thead {
    display: none;
}
.detail_page .detail_info_sec .sec_body .detail_info_body .tbl tbody {
    display: flex;
    flex-wrap: wrap;
}
.detail_page .detail_info_sec .sec_body .detail_info_body .tbl tbody tr,
  .detail_page .detail_info_sec .sec_body .detail_info_body .tbl tbody tr td {
    display: block;
}
.detail_page .detail_info_sec .sec_body .detail_info_body .tbl tbody {
    margin-right: -2rem;
    font-size: 0;
}
.detail_page .detail_info_sec .detail_info_body .tbl tbody tr {
    display: inline-block;
    flex: 1;
    min-width: calc(50% - 2rem);
    vertical-align: top;
    margin-right: 2rem;
    margin-bottom: 4rem;
    padding: 1.6rem;
    background: var(--box-bg);
    box-sizing: border-box;
}
.detail_page .detail_info_sec .detail_info_body tbody td,
  .detail_page .detail_info_sec .detail_info_body tbody td.item .thumb_area,
  .detail_page .detail_info_sec .detail_info_body tbody td.item .thumb_area img {
    width: 100%;
}
.detail_page .detail_info_sec .sec_body .detail_info_body .tbl tbody tr td {
    padding: 0;
    border: 0 none;
}
.detail_page .detail_info_sec .detail_info_body tbody td.item .thumb_area {
    display: block;
    height: auto;
}
.detail_page .detail_info_sec .detail_info_body tbody td.item .thumb_area img {
    height: auto;
}
.detail_page .detail_info_sec .detail_info_body tbody .label_info {
    display: flex !important;
    position: relative;
    flex-wrap: wrap;
    text-align: left;
    margin-bottom: 1rem;
}
.detail_page .detail_info_sec .detail_info_body tbody .label_info:before {
    display: inline-block;
    content: attr(data-label);
    min-width: 14rem;
    flex-shrink: 0;
    color: var(--txt-gray-2);
}
.detail_page .detail_info_sec .detail_info_body tbody .label_info.edit {
    margin-top: 2rem;
}
.detail_page .detail_info_sec .detail_info_body tbody .time.dv_pc {
    padding-left: 14rem !important;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    text-align: left;
    color: var(--txt-gray-2);
}
.detail_page .detail_info_sec .detail_info_body tbody .time.dv_pc b {
    font-weight: 200;
}
.detail_page .detail_info_sec .detail_info_body tbody .button {
    margin-top: 2rem;
}
.detail_page .detail_info_sec .detail_info_body tbody .button .btn {
    width: 100%;
}
}
@media screen and (max-width: 840px) {
  /* tab */
.set_inner .tab_wrap {
    margin-left: -4rem;
    margin-right: -4rem;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.tab_wrap::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
.tab_list {
    min-width: 100%;
    display: inline-flex;
    padding: 0 4rem;
    white-space: nowrap;
}
.tab_list .tab_node {
    min-width: auto;
}
.tab_list .tab_node:not(:first-child) {
    margin-left: 30px;
}
.detail_page .detail_info_sec .sec_head.is_fix .tab_wrap {
    padding: 0;
}
.detail_page.market_page .status_item .date_info{
    white-space: break-spaces;
}
}
@media screen and (max-width: 767px) {
.detail_page .detail_info_sec .sec_body .detail_info_head{
    display: none;
}
  /* marketplace 디테일 */
.detail_page.market_page .status_item {
    display: flex;
    /* flex-flow: column-reverse; */
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: left;
    padding: 1rem 1.6rem 1.6rem 1.6rem;
}
.detail_page.market_page .status_item > div {
    display: block;
    width: 100%;
    padding: 0;
}
.detail_page.market_page .status_item .price_info {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    margin:0;
    padding:1.6rem;
    border-bottom: 1px solid var(--line-color);
    font-size: 1.6rem;
    text-align: left;
}
.detail_page.market_page .status_item .history_info {
    width: 100%;
    padding: 5.3rem 0 0.5rem 0;
    font-size: 1.6rem;
}
.detail_page.market_page .status_item .market_info{
    position: absolute;
    top:0;
    right:1.6rem;
    width: auto;
    height: 53px;
    display: flex;
    align-items: center;
    min-width: auto;
    text-align: right;
}
.detail_page.market_page .status_item {
    font-size: 1.3rem;
}
.detail_page.market_page .status_item .date_info {
    width: 100%;
    padding-top:0.5rem;
    text-align: left;
}
  /* drops 디테일 */
.detail_page.drops_page .status_item {
    display: flex;
    /* flex-flow: column-reverse; */
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: left;
    padding: 1rem 1.6rem 1.6rem 1.6rem;
}
.detail_page.drops_page .status_item > div {
    display: block;
    width: 100%;
    padding: 0;
}
.detail_page.drops_page .status_item .price_info {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    margin:0;
    padding:1.6rem;
    border-bottom: 1px solid var(--line-color);
    font-size: 1.6rem;
    text-align: left;
}
.detail_page.drops_page .status_item .user_info {
    width: 100%;
    padding: 5.3rem 0 0.5rem 0;
    font-size: 1.6rem;
}
.detail_page.drops_page .status_item .market_info{
    position: absolute;
    top:0;
    right:1.6rem;
    width: auto;
    height: 53px;
    display: flex;
    align-items: center;
    min-width: auto;
    text-align: right;
}
.detail_page.drops_page .status_item {
    font-size: 1.3rem;
}
.detail_page.drops_page .status_item .date_info {
    width: 100%;
    padding-top:0.5rem;
    text-align: left;
}
.detail_page .detail_info_sec .detail_info_body .tbl tbody tr {
    width: 100%;
    min-width: calc(100% - 2rem);
}
.detail_page .detail_info_sec .detail_info_body tbody .label_info {
    font-size: 1.4rem;
}
.detail_page .detail_info_sec .detail_info_body tbody .label_info.price small {
    display: block;
    width: 100%;
    padding-left: 14rem;
    margin-top: 0.5rem;
    color: var(--txt-gray-2);
}
}
@media screen and (max-width: 600px) {
.detail_page.market_page .buy_group .label_list dt {
    width: 8rem;
}
.detail_page .overview_sec .bg_el {
    height: 45rem;
}
.detail_page .overview_sec .bg_el:after {
    height: 20rem;
}
.detail_page .overview_sec .ttl_wrap .ttl {
    font-size: 2.4rem;
}
.detail_page .overview_sec .ttl_wrap .ttl_area {
    margin-bottom: 0.9rem;
}
.detail_page .overview_sec .ttl_wrap .profile_info {
    margin-bottom: 1.2rem;
}
.detail_page .overview_sec .ttl_wrap,
  .detail_page .overview_sec .ttl_wrap .profile_info .name,
  .detail_page .overview_sec .buy_wrap .buy_group,
  .detail_page .overview_sec .desc_wrap,
  .detail_info_sec .sec_body .viewer_wrap .desc_area {
    font-size: 1.4rem;
}
.detail_page .overview_sec .buy_wrap {
    position: static;
    width: 100%;
    margin-top: 3.6rem;
}
.detail_page .overview_sec .buy_wrap .time_group dt {
    font-size: 1.8rem;
}
.detail_page .overview_sec .buy_wrap .time_group dd {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
}
.detail_page .detail_info_sec .detail_info_head .info_txt,
  .detail_page .overview_sec .buy_wrap .buy_group small {
    font-size: 1.2rem;
}
.detail_page .overview_sec .sec_head {
    position: static;
    padding-top: 4rem;
    margin-bottom: 3.6rem;
}
.detail_page .overview_sec .sec_head .btn_like {
    position: fixed;
    top: auto;
    left: 0;
    right: auto;
    bottom: 0;
    z-index: 100;
    width: 5rem;
    height: 5rem;
    background: #2c2c2c;
    opacity: 1;
}
.detail_page .overview_sec .sec_head .btn_like .ic {
    opacity: 0.6;
}
.detail_page .overview_sec .buy_wrap .buy_group {
    padding: 1rem 2rem 0.8rem;
}
.overview_sec .buy_wrap .label_list dt {
    min-width: 0;
}
.detail_page .buy_group .label_list .profile {
    width: 2.2rem !important;
    height: 2.2rem !important;
}
.detail_page .overview_sec .buy_wrap .buy_group .label_list.fix_el,
  .detail_page .overview_sec .buy_wrap .buy_group > .btn {
    position: fixed;
    left: 5rem;
    bottom: 0;
    right: 0;
    z-index: 100;
    width: auto;
    height: 5rem;
    margin: 0;
    font-size: 1.6rem;
    box-sizing: border-box;
}
.detail_page .overview_sec .buy_wrap .buy_group .label_list.fix_el {
    display: flex;
    left: 0;
    z-index: 100;
    height: 6rem;
    align-items: center;
    padding: 1rem 2rem;
    background: var(--box-bg);
}
.detail_page .overview_sec .buy_wrap .buy_group .label_list.fix_el dt,
  .detail_page .overview_sec .buy_wrap .buy_group .label_list.fix_el dd {
    flex: 1;
    padding: 0;
    font-size: 1.4rem;
}
.detail_page .overview_sec .buy_wrap .buy_group .price b {
    font-size: 1.6rem;
}
.detail_page .overview_sec .buy_wrap .buy_group .price b .dollars{
    font-size: 1.2rem;
}
.detail_page.market_page .status_item .price_info .price b {
    font-size: 1.6rem;
}
.detail_page.market_page .status_item .price_info .price b .dollars{
    font-size: 1.2rem;
}
.market_page .buy_group .drop_ui.select .input_box .drop_btn:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid var(--disabled-colr);
}
.market_page.detail_page .drop_ui.select .drop_op_list {
    top: auto;
    bottom: 100%;
}
.detail_page .overview_sec .desc_wrap {
    margin-top: 3.2rem;
}
.detail_page .overview_sec .desc_wrap > div:not(:last-child) {
    margin-bottom: 3.2rem;
}
.desc_wrap .more_ui .desc {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.desc_wrap .more_ui.is_more .desc {
    display: block;
}
.desc_wrap .more_ui.is_more .btn.has_arw:before {
    margin-top: 0;
    transform: rotate(225deg);
}
.detail_page .overview_sec .desc_wrap .desc_group > .btn {
    margin-top: 0.8rem;
    padding-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--txt-gray-2);
}
.set_inner .tab_wrap {
    margin-left: -2rem;
    margin-right: -2rem;
}
  /* Dev: 220503 추가 */
.detail_page .detail_info_sec .detail_info_body .tbl tbody tr {
    margin-bottom: 1rem;
}
.detail_page .detail_info_sec .sec_head {
    min-height: 4.6rem;
}
.detail_page .detail_info_sec .detail_info_head .info_txt {
    font-weight: 300;
}
.detail_page .detail_info_sec .detail_info_head .info_txt .ic {
    width: 2rem !important;
    height: 2rem !important;
    vertical-align: -0.4rem;
}
.detail_page.market_page .buy_group .btn_copy {
    margin-left: 0.8rem;
    margin-top: -0.5rem;
}
.detail_page .detail_info_sec .detail_info_head .info_txt .ic g {
    stroke-width: 1.5 !important;
}
.detail_page .detail_info_sec .sec_body {
    margin: 6rem 0 8rem;
}
.detail_page .detail_info_sec .sec_body .detail_info_head {
    margin-bottom: 1.2rem;
}
.detail_info_sec .sec_body .thumb_nav_wrap {
    margin-left: -2rem;
    margin-right: -2rem;
}
.thumb_nav_list {
    padding: 0 2rem;
}
.thumb_nav_list .nav_node {
    min-width: 12.5rem;
    margin-bottom: 2rem;
}
.thumb_nav_list .nav_node:not(:first-child) {
    margin-left: 1rem;
}
.thumb_nav_list .nav_item {
    max-width: 12.5rem;
}
.tbl_area .tbl .price b,
  .tbl_area .tbl .current b {
    font-size:1.6rem;
}
.tbl_area .tbl .price b .dollars,
  .tbl_area .tbl .current b .dollars{
    font-size: 1.2rem;
}
}
@media screen and (max-width: 360px) {
.detail_page .overview_sec .buy_wrap .buy_group {
    padding: 1rem 1.5rem 0.8rem;
}
}
@media screen and (max-width: 320px) {
.detail_page .overview_sec .buy_wrap .buy_group .label_list.fix_el dt {
    font-size: 1.1rem;
}
}
@media screen and (max-width: 300px) {
.detail_page .overview_sec .buy_wrap .buy_group .label_list.fix_el dt {
    font-size: 1rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
