// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#contents[data-v-31404d7d] {
  z-index: 400;
}
/* .loader {
  margin: auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  bottom: 50%;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
} */
.viewer_page[data-v-31404d7d] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.viewer_sec[data-v-31404d7d],
.viewer_sec .viewer_wrap[data-v-31404d7d],
.viewer_sec .view_area[data-v-31404d7d] {
  height: 100%;
  background-color: #000;
}
.viewer_sec .ttl_area[data-v-31404d7d],
.viewer_sec .controller_wrap[data-v-31404d7d] {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 50;
}
.viewer_sec .ttl_area[data-v-31404d7d]:before,
.viewer_sec .controller_wrap[data-v-31404d7d]:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  height: 30rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.73;
}
.viewer_sec .ttl_area[data-v-31404d7d] {
  top: 0;
  padding: 4rem 8rem;
  font-weight: 500;
}
.viewer_sec .ttl_area[data-v-31404d7d]:before {
  top: 0;
  transform: rotate(180deg);
}
.viewer_sec .ttl_area .ttl[data-v-31404d7d] {
  font-size: 4rem;
  margin-bottom: 0.8rem;
}
.viewer_sec .ttl_area .desc[data-v-31404d7d] {
  font-size: 1.8rem;
  color: #fff;
}
.viewer_sec .ttl_area .btn[data-v-31404d7d] {
  margin-top: 2.7rem;
}
.viewer_sec .ttl_area .logo[data-v-31404d7d] {
  width: 5rem;
  height: 5rem;
  margin-bottom: 0.8rem;
  border-radius: 50%;
  /* background: #ff521d url(); */
  /* background: #ff521d; */
  background-size: cover;
}
.logo .ttl[data-v-31404d7d] {
  margin-left: 6.5rem;
}
.view_area video[data-v-31404d7d],
.view_area img[data-v-31404d7d] {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.controller_wrap > div[data-v-31404d7d] {
  float: none;
}
.viewer_sec .controller_wrap[data-v-31404d7d] {
  bottom: 0;
  padding: 5rem 8rem;
}
.viewer_sec .controller_wrap[data-v-31404d7d]:before {
  bottom: 0;
}
.controller_wrap .btn_group[data-v-31404d7d] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playlist_sec[data-v-31404d7d] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 5.8rem 8rem 5rem;
  background: rgba(0, 0, 0, 0.7);
}
.playlist_sec > .btn[data-v-31404d7d] {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.playlist_sec .count[data-v-31404d7d] {
  margin-bottom: 1.7rem;
  font-size: 1.6rem;
  color: var(--txt-gray);
  font-weight: 300;
}
.viewer_sec > .btn[data-v-31404d7d] {
  /* display: none; */
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 100;
}
@media screen and (max-width: 320px) {
.viewer_sec > .btn[data-v-31404d7d] {
    display: block;
}
}
@media screen and (max-width: 600px) {
.viewer_sec > .btn[data-v-31404d7d] {
    display: block;
}
.viewer_sec .ttl_area[data-v-31404d7d] {
    padding: 2rem;
}
.viewer_sec .ttl_area .ttl[data-v-31404d7d] {
    font-size: 2rem;
}
.viewer_sec .ttl_area .desc[data-v-31404d7d] {
    margin-top: 0.7rem;
    font-size: 1.4rem;
    font-weight: 300;
}
.viewer_sec .controller_wrap[data-v-31404d7d] {
    padding: 2rem;
}
.controller_wrap .progress_group[data-v-31404d7d],
  .controller_wrap .progress_area[data-v-31404d7d] {
    margin-bottom: 2rem;
}
.viewer_sec .ttl_area .btn[data-v-31404d7d] {
    min-width: 12rem;
    margin-top: 2.6rem;
    font-size: 1.4rem;
}
.controller_wrap .btn_group .btn_zoom[data-v-31404d7d] {
    display: none;
}
.playlist_sec[data-v-31404d7d] {
    display: flex;
    width: 100%;
    margin: 10px 0;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    padding: 3rem 2rem;
    overflow: scroll;
}
.viewer_sec .ttl_area[data-v-31404d7d]:before,
  .viewer_sec .controller_wrap[data-v-31404d7d]:before {
    height: 120%;
    opacity: 0.55;
}
.controller_wrap .btn_group .open_playlist[data-v-31404d7d] {
    margin-right: 1rem;
    margin-left: 1rem; /* Dev: 220503 추가 */
}
.viewer_sec .ttl_area .logo[data-v-31404d7d] {
    width: 3.8rem;
    height: 3.8rem;
}
}
@media screen and (max-width: 1000px) and (max-height: 600px) and (orientation: landscape) {
.viewer_sec > .btn[data-v-31404d7d] {
    display: block;
}
.viewer_sec .ttl_area[data-v-31404d7d] {
    padding: 2rem;
}
.viewer_sec .ttl_area .ttl[data-v-31404d7d] {
    font-size: 2rem;
}
.viewer_sec .ttl_area .desc[data-v-31404d7d] {
    margin-top: 0.7rem;
    font-size: 1.4rem;
    font-weight: 300;
}
.viewer_sec .controller_wrap[data-v-31404d7d] {
    padding: 2rem;
}
.controller_wrap .progress_group[data-v-31404d7d],
  .controller_wrap .progress_area[data-v-31404d7d] {
    margin-bottom: 2rem;
}
.viewer_sec .ttl_area .btn[data-v-31404d7d] {
    min-width: 12rem;
    margin-top: 2.6rem;
    font-size: 1.4rem;
}
.controller_wrap .btn_group .btn_zoom[data-v-31404d7d] {
    display: none;
}
.playlist_sec[data-v-31404d7d] {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    padding: 3rem 2rem;
}
.viewer_sec .ttl_area[data-v-31404d7d]:before,
  .viewer_sec .controller_wrap[data-v-31404d7d]:before {
    height: 120%;
    opacity: 0.55;
}
.controller_wrap .btn_group .open_playlist[data-v-31404d7d] {
    margin-right: 1rem;
    margin-left: 1rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
