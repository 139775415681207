var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showEthLoading } },
    [
      _c(
        "div",
        {
          ref: "setPrice",
          staticClass: "modal",
          class: _vm.modalSize,
          attrs: {
            id: "setPrice",
            role: "dialog",
            "aria-labelledby": "setPrice_title",
            "aria-describedby": "setPrice_desc",
          },
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "modal_body" }, [
            _c("div", [_c("LoadingSpinner")], 1),
          ]),
          _c(
            "button",
            {
              staticClass: "btn type_ic close_modal",
              on: { click: _vm.close },
            },
            [_c("IcCloseM")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl" }, [_vm._v("Not for sale")]),
      _c("p", [_vm._v("Cancellation signature is in progress.")]),
      _c("p", [_vm._v("When completed, the screen will be updated")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }