var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal_wrap",
      class: { is_show: _vm.show },
      on: { click: _vm.dimEvt },
    },
    [
      _c("div", { staticClass: "modal follow" }, [
        _c("div", { staticClass: "modal_head" }, [
          _c("h3", { staticClass: "modal_ttl" }, [
            _vm._v("Follower / Following"),
          ]),
          _c("div", { staticClass: "tab_list type_stack" }, [
            _c(
              "div",
              { staticClass: "tab_node", attrs: { role: "tablist" } },
              _vm._l(_vm.tabMenu, function (item, index) {
                return _c(
                  "button",
                  {
                    key: index,
                    staticClass: "tab_item",
                    class: { is_active: index === _vm.activeIndex },
                    attrs: { role: "tab" },
                    on: {
                      click: function ($event) {
                        return _vm.handleTabClicked(index)
                      },
                    },
                  },
                  [
                    index === _vm.activeIndex
                      ? _c("span", { staticClass: "is_blind" }, [
                          _vm._v("Selected, "),
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item) + " "),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "modal_body" }, [
          _c("div", { staticClass: "has_scroll_y" }, [
            _vm.tabMenu[_vm.activeIndex] === "Followers"
              ? _c(
                  "ul",
                  { staticClass: "follow_list" },
                  [
                    !_vm.followers.length
                      ? _c("p", [_vm._v("No Follower Data")])
                      : _vm._e(),
                    _vm._l(_vm.followers, function (item) {
                      return _c(
                        "li",
                        { key: item.mbrNo, staticClass: "follow_node" },
                        [
                          _c("div", { staticClass: "follow_item" }, [
                            _c(
                              "div",
                              { staticClass: "profile_info profile_info_area" },
                              [
                                item.avatarRsrcUrl
                                  ? _c("a", {
                                      staticClass: "profile",
                                      style: `background-image: url('${item.avatarRsrcUrl}')`,
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRoute(item)
                                        },
                                      },
                                    })
                                  : _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRoute(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("IcProfile", {
                                          staticClass: "profile",
                                        }),
                                      ],
                                      1
                                    ),
                                _c("h2", { staticClass: "ttl name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            ),
                            item.mbrNo !== _vm.myMbrNo
                              ? _c("div", { staticClass: "is_row_focus" }, [
                                  _c(
                                    "button",
                                    {
                                      ref: "follow",
                                      refInFor: true,
                                      staticClass: "btn type_line_w w_xs h_m",
                                      class: {
                                        is_active: item.followYn === "Y",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.follow(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.followYn === "N"
                                              ? "Follow"
                                              : "Following"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _c(
                  "ul",
                  { staticClass: "follow_list" },
                  [
                    !_vm.following.length
                      ? _c("p", [_vm._v("No Following Data")])
                      : _vm._e(),
                    _vm._l(_vm.following, function (item) {
                      return _c(
                        "li",
                        { key: item.userId, staticClass: "follow_node" },
                        [
                          _c("div", { staticClass: "follow_item" }, [
                            _c(
                              "div",
                              { staticClass: "profile_info profile_info_area" },
                              [
                                item.avatarRsrcUrl
                                  ? _c("a", {
                                      staticClass: "profile",
                                      style: `background-image: url('${item.avatarRsrcUrl}')`,
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRoute(item)
                                        },
                                      },
                                    })
                                  : _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRoute(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("IcProfile", {
                                          staticClass: "profile",
                                        }),
                                      ],
                                      1
                                    ),
                                _c("h2", { staticClass: "ttl name" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            ),
                            item.mbrNo !== _vm.myMbrNo
                              ? _c("div", { staticClass: "is_row_focus" }, [
                                  _c(
                                    "button",
                                    {
                                      ref: "follow",
                                      refInFor: true,
                                      staticClass: "btn type_line_w w_xs h_m",
                                      class: {
                                        is_active: item.followYn === "Y",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.follow(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.followYn === "N"
                                              ? "Follow"
                                              : "Following"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
          ]),
        ]),
        _c(
          "button",
          { staticClass: "btn type_ic close_modal", on: { click: _vm.close } },
          [_c("IcCloseM")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }