var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "playlist_wrap", class: { mobile_wrap: _vm.$isMobile() } },
    [
      _vm.$isMobile() && _vm.checkWidth
        ? _c(
            "div",
            _vm._l(_vm.itemData, function (item, i) {
              return _c(
                "div",
                { key: item.id, ref: "txtSlide", refInFor: true },
                [
                  _c(
                    "button",
                    {
                      staticClass: "edit_item mobile-item",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.setCurrentIndex(i)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "item_body item_txt" }, [
                        _c("div", { staticClass: "ttl_area" }, [
                          _c(
                            "h3",
                            {
                              staticClass: "ttl mobile-ttl",
                              class: { is_active: _vm.current == i },
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(_vm._s(item.description)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            [
              _c(
                "swiper",
                {
                  ref: "itemSwiper",
                  staticClass: "edit_list",
                  class: _vm.addClass,
                  attrs: { options: _vm.playlistSlidOpt },
                },
                _vm._l(_vm.itemData, function (item, i) {
                  return _c(
                    "swiper-slide",
                    { key: item.id, staticClass: "edit_node" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "edit_item slide_item",
                          class: { is_active: _vm.current == i },
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function ($event) {
                              return _vm.setCurrentIndex(i)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "item_head" }, [
                            _c("div", { staticClass: "thumb_area" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    item.mimeType === "image/jpeg"
                                      ? item.imageInfos[0].resUrl
                                      : item.videoThumbInfos[0].resUrl,
                                  alt: item.title ? item.title : "",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "item_body" }, [
                            _c("div", { staticClass: "ttl_area" }, [
                              _c("h3", { staticClass: "ttl" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("p", { staticClass: "desc" }, [
                                _vm._v(_vm._s(item.description)),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
      _c("div", { staticClass: "set_inner" }, [
        _c("div", { staticClass: "controller_group" }, [
          _c("div", { staticClass: "control_area slide_nav_area" }, [
            _c(
              "button",
              { staticClass: "btn type_ic prev" },
              [_c("ArwPrev")],
              1
            ),
            _c(
              "button",
              { staticClass: "btn type_ic next" },
              [_c("ArwNext")],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }