// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal .modal_body + .modal_foot[data-v-074cd61f] {
  margin-top: 4.5rem;
}
.modal_body .bg_edit_wrap .ic_area.type_camera[data-v-074cd61f] {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.modal_body .profile_area[data-v-074cd61f] {
  position: relative;
}
.modal_body .profile_area .profile[data-v-074cd61f] {
  position: relative;
  display: inline-block;
  width: 13.2rem;
  height: 13.2rem;
  border-radius: 50%;
  background-size: cover;
}
.modal_body .profile_area .profile .ic_area.type_camera[data-v-074cd61f] {
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
}
.modal_body .name_group[data-v-074cd61f] {
  max-width: 100%;
  margin: 2rem auto 1rem;
}
.modal_body .name_group .input_box[data-v-074cd61f] {
  width: calc(100% - 17rem);
}
.modal_body .name_group .btn[data-v-074cd61f] {
  padding: 0;
}
.modal_body .zipcode_area h4[data-v-074cd61f] {
  margin: 2.5rem 0 1.5rem 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 500;
}
.modal_body .zipcode_area .zipcode_group[data-v-074cd61f] {
  padding: 0 2rem 1.8rem 2rem;
  border: 1px solid #4a4a4a;
}
.modal_body + .modal_foot[data-v-074cd61f] {
  margin-top: 30px;
}
.account_group .profile_info[data-v-074cd61f] {
  margin-right: 2rem;
  vertical-align: middle;
}
.email_input_group[data-v-074cd61f] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email_input_group label[data-v-074cd61f] {
  display: block;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  color: #fff;
}
.drop_ui[data-v-074cd61f] {
  text-align: left;
}
.is_scroll[data-v-074cd61f] {
  height: 80px !important;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  /* .modal_body .profile_area .profile {
    width: 10rem !important;
    height: 10rem !important;
  } */
.modal_body .profile_area .profile .ic_area.type_camera[data-v-074cd61f] {
    right: -1rem;
    bottom: 0;
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.6rem;
}
.ic_area.type_camera .ic[data-v-074cd61f] {
    width: 2.4rem !important;
    height: 2.4rem !important;
}
.modal_body .name_group[data-v-074cd61f] {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}
.modal_body .name_group .input_box[data-v-074cd61f] {
    flex: 1;
    margin-right: 1rem;
}
.modal_body .name_group .btn[data-v-074cd61f] {
    display: flex;
    min-width: 10rem;
    height: 4rem;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
}
.account_group .profile_info .profile[data-v-074cd61f] {
    width: 4rem !important;
    height: 4rem !important;
}
.btn_area_no_margin_top[data-v-074cd61f] {
    margin-top: 0px;
}
.modal_body .zipcode_area h4[data-v-074cd61f] {
    margin: 2.5rem 0 1.5rem 0;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 500;
}
.modal_body .zipcode_area .zipcode_group[data-v-074cd61f] {
    padding: 0;
    border: none;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
