var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit_item type_card" }, [
    _c("div", { staticClass: "item_head" }, [
      _c(
        "button",
        {
          staticClass: "thumb_area",
          attrs: { "aria-hidden": "true" },
          on: {
            click: function ($event) {
              return _vm.goCuration(_vm.itemData.dropsId)
            },
          },
        },
        [
          _vm.source.small
            ? _c("img", {
                attrs: {
                  src: _vm.source.small,
                  width: 480,
                  alt: _vm.itemData.title ? _vm.itemData.title : "",
                },
              })
            : _c("img", {
                attrs: {
                  src: require("@/assets/img/img_illust_nodata.png"),
                  alt: "nodata",
                },
              }),
        ]
      ),
      _c(
        "div",
        { staticClass: "ic_area type_blockchains" },
        [_vm.itemData.bcNwTp == "10" ? _c("IcHedera") : _c("IcEth")],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn type_ic btn_like",
          class: {
            is_active: _vm.likeStatus,
          },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.likeThis(_vm.itemData.dropsId)
            },
          },
        },
        [
          !_vm.likeStatus
            ? _c("IcLike", {
                ref: "icLike",
                attrs: { status: !_vm.likeStatus, "icon-color": "#fff" },
              })
            : _c("IcLike", {
                ref: "icLike",
                attrs: { "icon-color": "#ff521d" },
              }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "item_body" }, [
      _c("button", {
        staticClass: "link",
        attrs: {
          "aria-describedby": _vm.itemData.dropsId,
          title: _vm.itemData.dropsId,
        },
        on: {
          click: function ($event) {
            return _vm.goCuration(_vm.itemData.dropsId)
          },
        },
      }),
      _c("div", { staticClass: "basic_info_group" }, [
        _c(
          "button",
          {
            staticClass: "profile_info is_art",
            on: {
              click: function ($event) {
                return _vm.goArtistProfile()
              },
            },
          },
          [
            _vm.itemData.artistAvatarInfos &&
            _vm.itemData.artistAvatarInfos.length
              ? _c("span", {
                  staticClass: "profile",
                  style: `background-image: url(${_vm.itemData.artistAvatarInfos[0].resUrl})`,
                })
              : _c("IcProfile", { staticClass: "profile" }),
            _c("IcRing", { staticClass: "ic_ring" }),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.itemData.artistName)),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "ttl_area" }, [
          _c(
            "h3",
            { staticClass: "ttl", attrs: { id: _vm.itemData.artworkId } },
            [_vm._v(_vm._s(_vm.itemData.title))]
          ),
          _c("p", { staticClass: "edit" }),
        ]),
      ]),
      _c("div", { staticClass: "state_info clearFix" }, [
        _c("div", { staticClass: "date_area endedDate" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("UsaFormat")(
                  _vm._f("GmtToTz")(_vm.itemData.endedEndDate),
                  "onlyDate"
                )
              ) +
              " "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }