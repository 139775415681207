var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCloseDrop",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [_c("path", { attrs: { d: "m5.92 19.54 8.648-9.08 9.512 9.08" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }