var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icPauseL",
        "icon-label": _vm.iconLabel,
        width: 60,
        height: 60,
        viewBox: "0 0 60 60",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", { attrs: { "data-name": "Line 10", d: "M20.5 11v38" } }),
      _c("path", { attrs: { "data-name": "Line 11", d: "M39.5 11v38" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }