var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { ref: "footer", attrs: { id: "footer" } }, [
    _c("article", { staticClass: "fnb" }, [
      _c("div", { staticClass: "fnb_head clearFix" }, [
        _c("div", { staticClass: "logo_wrap" }, [
          _c("p", { staticClass: "logo_area" }, [_c("Logo")], 1),
        ]),
        _vm.$parent.isMob
          ? _c("div", { staticClass: "info_wrap" }, [_vm._m(0)])
          : _vm._e(),
        _c("div", { staticClass: "menu_wrap" }, [
          _c(
            "ul",
            { staticClass: "menu_list clearFix" },
            _vm._l(_vm.fnbMenuItems, function (item, i) {
              return _c(
                "li",
                { key: "main_menu" + i, staticClass: "menu_node" },
                [
                  _c(
                    "router-link",
                    { staticClass: "menu_item", attrs: { to: item.link } },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c("ul", { staticClass: "menu_list sns_menu clearFix" }, [
            _c("li", { staticClass: "menu_node" }, [
              _c(
                "a",
                {
                  staticClass: "menu_item",
                  attrs: {
                    href: "https://twitter.com/LGArtLab",
                    target: "_blank",
                    title: "Open in new window",
                    "aria-label": "Twitter",
                  },
                },
                [_c("IcFootTwitter")],
                1
              ),
            ]),
            _c("li", { staticClass: "menu_node" }, [
              _c(
                "a",
                {
                  staticClass: "menu_item",
                  attrs: {
                    href: "https://www.instagram.com/lg_oledart/ ",
                    target: "_blank",
                    title: "Open in new window",
                    "aria-label": "instagram",
                  },
                },
                [_c("IcFootInstagram")],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "fnb_body" }, [
        _c("div", { staticClass: "info_wrap" }, [
          !_vm.$parent.isMob
            ? _c("p", [
                _vm._v(
                  " Create, sell and collect truly rare digital artworks. "
                ),
                _c("br"),
                _vm._v("Powered by blockchain technology. "),
              ])
            : _vm._e(),
          _c("p", { staticClass: "copy" }, [
            _c("label", { on: { click: _vm.testLink } }, [_vm._v("©")]),
            _vm._v(" 2022 LG Electronics. All rights reserved. "),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        ref: "floatEl",
        staticClass: "float_btn_area",
        class: [
          { is_active: _vm.showFixEl },
          { fix_bottom: _vm.fixBottom },
          { dev_mob: _vm.$parent.isMob },
        ],
      },
      [
        _c(
          "button",
          { staticClass: "btn basic btn_gotop", on: { click: _vm.goToTop } },
          [_vm._v("top")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Create, sell and collect truly rare digital artworks. "),
      _c("br"),
      _vm._v("Powered by blockchain technology. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }