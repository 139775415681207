var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCopy",
        "icon-label": _vm.iconLabel,
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", { attrs: { d: "M4.6 4.6h11.8v11.8H4.6z" } }),
      _c("path", {
        attrs: {
          fill: _vm.fillColor,
          "stroke-width": "0",
          d: "M19 6v13H6v1h14V6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }