var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icAlarm",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 75",
          d: "M22.414 19.69v-7.887a7.313 7.313 0 0 0-14.625 0v7.887L5.2 22.5H25Z",
        },
      }),
      _c("path", { attrs: { "data-name": "Path 109", d: "M11 26.5h8" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }