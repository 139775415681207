var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "arwSelect",
        "icon-label": _vm.iconLabel,
        width: 20,
        height: 20,
        viewBox: "0 0 20 20",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [_c("path", { attrs: { d: "m4.5 7.499 5.714 6 6.285-6" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }