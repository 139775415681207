var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showChangeWallet } },
    [
      _c("div", { staticClass: "modal", attrs: { role: "dialog" } }, [
        _c("div", { staticClass: "modal_head" }, [
          _c("h3", { staticClass: "modal_ttl" }, [
            _vm._v(" " + _vm._s(_vm.modalData.title) + " "),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.modalData.desc))]),
        ]),
        _c("div", { staticClass: "modal_body" }, [
          _c("ul", { staticClass: "wallet_list" }, [
            _vm.openBlade
              ? _c("li", { staticClass: "wallet_item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn type_line",
                      on: {
                        click: function ($event) {
                          return _vm.close("WLT001")
                        },
                      },
                    },
                    [
                      _c("picture", { staticClass: "thumb" }, [
                        _c("img", {
                          attrs: {
                            width: "30",
                            height: "30",
                            src: require("@/assets/img/img_mob_wallet_blade.png"),
                            srcset: `${require("@/assets/img/img_mob_wallet_blade_4x.png")} 4x, ${require("@/assets/img/img_mob_wallet_blade_3x.png")} 3x, ${require("@/assets/img/img_mob_wallet_blade_2x.png")}`,
                            alt: "img_mob_wallet_blade",
                          },
                        }),
                      ]),
                      _c("strong", [_vm._v("Blade Wallet")]),
                      _c("b", { staticClass: "badge" }, [_vm._v("Popular")]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.openWallypto
              ? _c("li", { staticClass: "wallet_item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn type_line",
                      on: {
                        click: function ($event) {
                          return _vm.close("WLT002")
                        },
                      },
                    },
                    [
                      _c("picture", { staticClass: "thumb" }, [
                        _c("img", {
                          attrs: {
                            width: "30",
                            height: "30",
                            src: require("@/assets/img/img_mob_wallet_wallypto.png"),
                            srcset: `${require("@/assets/img/img_mob_wallet_wallypto_4x.png")} 4x, ${require("@/assets/img/img_mob_wallet_wallypto_3x.png")} 3x, ${require("@/assets/img/img_mob_wallet_wallypto_2x.png")}`,
                            alt: "img_mob_wallet_wallypto",
                          },
                        }),
                      ]),
                      _c("strong", [_vm._v("Wallypto")]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "button",
          {
            staticClass: "btn type_ic close_modal",
            on: {
              click: function ($event) {
                return _vm.close("")
              },
            },
          },
          [_c("IcCloseM")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }