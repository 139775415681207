var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal_wrap", class: { is_show: _vm.showEthModal } },
    [
      _c(
        "div",
        {
          ref: "setPrice",
          staticClass: "modal ethModal",
          class: _vm.modalSize,
          attrs: {
            id: "setPrice",
            role: "dialog",
            "aria-labelledby": "setPrice_title",
            "aria-describedby": "setPrice_desc",
          },
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "modal_body" }, [
            _vm.isLoading
              ? _c("div", [_c("LoadingSpinner")], 1)
              : _c("div", { staticClass: "input_area txt" }, [
                  _c("div", { staticClass: "input_box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.setPrice,
                          expression: "setPrice",
                        },
                      ],
                      attrs: {
                        type: "number",
                        title: "Price",
                        placeholder: "0.001",
                        label: "set Price",
                        refs: "ethInput",
                      },
                      domProps: { value: _vm.setPrice },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.setPrice = $event.target.value
                          },
                          _vm.checkInput,
                        ],
                        paste: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    }),
                    _c("span", [_vm._v("ETH")]),
                  ]),
                ]),
          ]),
          _c("div", { staticClass: "modal_foot" }, [
            _c("div", { staticClass: "btn_area grid flex" }, [
              _c(
                "button",
                {
                  staticClass: "btn col basic w_s h_m",
                  on: { click: _vm.close },
                },
                [_vm._v("Cancel")]
              ),
              !_vm.isLoading
                ? _c(
                    "button",
                    {
                      staticClass: "btn col strong w_s h_m",
                      on: { click: _vm.onAppSetPrice },
                    },
                    [_vm._v(" OK ")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "btn type_ic close_modal",
              on: { click: _vm.close },
            },
            [_c("IcCloseM")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl" }, [_vm._v("Set A Price")]),
      _c("p", [_vm._v("Set a price for the artwork.")]),
      _c("p", [_vm._v("(A 10% royalty payment will be applied)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }