// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";

/*-------------------------------------------------------------------
	파일정의 : 그리드 스타일
	작성날짜 : 2019-10-22 장병주
	참고사항 : 그리드 시스템 정의
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/

/* Grid */
.grid:after {
  display: block;
  content: '';
  clear: both;
}

.grid > .col {
  float: left;
  width: 100%;
  min-height: 1px;
  box-sizing: border-box;
}

.grid > .col.right {
  float: right;
}

/* 구터 넒이 */
.grid.grid_5 {
  margin-left: -5px;
  margin-right: -5px;
}

.grid.grid_5 > .col {
  padding: 0 5px;
}

.grid.grid_10 {
  margin-left: -10px;
  margin-right: -10px;
}

.grid.grid_10 > .col {
  padding: 0 10px;
}

.grid.grid_15 {
  margin-left: -15px;
  margin-right: -15px;
}

.grid.grid_15 > .col {
  padding: 0 15px;
}

.grid.grid_30 {
  margin-left: -30px;
  margin-right: -30px;
}

.grid.grid_30 > .col {
  padding: 0 30px;
}

.grid.ib {
  font-size: 0;
}

.grid.ib > .col {
  display: inline-block;
  float: none;
}

/*flex*/
.grid.flex {
  display: flex;
}

.grid.flex .col {
  float: none !important;
  flex: 1;
  padding: 0 !important;
}

.grid .col.flex_2 {
  flex: 2;
}

.grid.grid_5.flex > .col {
  margin: 0 5px;
}

.grid.grid_10.flex > .col {
  margin: 0 10px;
}

/* 너비 - 자신 칸수 */
.grid > .col.col_1 {
  width: 8.33333333%;
}

.grid > .col.col_2 {
  width: 16.6666%;
}

.grid > .col.col_3 {
  width: 25%;
}

.grid > .col.col_4 {
  width: 33.3333%;
}

.grid > .col.col_5 {
  width: 41.66666667%;
}

.grid > .col.col_6 {
  width: 50%;
}

.grid > .col.col_7 {
  width: 58.33333333%;
}

.grid > .col.col_8 {
  width: 66.66666667%;
}

.grid > .col.col_9 {
  width: 75%;
}

.grid > .col.col_10 {
  width: 83.33333334%;
}

.grid > .col.col_11 {
  width: 91.66666667%;
}

.grid > .col.col_12 {
  width: 100%;
}

.grid > .col.col_x5 {
  width: 20%;
}

@media screen and (max-width: 1024px) {
  .grid > .col.col_3,
  .grid > .col.col_4 {
    width: 50%;
  }
}

/* 태블릿 이하 */
@media screen and (max-width: 600px) {
  /* 구터 넒이 */
  .grid.grid_5 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .grid.grid_5 > .col {
    padding: 0 2px;
  }

  .grid.grid_10 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid.grid_10 > .col {
    padding: 0 5px;
  }

  .grid.grid_15 {
    margin-left: -7px;
    margin-right: -7px;
  }
  .grid.grid_15 > .col {
    padding: 0 7px;
  }

  .grid.grid_20 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid.grid_20 > .col {
    padding: 0 10px;
  }

  /* 너비 - 자신 칸수 */
  .grid > .col.col_1,
  .grid > .col.col_2 {
    width: 25%;
  }
  .grid > .col.col_x5,
  .grid > .col.col_3 {
    width: 50%;
  }
  .grid > .col.col_4,
  .grid > .col.col_5,
  .grid > .col.col_6,
  .grid > .col.col_7,
  .grid > .col.col_8,
  .grid > .col.col_9,
  .grid > .col.col_10,
  .grid > .col.col_11,
  .grid > .col.col_12 {
    width: 100%;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
