var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "drops_page list_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Drops")]),
      _c("div", { staticClass: "set_inner" }, [
        _vm.liveInfos && _vm.liveInfos.dropsId
          ? _c("section", { staticClass: "list_sec live_sec" }, [
              _vm._m(0),
              _c("div", { staticClass: "sec_body" }, [
                _c(
                  "div",
                  {
                    staticClass: "img_wrap clickable",
                    on: {
                      click: function ($event) {
                        return _vm.goCuration(_vm.dropsId)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.liveDropsMainImg,
                        width: 980,
                        alt:
                          "Capture of Artwork for the " + _vm.liveInfos.title,
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "info_wrap" }, [
                  _c(
                    "div",
                    { staticClass: "time_group clearFix" },
                    [
                      _vm.liveInfos.dropsEndDate
                        ? _c("CountDown", {
                            attrs: { "date-time": _vm.liveInfos.dropsEndDate },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ttl_area artist_info" }, [
                    _c("h3", { staticClass: "ttl" }, [
                      _c("b", [_vm._v(_vm._s(_vm.liveInfos.artistName))]),
                    ]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(_vm._s(_vm.liveInfos.artistDescription)),
                    ]),
                  ]),
                  _c("div", { staticClass: "ttl_area drops_info" }, [
                    _c("h4", { staticClass: "ttl" }, [
                      _c("b", [_vm._v(_vm._s(_vm.liveInfos.title))]),
                    ]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(_vm._s(_vm.liveInfos.artworkDescription)),
                    ]),
                  ]),
                  _c("div", { staticClass: "btn_area" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn w_s h_l strong",
                        attrs: {
                          title: "artwork named the " + _vm.liveInfos.title,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goCuration(_vm.dropsId)
                          },
                        },
                      },
                      [_vm._v(" View Details ")]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.upcomingInfos.length
          ? _c("section", { staticClass: "list_sec upcoming_sec" }, [
              _vm._m(1),
              _c("div", { staticClass: "sec_body" }, [
                _c("div", { staticClass: "upcoming_flow_wrap" }, [
                  _vm.upcomingInfos
                    ? _c(
                        "ul",
                        { staticClass: "flow_list" },
                        _vm._l(_vm.upcomingInfos, function (flow, index) {
                          return _c(
                            "li",
                            {
                              key: flow.artworkId + index,
                              staticClass: "flow_node",
                            },
                            [
                              _c("div", { staticClass: "flow_item" }, [
                                _c("div", { staticClass: "date_info" }, [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        is_pc_date: !_vm.isMob,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.setUpcomingDateStr(
                                              flow.dropsId,
                                              flow.dropsStartDate,
                                              !_vm.isMob
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "item_head clickable",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goCuration(flow.dropsId)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "thumb_area" }, [
                                      _c("button", [
                                        flow.mimeType === "video/mp4"
                                          ? _c("img", {
                                              attrs: {
                                                srcSet: `${flow.videoThumbInfos[0].resUrl} 768w,
                        ${flow.videoThumbInfos[0].resUrl}`,
                                                src: flow.videoThumbInfos[0]
                                                  .resUrl,
                                                width: 598,
                                                alt:
                                                  "Capture of Artwork for the " +
                                                  flow.title,
                                              },
                                            })
                                          : flow.mimeType === "image/jpeg" &&
                                            flow.imageInfos[0]
                                          ? _c("img", {
                                              attrs: {
                                                srcSet: `${flow.imageInfos[0].resUrl} 768w,
                        ${flow.imageInfos[0].resUrl}`,
                                                src: flow.imageInfos[0].resUrl,
                                                width: 598,
                                                alt:
                                                  "Capture of Artwork for the " +
                                                  flow.title,
                                              },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/img_illust_nodata.png"),
                                                alt: "nodata",
                                              },
                                            }),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "item_body" }, [
                                  _c("div", { staticClass: "ttl_area" }, [
                                    _c("h3", { staticClass: "ttl" }, [
                                      _c("b", [
                                        _vm._v(_vm._s(flow.artistName)),
                                      ]),
                                    ]),
                                    _c("p", { staticClass: "desc" }, [
                                      _c("b", [
                                        _vm._v(_vm._s(flow.artistDescription)),
                                      ]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "btn_area" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn basic w_xl h_l",
                                        class: {
                                          is_active:
                                            _vm.alarmList.indexOf(
                                              flow.dropsId
                                            ) !== -1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setAlarm(flow.dropsId)
                                          },
                                        },
                                      },
                                      [
                                        _vm.alarmList.indexOf(flow.dropsId) ===
                                        -1
                                          ? _c("IcAlarm", {
                                              staticClass: "ic_alarm",
                                              attrs: {
                                                "stroke-color": "#000",
                                                alarmList: _vm.alarmList,
                                              },
                                              on: {
                                                "update:alarmList": function (
                                                  $event
                                                ) {
                                                  _vm.alarmList = $event
                                                },
                                                "update:alarm-list": function (
                                                  $event
                                                ) {
                                                  _vm.alarmList = $event
                                                },
                                              },
                                            })
                                          : _c("IcCheck", {
                                              staticClass: "ic_check",
                                              attrs: {
                                                alarmList: _vm.alarmList,
                                              },
                                              on: {
                                                "update:alarmList": function (
                                                  $event
                                                ) {
                                                  _vm.alarmList = $event
                                                },
                                                "update:alarm-list": function (
                                                  $event
                                                ) {
                                                  _vm.alarmList = $event
                                                },
                                              },
                                            }),
                                        _c("span", [
                                          _vm._v("Get Notification"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.endedInfos && _vm.endedInfos.length
          ? _c("section", { staticClass: "list_sec ended_sec" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "sec_body" },
                [
                  _c("EndedSlide", {
                    attrs: {
                      "item-data": _vm.endedInfos,
                      "slide-opt": _vm.endedSlidOpt,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "sec_head" }, [
      _c("div", { staticClass: "ttl_area" }, [
        _c("h2", { staticClass: "ttl" }, [
          _c("b", [_vm._v("Live")]),
          _vm._v(" Drops"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "sec_head" }, [
      _c("div", { staticClass: "ttl_area" }, [
        _c("h2", { staticClass: "ttl" }, [
          _c("b", [_vm._v("Upcoming")]),
          _vm._v(" Drops"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "sec_head" }, [
      _c("div", { staticClass: "ttl_area" }, [
        _c("h2", { staticClass: "ttl" }, [
          _c("b", [_vm._v("Ended")]),
          _vm._v(" Drops"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }