// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/ic/ic_wallet.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../img/ic/ic_arrow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my_list_page {
  padding-top: 6rem;
  padding-bottom: 15rem;
}
.my_list_page .page_ttl {
  border-bottom: 0.1rem solid var(--line-color);
  padding-bottom: 2rem;
  font-size: 3.2rem;
  font-weight: 300;
  color: #fff;
}
.my_list_page .owner_info_area {
  margin-top: 0.7rem;
}
.my_list_page .owner_info_area dl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my_list_page .owner_info_area dt {
  flex-shrink: 0;
  padding-right: 2rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.my_list_page .owner_info_area dd {
  font-size: 1.4rem;
  font-weight: 200;
  color: #ffffff;
}
.my_list_page .owner_info_area dd .profile {
  margin-right: 0;
}
.my_list_page .owner_info_area dd .name {
  margin-left: 0.8rem;
}
.my_list_page .basic_info_group {
  min-height: 12.4rem;
}
.my_list_page .time_area {
  float: right;
  padding: 0;
}
.my_list_page .time_area .type {
  margin-bottom: 5px;
  font-size: 1.4rem;
  color: var(--txt-gray-2);
  text-align: right;
}
.my_list_page .time_area span:first-of-type {
  padding-left: 0;
}
.my_list_page .time_area span:last-of-type {
  padding-right: 0;
}
.my_list_page .time_area span {
  position: relative;
  font-weight: 300;
  padding: 0 0.6rem;
}
.my_list_page .time_area span:not(:first-of-type):before {
  display: block;
  content: ':';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  margin-top: -0.2rem;
  font-size: 1.6rem;
  font-weight: 300;
  color: #a09f9f;
  transform: translateX(-50%);
}
.my_list_page .data_length_area .ttl {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--txt-gray-2);
}
.my_list_page .data_length_area .ttl em {
  color: var(--main-color);
}
.my_list_page .favorites_sec {
  padding-top: 5rem;
}
.my_list_page .favorites_sec_head {
  margin-bottom: 1.7rem;
}
.my_list_page .favorites_sec_head .ttl {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--txt-gray);
}
.my_list_page .favorites_sec_head .ttl em {
  color: var(--main-color);
}
.favorites_sec .guide_ui p {
  margin-top: 3.5rem;
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
}
.favorites_sec .guide_ui .btn {
  margin-top: 7rem;
  min-width: 30rem;
}

/* wallet_page */
.wallet_page .wallet_sec {
  padding-top: 5rem;
}
.wallet_page .wallet_sec .wallet_title,
.wallet_page .wallet_sec .wallet_connect_title {
  margin: 0 auto 5rem auto;
  text-align: center;
}
.wallet_page .wallet_sec .wallet_title h2,
.wallet_page .wallet_sec .wallet_connect_title h2{
  margin: 0 0 2rem 0 !important;
  font-size: 3.2rem;
  font-weight: 500;
  color: #fff;
}
.wallet_page .wallet_sec .wallet_title p,
.wallet_page .wallet_sec .wallet_connect_title p{
  font-size: 1.8rem;
  line-height: 1.6;
}
.wallet_page .wallet_item_wrap {
  display: flex;
  align-items: stretch;
  padding: 4rem 0;
  background: var(--box-bg);
}
.wallet_list_group{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3rem 7%;
}
.wallet_list_group .name_area {
  width: calc(100% - 448px);
  margin-left: 3rem;
  padding-right: 7rem;
}
.wallet_list_group .name_area p{
  margin: 0 0 1.8rem 0;
  font-size: 3.2rem;
  font-weight: 400;
  color: #fff;
}
.wallet_list_group .name_area span{
  font-size: 2.2rem;
  color: #aaa;
}
.wallet_page .wallet_item_wrap + .wallet_item_wrap{
  margin-top: 3rem;
}
.wallet_page .wallet_item_wrap .wallet_item_head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28%;
  border-right: 0.1rem solid var(--line-color);
  border-bottom: none;
}
.wallet_page .wallet_item_wrap .wallet_item_head .wallet_info_group{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wallet_page .wallet_item_wrap .wallet_item_head .img_area {
  display: inline-block;
  max-width: 7rem;
}
.wallet_page .wallet_item_wrap .wallet_info_group .name_area {
  margin: 0.8rem 0 1.8rem 0;
  font-size: 3.2rem;
  font-weight: 400;
  color: #fff;
  vertical-align: middle;
}
.wallet_page .wallet_item_wrap .wallet_address {
  display: inline-block;
  min-width: 40rem;
  font-size: 1.6rem;
  color: var(--txt-gray-2);
  text-align: right;
}
.wallet_page .wallet_item_wrap .wallet_address p {
  overflow: hidden;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wallet_page .wallet_item_wrap .wallet_item_body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width:90%;
  padding:0 7%;
  font-size: 0;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area,
.wallet_page .wallet_item_wrap .wallet_item_body .money_area {
 
  padding: 0;
  vertical-align: top;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area {
  position: relative;
}
.wallet_page .wallet_item_wrap .wallet_item_body .money_area {
}

/* .wallet_page .wallet_item_wrap .wallet_item_body .account_area:after {
  position: absolute;
  content: '';
  right: 0;
  top: 50%;
  width: 0.1rem;
  height: 4rem;
  margin-top: -2rem;
  background: var(--line-color);
} */
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dl,
.wallet_page .wallet_item_wrap .wallet_item_body .money_area dl {
  display: flex;
  justify-content: space-between;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dt,
.wallet_page .wallet_item_wrap .wallet_item_body .money_area dt{
  font-size: 2.2rem;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dd{
  text-align: right;
  font-size: 2.2rem;
  font-weight: 200;
}
.wallet_page .wallet_item_wrap .wallet_item_body .money_area dd {
  text-align: right;
  font-size: 3.2rem;
  font-weight: 500;
  word-break: break-all;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dd {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dt,
.wallet_page .wallet_item_wrap .wallet_item_body .money_area dt {
  /* padding-top:0.7rem; */
  flex-shrink: 0;
  color: var(--txt-gray-2);
}
.wallet_page .wallet_item_wrap .wallet_item_body .money_area dl + dl {
  margin-top: 2rem;
}
.wallet_page .btn_area {
  text-align: center;
}
.wallet_page .wallet_sec .guide_ui {
  max-width: 62rem;
  margin: 0 auto;
  padding: 0;
}
.wallet_page .wallet_sec .guide_ui h2 {
  margin-top: 3rem;
  font-size: 3.4rem;
  font-weight: 300;
  color: #fff;
}
.wallet_page .wallet_sec .guide_ui p {
  margin: 3rem 1rem;
  font-size: 1.8rem;
  font-weight: 200;
  line-height: 1.6;
  color: #fff;
}

/* .wallet_page .wallet_sec .guide_ui .btn_area {
  display: flex;
} */

/* activities_page */
.activities_page .activities_sec {
  padding-top: 4.2rem;
}
.activities_page .activities_sec_head .ttl_area {
  margin-bottom: 2rem;
}
.activities_page .activities_sec_head .ttl_area .ttl {
  font-size: 2.8rem;
  font-weight: 300;
}
.activities_page .date_group {
  display: flex;
  align-items: center;
}
.activities_page .date_group .or {
  font-size: 1.6rem;
  margin: 0 1.4rem;
}
.activities_page .date_group .wiggle {
  font-size: 1.6rem;
  margin: 0 1.2rem;
}
.activities_page .date_group .input_area {
  display: flex;
  align-items: center;
}
.activities_page .date_group .input_area .input_box {
  display: inline-block;
  position: relative;
  width: 26rem;
  height: 5rem;
}
.activities_page .date_group .input_area .input_box .vdp-datepicker,
.activities_page .date_group .input_area .input_box .vdp-datepicker > div {
  height: 100%;
}
.activities_page .date_group .input_area .input_box .ic {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -1.5rem;
  pointer-events: none;
}
.activities_page .date_group .input_area .input_box input {
  width: 100%;
  height: 100%;
  padding: 0 4rem 0 1.5rem;
  background: var(--box-bg);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.activities_page .date_group .search_btn {
  margin-left: 2rem;
}
.thumb_item.type_list .card_frame {
  position: relative;
  display: flex;
  border: 0.1rem solid var(--line-color);
}
.thumb_item.type_list .reject_area {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 4rem;
  color: #fff;
  text-align: center;
}
.thumb_item.type_list .card_head {
  flex-shrink: 0;
}
.thumb_item.type_list .card_body {
  padding: 1.2rem 12rem 1.2rem 1.4rem;
}
.thumb_item.type_list .card_body .ttl_area .ttl {
  display: -webkit-box;
  overflow: hidden;
  margin: 1rem 0 1rem 0;
  font-size: 2rem;
  font-weight: 200;
  color: #fff;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.thumb_item.type_list .artist_info_group.is_art {
  position: relative;
}
.thumb_item.type_list .artist_info_group .ttl {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 200;
  vertical-align: middle;
}
.thumb_item.type_list .editions {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.thumb_item.type_list .contract_info_group{
  margin-bottom: 1rem;
}
.thumb_item.type_list .contract_info_group span{
  padding-right: 0.8rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.thumb_item.type_list .contract_info_group button{
  position: relative;
}
.thumb_item.type_list .contract_info_group button::after{
  content: '';
  position: absolute;
  left:0;
  right:0;
  bottom:0;
  height: 0.1rem;
  background: #fff;
}
.thumb_item.type_list .info_area{
  display: flex;
}
.thumb_item.type_list .owner_info_group{
  display: flex;
  flex-wrap:wrap;
  align-items: center;
}
.thumb_item.type_list .blockchains_info_group{
  display: inline-block;
}
.thumb_item.type_list .blockchains_info_group .blockchains{
  display: flex;
  align-items: center;
}
.thumb_item.type_list .blockchains_info_group .blockchains b{
  padding-left:0.8rem;
  font-size: 1.4rem;
  color: var(--txt-gray-2);
  font-weight: 200;
}
.thumb_item.type_list .owner_info_group .editions:before {
  content: '';
  display: inline-block;
  width: 0.1rem;
  height: 1.4rem;
  margin: 0 1rem 0 1.2rem;
  background-color: var(--line-color);
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group dl {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group dl:before {
  content: '';
  display: inline-block;
  width: 0.1rem;
  height: 1.4rem;
  margin: 0 1rem 0 1.2rem;
  background-color: var(--line-color);
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group dt {
  flex-shrink: 0;
  display: inline-block;
  margin-right: 2rem;
  font-size: 1.4rem;
  color: var(--txt-gray-2);
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group dd {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.4rem;
  font-weight: 200;
}
.thumb_item.type_list .owner_info_group dd i {
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group dd span {
  margin-left: 0.5rem;
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group .time {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group .time:before {
  content: '';
  display: inline-block;
  width: 0.1rem;
  height: 1.4rem;
  margin: 0 1rem 0 1.2rem;
  background-color: var(--line-color);
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group .time span {
  display: inline-block;
  vertical-align: middle;
}
.thumb_item.type_list .owner_info_group dd.profile_info {
  display: inline-flex;
}
.thumb_item.type_list .owner_info_group .profile {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}
.thumb_item.type_list .owner_info_group .profile_info .name {
  max-width: 18rem;
}
.thumb_item.type_list .card_foot {
  position: absolute;
  right: 0;
  max-width: 20rem;
  padding: 3.5rem 2rem 3.5rem 1rem;
  word-break: break-all;
}
.thumb_item.type_list .sale_info_group {
  border: none;
  padding-top: 0;
  text-align: right;
}
.thumb_item.type_list .sale_info_group dl {
  display: block;
}
.thumb_item.type_list .sale_info_group dt {
  min-width: 12rem;
  max-width: 12rem;
  height: 4rem;
  margin-bottom: 1.3rem;
  margin-left: auto;
  border: 0.1rem solid #fff;
  border-radius: 2rem;
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 4rem;
  color: #999999;
  text-align: center;
  box-sizing: border-box;
}
.thumb_item.type_list .sale_info_group dt.type_1 {
  border-color: #00b981;
  color: #00b981;
}
.thumb_item.type_list .sale_info_group dt.type_2 {
  border-color: #389bff;
  color: #389bff;
}
.thumb_item.type_list .sale_info_group dt.type_3 {
  border-color: var(--main-color);
  color: var(--main-color);
}
.activities_page .thumb_item.type_list .sale_info_group dd {
  font-size: 1.8rem;
  font-weight: 300;
}
.thumb_item.type_list .sale_info_group .price b {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 300;
}
.thumb_item.type_list .sale_info_group .price b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.thumb_item.type_list .sale_info_group .price b .dollars{
  font-size: 1.4rem;
  font-weight: 200;
}
.activities_page .thumb_item.type_list .ic_area {
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.activities_page .thumb_list {
  margin-bottom: 0;
}
.activities_page .thumb_list .thumb_node {
  margin-bottom: 2rem;
}
.activities_sec_body .list_wrap {
  margin-top: 4rem;
}
.activities_page .account_change_banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 3% 2.5rem 7%;
  background-color: #191919;
}
.activities_page .account_change_banner .ttl_area {
  display: flex;
  align-items: center;
}
.activities_page .account_change_banner .ttl_area::before{
  content: '';
  width: 5.8rem;
  height: 4.8rem;
  margin-right: 3.7rem;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
  background-size: contain;
}
.activities_page .account_change_banner .ttl_area .ttl {
  display: block;
  padding-top: 0.4rem;
  font-size: 1.8rem;
  font-weight: 400;
}
.activities_page .account_change_banner .ttl_area .time{
  color:#aaa;
}
.activities_page .account_change_banner .check_wallet_btn {
  width: 19rem;
  padding: 1.4rem 0;
  font-weight: 300;
  border-radius: 10rem;
  background: #000 url(${___CSS_LOADER_URL_REPLACEMENT_1___}) right 20px center no-repeat;
  background-size: 0.5rem;
}
.activities_sec_body .input_area.check label {
  padding-left: 0;
}
.activities_sec_body .drop_ui .drop_op_list .sort_tit {
  padding: 0 1.4rem 1rem 1.4rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.activities_sec_body .drop_ui .drop_op_list .sort_tit span {
  display: block;
  margin-top:1rem;
  padding-top: 2.5rem;
}
.activities_sec_body .drop_ui .drop_op_list .sort_tit span.bdTop {
  border-top:1px solid #333;
}
.activities_sec_body .drop_ui .drop_op_list [role='option'] span {
  display: flex;
  align-items: center;
  padding-left: 3.2rem;
  color:#fff;
}
.activities_sec_body .drop_ui .drop_op_list [role='option'] span:before,
.activities_sec_body .drop_ui .drop_op_list [role='option'] span:after{
  top:50%;
  transform: translateY(-50%);
}
.activities_sec_body .drop_ui .drop_op_list [role='option'] span i {
  margin-right: 0.6rem;
}

/* notification_page */
.notification_page .notification_sec {
  padding-top: 4.2rem;
}
.notification_page .notification_sec .notification_sec_head .ttl {
  margin-bottom: 1.7rem;
  font-size: 2.8rem;
  font-weight: 300;
  color: #fff;
}
.notification_page .notification_sec .notification_sec_head p {
  font-size: 1.6rem;
  font-weight: 200;
  color: #fff;
}
.notification_page .notification_sec .notification_sec_body {
  padding-top: 5rem;
}
.notification_page .notification_sec .info {
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
}
.notification_page .notification_sec .subscribe_list li {
  padding: 2.6rem 3rem;
  background: var(--box-bg);
}
.notification_page .notification_sec .subscribe_list li + li {
  margin-top: 2rem;
}
.notification_page .notification_sec .info_area .subscribe {
  margin-bottom: 1.2rem;
  font-weight: 300;
  color: #fff;
}
.notification_page .notification_sec .info_area p {
  color: var(--txt-gray-2);
  font-weight: 200;
}
.notification_page .notification_sec .subscribe_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
}
.notification_page .notification_sec .subscribe_group .input_box {
  display: inline-block;
  width: 5rem;
  height: 2.5rem;
}
.notification_page .notification_sec .btn_area {
  margin-top: 6rem;
  text-align: center;
}
.notification_page .notification_sec .delete_account {
  /* margin-top: -3.7rem; */
  margin-top: 1.5rem;
}
.notification_page .notification_sec .delete_account a {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--txt-gray);
}
.notification_page .notification_sec .delete_account a i {
  margin-left: 1rem;
  vertical-align: -7px;
}

/* my_artist_page */
.my_artist_page .artist_sec {
  padding-top: 5rem;
}
.my_artist_page .artist_sec .visual_wrap {
  width: 100%;
  height: 26rem;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.my_artist_page .artist_sec_head {
  position: relative;
}
.my_artist_page .artist_sec_head .profile_info {
  align-items: flex-end;
}
.my_artist_page .artist_sec_head .profile_info.is_art .profile {
  width: 9.4rem !important;
  height: 9.4rem !important;
  flex-shrink: 0;
  border-radius: 50%;
  background-size: cover;
  margin: -2.2rem 3.5rem -0.9rem 1.4rem !important;
}
.my_artist_page .artist_sec_head .profile_info.is_art .ic_ring {
  margin: -3rem 0.6rem;
}
.my_artist_page .artist_sec_head .profile_info .ttl {
  display: -webkit-box;
  overflow: hidden;
  max-width: 55rem;
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.my_artist_page .artist_sec_head .profile_group {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my_artist_page .artist_sec_head .profile_info .edit_btn {
  margin-left: 3.2rem;
}
.my_artist_page .artist_sec_head .profile_info .edit_btn i {
  margin-top: 0.5rem;
}
.my_artist_page .artist_sec_head .sns_area {
  position: absolute;
  top: 28rem;
  right: 0;
}
.my_artist_page .artist_sec_head .sns {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--line-color);
  line-height: 5rem;
  text-align: center;
}
.my_artist_page .artist_sec_head .sns + .sns {
  margin-left: 2rem;
}
.my_artist_page .artist_sec_head .sns i {
  vertical-align: -0.9rem;
}
.my_artist_page .follow_group {
  margin-top: 3rem;
  padding-left: 14.5rem;
  font-size: 0;
}
.my_artist_page .following,
.my_artist_page .followers {
  display: inline-block;
  position: relative;
  min-width: 13rem;
  font-size: 0;
}
.my_artist_page .followers {
  padding-left: 6rem;
}
.my_artist_page .followers:before {  
  content: '';
  display: block;
  position: absolute;
  top:0.2rem;
  left:0;
  width: 0.1rem;
  height: 5rem;
  background: #444;
}
.my_artist_page .follow_group .follow_area li button {
  display: inline-block;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  vertical-align: bottom;
}
.my_artist_page .follow_group .follow_area li button span {
  display: block;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.my_artist_page .desc_area {
  margin-top: 3.5rem;
  padding-left: 14.5rem;
}
.my_artist_page .desc_area .desc {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
}
.my_artist_page .artist_sec_body {
  margin-top: 5.7rem;
}
.my_artist_page .basic_info_group {
  min-height: 8.2rem;
}
.my_artist_page .tab_list {
  margin-bottom: 5rem;
}

/* artist_page */
.my_list_page.artist_page {
  padding-top: 0;
}
.artist_page .main_visual_sec {
  height: 28rem;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.artist_page .artist_sec_head {
  position: relative;
  text-align: center;
}
.artist_page .artist_sec_head .profile_info {
  display: block;
  pointer-events: none;
}
.artist_page .artist_sec_head .profile_info .profile {
  width: 11rem !important;
  height: 11rem !important;
  flex-shrink: 0;
  border-radius: 50%;
  background-size: cover;
  margin: -6.6rem 0 1.8rem !important;
}
.artist_page .artist_sec_head .profile_info.is_art .profile {
  width: 9.4rem !important;
  height: 9.4rem !important;
  margin: -4.7rem 0 3rem !important;
}
.artist_page .artist_sec_head .profile_info.is_art .ic_ring {
  position: absolute;
  left: 50%;
  margin-left: -5.5rem;
  margin-top: -5.5rem;
}
.artist_page .artist_sec_head .profile_info .ttl {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
}
.artist_page .artist_sec_head .sns_area {
  position: absolute;
  top: 3rem;
  right: 0;
}
.artist_page .artist_sec_head .sns {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  padding: 0.9rem;
  border-radius: 50%;
  border: 0.1rem solid var(--line-color);
  line-height: 5rem;
  text-align: center;
}
.artist_page .artist_sec_head .sns + .sns {
  margin-left: 2rem;
}
.artist_page .follow_group {
  margin-top: 2.8rem;
  font-size: 0;
}
.artist_page .follow_area {
  display: inline-block;
  vertical-align: middle;
}
.artist_page .following,
.artist_page .followers {
  display: inline-block;
  position: relative;
  min-width: 11rem;
  font-size: 0;
}
.artist_page .followers {
  margin-left: 6.4rem;
  padding-left: 5.5rem;
}
.artist_page .followers:before {
  content: '';
  display: block;
  position: absolute;
  top: 0.3rem;
  left: -0.1rem;
  width: 0.1rem;
  height: 5rem;
  background: #444;
}
.artist_page .follow_group .follow_area li button {
  display: inline-block;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
}
.artist_page .follow_group .follow_area li button span {
  display: block;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.artist_page .follow_group .btn_area {
  display: inline-block;
  vertical-align: middle;
}
.artist_page .follow_group .btn_area i {
  margin-right: 1rem;
  vertical-align: -0.8rem;
}
.artist_page .follow_group .btn_area:before {
  display: inline-block;
  content: '';
  margin-left: 6.4rem;
  margin-right: 5.5rem;
  width: 0.1rem;
  height: 5rem;
  background: #444;
}
.artist_page .desc_area {
  max-width: 72rem;
  margin: 3.5rem auto 0;
  text-align: center;
}
.artist_page .desc_area .desc {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
}
.artist_page .tab_list {
  margin-bottom: 5rem;
}
.artist_page .artist_sec_body {
  margin-top: 5.7rem;
}
.artist_page .basic_info_group {
  min-height: 8.1rem;
}
/* other page */
.other_page .other_sec_head {
  position: relative;
  text-align: center;
}
.other_page .other_sec_head .profile_info {
  display: block;
}
.other_page .other_sec_head .profile_info .profile {
  width: 11rem;
  height: 11rem;
  margin-bottom: 2.4rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-size: cover;
}
.other_page .other_sec_head .profile_info .ttl {
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
}
.other_page .follow_group {
  margin-top: 2.8rem;
  font-size: 0;
}
.other_page .follow_area {
  display: inline-block;
  vertical-align: middle;
}
.other_page .following,
.other_page .followers {
  display: inline-block;
  position: relative;
  min-width: 11rem;
  font-size: 0;
}
.other_page .followers {
  margin-left: 6.4rem;
  padding-left: 5.5rem;
}
.other_page .followers:before {  
  content: '';
  display: block;
  position: absolute;
  top: 0.3rem;
  left: -0.1rem;  
  width: 0.1rem;
  height: 5rem;
  background: #444;
}
.other_page .follow_group .follow_area li button {
  display: inline-block;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
}
.other_page .follow_group .follow_area li button span {
  display: block;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.other_page .follow_group .btn_area {
  display: inline-block;
  vertical-align: middle;
}
.other_page .follow_group .btn_area i {
  margin-right: 1rem;
  vertical-align: -0.8rem;
}
.other_page .follow_group .btn_area:before {
  display: inline-block;
  content: '';
  margin-left: 6.4rem;
  margin-right: 5.5rem;
  width: 0.1rem;
  height: 5rem;
  background: #444;
}
.other_page .other_sec_body {
  margin-top: 6.6rem;
}
.other_page .other_sec_body .data_length_area {
  padding-top: 4.2rem;
  margin-bottom: 1.7rem;
  border-top: 0.1rem solid var(--line-color);
}
.other_page .other_sec_body .guide_ui.no_data a.btn {
  margin-top: 7rem;
}
.my_list_page.other_page .basic_info_group {
  min-height: auto;
}

/* my_user_page */
.my_user_page .user_sec {
  padding-top: 5rem;
}
.my_user_page .data_length_area {
  margin-bottom: 1.7rem;
}
.my_user_page .basic_info_group {
  min-height: auto;
}
.my_user_page .state_info_group {
  position: relative;
  padding-top: 11px;
  margin-top: 15px;
  border-top: 1px solid var(--line-color);
}
.my_user_page .state_info_group .state_info {
  float: none;
  margin-top: 0;
  padding-top: 0;
  border: 0 none;
}
.my_user_page .state_info_group .btn_group {
  position: absolute;
  z-index: 11;
  top: 50%;
  right: -0.5rem;
  margin-top: -1.5rem;
  padding-top: 0.7rem;
}
.my_user_page .state_info_group .btn_group .share_area,
.my_user_page .state_info_group .btn_group .selling_area {
  display: inline-block;
}
.my_user_page .state_info_group .btn_group .selling_area {
  margin-left: 0.6rem;
}
.my_user_page .state_info_group .btn_group .share_area > button,
.my_user_page .state_info_group .btn_group .selling_area > button {
  width: 3rem;
  height: 3rem;
}
.my_user_page .state_info_group .btn_group button i {
  vertical-align: middle;
}
.layer_area {
  position: absolute;
  bottom: 100%;
  right: 3.4rem;
  width: 20rem;
  padding: 2.7rem 2.5rem;
  background: #000;
  border: 0.1rem solid var(--disabled-colr);
  box-sizing: border-box;
}
.layer_area.type {
  right: -0.1rem;
  padding: 2.4rem 3rem 2.8rem;
}
.layer_area:before,
.layer_area:after {
  display: block;
  content: '';
  position: absolute;
  top: 100%;
  z-index: 50;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top: 8px solid #000;
}
.layer_area:after {
  margin-top: -2px;
  right: 0.8rem;
}
.layer_area:before {
  right: 0.8rem;
  border-top-color: var(--disabled-colr);
}
.layer_area h3 {
  margin-bottom: 1.7rem;
  font-size: 2rem;
  font-weight: 300;
  color: #fff;
}
.my_user_page .state_info_group .btn_group .layer_area button {
  white-space: nowrap;
}
.my_user_page .state_info_group .btn_group .layer_area button i {
  margin: -0.2rem 0.8rem -0.2rem -0.3rem;
}
.layer_area .copy_group div + div {
  margin-top: 1.2rem;
}
.my_user_page .guide_ui.no_data .btn {
  margin-top: 7rem;
}
.activities_page .date_group .date_btn_area {
  display: flex;
}
.activities_page .date_group .date_btn_area .btn {
  background: var(--box-bg);
  color: var(--txt-gray);
  font-size: 1.6rem;
}
.activities_page .date_group .date_btn_area .btn.is_active {
  background: #fff;
  color: #000;
}
.activities_page .date_group .date_btn_area .dv_tab {
  margin-left: 1.3rem;
}
.activities_page .date_group .input_area .input_box input {
  font-size: 1.6rem;
}

/* Family_page */
.family_page .guide_ui.no_data h2 {
  margin-bottom: 3rem;
  font-size: 3.4rem;
  font-weight: 300;
  color: #fff;
}
.family_page .guide_ui.no_data p {
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
}
.family_page .guide_ui.no_data p em {
  color: var(--main-color);
}
.family_page .guide_ui.no_data .btn {
  margin-top: 7rem;
}

/* delete_page */
.delete_page .delete_sec {
  padding-top: 4.2rem;
}
.delete_page .delete_sec .delete_sec_head .ttl {
  margin-bottom: 1.7rem;
  font-size: 2.8rem;
  font-weight: 300;
  color: #fff;
}
.delete_page .delete_sec .delete_sec_head p {
  margin-bottom: 4rem;
  font-size: 1.6rem;
  font-weight: 200;
  color: #fff;
}
.delete_page .delete_sec .permanent_box {
  padding: 2.6rem 3rem;
  background: var(--box-bg);
}
.delete_page .delete_sec .permanent_box h3 {
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
}
.delete_page .delete_sec .permanent_box p {
  font-size: 1.6rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.delete_page .delete_sec .permanent_box ul {
  margin-top: 2rem;
}
.delete_page .delete_sec .permanent_box ul li {
  position: relative;
  padding-left: 1.4rem;
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 1.5;
  color: var(--txt-gray-2);
}
.delete_page .delete_sec .permanent_box ul li:before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0.7em;
  left: 0;
  margin-right: 1rem;
  width: 0.4rem;
  height: 0.4rem;
  background: var(--disabled-colr);
  border-radius: 50%;
  vertical-align: middle;
}
.delete_page .delete_sec .permanent_box ul li + li {
  margin-top: 0.5rem;
}
.delete_page .delete_sec .btn_area {
  margin-top: 6rem;
  text-align: center;
}
@media screen and (max-width: 1024px) {
.my_list_page {
    padding-top: 2.8rem;
    padding-bottom: 8rem;
}
.my_list_page .page_ttl {
    padding-bottom: 1rem;
}

  /* favorites_page */
.my_list_page .favorites_sec {
    padding-top: 3rem;
}
.my_list_page .favorites_sec_head {
    margin-bottom: 1.6rem;
}
.favorites_sec .guide_ui p {
    margin-top: 2.6rem;
}
.favorites_sec .guide_ui .btn {
    margin-top: 5rem;
}
.wallet_page .wallet_sec {
    padding-top: 3rem;
}
.wallet_page .wallet_sec .wallet_title,
  .wallet_page .wallet_sec .wallet_connect_title {
    margin:0 auto 3rem auto;
    padding:0 2rem;
}
.wallet_page .wallet_sec .wallet_title h2 {
    display: none;
}
.wallet_page .wallet_item_wrap {
    padding: 3rem 2rem;
}
.wallet_page .wallet_item_wrap .wallet_address {
    padding-right: 3rem;
}
.wallet_page .wallet_item_wrap .wallet_info_group .name_area {
    font-size: 2.4rem;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dt,
  .wallet_page .wallet_item_wrap .wallet_item_body .money_area dt{
    padding-top: 0.3rem;
    font-size: 1.8rem;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dd,
  .wallet_page .wallet_item_wrap .wallet_item_body .money_area dd {
    font-size: 2.2rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group {
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1rem 7%;
}
.wallet_page .wallet_item_wrap .wallet_list_group .img_area {
    width: 9.8rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group .name_area {
    width: calc(100% - 13.8rem);
    padding-right: 0;
}
.wallet_page .wallet_item_wrap .wallet_list_group .name_area p {
    margin: 0 0 0.8rem 0;
    font-size: 2.8rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group .name_area span {
    font-size: 2rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group .btn_area {
    width: 100%;
    text-align: left;
    padding-left:13rem;
}
.wallet_page .wallet_sec .guide_ui h2 {
    font-size: 2.4rem;
    font-weight: 500;
}
.wallet_page .wallet_sec .guide_ui p {
    margin: 2rem 0 1rem;
    font-size: 1.4rem;
}
.notification_page .notification_sec {
    padding-top: 3rem;
}
.delete_page .delete_sec {
    padding-top: 3rem;
}
.my_artist_page .artist_sec {
    padding-top: 0;
}
.my_artist_page .artist_sec_head .sns_area {
    position: static;
    padding-left: 18.2rem;
    margin-top: 4rem;
}
.activities_page .activities_sec {
    padding-top: 2rem;
}
.activities_page .date_group {
    display: block;
}
.activities_page .date_group .date_btn_area {
    display: flex;
}
.activities_page .date_group .date_btn_area li {
    width: 33.3333%;
    flex: 1;
    border-left: 1px solid var(--line-color);
}
.activities_page .date_group .date_btn_area li:last-child {
    display: flex;
    align-items: center;
    width: auto;
    flex:none;
}
.activities_page .date_group .date_btn_area .btn {
    width: 100%;
    
    min-width: auto;
    padding: 0 0.5rem;
}
.activities_page .date_group .date_btn_area li:first-child,
  .activities_page .date_group .date_btn_area li:last-child{
    border: none;
}
.activities_page .date_group .date_btn_area .dv_tab {
    margin-left: 1.3rem;
}
.activities_page .date_group .input_area {
    display: none;
    width: calc(100% - 4rem);
    margin-top: 2rem;
}
.activities_page .date_group .input_area.is_active {
    display: inline-block;
}
.activities_page .date_group .search_btn {
    display: none;
    margin-left: 1rem;
}
.activities_page .date_group .search_btn.is_active {
    display: inline-block;
}
.activities_page .activities_sec_head {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid var(--line-color);
}
.activities_page .date_group .search_btn i {
    vertical-align: middle;
}
.activities_page .date_group .input_area .input_box {
    width: 49%;
}
.activities_page .date_group .input_area .input_box:nth-of-type(2) {
    margin-left: 2%;
}
.thumb_item.type_list .card_frame {
    display: block;
}
.thumb_item.type_list .card_body {
    padding: 2rem 2rem 1.4rem;
}
.thumb_item.type_list .card_body .ttl_area .ttl {
    margin: 1.3rem 0 0.5rem;
}
.thumb_item.type_list .contract_info_group{position: absolute; left:2rem; bottom:12.2rem; margin:0;}
.thumb_item.type_list .owner_info_group{
    position:relative;
    flex-wrap: wrap;
}
.thumb_item.type_list .blockchains_info_group{display: block;}
.thumb_item.type_list .blockchains_info_group .blockchains{position: absolute; right:0.9rem; top:0.9rem;}
.thumb_item.type_list .blockchains_info_group .blockchains b{display: none;}
.thumb_item.type_list .owner_info_group dl{width: 100%;}
.thumb_item.type_list .owner_info_group .editions{margin-top:1rem;}
.thumb_item.type_list .owner_info_group .editions:before {
    display: none;
}
.thumb_item.type_list .owner_info_group .time {
    /* position:absolute;
    left:0;
    bottom:7.8rem; */
    display: block;
    margin-top: 3.9rem;
}
.thumb_item.type_list .owner_info_group .time:before {
    display: none;
}
.thumb_item.type_list .owner_info_group dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8rem;
}
.thumb_item.type_list .owner_info_group dl:before {
    display: none;
}
.thumb_item.type_list .card_foot {
    position: static;
    max-width: unset;
    padding: 0 2rem;
}
.thumb_item.type_list .sale_info_group {
    padding: 1.8rem 0 1.9rem;
    border-top: 0.1rem solid var(--line-color);
}
.thumb_item.type_list .sale_info_group dl {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.thumb_item.type_list .sale_info_group dt {
    margin-left: 0;
    margin-bottom: 0;
}
.thumb_item.type_list .reject_area {
    height: 5rem;
    line-height: 5rem;
}
.activities_sec .list_head.has_elem {
    display: block;
}
.activities_sec .list_head .count {
    margin-bottom: 1.6rem;
}
.activities_sec .sort_group .label {
    display: none;
}
.activities_sec .list_head .sort_group > div {
    width: 100%;
}
.activities_sec .list_head .sort_group .drop_box {
    width: 100%;
}
.activities_sec .drop_ui.select .drop_btn {
    width: 100%;
}
.activities_page .thumb_list {
    margin: 0 -1.5rem;
}
.activities_page .thumb_list .thumb_node {
    display: inline-block;
    width: 50%;
    padding: 0 1.5rem;
}
.activities_page .account_change_banner {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
    padding: 2.5rem;
}
.activities_page .account_change_banner .ttl_area::before {
    margin-right: 1.5rem;
}
}
@media screen and (max-width: 767px) {
.my_list_page .page_ttl {
    position: relative;
    border-bottom: 0 none;
    font-size: 2.4rem;
    font-weight: 500;
}
.my_list_page .page_ttl:before {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: -2rem;
    right: -2rem;
    height: 1px;
    background: var(--line-color);
}
.my_list_page .favorites_sec {
    padding-top: 2rem;
}
.my_list_page .favorites_sec_head {
    margin-bottom: 1.6rem;
}
.my_list_page .favorites_sec_head .ttl {
    font-size: 1.2rem;
}
.my_list_page .grid > .col.col_3 {
    width: 100%;
}
.my_list_page .edit_list .edit_node {
    margin-bottom: 4rem;
}
.favorites_sec .guide_ui p {
    font-size: 1.4rem;
}
.wallet_page .wallet_sec .wallet_title,
  .wallet_page .wallet_sec .wallet_connect_title {
    margin:0 auto 3rem auto;
    padding:0;
}
.wallet_page .wallet_sec .wallet_title h2{
    display: none;
}
.wallet_page .wallet_sec .wallet_connect_title h2 {
    margin: 0 0 1.5rem 0 !important;
    font-size: 2rem;
}
.wallet_page .wallet_sec .wallet_title p, 
  .wallet_page .wallet_sec .wallet_connect_title p {
    padding: 0 2rem;
    font-size: 1.4rem;
}
.wallet_page .wallet_item_wrap {
    position: relative;
    flex-direction: column;
    align-items: center;
}
.wallet_page .wallet_item_wrap .wallet_item_head {
    width: 100%;
    flex-direction: column;
    border-right:none;
}
.wallet_page .wallet_item_wrap .wallet_info_group {
    text-align: center;
}
.wallet_page .wallet_item_wrap .wallet_info_group .name_area {

    margin-top: 1rem;
    margin-left: 0;
    font-size: 2rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group .name_area span {
    font-size: 1.6rem;
}
.wallet_page .wallet_item_wrap .wallet_address {
    width: 100%;
    min-width: auto;
    margin-top: 1.5rem;
    padding-right: 0;
    text-align: center;
}
.wallet_page .wallet_item_wrap .wallet_address p {
    overflow: unset;
    font-size: 1.4rem;
    line-height: 1.4;
    white-space: unset;
    text-overflow: unset;
    word-wrap: break-word;
}
.wallet_page .wallet_item_wrap .wallet_item_body {
    width: 100%;
    padding: 0 0 2.4rem 0;
    margin-bottom: 3rem;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area,
  .wallet_page .wallet_item_wrap .wallet_item_body .money_area {
    display: block;
    width: 100%;
    padding: 0;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area {
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid var(--line-color);
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area:after {
    display: none;
}
.wallet_page .wallet_item_wrap .wallet_item_body .money_area {
    padding-top: 2rem;
    border-top: 0.1rem solid var(--line-color);
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dt,
  .wallet_page .wallet_item_wrap .wallet_item_body .money_area dt{
    padding-top: 0;
    font-size: 1.4rem;
}
.wallet_page .wallet_item_wrap .wallet_item_body .account_area dd,
  .wallet_page .wallet_item_wrap .wallet_item_body .money_area dd {
    font-size: 1.6rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group {
    flex-direction: column;
    align-items: center;
}
.wallet_page .wallet_item_wrap .wallet_list_group .img_area {
    width: 7rem;
}
.wallet_page .wallet_item_wrap .wallet_list_group .name_area {
    width: 100%;
    padding:0 0 6rem 0;
    margin: 1rem 0 0 0;
    text-align: center;
}
.wallet_page .wallet_item_wrap .wallet_list_group .btn_area {
    padding:0;
    text-align: center;
}
.wallet_page .wallet_item_wrap .wallet_info_group .btn_area {
    position: absolute;
    left: 50%;
    bottom:3rem;
    transform: translateX(-50%);
}
.wallet_page .wallet_sec .guide_ui .btn_area {
    justify-content: center;
}
.wallet_page .wallet_sec .guide_ui .btn_area .basic {
    display: inline-flex;
    line-height: 1.2;
    align-items: center;
    justify-content: center;
}
.wallet_page .wallet_sec .guide_ui .btn {
    width: 50%;
    min-width: auto;
    font-size: 1.4rem;
}
.notification_page .notification_sec {
    padding-top: 2rem;
}
.notification_page .notification_sec .notification_sec_head .ttl {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    font-weight: 500;
}
.notification_page .notification_sec .notification_sec_head p {
    font-size: 1.4rem;
}
.notification_page .notification_sec .notification_sec_body {
    padding-top: 5rem;
}
.notification_page .notification_sec .info {
    margin-bottom: 1.7rem;
    font-size: 1.6rem;
    font-weight: 500;
}
.notification_page .notification_sec .btn_area {
    margin-top: 5rem;
}
.notification_page .notification_sec .delete_account {
    margin-top: 8.2rem;
}
.delete_page .delete_sec {
    padding-top: 2rem;
}
.delete_page .delete_sec .delete_sec_head .ttl {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    font-weight: 500;
}
.delete_page .delete_sec .delete_sec_head p {
    margin-bottom: 0;
    font-size: 1.4rem;
}
.delete_page .delete_sec .delete_sec_body {
    padding-top: 5rem;
}
.delete_page .delete_sec .btn_area {
    display: flex;
    margin-top: 5rem;
}
.delete_page .delete_sec .btn_area .btn {
    width: 50%;
    min-width: auto;
}
.other_page .other_sec_head .profile_info .profile {
    width: 10rem;
    height: 10rem;
}
.other_page .other_sec_head .profile_info .ttl {
    font-size: 2.4rem;
}
.other_page .following,
  .other_page .followers {
    min-width: auto;
}
.other_page .followers:before {
    margin-left: 5rem;
    margin-right: 5rem;
}
.other_page .follow_group .follow_area li button {
    font-size: 2.2rem;
}
.other_page .follow_group .follow_area li button span {
    margin-top: 0.4rem;
}
.other_page .follow_group .btn_area {
    display: block;
    margin-top: 2.7rem;
}
.other_page .follow_group .btn_area:before {
    display: none;
}
.other_page .other_sec_body {
    margin-top: 4.5rem;
}
.other_page .other_sec_body .data_length_area {
    padding-top: 1.5rem;
}
.artist_page .artist_sec_head .profile_info.is_art .profile {
    width: 9rem !important;
    height: 9rem !important;
    margin: -4.5rem 0.5rem 3rem !important;
}
.artist_page .artist_sec_head .profile_info.is_art .ic_ring {
    width: 10rem !important;
    height: 10rem !important;
    margin-left: -5rem;
    margin-top: -5rem;
}
.artist_page .artist_sec_head .profile_info .ttl {
    font-size: 2.4rem;
}
.my_list_page .following,
  .my_list_page .followers {
    width: 50%;
    text-align: center;
}
.artist_page .follow_area,
  .other_page .follow_area {
    display: block;
}
.my_list_page .followers {
    position: relative;
    margin:0;
    padding: 0;
}
.artist_page .followers:before,
  .other_page .followers:before,
  .my_artist_page .followers:before {
    position: absolute;
    left: 0;
    margin: 0;
}
.my_account_head .follow_group .following:before {
    margin: 0;
    left: 0;
}
.my_list_page .follow_group .follow_area li button {
    font-size: 2.2rem;
}
.my_list_page .follow_group .follow_area {
    display:block;
}
.my_list_page .follow_group .follow_area li button span {
    margin-top: 0.4rem;
}
.my_list_page .follow_group .btn_area {
    display: block;
    margin-top: 2.7rem;
}
.my_list_page .follow_group .btn_area:before {
    display: none;
}
.artist_page .other_sec_body {
    margin-top: 4.5rem;
}
.artist_page .other_sec_body .data_length_area {
    padding-top: 1.5rem;
}
.artist_page .artist_sec_head .sns_area {
    position: static;
    margin-top: 4rem;
    text-align: left;
}
.my_list_page .desc_area {
    position: relative;
    overflow: hidden;
    max-width: 72rem;
    max-height: 60px;
    margin: 3.5rem auto 0;
    text-align: left;
}
.my_list_page .desc_area.is_active {
    max-height: unset;
}
.my_list_page .desc_area.is_active:before {
    display: none;
}
.my_list_page .desc_area:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4rem;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
.my_list_page .desc_area .desc {
    font-size: 1.4rem;
}
.my_list_page .expand_btn {
    margin-top: 1.1rem;
    font-size: 1.4rem;
    font-weight: 300;
    color: #707070;
}
.my_list_page .expand_btn i {
    margin-left: 1.4rem;
    vertical-align: middle;
}
.my_list_page .artist_sec_body .tab_list.type_stack .tab_node {
    min-width: 50%;
    margin-left: 0;
}
.my_list_page .artist_sec_body .tab_list.type_stack .tab_item {
    width: 100%;
    font-size: 1.6rem;
}
.my_artist_page .artist_sec_head .profile_info {
    display: block;
    width: 100%;
    text-align: center;
}
.my_artist_page .artist_sec_head .profile_info.is_art .profile {
    display: block;
    width: 9rem !important;
    height: 9rem !important;
    margin: -4.5rem auto 1.8rem !important;
}
.my_artist_page .artist_sec_head .profile_info.is_art .ic_ring {
    width: 10rem !important;
    height: 10rem !important;
    left: 0;
    right: 0;
    margin: -5rem auto 0;
}
.my_artist_page .artist_sec_head .profile_info .ttl {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 500;
    vertical-align: middle;
}
.my_artist_page .artist_sec_head .profile_info .edit_btn {
    display: inline-block;
    margin-left: 5.2rem;
    vertical-align: middle;
}
.my_artist_page .follow_group {
    margin-top: 2.3rem;
    padding-left: 0;
    text-align: center;
}
.my_artist_page .artist_sec_head .sns_area {
    padding-left: 0;
}
.my_artist_page .desc_area {
    padding-left: 0;
}
.my_user_page .user_sec {
    padding-top: 1.5rem;
}
.my_list_page .data_length_area .ttl {
    font-size: 1.2rem;
}
.layer_area {
    padding: 2.2rem 2rem;
}
.layer_area h3 {
    font-size: 1.8rem;
}
.activities_page .activities_sec_head .ttl_area .ttl {
    font-size: 1.8rem;
}
.my_user_page .guide_ui.no_data .btn {
    margin-top: 5rem;
}
.my_user_page .guide_ui.no_data p {
    font-size: 1.4rem;
}
.activities_sec .list_head .count {
    font-size: 1.2rem;
}
.activities_page .date_group .input_area .input_box {
    display: block;
    width: 100%;
    height: 4rem;
}
.activities_page .date_group .input_area .activities_page .date_group .input_area .input_box {
    display: block;
    width: 100%;
}
.activities_page .date_group .input_area .input_box:nth-of-type(2) {
    margin-top: 1rem;
    margin-left: 0;
}
.family_page .guide_ui.no_data h2 {
    margin-bottom: 2rem;
    font-size: 2.4rem;
}
.family_page .guide_ui.no_data p {
    font-size: 1.4rem;
}
.family_page .guide_ui.no_data .btn {
    margin-top: 5rem;
}

  /* s: DEV 221207 추가  */
.wallet_page .wallet_list {
    margin-top: 3.6rem;
    width:100%;
}
.wallet_page .guide_ui .wallet_list {
    margin-bottom: -6.2rem;
}
.wallet_page .wallet_list .wallet_item + .wallet_item {
    margin-top: 2rem;
}
.wallet_page .wallet_list .wallet_item .btn {
    display: inline-flex;
    align-items: center;
    width: 100% !important;
    height: 6rem;
    padding: 0 8rem 0 1.4rem;
    color: #fff;
    text-align: left;
}
.wallet_page .wallet_list .wallet_item .thumb {
    display: block;
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-right: 1.5rem;
    border-radius: 50%;
}
.wallet_page .wallet_list .wallet_item .thumb img {
    vertical-align: top;
}
.wallet_page .wallet_list .wallet_item strong {
    font-weight: 500;
}
.wallet_page .wallet_list .wallet_item .badge {
    position: absolute;
    right: 15px;
    height: 2.4rem;
    padding: 0 1.4rem;
    color: #9058dd;
    border: 1px solid;
    border-radius: 1.2rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
}
.wallet_page .wallet_list .wallet_item .btn:before {
    border-color: var(--disabled-colr);
}
  /* e: DEV 221207 추가  */
}
@media screen and (max-width: 600px) {
.my_list_page .owner_info_area dd .profile {
    width: 2.2rem !important;
    height: 2.2rem !important;
}
.artist_page .main_visual_sec {
    height: 44.4444vw;
}
.notification_page .notification_sec .subscribe_group {
    align-items: start;
}
.notification_page .notification_sec .info_area .subscribe {
    font-size: 1.6rem;
    margin-bottom: 2rem;
}
.notification_page .notification_sec .info_area p {
    font-size: 1.4rem;
}
.notification_page .notification_sec .delete_account a {
    font-size: 1.4rem;
}
.notification_page .notification_sec .subscribe_list li {
    padding: 1.8rem 2rem;
}
.delete_page .delete_sec .permanent_box {
    padding: 2rem;
}
.delete_page .delete_sec .permanent_box h3 {
    margin-bottom: 2rem;
}
.delete_page .delete_sec .permanent_box p {
    font-size: 1.4rem;
}
.delete_page .delete_sec .permanent_box ul {
    margin-top: 1.8rem;
}
.delete_page .delete_sec .permanent_box ul li {
    font-size: 1.4rem;
}
.delete_page .delete_sec .permanent_box ul li + li {
    margin-top: 1.2rem;
}
.my_artist_page .artist_sec_head .profile_info .edit_btn {
    margin-left: 1rem;
}
.my_artist_page .artist_sec_head .sns .ic,
  .my_artist_page .artist_sec_head .profile_info .edit_btn .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
}
.my_artist_page .artist_sec_head .profile_info .edit_btn g {
    stroke-width: 2;
}
.my_artist_page .artist_sec_head .sns {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
}
.my_artist_page .artist_sec_head .sns .ic {
    vertical-align: -0.6rem;
}
.my_user_page .state_info_group {
    padding-top: 1.7rem !important;
}
  /* .my_user_page .state_info_group.has_btn {
    padding-right: 7rem;
  } */
  /* .my_user_page .state_info_group.has_btn .state_info {
    display: inline-flex;
  } */
.activities_page .thumb_item.type_list .sale_info_group dd {
    font-size: 1.4rem;
}
.thumb_item.type_list .sale_info_group dt {
    min-width: 10.8rem;
    font-size: 1.4rem;
    font-weight: 300;
}
.thumb_item.type_list .card_body .ttl.artist {
    font-weight: 300;
}
.thumb_item.type_list .artist_info_group .profile {
    width: 2.6rem !important;
    height: 2.6rem !important;
}
.thumb_item.type_list .artist_info_group.is_art .profile {
    width: 2.2rem !important;
    height: 2.2rem !important;
}
.thumb_item.type_list .owner_info_group .profile_info .name {
    max-width: 100%;
}
.thumb_item.type_list .owner_info_group .profile {
    width: 2.2rem !important;
    height: 2.2rem !important;
}
.thumb_item.type_list .owner_info_group.is_art .profile {
    width: 1.8rem !important;
    height: 1.8rem !important;
}
.thumb_item.type_list .sale_info_group .price b {
    font-size: 1.6rem;
}
.thumb_item.type_list .sale_info_group .price b .dollars{
    font-size: 1.2rem;
}
.my_artist_page .page_ttl {
    border-bottom: 0 none;
    padding-bottom: 2rem;
}
.my_artist_page .artist_sec .visual_wrap {
    width: calc(100% + 4rem);
    height: 0;
    padding-bottom: calc(44.4444% + (4rem * 0.4444));
    margin-left: -2rem;
    margin-right: -2rem;
}
.activities_sec_body .list_wrap {
    margin-top: 2.5rem;
}
.activities_page .thumb_list {
    margin: 0;
}
.activities_page .thumb_list .thumb_node {
    display: block;
    width: 100%;
    margin-bottom: 4rem;
    padding: 0;
}
.notification_page .notification_sec .delete_account a i {
    margin-left: 0.5rem;
}
.activities_page .account_change_banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 4rem 2rem;
    
    background-color: #191919;
}
.activities_page .account_change_banner .ttl_area {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    text-align: center;
}
.activities_page .account_change_banner .ttl_area::before{
    content: '';
    width: 100%;
    height: 5.2rem;
    margin:0 0 2rem 0;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
    background-size: 6.2rem;
}
.activities_page .account_change_banner .ttl_area .ttl {
    display: block;
    width: 100%;
    padding:0 0 1rem 0;
    font-size: 1.8rem;
    font-weight: 400;
}
.activities_page .account_change_banner .ttl_area .ttl_group{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin-bottom:2.8rem;
}
.activities_page .account_change_banner .check_wallet_btn {
    width: 19rem;
    padding: 1.4rem 0;
    font-weight: 300;
    border-radius: 10rem;
    background: #000 url(${___CSS_LOADER_URL_REPLACEMENT_1___}) right 20px center no-repeat;
    background-size: 0.5rem;
}
.thumb_item.type_list .blockchains_info_group .blockchains{
    width: 2.8rem !important;
    height: 3rem !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
