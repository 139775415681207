// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.drop_ui .drop_btn[data-v-455e6f02] {
  width: 100%;
  text-align: left;
  background-color: #2e2e2e;
}
.validation[data-v-455e6f02] {
  color: red;
  padding: 10px;
  font-weight: 500;
}
.choose-type[data-v-455e6f02] {
  padding: 2px;
}
.modal_head[data-v-455e6f02] {
  text-align: left;
}
.modal_body .bg_edit_wrap[data-v-455e6f02] {
  position: relative;
  width: 100%;
  height: 13.6rem;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.modal_body .bg_edit_wrap .ic_area.type_camera[data-v-455e6f02] {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.modal_body .profile_area[data-v-455e6f02] {
  position: relative;
  display: block;
  cursor: auto;
}
.modal_body .profile_area .profile[data-v-455e6f02] {
  position: relative;
  display: inline-block;
  width: 11rem !important;
  height: 11rem !important;
  margin-top: -5.5rem !important;
  margin-right: 0.3rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 50%;
  background-size: cover;
}
.modal_body .profile_area.is_art .profile[data-v-455e6f02] {
  width: 9.4rem !important;
  height: 9.4rem !important;
}
.modal_body .profile_area .ic_ring[data-v-455e6f02] {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  margin-top: -6.3rem !important;
  margin-left: -5.4rem !important;
}
.modal_body .profile_area .profile .ic_area.type_camera[data-v-455e6f02] {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
}
.modal_body .profile_area .ttl[data-v-455e6f02] {
  display: block;
  margin-top: 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  color: #fff;
}
.modal_body .introduce_wrap[data-v-455e6f02] {
  margin-top: 3.3rem;
  margin-bottom: 1.3rem;
  text-align: left;
}
.modal_body .introduce_wrap .input_area.textarea textarea[data-v-455e6f02] {
  min-height: 10rem;
}
.modal_body .introduce_wrap .input_area.text label[data-v-455e6f02] {
  width: 9.5rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
  vertical-align: middle;
}
.modal_body .introduce_wrap .input_area.text .input_box[data-v-455e6f02] {
  width: calc(100% - 11rem);
  vertical-align: middle;
}
.modal_body .introduce_wrap .input_area.text .input_box input[data-v-455e6f02] {
  width: 100%;
  padding: 1.1rem 1.4rem;
  background: #2e2e2e;
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
}
.modal_body .introduce_wrap .input_area + .input_area[data-v-455e6f02] {
  margin-top: 1.4rem;
}
.modal_body + .modal_foot[data-v-455e6f02] {
  margin-top: 30px;
}
.input_area[data-v-455e6f02] {
  display: flex;
  flex-flow: column;
}
.input_box[data-v-455e6f02] {
  width: 100% !important;
}
.modal_body .introduce_wrap .input_area label[data-v-455e6f02],
.modal_body .introduce_wrap .input_area.text label[data-v-455e6f02] {
  width: 100% !important;
  padding-bottom: 10px;
}
@media screen and (max-width: 767px) {
.modal_wrap[data-v-455e6f02] {
    overflow: hidden !important;
}
.choose-type[data-v-455e6f02] {
    display: block;
}
.modal_body .introduce_wrap .input_area.text label[data-v-455e6f02] {
    padding-bottom: 5px;
}
.modal_body .introduce_wrap .input_area.text .input_box[data-v-455e6f02] {
    width: 100%;
}
.modal_body .introduce_wrap .input_area label[data-v-455e6f02],
  .modal_body .introduce_wrap .input_area.text label[data-v-455e6f02] {
    margin-bottom: 0 !important;
    font-size: 1.4rem;
    width: 100%;
}
.modal_body .introduce_wrap .input_area.textarea textarea[data-v-455e6f02] {
    height: 5rem;
}
.modal_body .introduce_wrap .input_area + .input_area[data-v-455e6f02] {
    margin-top: 0.7rem;
}
.modal_body .introduce_wrap[data-v-455e6f02] {
    max-height: 270px;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
    overflow-x: hidden;
    overflow-y: auto;
}
}
.file-box[data-v-455e6f02] {
  display: flex !important;
}
.file-input[data-v-455e6f02] {
  width: 80% !important;
}
.file-btn[data-v-455e6f02] {
  padding: 1.1rem 1.4rem;
}
.input_area > select[data-v-455e6f02],
.input_area > select option[data-v-455e6f02] {
  color: #000;
  height: 42px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
