var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "search_page", attrs: { id: "contents" } }, [
    _c("div", { staticClass: "set_inner" }, [
      _c("section", { staticClass: "result_sec" }, [
        _c("header", { staticClass: "sec_head" }, [
          _c("div", { staticClass: "ttl_area" }, [
            _c("h2", { staticClass: "ttl" }, [
              _vm._v(" " + _vm._s(_vm.totalSearchCount) + " Results For “"),
              _c("b", { staticClass: "c_point" }, [
                _vm._v(_vm._s(_vm.resultKeyword)),
              ]),
              _vm._v("” "),
            ]),
          ]),
          _c("div", { staticClass: "tab_wrap" }, [
            _c(
              "div",
              {
                staticClass: "tab_list type_stack",
                attrs: { role: "tablist" },
              },
              [
                _vm._l(_vm.searchTabData, function (tab) {
                  return _c(
                    "div",
                    {
                      key: tab.id,
                      staticClass: "tab_node",
                      attrs: { role: "presentation" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "tab_item",
                          class: { is_active: _vm.currentTab === tab.id },
                          attrs: {
                            id: "tab_" + tab.id,
                            role: "tab",
                            "aria-controls": tab.id + "_panel",
                            "aria-selected":
                              tab.id === _vm.currentTab ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setTab(tab.id)
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(tab.name) + " "),
                            tab.id === "artworks"
                              ? _c("em", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.totalArtworkCount)),
                                ])
                              : tab.id === "artists"
                              ? _c("em", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.totalArtistCount)),
                                ])
                              : _c("em", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.totalOtherProfileCount)),
                                ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "sec_body tab_panel",
            attrs: {
              id: _vm.currentTab + "_panel",
              role: "tabpanel",
              "aria-labelledby": "tab_" + _vm.currentTab,
            },
          },
          [
            _vm.currentTab === "artworks"
              ? [
                  _c("div", { staticClass: "list_wrap works_list_wrap" }, [
                    _vm.totalArtworkCount > 0
                      ? _c(
                          "ul",
                          {
                            staticClass: "grid grid_15 ib edit_list works_list",
                          },
                          _vm._l(_vm.artworkInfos, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                staticClass: "col col_3 edit_node",
                              },
                              [
                                _c("WorkItem", {
                                  attrs: { "item-data": item },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "guide_ui no_data" }, [
                          _c("div", { staticClass: "img_area" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  media: "(max-width: 600px)",
                                  type: "image/png",
                                  srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/img_illust_nosearch.png"),
                                  alt: "No Result",
                                },
                              }),
                            ]),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(" We couldn’t find any match for “"),
                            _c("span", { staticClass: "c_point" }, [
                              _vm._v(_vm._s(_vm.resultKeyword)),
                            ]),
                            _vm._v("”."),
                            _c("br"),
                            _vm._v(" Try another keyword. "),
                          ]),
                        ]),
                  ]),
                ]
              : _vm._e(),
            _vm.currentTab === "artists"
              ? [
                  _c("div", { staticClass: "list_wrap user_list_wrap" }, [
                    _vm.totalArtistCount > 0
                      ? _c(
                          "ul",
                          {
                            staticClass:
                              "grid grid_15 ib user_list artist_list",
                          },
                          _vm._l(_vm.artistInfos, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                staticClass: "col col_3 user_node",
                              },
                              [
                                _c("ArtistItem", {
                                  attrs: { "item-data": item },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "guide_ui no_data" }, [
                          _c("div", { staticClass: "img_area" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  media: "(max-width: 600px)",
                                  type: "image/png",
                                  srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/img_illust_nosearch.png"),
                                  alt: "No Result",
                                },
                              }),
                            ]),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(" We couldn’t find any match for “"),
                            _c("span", { staticClass: "c_point" }, [
                              _vm._v(_vm._s(_vm.resultKeyword)),
                            ]),
                            _vm._v("”."),
                            _c("br"),
                            _vm._v(" Try another keyword. "),
                          ]),
                        ]),
                  ]),
                ]
              : _vm._e(),
            _vm.currentTab === "other"
              ? [
                  _c("div", { staticClass: "list_wrap user_list_wrap" }, [
                    _vm.totalOtherProfileCount > 0
                      ? _c(
                          "ul",
                          { staticClass: "grid grid_15 ib user_list" },
                          _vm._l(_vm.otherProfileInfos, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                staticClass: "col col_3 user_node",
                              },
                              [
                                _c("UserItem", {
                                  attrs: { "item-data": item },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "guide_ui no_data" }, [
                          _c("div", { staticClass: "img_area" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  media: "(max-width: 600px)",
                                  type: "image/png",
                                  srcset: `${require("@/assets/img/img_mob_illust_nosearch_l.png")} 4x, ${require("@/assets/img/img_mob_illust_nosearch_m.png")} 3x, ${require("@/assets/img/img_mob_illust_nosearch.png")}`,
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/img_illust_nosearch.png"),
                                  alt: "No Result",
                                },
                              }),
                            ]),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(" We couldn’t find any match for “"),
                            _c("span", { staticClass: "c_point" }, [
                              _vm._v(_vm._s(_vm.resultKeyword)),
                            ]),
                            _vm._v("”."),
                            _c("br"),
                            _vm._v(" Try another keyword. "),
                          ]),
                        ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }