var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icMore",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": _vm.iconColor,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            "data-name": "그룹 3598",
            transform: "translate(-315 -190)",
          },
        },
        [
          _c("circle", {
            attrs: {
              cx: "2",
              cy: "2",
              r: "2",
              "data-name": "타원 402",
              transform: "translate(320 203)",
            },
          }),
          _c("circle", {
            attrs: {
              cx: "2",
              cy: "2",
              r: "2",
              "data-name": "타원 403",
              transform: "translate(328 203)",
            },
          }),
          _c("circle", {
            attrs: {
              cx: "2",
              cy: "2",
              r: "2",
              "data-name": "타원 404",
              transform: "translate(336 203)",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }