var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "purchase_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Purchase")]),
      _c("article", { staticClass: "purchase_sec" }, [
        _c("div", { staticClass: "set_inner" }, [
          _vm.type === "done"
            ? _c("div", { staticClass: "guide_ui" }, [
                _c("div", { staticClass: "img_area" }, [
                  _c("picture", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/img_illust_done.png"),
                        alt: "No Result",
                      },
                    }),
                  ]),
                ]),
                _c("h2", { staticClass: "ttl" }, [
                  _vm._v("Purchase is complete"),
                ]),
                _vm._m(0),
                _c("div", { staticClass: "add_help_msg_box" }, [
                  _c("div", { staticClass: "add_help_inner" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "add_help_content" }, [
                      _c("div", { staticClass: "add_help_content_item" }, [
                        _c("h4", [_vm._v("Contract Address")]),
                        _c("div", { staticClass: "add_help_copy" }, [
                          _c("p", [_vm._v(" " + _vm._s(_vm.address) + " ")]),
                          _c(
                            "button",
                            {
                              staticClass: "btn type_ic btn_copy",
                              on: {
                                click: function ($event) {
                                  return _vm.copy(_vm.address)
                                },
                              },
                            },
                            [_c("IcCopy")],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "add_help_content_item" }, [
                        _vm.purchase == "eth"
                          ? _c("h4", [_vm._v("Token Id")])
                          : _c("h4", [_vm._v("Collectible ID")]),
                        _c("div", { staticClass: "add_help_copy" }, [
                          _c("p", [_vm._v(_vm._s(_vm.collectibleId))]),
                          _c(
                            "button",
                            {
                              staticClass: "btn type_ic btn_copy",
                              on: {
                                click: function ($event) {
                                  return _vm.copy(_vm.collectibleId)
                                },
                              },
                            },
                            [_c("IcCopy")],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "btn_area" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn basic w_l h_l",
                        attrs: { href: "/" },
                      },
                      [_vm._v("Home")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn strong w_l h_l",
                        attrs: { to: "/collections" },
                      },
                      [
                        _c("span", { staticClass: "dv_pc" }, [
                          _vm._v("Go to "),
                        ]),
                        _vm._v("My Collections"),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.type === "fail"
            ? _c("div", { staticClass: "guide_ui" }, [
                _c("div", { staticClass: "img_area" }, [
                  _c("picture", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/img_illust_warn.png"),
                        alt: "No Result",
                      },
                    }),
                  ]),
                ]),
                _c("h2", { staticClass: "ttl" }, [
                  _vm._v("Something Went Wrong."),
                ]),
                _vm._m(2),
                _c(
                  "div",
                  {
                    staticClass: "btn_area",
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [
                    _c("button", { staticClass: "btn strong w_l h_l" }, [
                      _vm._v("Back to previous Page"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.polling
        ? _c("aside", { staticClass: "progress_sec" }, [
            _vm._m(3),
            _c("div", { staticClass: "set_inner" }, [
              _c("div", { staticClass: "guide_ui" }, [
                _c("h3", { staticClass: "ttl" }, [
                  _vm._v("Payment is in Progress."),
                ]),
                _c("div", { staticClass: "img_area" }, [
                  _c("picture", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/img_illust_nodata.png"),
                        alt: "nodata",
                      },
                    }),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "Do not close your browser until payment is complete."
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "desc" }, [
      _vm._v(" Your purchase’s successfully done! "),
      _c("br"),
      _vm._v(" You can appreciate and enjoy your collections in "),
      _c("span", { staticClass: "c_point" }, [_vm._v("My Collections")]),
      _vm._v(" or "),
      _c("span", { staticClass: "c_point" }, [_vm._v("Profiles")]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "add_help_head" }, [
      _c("h3", [_vm._v("Add artwork to your wallet.")]),
      _c("p", [
        _vm._v(
          " copy both Contract Address and Collectible ID and paste them to your wallet. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "desc" }, [
      _vm._v(" We’re sorry, something went wrong. "),
      _c("br"),
      _vm._v(
        "Transaction failed. Something is wrong with your wallet. Please try again later. "
      ),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "progress_area" }, [
      _c("progress", { attrs: { max: "100", value: "20" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }