// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.item_head[data-v-46a21463],
.item_body[data-v-46a21463],
.profile_info[data-v-46a21463] {
  cursor: pointer;
}
.ttl_area .ttl[data-v-46a21463] {
  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.thumb_btn[data-v-46a21463] {
  width: 100%;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
