var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "thumb_nav_wrap" }, [
    _c("div", { ref: "wrap", staticClass: "thumb_slide_ui" }, [
      _c(
        "div",
        {
          ref: "moveElWrap",
          staticClass: "has_scroll",
          on: {
            mousedown: _vm.satStartPoint,
            mousemove: _vm.swipeMove,
            touchstart: _vm.satStartPoint,
            touchmove: _vm.swipeMove,
          },
        },
        [
          _c(
            "ul",
            { ref: "moveEl", staticClass: "thumb_nav_list move_el" },
            _vm._l(_vm.itemData, function (item, i) {
              return _c(
                "li",
                {
                  key: "docent_" + i,
                  staticClass: "nav_node",
                  on: {
                    click: function ($event) {
                      return _vm.setCurrent(item, i)
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      ref: "item",
                      refInFor: true,
                      staticClass: "nav_item",
                      class: { is_active: _vm.currentDocent === i },
                      attrs: { href: "javascript:;" },
                    },
                    [
                      _c("div", { staticClass: "thumb_area" }, [
                        _c("img", {
                          attrs: {
                            src: item.thumbInfos[0].resUrl,
                            width: 180,
                            height: 101,
                            alt: item.thumbInfos[0].tpDesc
                              ? item.thumbInfos[0].tpDesc
                              : "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      this.itemData.length - this.showItemCount > 0
        ? _c("div", { staticClass: "control_group" }, [
            _c(
              "div",
              { staticClass: "control_area pagination_area type_num" },
              [
                _c("span", { staticClass: "current" }, [
                  _vm._v(_vm._s(_vm.currentDocent + 1)),
                ]),
                _vm._v(" / "),
                _c("span", [_vm._v(_vm._s(_vm.itemData.length))]),
              ]
            ),
            _c("div", { staticClass: "control_area slide_nav_area" }, [
              _c(
                "button",
                {
                  staticClass: "btn type_ic prev",
                  attrs: { disabled: _vm.currentDocent < 1 },
                  on: {
                    click: function ($event) {
                      return _vm.setNav("prev")
                    },
                  },
                },
                [_c("ArwPrevS")],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn type_ic next",
                  attrs: {
                    disabled: _vm.currentDocent >= _vm.itemData.length - 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setNav("next")
                    },
                  },
                },
                [_c("ArwNextS")],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }