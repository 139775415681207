var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icZoomOut",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [
      _c("path", {
        attrs: { "data-name": "Path 2729", d: "M4.011 11.004h6.993V4.011" },
      }),
      _c("path", {
        attrs: { "data-name": "Path 2730", d: "M18.998 4.01v6.993h6.993" },
      }),
      _c("path", {
        attrs: { "data-name": "Path 2731", d: "M11.002 25.99v-6.993H4.009" },
      }),
      _c("path", {
        attrs: { "data-name": "Path 2734", d: "M18.997 25.99v-6.993h6.993" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }