// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 1025px) {
.activity_item_wrap .edit_node:first-child .item_body .state_info {
    display: flex;
    position: relative;
    width: 148px;
    flex-flow: column;
    flex-shrink: 0;
    justify-content: center;
    padding: 0 0 0 20px;
    margin-top: 0;
    border: 0 none;
    white-space: nowrap;
    align-items: center;
}
}
@media screen and (max-width: 767px) {
.activity_item_wrap .edit_node:first-child .item_body .state_info {
    padding-top: 1.7rem;
    margin-bottom: 0;
}
}
.profile_info{
  cursor: pointer;
}
.my_artist_page .basic_info_group {
  min-height: 9rem !important;
}

/* MyAccount > Activities 달력 관련 수정 */
.input_area.date .vdp-datepicker__calendar .disabled {
  color: #444 !important;
  cursor: default;
}


/* 모바일 Drops 수정 */
@media screen and (max-width: 767px) {
.drops_page.curation_page .live_sec .btn_area {
    display: block !important;
    margin-top: 1rem;
}
.drops_page.curation_page .live_item .ttl_area {
    order: 0 !important;
    padding-bottom: 30px;
}
.liveDrop{
    order: 1 !important;
}
.time_group {
    display: flex;
    justify-content: center;
}
}


/* Help center 텍스트 크기 */
.board_sec_head > span {
  font-size: 2rem !important;
}
.footer_page .board_sec .board_sec_body {
  font-size: 2rem !important;
  line-height: 5rem;
}
.footer_page .board_sec .board_sec_head .ttl {
  font-size: 3rem !important;
}

/* Help center 버튼 커서 */
.board_sec_foot > a {
  cursor: pointer;
}

/* MyProfile > Activities 코드 칩 색상정의*/
.thumb_item.type_list .sale_info_group dt.type_1 {
  /* purchased */
  border-color: #00b981;
  color: #00b981;
}
.thumb_item.type_list .sale_info_group dt.type_2 {
  /* sold */
  border-color: #389bff;
  color: #389bff;
}
.thumb_item.type_list .sale_info_group dt.type_3 {
  /*  */
  border-color: #ff3838;
  color: #ff3838;
}
.thumb_item.type_list .sale_info_group dt.type_4 {
  /*  */
  border-color: #c2c2c2;
  color: #c2c2c2;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
