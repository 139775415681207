// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-12.use[2]!../../assets/css/drops.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.clickable[data-v-3472f618] {
  cursor: pointer;
}
.date_info[data-v-3472f618] {
  text-align: left;
}
.is_pc_date[data-v-3472f618] {
  white-space: pre-line;
}

/* 2022-08-26 Live 드랍 상태의 카운터 중 해당영역만 margin이 줄어들게 처리 요청 - by 기획자 */
.time_group[data-v-3472f618] {
  margin-bottom: 15px !important;
}
@media screen and (min-width: 1025px) {
.flow_list .flow_item .item_body .desc b[data-v-3472f618] {
    -webkit-line-clamp: 5 !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
