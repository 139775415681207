// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.item_head[data-v-48d0cc52],
.item_body[data-v-48d0cc52] {
  cursor: pointer;
}
.state_info[data-v-48d0cc52] {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.status_chip[data-v-48d0cc52] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.state_info_group[data-v-48d0cc52] {
  position: relative;
  padding-top: 11px;
  margin-top: 15px;
  border-top: 1px solid var(--line-color);
}
.state_info_group .state_info[data-v-48d0cc52] {
  float: none;
  margin-top: 0;
  padding-top: 0;
  border: 0 none;
}
.state_info_group .btn_group[data-v-48d0cc52] {
  position: absolute;
  z-index: 11;
  top: 50%;
  right: -0.5rem;
  margin-top: -1.5rem;
  padding-top: 0.7rem;
}
.state_info_group .btn_group .share_area[data-v-48d0cc52],
.state_info_group .btn_group .selling_area[data-v-48d0cc52] {
  display: inline-block;
}
.state_info_group .btn_group .selling_area[data-v-48d0cc52] {
  margin-left: 0.6rem;
}
.state_info_group .btn_group .share_area > button[data-v-48d0cc52],
.state_info_group .btn_group .selling_area > button[data-v-48d0cc52] {
  width: 3rem;
  height: 3rem;
}
.state_info_group .btn_group button i[data-v-48d0cc52] {
  vertical-align: middle;
}
.layer_area[data-v-48d0cc52] {
  right: 0px !important;
}
.layer_area_copy_action[data-v-48d0cc52] {
  width: 26rem;
}
.artist_page .basic_info_group[data-v-48d0cc52] {
  min-height: 9rem;
}
.priceOwnMob[data-v-48d0cc52] {
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: space-between;
}
.isShare > .btn[data-v-48d0cc52] {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 100;
}
@media screen and (max-width: 600px) {
.priceOwnMob[data-v-48d0cc52] {
    flex-direction: unset;
    align-items: flex-end !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
