// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.is_loading[data-v-94ba546a] {
  background: #e9ecef !important;
  color: black !important;
}
.restoreBtn[data-v-94ba546a] {
  height: 4rem;
  line-height: 3rem;
}
input[data-v-94ba546a] {
  cursor: pointer;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
