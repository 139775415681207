// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* .wallet_page .wallet_item_wrap .wallet_item_head {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid var(--line-color);
} */
.unit[data-v-05f91dce] {
  font-weight: 400;
}
.is_loading[data-v-05f91dce] {
  background: #e9ecef !important;
  color: black !important;
}
@media screen and (max-width: 1024px) {
.usdc[data-v-05f91dce] {
    font-size: 1.8rem;
}
}
@media screen and (max-width: 767px) {
.usdc[data-v-05f91dce] {
    font-size: 1.6rem;
}
}
.qr-title[data-v-05f91dce] {
  margin-top: 0px !important;
}
.qr-area[data-v-05f91dce] {
  max-width: 310px;
  margin: 36px auto;
}
.qr-code[data-v-05f91dce] {
  background-color: #fff;
  padding: 10px;
}
.connect[data-v-05f91dce] {
  min-width: 30rem;
}
.disconnect[data-v-05f91dce] {
  min-width: 13rem;
  background: transparent;
  color: #939393;
  border: 1px solid #444;
}
@media screen and (max-width: 767px) {
.connect[data-v-05f91dce] {
    min-width: 15.5rem;
}
}
@media screen and (max-width: 600px) {
.change[data-v-05f91dce] {
    margin: 0px 20px 10px 20px;
}
.disconnect[data-v-05f91dce] {
    margin-left: 0px !important;
}
}
@media screen and (max-width: 320px) {
.wallet_sec .guide_ui p br[data-v-05f91dce] {
    display: none;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
