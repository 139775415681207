var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icPlayL",
        "icon-label": _vm.iconLabel,
        width: 60,
        height: 60,
        viewBox: "0 0 60 60",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [_c("path", { attrs: { d: "M18.461 9.528v40.495l30.37-20.247Z" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }