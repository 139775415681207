// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/ic/ic_heart_shadow.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../img/ic/ic_check.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";

/*-------------------------------------------------------------------
    ## Common
-------------------------------------------------------------------*/
:root {
  --main-color: #ff521d;
  --txt-gray: #939393;
  --txt-gray-2: #aaaaaa;
  --box-bg: #1f1f1f;
  --disabled-colr: #888;
  --line-color: #333;
  --divider-bg: #1c1c1c;
}

/*-------------------------------------------------------------------
    ## icon
-------------------------------------------------------------------*/
.ic {
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: top;
}
.ic svg {
  display: block;
  width: 100%;
  height: 100%;
}
.ic_menu {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -0.9rem;
  width: 1.8rem;
  height: 2px;
  background: #fff;
}
.ic_menu:before,
.ic_menu:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: top ease-out 0.2s 0.2s, transform ease-out 0.2s;
}
.ic_menu:before {
  top: -6px;
}
.ic_menu:after {
  top: 6px;
}
.is_active .ic_menu {
  background: transparent;
}
.is_active .ic_menu:before,
.is_active .ic_menu:after {
  top: 0;
  width: 2.2rem;
  margin-left: -0.2rem;
  transition: top ease-out 0.2s, transform ease-out 0.3s 0.2s;
}
.is_active .ic_menu:before {
  transform: rotate(45deg);
}
.is_active .ic_menu:after {
  transform: rotate(-45deg);
}

/*-------------------------------------------------------------------
      ## arrow
  -------------------------------------------------------------------*/
.arw {
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: top;
}

/*-------------------------------------------------------------------
    ## btn
-------------------------------------------------------------------*/
.btn_area .btn + .btn {
  margin-left: 2rem;
}
.modal .btn_area .btn + .btn {
  margin-left: 10px;
}
.btn {
  position: relative;
  padding: 0 1.5rem;
  font-weight: 400;
}
.btn.w_f {
  width: 100%;
}
.btn.w_xs {
  min-width: 14rem;
}
.btn.w_s {
  min-width: 16rem;
}
.btn.w_m {
  min-width: 18rem;
}
.btn.w_l {
  min-width: 20rem;
}
.btn.w_xl {
  min-width: 21rem;
}
.modal .btn.w_xl {
  min-width: 23rem;
}
.guide_ui .btn.w_l {
  min-width: 23rem;
}
.guide_ui .btn.w_xl {
  min-width: 30rem;
}
.btn.h_m {
  height: 4rem;
  font-size: 1.6rem;
  line-height: 4rem;
}

.btn.h_l {
  height: 5rem;
  font-size: 1.8rem;
  line-height: 5rem;
}
.btn.basic {
  background: #fff;
  color: #000;
}

.btn.basic:hover,
.btn.basic:focus-visible {
  background: #d0d0d0;
}

.btn.strong,
.btn.basic.is_active {
  background: var(--main-color);
  color: #fff;
}
.btn.strong:hover,
.btn.strong:focus-visible,
.btn.basic.is_active:hover,
.btn.basic.is_active:focus-visible {
  background: #c63e14;
}
.btn.type_line:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #444;
  pointer-events: none;
}
.btn.type_line {
  background: transparent;
  color: var(--txt-gray);
}
.btn.type_line_w:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  pointer-events: none;
}
.btn.type_line_w {
  background: transparent;
  color: #fff;
}

.btn.type_line_w.is_active {
  background: #ffffff;
  color: #000000;
}
.btn.type_line_w:hover {
  background: #fff;
  color: #000;
  font-weight: 400 !important;
}
.btn.type_line_w:hover .ic g {
  stroke: #000;
}
.btn.type_ic,
.btn.type_txt {
  padding: 0;
}
.btn.type_round {
  border-radius: 30px;
}
.btn:disabled,
.btn.disabled {
  background: var(--line-color) !important;
  color: var(--txt-gray-2) !important;
}
.btn.type_ic:disabled,
.btn.type_ic.disabled {
  background: transparent !important;
  opacity: 0.4;
}
.modal .btn:disabled,
.modal .btn.disabled {
  background: #4e4e4e !important;
  color: var(--txt-gray) !important;
}
.btn b {
  font-weight: 300;
}

.btn_like {
  position: absolute;
  right: 12px;
  bottom: 9px;
  z-index: 10;
  opacity: 0.6;
}
.btn_like.is_active {
  opacity: 1;
}
.btn_like:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  width: 42px;
  height: 40px;
  margin-top: -20px;
  margin-left: -21px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  pointer-events: none;
}
.btn_alarm.is_active {
  position: relative;
}
.btn_alarm.is_active:before {
  display: block;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0.6rem;
  height: 0.6rem;
  background: var(--main-color);
  border-radius: 50%;
}
.btn.mode i {
  vertical-align: -5px;
  margin-left: 5px;
  margin-right: -5px;
}
.btn.select {
  background: var(--box-bg);
}

.btn_copy {
  width: 30px;
  height: 30px;
  background: var(--line-color);
  border-radius: 50%;
}
.btn.has_arw {
  position: relative;
  padding-right: 2.5rem;
}
.btn.has_arw:before {
  display: block;
  content: '';
  position: absolute;
  right: 0.3rem;
  top: 50%;
  width: 0.6rem;
  height: 0.6rem;
  margin-top: -0.5rem;
  border-right: 0.1rem solid;
  border-bottom: 0.1rem solid;
  box-sizing: content-box;
  transform: rotate(45deg);
}
/*-------------------------------------------------------------------
    ## Text
-------------------------------------------------------------------*/
.ttl_area p {
  color: var(--txt-gray);
  line-height: 1.5;
}
.ttl b,
.desc b {
  font-weight: 400;
}
.page_ttl {
  font-weight: 500;
}
.desc {
  line-height: 1.5;
}
.list_sec .sec_head .ttl {
  font-size: 3.2rem;
  font-weight: 200;
}
.ttl.type_underL {
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--line-color);
  font-size: 2.4rem;
}
.t_upper {
  text-transform: uppercase !important;
}
.c_point {
  color: var(--main-color) !important;
}
.box_txt {
  padding: 20px;
  border: 1px solid #535353;
  font-size: 1.4rem;
  color: var(--txt-gray-2);
  line-height: 1.6;
}
/*-------------------------------------------------------------------
    ## animation
-------------------------------------------------------------------*/
.ani_el {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.ani_el.is_on {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: ease-in-out 0.6s;
}

.delay_2 {
  transition-delay: 0.2s !important;
}

.delay_3 {
  transition-delay: 0.3s !important;
}

.delay_4 {
  transition-delay: 0.4s !important;
}

.delay_5 {
  transition-delay: 0.5s !important;
}

/*--------------------------------------------------------
  ## Menu
---------------------------------------------------------*/
.menu_list:after {
  display: block;
  content: '';
  clear: both;
}
.menu_list .menu_node {
  float: left;
}
.menu_list .menu_item {
  display: block;
}
.menu_list .menu_item.type_line {
  position: relative;
}
.menu_list .menu_item.type_line:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--disabled-colr);
}
/*--------------------------------------------------------
  ## tag
---------------------------------------------------------*/
.tag_list {
  margin: -5px;
}
.tag_list .tag_node {
  float: left;
  padding: 5px;
}
.tag_list .tag_item {
  display: block;
  position: relative;
  min-width: 60px;
  padding: 10px 14px;
  font-size: 1.6rem;
  color: var(--txt-gray-2);
  box-sizing: border-box;
}
.tag_list .tag_item:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3e3e3e;
  box-sizing: border-box;
}
.tag_list.type_big {
  margin: -15px;
}
.tag_list.type_big .tag_node {
  padding: 15px;
}
.tag_list.type_big .tag_item {
  padding: 16px 31px;
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 300;
  color: #fff;
}
/*--------------------------------------------------------
  ## banner
--------------------------------------------------------*/
.banner_item {
  height: 580px;
  padding: 23px 0;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
}
.banner_item .txt_area {
  font-size: 1.6rem;
}
.banner_item .txt_area .ttl {
  display: -webkit-box;
  overflow: hidden;
  font-size: 4rem;
  font-weight: 500;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.banner_item .txt_area .desc {
  display: -webkit-box;
  overflow: hidden;
  margin: 7px 0 10px;
  opacity: 1;
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 500;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.banner_item .txt_area p {
  opacity: 0.5;
}
/*--------------------------------------------------------
  ## List
--------------------------------------------------------*/
.list_head {
  margin-bottom: 17px;
}
.list_head.has_elem {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.list_head .count {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--txt-gray-2);
}
.list_head .sort_group > div {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}
.list_head .sort_group > div {
}
.list_head .sort_group > div:not(:first-child) {
  margin-left: 15px;
}

.guide_ui {
  padding: 45px 0 60px;
  text-align: center;
  font-size: 2rem;
}
.guide_ui .img_area {
  max-width: 310px;
  margin: 0 auto 36px;
}
.guide_ui .ttl {
  margin-bottom: 30px;
  font-size: 3.2rem;
  font-weight: 600;
}
.guide_ui .desc {
  font-size: 1.8rem;
  line-height: 1.667;
}

.guide_ui.error {
  position: relative;
}
.guide_ui.error .img_area {
  max-width: 44rem;
}

.guide_ui.error h2 {
  margin-bottom: 3rem;
  font-size: 3.4rem;
  font-weight: 300;
}

.guide_ui.error p {
  font-size: 1.8rem;
  font-weight: 200;
}

.guide_ui.error .btn {
  margin-top: 7rem;
}

.guide_ui.error .btn_area {
  display: flex;
  justify-content: center;
  margin-top: 7rem;
}

.guide_ui.error .btn_area .btn {
  margin-top: 0;
}

.guide_ui.error .btn + .btn {
  margin-left: 2rem;
}
.guide_ui .btn_area {
  margin-top: 7rem;
}
.guide_txt {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.2);
  font-size: 1.2rem !important;
  font-weight: 300 !important;
}
.label_list {
  display: table;
  width: 100%;
}
.label_list dt,
.label_list dd {
  display: table-cell;
  padding: 12px 0;
  vertical-align: top;
}
.label_list dt {
  padding-right: 16px;
  white-space: nowrap;
}
.label_list_group {
  display: table;
  width: 100%;
}
.label_list_group .label_list {
  display: table-row;
}
.label_list_group .label_list .blockchains{
  position: relative;
}
.label_list_group .label_list .blockchains i{
  position: absolute;
  left: 0;
  top:50%;
  transform: translate(0, -50%);
}
.label_list_group .label_list .blockchains b{
  padding-left:40px;
  font-weight: 300;
}
/*--------------------------------------------------------
  ## item
--------------------------------------------------------*/
.edit_list {
  margin-bottom: -30px;
}
.edit_list .edit_node,
.user_list .user_node {
  vertical-align: top;
  margin-bottom: 30px;
}
.edit_item,
.user_item {
  position: relative;
  font-size: 1.6rem;
}
.edit_item .item_head,
.user_item .item_head {
  position: relative;
}
.edit_item .item_head .ic_area {
  position: absolute;
  z-index: 10;
}
.edit_item .item_head .ic_area {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.edit_item .item_head .ic_area.type_video {
  right: 7px;
  top: 7px;
}
.edit_item .item_head .ic_area.type_blockchains {
  width: 34px;
  height: 37px;
  padding: 0;
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  box-sizing: border-box;
  border-radius: 0;
  right: 10px;
  top: 10px;
}
.edit_item .ttl_are {
}
.edit_item .profile_info + .ttl_area {
  margin-top: 11px;
}
.edit_item .ttl_area .ttl {
  overflow: hidden;
  font-size: 2rem;
  font-weight: 200;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.edit_item .ttl_area .ttl + p {
  margin-top: 4px;
}
.edit_item .ttl_area p,
.user_item .ttl_area p {
  font-size: 1.4rem;
  color: var(--txt-gray-2);
}
.edit_item .item_body,
.user_item .item_body {
  position: relative;
}
.edit_item .link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.edit_item.type_card .item_body,
.user_item.type_card .item_body {
  padding: 14px 20px 13px;
  background: var(--box-bg);
}
.edit_item.type_card .chip {
  margin: 8px 0 5px;
  color:#9f9f9f;
}
.edit_item.type_card .live_drop {
  float: right;
  margin: 8px 0 5px;
}
.edit_item.type_card .state_info {
  padding-top: 11px;
  margin-top: 14px;
  border-top: 1px solid var(--line-color);
}
.edit_item.type_card .state_info .price_area {
  float: left;
}
.edit_item.type_card .state_info .date_area {
  float: right;
}
.user_item .basic_info_group {
  display: flex;
  align-items: center;
}
.user_item .basic_info_group .ttl_area {
  flex: 1;
}
.user_item .ttl_area .ttl {
  overflow: hidden;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user_item .ttl_area .own b {
  color: #fff;
}
.artist_list .user_item .profile_info {
  position: absolute;
  left: 50%;
  top: 0;
  width: 11rem;
  height: 11rem;
  margin-left: -5.5rem;
  margin-top: -5.5rem;
}
.artist_list .user_item .profile_info:before {
  width: 11rem;
  height: 11rem;
}
.artist_list .user_item .profile_info.is_art .profile {
  width: 9.4rem !important;
  height: 9.4rem !important;
  margin: 0.8rem !important;
}
.artist_list .user_item .profile_info.is_art .profile.ic {
  margin: -0.8rem;
  border-color: transparent;
}
.artist_list .user_item .profile_info.is_art .ic_ring {
  width: 11rem !important;
  height: 11rem !important;
}
.artist_list .user_item .profile_info.is_art .profile.ic svg {
  position: relative;
  z-index: 1;
}
.artist_list .user_item .item_body {
  padding-top: 6.8rem;
  padding-bottom: 1.8rem;
  text-align: center;
}
.artist_list .user_item .item_body .ttl {
  margin-bottom: 1.7rem;
  font-size: 2rem;
}
.artist_list .user_item .item_body .ttl b {
  font-weight: 400;
}
.artist_list .user_item .item_body p {
  font-size: 1.4rem;
  text-align: left;
}
.artist_list .user_item .item_body p.desc {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.state_info .type {
  margin-bottom: 5px;
  font-size: 1.2rem;
  color: var(--txt-gray-2);
}
.state_info .price b {
  font-weight: 300;
  font-size: 1.8rem;
}
.state_info .price b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.state_info .price b .dollars{
  font-size: 1.4rem;
  font-weight: 200;
}
.chip {
  display: inline-block;
  padding: 6px 19px;
  background: var(--line-color);
  border-radius: 20px;
  font-size: 1.4rem;
  color: var(--txt-gray);
  text-align: center;
}
.chip.type_big {
  padding: 10px 30px;
  font-size: 1.6rem;
  color: var(--txt-gray-2);
}
.live_drop {
  display: inline-block;
  padding: 6px 19px;
  background: #fff;
  border-radius: 20px;
  font-size: 1.4rem;
  color: #000;
  text-align: center;
}
.swiper-slide.edit_node {
  margin-bottom: 0 !important;
}
.status_node:not(:last-child) {
  margin-bottom: 10px;
}
.status_item {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  background: var(--box-bg);
}
.status_item .profile_info,
.status_item .price_info {
  flex-shrink: 0;
}
.status_item .profile_info .profile {
  width: 5rem;
  height: 5rem;
  margin-right: 14px;
}

.status_item .profile_info.is_art .profile {
  width: 4.2rem !important;
  height: 4.2rem !important;
  margin: 0.4rem 1.8rem 0.4rem 0.4rem !important;
}

.status_item .txt_info {
  flex: 1;
  font-size: 1.4rem;
  color: var(--txt-gray);
}
.status_item .txt_info .name {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 1.8rem;
  color: #fff;
}
.status_item .txt_info .badge {
  margin-left: 8px;
  vertical-align: -1px;
}
.status_item .price_info .price b {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 300;
}
.status_item .price_info .price b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.status_item .price_info .price b .dollars{
  font-size: 1.4rem;
  font-weight: 200;
}
.thumb_list {
  margin-bottom: -57px;
}
.thumb_list .thumb_node {
  margin-bottom: 57px;
}
.thumb_item {
  font-size: 1.4rem;
}
.thumb_item .edit {
  margin-top: 14px;
}

.ic_area.type_camera {
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  line-height: 4rem;
  background: var(--box-bg);
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
}

.ic_area.type_camera input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------
    ## profile
  ---------------------------------------------------------*/
.profile_info,
.profile_info .profile {
  position: relative;
}
.profile_info {
  display: inline-flex;
  z-index: 2;
  cursor: pointer;
  align-items: center;
  vertical-align: top;
}
.profile_info .profile,
.profile_info .name {
  display: inline-block;
  vertical-align: middle;
}
.profile_info .profile {
  width: 3.2rem;
  height: 3.2rem;
  flex-shrink: 0;
  margin-right: 1rem;
  border-radius: 50%;
  background-size: cover;
}
.profile_info .profile.s {
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
}
.profile_info .profile.l {
  width: 6rem;
  height: 6rem;
  margin-right: 2rem;
}
.profile_info .profile.ic {
  background: var(--box-bg);
}
.profile_info .profile.l.ic {
  margin: -0.8rem 1.2rem -0.8rem -0.8rem;
}
.profile_info.is_art .profile.ic {
  border-color: transparent;
}
.is_art .profile {
  width: 2.4rem !important;
  height: 2.4rem !important;
  margin: 0.4rem 1.4rem 0.4rem 0.4rem;
  box-sizing: border-box;
}
.is_art .profile svg {
  position: relative;
  z-index: 1;
}
.is_art .ic_ring {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.is_art .profile.s {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0.2rem 1.2rem 0.2rem 0.2rem;
  border: 0.2rem solid var(--box-bg);
}
.profile_info .name {
  display: -webkit-box;
  position: relative;
  flex: 1;
  overflow: hidden;
  font-size: 1.4rem;
  text-align: left;
  font-weight: 200;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.profile_info .badge {
  flex-shrink: 0;
  margin-left: 5px;
  transform: translateY(-0.6em);
}

/*--------------------------------------------------------
  ## pagination
---------------------------------------------------------*/
.pagination_area.type_dot {
  margin: -4px;
  font-size: 0;
}
.pagination_area.type_dot .swiper-pagination-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #fff;
  opacity: 0.3;
  margin: 4px;
  border-radius: 4px;
}
.pagination_area.type_dot .swiper-pagination-bullet-active {
  width: 24px;
  background: var(--main-color);
  opacity: 1;
}

.slide_nav_area .btn + .btn {
  margin-left: 11px;
}
.slide_nav_area .btn.swiper-button-disabled {
  opacity: 0.42;
}
.pagination_area.type_num {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: var(--disabled-colr);
  text-align: center;
}
.pagination_area.type_num .current {
  color: #fff;
}

/*--------------------------------------------------------
  ## Time group
---------------------------------------------------------*/
.time_group > ul li {
  position: relative;
  float: left;
  font-size: 2.4rem;
  font-weight: 300;
  text-align: center;
}
.time_group > ul li:not(:first-child):before {
  display: block;
  content: '';
  position: absolute;
  top: 6px;
  bottom: 2px;
  left: 0;
  width: 1px;
  background: #fff;
  opacity: 0.4;  
}

.time_group li span.num {
  display: inline-block;
  min-width: 40px;
}

.time_group li span.txt {
  display: block;
  opacity: 0.6;
  font-size: 1.6rem;
  font-weight: 200;
  margin: 0 -1.2rem;
}
.time_area {
  position: relative;
  padding: 0 25px;
}
.time_group .time_area:first-child {
  padding-left: 0;
}
.time_group .time_area:last-child {
  padding-right: 0;
}
.time_group .time_area dt,
.time_group .time_area dd {
  
}
.time_area li {
  
}
.time_area dd {
  
}
/*--------------------------------------------------------
  ## slider
---------------------------------------------------------*/
.edition_slide_wrap {
  position: relative;
}
.edition_slide_wrap .control_area {
  position: absolute;
}
.edition_slide_wrap .pagination_area {
  display: none;
}
.edition_slide_wrap .slide_nav_area {
  right: 0;
  bottom: 100%;
  margin-right: -11px;
  margin-bottom: 12px;
}

/*-------------------------------------------------------------------
      ## tab_list
-------------------------------------------------------------------*/

.tab_list {
  display: flex;
  position: relative;
}
.tab_list:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: var(--line-color);
}

.tab_list .tab_node {
  min-width: 25%;
  flex: 1;
}
.tab_list .tab_item {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 0;
  padding: 2rem 0;
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
}

.tab_list .tab_item + .tab_item {
  margin-left: 4rem;
}

.tab_list .tab_item.is_active {
  font-weight: 400 !important;
}
.tab_list .tab_item.is_active:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 2px;
  background: var(--main-color);
}
.tab_list .tab_item .count {
  margin-left: 10px;
}
.tab_list .tab_item .ic {
  margin-right: 10px;
  vertical-align: -7px;
}
.tab_list.type_stack {
  display: block;
}
.tab_list.type_stack .tab_node {
  display: inline-block;
  min-width: auto;
}
.tab_list.type_stack .tab_item {
  width: auto;
  font-size: 2rem;
}
.tab_list.type_stack .tab_node + .tab_node {
  margin-left: 4rem;
}
/*-------------------------------------------------------------------
      ## viewer
-------------------------------------------------------------------*/

.viewer_wrap {
  position: relative;
}
.video_area video {
  max-width: 100%;
}
.viewer_wrap .controls_area {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 50px 80px;
  box-sizing: border-box;
}
.controller_wrap > div {
  float: left;
}
.controller_wrap .progress_group,
.controller_wrap .progress_area {
  width: 100%;
  margin-bottom: 37px;
}
.progress_group progress,
.progress_area progress {
  display: block;
  width: 100%;
  height: 6px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}

::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.4);
}

::-webkit-progress-value {
  background-color: var(--main-color);
}
.controls_area .btn_start {
  position: absolute;
  left: 0;
  top: 0;
}
.controller_wrap .btn_group {
  margin-left: -10px;
  margin-right: -10px;
}
.controller_wrap .btn_group .btn {
  margin: 0 10px;
}
.controls_area .type_state_control {
  float: right;
}
.controls_area.type_start {
  right: 0;
  top: 0;
  text-align: center;
}
.controls_area.type_start:before {
  display: inline-block;
  content: '';
  height: 100%;
  vertical-align: middle;
}
.controls_area.type_start .btn {
  position: relative;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  vertical-align: middle;
}
.controller_wrap .btn_group .open_playlist {
  margin: 0 2rem;
  font-size: 1.4rem;
  font-weight: 300;
}
.controller_wrap .btn_group .open_playlist .ic {
  margin-right: 1.1rem;
  vertical-align: -1rem;
}
/* thumb_nav_list */

.thumb_nav_list {
}
.thumb_nav_list .nav_node {
  margin-bottom: 20px;
}
.thumb_nav_list .nav_item {
  display: block;
}
.thumb_nav_list .nav_item.is_active {
  position: relative;
}
.thumb_nav_list .nav_item.is_active:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border: 2px solid var(--main-color);
  pointer-events: none;
}
.filter_ui {
  position: relative;
}
.filter_opt_group {
  width: 390px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 30;
  background: #000;
  padding: 22px 29px 29px;
  border: 1px solid var(--disabled-colr);
  box-sizing: border-box;
}
.filter_opt_group .filter_opt_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.filter_opt_group .filter_opt_head .ttl {
  font-size: 2.8rem;
  font-weight: 300;
}
.filter_opt_group > .btn {
  position: absolute;
  top: 2.5rem;
  right: 2.9rem;
  margin-right: -4px;
}
.filter_opt_group .filter_opt_body .input_area:not(:last-child) {
  margin-bottom: 28px;
}
.filter_opt_group .filter_opt_body .input_area {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--line-color);
}
.filter_opt_group .filter_opt_body .input_area > label,
.filter_opt_group .filter_opt_body .input_area > .label {
  margin-bottom: 1.8rem;
  font-size: 2rem;
  color: #fff;
}
.filter_opt_group .filter_opt_foot {
  margin-top: 30px;
}
.filter_opt_group .filter_opt_foot .btn_area {
  display: flex;
}
.filter_opt_group .filter_opt_foot .btn_area .btn {
  min-width: auto;
  flex: 1;
}
/*------------------------------------------------------
    ## Drop Ui
-------------------------------------------------------*/
.drop_ui .drop_box {
  position: relative;
}
.drop_ui .drop_btn {
  display: inline-block;
  position: relative;
  padding: 15px 14px;
  background: var(--box-bg);
  font-size: 1.6rem;
  vertical-align: top;
  box-sizing: border-box;
}
.drop_ui .drop_btn.type_line {
  background: transparent;
}
.drop_ui .drop_btn.type_line:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid var(--disabled-colr);
  pointer-events: none;
}
.drop_ui .drop_btn.type_line.is_active {
  background: var(--box-bg);
}
.drop_ui .drop_btn.type_line.is_active:before {
  display: none;
}
.drop_ui .drop_btn.has_arw {
  padding-right: 46px !important;
}
.drop_ui .drop_btn.has_arw .ic {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -9px;
  opacity: 0.52;
}
.drop_ui .drop_btn.has_arw.is_active .ic {
  transform: rotate(180deg);
}
.drop_ui.select .drop_btn {
  min-width: 200px;
  text-align: left;
}
.drop_ui .drop_btn.is_disabled,
.drop_ui .drop_btn:disabled {
  color: var(--disabled-colr);
}
.drop_ui .drop_btn.is_disabled .ic,
.drop_ui .drop_btn:disabled .ic {
  opacity: 0.34;
}
.drop_ui .drop_op_list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 100%;
  background: #141414;
  font-size: 1.6rem;
}
.drop_ui .drop_op_list:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid var(--box-bg);
  pointer-events: none;
  box-sizing: border-box;
}
.drop_ui .drop_op_list [role='option'] {
  padding: 15px 14px;
  color: var(--txt-gray);
  cursor: pointer;
}
.drop_ui .drop_op_list [role='option'] button {
  position: relative;
  overflow:unset;
}
.drop_ui .drop_op_list [role='option'].is_active,
.drop_ui .drop_op_list [role='option'].is_focus {
  color: #fff;
}
.drop_ui .drop_op_list [role='option'].is_active button:before,
.drop_ui .drop_op_list [role='option'][aria-selected='true'] button:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 1px;
  background: var(--main-color);
  color: #fff;
}
/*------------------------------------------------------
        ## input
    -------------------------------------------------------*/
.input_area > label,
.input_area > .label,
.input_area .label_box label,
.input_area .label_box .label {
  font-size: 1.66rem;
  font-weight: 300;
  color: var(--txt-gray);
}
.input_area .input_box {
  display: inline-flex;
  align-items: center;
}
.input_area .input_box input {
  flex: 1;
}
.input_area .input_box .remove_word {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-left: 10px;
  background: #222;
  border-radius: 50%;
}
.input_area .input_box .remove_word .ic {
  margin-top: 1px;
}
.input_area.check .input_box:not(:last-child) {
  margin-bottom: 16px;
}
.input_area.switch label,
.input_area.check label {
  position: relative;
  padding-left: 32px;
  font-size: 1.6rem;
}
.input_area.check .input_box {
  display: block;
}
.input_area.switch input,
.input_area.check input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.input_area.switch input + span:before,
.input_area.switch input + span:after,
.input_area.check input + span:before,
.input_area.check input + span:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
}
.input_area.check input + span:before,
.input_area.check input + span:after {
  width: 20px;
  height: 20px;
}
.input_area.check input + span:before {
  background: #4b4b4b;
}
.input_area.check input + span:after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  opacity: 0.55;
}
.input_area.switch input:checked + span:before,
.input_area.check input:checked + span:before {
  background: var(--main-color);
}
.input_area.check input:checked + span:after {
  opacity: 1;
}
.input_area.switch label {
  display: block;
  width: 50px;
  height: 25px;
  padding-left: 0;
}
.input_area.switch input + span {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 25px;
  background: #4b4b4b;
  border-radius: 20px;
  vertical-align: top;
}
.input_area.switch input + span:before,
.input_area.switch input + span:after {
  height: 25px;
  border-radius: 20px;
  background: #fff;
}
.input_area.switch input + span:before {
  width: 50px;
  margin-left: -25px;
  transition: left ease-out 0.2s, background-color ease-out 0s 0.2s;
}
.input_area.switch input + span:after {
  width: 25px;
  transition: left ease-out 0.2s;
}
.input_area.switch input:checked + span:before {
  background: var(--main-color);
  transition: left ease-out 0.2s, background-color ease-out 0s 0s;
}
.input_area.switch input:checked + span:before,
.input_area.switch input:checked + span:after {
  left: 25px;
}

.input_area.txt .input_box,
.input_area.txt .input_box input {
  background: var(--box-bg);
}
.input_area.txt .input_box {
  padding: 10px 14px;
}
.input_area.txt .input_box input {
  width: 100%;
  color: #fff;
  font-size: 1.6rem;
}
.input_area.txt .input_box input:focus-visible {
  outline: 0;
}
.input_area .input_box input::-moz-placeholder {
  color: var(--disabled-colr);
}
.input_area .input_box input::placeholder {
  color: var(--disabled-colr);
}
.modal .input_area.txt .input_box input::-moz-placeholder {
  color: var(--txt-gray-2);
}
.modal .input_area.txt .input_box input::placeholder {
  color: var(--txt-gray-2);
}
.input_area.search .input_box,
.input_area.search input {
  background: #000;
}
.input_area.search .input_box {
  padding-bottom: 9px;
  border-bottom: 1px solid #fff;
}
.input_area.search input {
  width: 100%;
  font-size: 1.6rem;
}

.input_area.search .input_box > .ic_search {
  flex-shrink: 0;
  margin-right: 10px;
}
.input_area.txt.has_btn {
  display: flex;
  width: 100%;
}
.input_area.txt.has_btn .input_box {
  flex: 1;
  margin-right: 10px;
}
.modal .input_area.txt .input_box,
.modal .input_area.txt .input_box input {
  background: #2e2e2e;
}
.input_area.txt.range_area .input_box {
  max-width: 160px;
  box-sizing: border-box;
}
.input_area .hoz_group {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.input_area .hoz_group span {
  flex-shrink: 0;
  font-size: 1.6rem;
  margin: 0 7px;
}

.input_area.type_hoz label,
.input_area.type_hoz .label,
.input_area.type_hoz .input_box {
  display: inline-block;
  vertical-align: middle;
}
.input_area.type_hoz label,
.input_area.type_hoz .label {
  margin-right: 15px;
}
.input_area.textarea label {
  width: 9.5rem;
  padding-top: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
  vertical-align: top;
}
.input_area.textarea .input_box {
  width: calc(100% - 11rem);
  vertical-align: top;
}

.input_area.textarea .input_box textarea {
  padding: 1.2rem 2rem 0.9rem;
  background: #2e2e2e;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray-2);
  scrollbar-color: #fff #222;
  scrollbar-width: thin;
}
.input_area.textarea .input_box textarea::-webkit-scrollbar {
  width: 2rem;
  background-color: transparent;
}
.input_area.textarea .input_box textarea::-webkit-scrollbar-thumb {
  width: 2rem;
  background-color: #4b4b4b;
  border-radius: 1rem;
  border: 0.7rem solid #2e2e2e;
}
.input_area.textarea .input_box textarea::-webkit-scrollbar-track {
  width: 2em;
  background-color: transparent;
}
/* IE의 경우 */
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
/* 크롬의 경우 */
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

/* tooltip */
.tooltip_ui {
  position: relative;
  display: inline-block;
}
.tooltip_ui > b,
.tooltip_ui > span {
  display: inline-block;
  position: relative;
}
.tooltip_ui > b:before,
.tooltip_ui > span:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 1px;
  background: #fff;
}
.tooltip_ui [role='tooltip'],
.tooltip_ui .tooltip_area {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;
  margin-top: 16px;
  padding: 7px 15px;
  background: #fff;
  border-radius: 30px;
  font-size: 1.3rem;
  font-weight: 200;
  color: var(--disabled-colr);
  transform: translateX(-50%);
}
.tooltip_ui [role='tooltip']:before,
.tooltip_ui .tooltip_area:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  bottom: 100%;
  z-index: -1;
  margin-left: -6px;
  margin-bottom: -2px;
  border: 6px solid transparent;
  border-bottom: 10px solid #fff;
  border-top: 0 none;
}
.tooltip_ui:hover [role='tooltip'],
.tooltip_ui:hover .tooltip_area {
  display: block;
}

/*  table */
.tbl_area .tbl {
}
.tbl_area .tbl thead {
  background: var(--box-bg);
}
.tbl_area .tbl thead th {
  padding: 11px 30px;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray-2);
}
.tbl_area .tbl tbody td {
  padding: 10px 30px;
  border-bottom: 1px solid var(--line-color);
  font-size: 1.6rem;
  vertical-align: middle;
}
.tbl_area .tbl tbody tr:first-child td {
  padding-top: 20px;
}
.tbl_area .tbl .button {
  width: 140px;
  padding: 10px 0;
}

/*-------------------------------------------------------------------
    ## modal
-------------------------------------------------------------------*/

.modal_wrap {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}
.modal_wrap.is_show {
  display: block;
}
.modal {
  display: inline-block;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  width: 100%;
  max-width: 64rem;
  margin: 4rem auto;
  padding: 4.5rem 4rem 5rem;
  background: #393939;
  box-sizing: border-box;
}
.modal.s {
  max-width: 34rem;
  padding: 4.3rem 4rem 5rem;
}
.modal.m {
  max-width: 42rem;
}
.modal_wrap:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.modal > .close_modal {
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: 1.2rem;
  top: 1.2rem;
  font-size: 0;
}
.modal p {
  font-size: 1.6rem;
}
.modal .modal_ttl {
  margin-bottom: 1rem;
  font-size: 2.4rem;
  font-weight: 500;
}
.modal .modal_head p {
  line-height: 1.5;
  white-space: pre-line;
}
.modal .modal_head + .modal_body {
  margin-top: 30px;
}
.modal .modal_body + .modal_foot {
  margin-top: 17px;
}
.modal.type_dialog .modal_body p {
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.375;
  color: #fff;
  white-space: pre-line;
}
.modal.type_dialog .modal_foot {
  margin-top: 2.2rem;
}
.modal.type_dialog .modal_foot .btn {
  max-width: 16.5rem;
}

.modal.type_dialog .modal_foot .btn_area.flex {
  display: flex;
  justify-content: space-between;
}

.modal.type_dialog .modal_foot .btn_area.flex .btn {
  max-width: unset;
  width: 50%;
}
.modal_wrap.type_full {
  overflow-x: hidden;
  overflow-y: auto;
  background: #000;
}
.modal_wrap.type_full:before {
  display: none;
}
.type_full .modal {
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 6rem 0 0;
  background: #000;
}
.type_full .modal .modal_head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  background: #000;
}
.type_full .modal .modal_head:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--line-color);
}
.type_full .modal .modal_ttl {
  margin-bottom: 0;
  font-size: 2.4rem;
  line-height: 3rem;
}
.type_full .modal .modal_head + .modal_body {
  margin-top: 0;
}
.type_full .modal > .close_modal {
  top: 1.4rem;
  right: 1rem;
  left: auto;
}
.type_full .modal > .close_modal {
  position: fixed;
}
.type_full .modal > .close_modal g {
  stroke-width: 2;
  stroke: #fff;
}

/*-------------------------------------------------------------------
    ## scroll
-------------------------------------------------------------------*/

.has_scroll_y {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #fff #222;
  scrollbar-width: thin;
}
.has_scroll_y::-webkit-scrollbar {
  width: 0.6rem;
  background-color: transparent;
}
.has_scroll_y::-webkit-scrollbar-thumb {
  width: 0.6rem;
  background-color: #4b4b4b;
  border-radius: 0.3rem;
}
.has_scroll_y::-webkit-scrollbar-track {
  width: 0.6rem;
  background-color: transparent;
}
.input_area.date .vdp-datepicker__calendar {
  max-width: 100%;
  height: auto !important;
}
.input_area.date .vdp-datepicker__calendar:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--line-color);
  pointer-events: none;
}
.input_area.date .vdp-datepicker__calendar,
.input_area.date .vdp-datepicker__calendar > div,
.input_area.date .vdp-datepicker__calendar .cell {
  background: #000;
  border: 0 none;
}
.input_area.date .vdp-datepicker__calendar .cell {
  border: 1px solid transparent;
}
.input_area.date .vdp-datepicker__calendar .cell:not(.blank):not(.day-header):hover {
  position: relative;
  z-index: 110;
}
.vdp-datepicker__calendar header {
  position: relative;
  z-index: 1;
}
.input_area.date .vdp-datepicker__calendar header .prev:after {
  border-right-color: #fff;
}
.input_area.date .vdp-datepicker__calendar header .next:after {
  border-left-color: #fff;
}
.vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker__calendar header .up:not(.disabled):hover {
  background: transparent !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border-color: var(--main-color) !important;
}
@media screen and (max-width: 1024px) {
  .thumb_area img {
    height: auto;
  }
  .tag_list.type_big {
    margin: -1rem;
  }
  .tag_list.type_big .tag_node {
    padding: 1rem;
  }
  .tag_list.type_big .tag_item {
    font-size: 2rem;
  }

  .tab_list .tab_item .ic {
    vertical-align: -9px;
    margin-left: -3px;
    margin-right: 8px;
  }
}

@media screen and (max-width: 767px) {
  .guide_ui .btn {
    height: 4rem;
    font-size: 1.4rem;
    line-height: 4rem;
  }
  .btn.h_l {
    height: 4rem;
    font-size: 1.4rem;
    line-height: 4rem;
  }
  .input_area.search input {
    font-size: 1.4rem;
  }
  .input_area.type_hoz label,
  .input_area.type_hoz label {
    font-size: 1.5rem;
  }

  .drop_ui .drop_btn {
    padding: 1.1rem 1.4rem;
    font-size: 1.4rem;
  }

  .guide_ui .btn.w_xl {
    min-width: 22rem;
  }

  .guide_ui .btn.w_l {
    min-width: 18rem;
  }

  .guide_ui.error {
    padding: 0 2rem;
  }

  .guide_ui.error h2 {
    margin-bottom: 3.5rem;
    font-size: 2.4rem;
  }

  .guide_ui.error p {
    font-size: 1.4rem;
    font-weight: 200;
  }

  .guide_ui.error .btn_area,
  .guide_ui.error .btn {
    margin-top: 5.4rem;
  }
  .guide_ui.error .btn_area .btn {
    margin-top: 01;
  }
  .guide_ui.error .btn_area .btn {
    width: 100%;
    min-width: auto;
  }

  .guide_ui.error .btn + .btn {
    margin-left: 1rem;
  }

  .tab_list .tab_item .ic {
    margin-left: 0;
  }
  .modal,
  .modal.m {
    max-width: calc(100% - 2rem);
    padding: 2.5rem 2rem 3rem;
  }
  .modal .modal_ttl {
    font-size: 1.8rem;
  }
  .modal .modal_head + .modal_body {
    margin-top: 1.5rem;
  }
  .modal > .close_modal {
    top: auto;
    bottom: 100%;
    left: 50%;
    margin-left: -1.5rem;
    margin-bottom: 1rem;
  }
  .modal > .close_modal g {
    stroke: #fff;
    stroke-width: 2;
  }
  .modal .btn_area.flex .btn {
    min-width: 0;
  }
  .modal .btn.h_m {
    height: 4rem;
    line-height: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .btn {
    padding: 0 0.5rem;
  }
  .btn_area .btn + .btn {
    margin-left: 1rem;
  }
  .btn.h_m {
    height: 3.4rem;
    font-size: 1.4rem;
    line-height: 3.4rem;
  }
  .btn.w_xl {
    min-width: 18rem;
  }
  .btn_like {
    right: 1.1rem;
    bottom: 0.7rem;
  }
  .btn_like .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }

  .modal .btn_area .btn + .btn {
    margin-left: 1rem;
  }
  .btn.mode i,
  .btn.mode i {
    width: 2rem !important;
    height: 2rem !important;
    /* margin-right: -0.9rem; */
    margin-top: -0.1rem;
  }
  .btn.mode i g,
  .btn.mode i g {
    stroke-width: 1.5 !important;
  }

  /* input */

  .input_area.type_hoz label,
  .input_area.type_hoz .label,
  .input_area.type_hoz .input_box {
    display: block;
  }
  .input_area.type_hoz .input_box {
    width: 100%;
  }
  .input_area.check .input_box label {
    font-size: 1.4rem;
  }
  .input_area.check input + span:before,
  .input_area.check input + span:after {
    width: 1.8rem;
    height: 1.8rem;
  }
  .input_area.check input + span:after {
    background-size: cover;
    background-position: center bottom 1px;
  }
  .input_area .hoz_group span {
    margin: 0 1.2rem;
  }
  .list_sec .sec_head .ttl {
    font-size: 2rem;
  }
  .list_sec .sec_head .float_el.btn {
    top: -0.4rem;
    min-width: 8rem;
  }
  .list_sec .sec_head .float_el.btn {
    min-width: 7rem;
  }
  .list_head .count {
    font-size: 1.2rem;
  }
  .ttl.type_underL {
    font-size: 1.8rem;
  }
  .tag_list .tag_item {
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
  }
  .tab_list .tab_item {
  }

  .state_info .type {
    flex-shrink: 0;
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  .edit_item.type_card .state_info {
    padding-top: 1.7rem;
  }
  .edit_item.type_card .chip {
    justify-self: flex-end;
    margin: -0.7rem 0 -0.5rem 0;
    font-weight: 300;
  }

  /* tab */
  .set_inner .tab_wrap {
    margin-left: -2rem;
    margin-right: -2rem;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .tab_wrap::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .tab_wrap .tab_list:before {
    height: 2px;
  }
  .tab_wrap .tab_list {
    display: inline-flex;
    padding: 0 2rem;
    white-space: nowrap;
  }
  .tab_wrap .tab_list .tab_node {
    min-width: auto;
    flex: none;
  }
  .tab_wrap .tab_list .tab_node:not(:first-child) {
    margin-left: 30px;
  }
  .tab_wrap .tab_list .tab_item {
    padding: 1.1rem 0;
    font-size: 1.6rem;
  }
  .tab_list .tab_item .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
    margin-right: 0.6rem;
    vertical-align: -0.6rem;
  }
  .tab_list .tab_item .ic g {
    stroke-width: 1.5 !important;
  }
  .tab_list .tab_item.is_active .ic g {
    stroke-width: 2.2 !important;
  }
  .tag_list.type_big {
    margin: -0.5rem;
  }
  .tag_list.type_big .tag_node {
    padding: 0.5rem;
  }
  .tag_list.type_big .tag_item {
    padding: 1.2rem 2rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .tag_list.type_big .tag_item:before {
    border-width: 2px;
  }

  /* item */
  .banner_item {
    height: 0;
    padding: 0 0 56.1111%;
  }
  .banner_item .txt_area .ttl {
    font-size: 2.4rem;
    font-weight: 500;
  }
  .banner_item .txt_area .desc {
    margin-top: 0.5rem;
    margin-bottom: 2.3rem;
    font-size: 1.6rem;
  }
  .edit_list,
  .user_list.artist_list {
    margin-bottom: -4rem;
  }
  .edit_list .edit_node,
  .user_list.artist_list .user_node {
    margin-bottom: 4rem;
  }
  .user_list {
    margin-bottom: -2rem;
  }
  .user_list .user_node {
    margin-bottom: 2rem;
  }
  .edit_item,
  .user_item {
    font-size: 1.4rem;
  }
  .edit_item .item_head .ic_area {
    width: 2.8rem !important;
    height: 3rem !important;
  }
  .edit_item .item_head .ic_area i {
    width: 100% !important;
    height: 100% !important;
  }
  .edit_item.type_card .item_body,
  .user_item.type_card .item_body {
    padding-bottom: 1.6rem;
  }
  .status_item {
    padding: 0 1.5rem 1.7rem;
    flex-wrap: wrap;
  }
  .status_item .profile_info,
  .status_item .txt_info {
    order: 1;
  }
  .status_item .price_info {
    flex: none;
    width: calc(100% + 3rem);
    padding: 1rem 1.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 1.6rem;
    border-bottom: 1px solid var(--line-color);
  }
  .status_item .profile_info .profile {
    width: 3.6rem !important;
    height: 3.6rem !important;
  }
  .status_item .profile_info.is_art .profile {
    width: 3rem !important;
    height: 3rem !important;
    margin: 0.3rem 1.4rem 0.3rem 0.3rem !important;
  }
  .status_item .profile_info.is_art .ic_ring {
    width: 3.6rem !important;
    height: 3.6rem !important;
  }
  .thumb_item,
  .status_item .txt_info {
    font-size: 1.2rem;
  }
  .status_item .txt_info .name {
    font-size: 1.4rem;
  }
  .thumb_list .thumb_node {
    margin-bottom: 2rem;
  }
  .thumb_item .edit {
    margin-top: 1rem;
  }

  .btn_alarm.is_active:before {
    width: 0.5rem;
    height: 0.5rem;
  }
  .profile_info .profile,
  .profile_info.is_art::before {
    width: 2.6rem;
    height: 2.6rem;
  }
  .is_art .profile {
    width: 2.2rem !important;
    height: 2.2rem !important;
    margin: 0.2rem 1.2rem 0.2rem 0.2rem !important;
    box-sizing: border-box;
  }
  .is_art .ic_ring {
    width: 2.6rem !important;
    height: 2.6rem !important;
  }
  .artist_list .user_item .profile_info {
    width: 10rem;
    height: 10rem;
  }
  .artist_list .user_item .profile_info:before {
    width: 10rem;
    height: 10rem;
  }
  .artist_list .user_item .profile_info.is_art .profile {
    width: 9rem !important;
    height: 9rem !important;
    margin: 0.5rem !important;
  }
  .artist_list .user_item .profile_info.is_art .profile.ic {
    margin: -0.5rem;
  }
  .artist_list .user_item .profile_info.is_art .ic_ring {
    width: 10rem !important;
    height: 10rem !important;
  }
  .artist_list .user_item .profile_info.is_art .ic_ring circle:last-child {
    r: 15 !important;
  }
  .profile_info .name {
    font-size: 1.4rem;
  }
  .time_area {
    padding: 0 1.8rem;
  }
  .time_area dt {
    font-size: 1.8rem;
  }
  .time_area dd {
    font-size: 1.3rem;
  }
  .drop_ui .drop_op_list {
    padding: 0.5rem 0;
    font-size: 1.4rem;
  }
  .drop_ui .drop_op_list [role='option'] {
    padding: 1.1rem 1.5rem;
  }
  .drop_ui .drop_btn.has_arw .ic {
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-top: -0.7rem;
    margin-right: -0.4rem;
    opacity: 0.4;
  }
  .drop_ui .drop_btn.has_arw .ic g {
    stroke-width: 2.2 !important;
  }
  .filter_opt_group {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 600;
    max-width: 32rem;
    margin-top: 0;
    padding: 6rem 0 5rem;
    border: 0 none;
    border-left: 1px solid var(--line-color);
  }
  .filter_opt_group:before {
    display: block;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.6;
  }
  .filter_opt_group .filter_opt_head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 1.5rem 1.9rem 1.4rem;
    border-bottom: 1px solid var(--line-color);
    background: #000;
  }
  .filter_opt_group .filter_opt_head .ttl {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
  .filter_opt_group .filter_opt_body {
    position: absolute;
    top: 6rem;
    right: 0;
    bottom: 5rem;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .filter_opt_group .filter_opt_body .input_area {
    padding: 2.8rem 1.9rem 2.2rem;
    margin-bottom: 0 !important;
  }
  .filter_opt_group .filter_opt_foot {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .filter_opt_group .filter_opt_foot .btn {
    width: 50%;
    min-width: 0;
    margin: 0;
    height: 5rem;
    font-size: 1.6rem;
  }
  .filter_opt_group > .btn {
    position: fixed;
    top: 1.6rem;
    right: 1.9rem;
    z-index: 605;
  }
  .filter_opt_group > .btn .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }
  .filter_opt_group > .btn .ic g {
    stroke-width: 2.2;
    stroke: #fff;
  }
  .guide_ui {
    padding-bottom: 8rem;
    font-size: 1.4rem;
  }
  .guide_ui .img_area {
    max-width: 21rem;
    margin-bottom: 1rem;
  }
  .guide_ui .ttl {
    font-size: 2.4rem;
  }
  .guide_ui .desc {
    font-size: 1.4rem;
  }
  .guide_ui .btn_area {
    margin-top: 5rem;
  }
  .edit_item.type_card .state_info .date_area {
    float: none;
  }
  .artist_list .user_item .item_body p.desc {
    -webkit-line-clamp: 4;
  }
  .status_item .price_info .price b {
    font-size: 1.6rem;
  }
  .status_item .price_info .price b .dollars{
    font-size: 1.2rem;
  }
  .edit_item.type_card .live_drop {
    margin-top: 1.4rem;
    margin-bottom: -0.7rem;
    float: none;
  }

  .label_list_group .label_list .blockchains i{
    width: 2.2rem !important;
    height: 2.4rem !important;
  }

  .label_list_group .label_list .blockchains b{
    padding-left:28px;
  }

  .state_info .price b {
    font-size: 1.6rem;
  }

  .state_info .price b .dollars{
    font-size: 1.2rem;
  }

  .controls_area.type_start .btn {
    width: 60px;
    height: 60px;
  }
  .controls_area.type_start .btn .ic {
    width: 3rem !important;
    height: 3rem !important;
  }
  .controls_area.type_start .btn .ic g {
    stroke-width: 2.4;
  }
}
@media screen and (max-width: 430px) {
  .tooltip_ui [role='tooltip'],
  .tooltip_ui .tooltip_area {
    width: 200%;
    max-width: 80vw;
  }
}
@media screen and (max-width: 360px) {
  .drop_ui .drop_btn {
    padding: 1.1rem 1rem;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 320px) {
  .btn.h_l {
    font-size: 1.2rem;
  }
  .drop_ui .drop_btn {
    padding: 1.1rem 0.7rem;
    font-size: 1rem;
  }
  .drop_ui .drop_op_list {
    font-size: 1.2rem;
  }
  .time_area {
    padding: 0 1.2rem;
  }
  .time_area dd {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 280px) {
  .btn.h_l {
    font-size: 1.1rem;
  }
  .drop_ui .drop_btn {
    padding: 1.1rem 0.5rem;
    font-size: 0.8rem;
  }
  .drop_ui .drop_op_list {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
  .viewer_sec > .btn g,
  .playlist_sec > .btn g {
    stroke-width: 2 !important;
  }
  .controller_wrap .btn_group .btn .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }
  .controller_wrap .btn_group .btn .ic g {
    stroke-width: 2.2;
  }
  .controller_wrap .btn_group .open_playlist .ic {
    vertical-align: -0.8rem;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
