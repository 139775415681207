var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("div", { staticClass: "modal_wrap show" }, [
        _c("div", { staticClass: "modal s type_dialog" }, [
          _c("div", { staticClass: "modal_head" }, [
            _c("h3", { staticClass: "ttl" }, [_vm._v(_vm._s(_vm.message))]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }