var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "footer_page help_page", attrs: { id: "contents" } },
    [
      _c("div", { staticClass: "set_inner" }, [
        _c("div", { staticClass: "page_ttl_area" }, [
          _c("h1", { staticClass: "page_ttl" }, [_vm._v("Help Center")]),
          _c("div", { staticClass: "btn_area" }, [
            _c("button", { ref: "modalBtn", on: { click: _vm.openMailForm } }, [
              _c(
                "div",
                { staticClass: "mail-form" },
                [_c("span", [_vm._v("Submit a request")]), _c("ArwNextS")],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "section",
          { staticClass: "board_sec" },
          [
            _c("div", { staticClass: "search_wrap" }, [
              _c("div", { staticClass: "input_area search" }, [
                _c("div", { staticClass: "input_box" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search",
                      },
                    ],
                    ref: "searchRef",
                    attrs: {
                      type: "search",
                      placeholder: "Search help articles…",
                      label: "Search",
                      title: "Search",
                    },
                    domProps: { value: _vm.search },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.search = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn_search",
                      on: { click: _vm.handleSearch },
                    },
                    [_c("IcSearch", { staticClass: "ic_search" })],
                    1
                  ),
                  _vm.search !== ""
                    ? _c(
                        "button",
                        {
                          staticClass: "btn type_ic remove_word",
                          attrs: { type: "button" },
                          on: { click: _vm.clear },
                        },
                        [
                          _c("IcCloseS", {
                            attrs: {
                              iconLabel: "Clear search field",
                              "stroke-color": "#fff",
                              width: 20,
                              height: 20,
                              "stroke-width": 1,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "tab_wrap" }, [
              _vm.resultIndex === 0
                ? _c(
                    "div",
                    { staticClass: "tab_list", attrs: { role: "tablist" } },
                    _vm._l(_vm.tabMenu, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "tab_node",
                          staticStyle: { "white-space": "pre-wrap" },
                          on: {
                            click: function ($event) {
                              return _vm.handleTabClick(index)
                            },
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "tab_item",
                              class: { is_active: index === _vm.activeIndex },
                              attrs: { role: "tab" },
                            },
                            [
                              index === _vm.activeIndex
                                ? _c("span", { staticClass: "is_blind" }, [
                                    _vm._v("selected"),
                                  ])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.cateName) + " "),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm.resultIndex === 0
              ? [
                  _c(
                    "ul",
                    _vm._l(_vm.faqInfos, function (item) {
                      return _c("li", { key: item.num }, [
                        _c(
                          "div",
                          { staticClass: "board_item basic" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  "aria-describedby": "board" + item.num,
                                  to: {
                                    name: `helpCenterDetail`,
                                    query: {
                                      num: item.num,
                                      cateNo: _vm.cateNo,
                                    },
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "item_body" }, [
                                  _c("div", { staticClass: "item_info_area" }, [
                                    _c("p", { staticClass: "ttl" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "btn_area" }, [
                                  _c(
                                    "button",
                                    { staticClass: "only_pc" },
                                    [_c("ArwNext")],
                                    1
                                  ),
                                  _c(
                                    "button",
                                    { staticClass: "dv_tab" },
                                    [_c("ArwNextS")],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "button",
                        {
                          staticClass: "license_area",
                          on: { click: _vm.openLicense },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "license" },
                            [
                              _vm._v(" Open source license "),
                              _c("span", { staticClass: "is_blind" }, [
                                _vm._v("Open in new window"),
                              ]),
                              _c("ArwNextS", {
                                attrs: { "stroke-color": "var(--txt-gray)" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("Pagination", {
                        attrs: { totalCount: _vm.totalCount, pageSize: "10" },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("MailModal", { ref: "MailModal", on: { closeModal: _vm.closeModal } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }