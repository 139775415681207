<template>
  <main id="contents" class="footer_page terms_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">About Us</h1>
      </div>
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div>
        <section class="board_sec">
          <div class="board_sec_head">
            <p class="ttl">About LG Art Lab</p>
          </div>
          <div
            class="board_sec_body"
            style="border: 0px; padding-bottom: 2rem; line-height: 3.6rem"
          >
            <span
              >LG Art Lab is LG Electronics’ dynamic NFT marketplace curating a
              diverse collection of digital art accessible through LG Smart TVs
              and online. </span
            ><br />
            <span
              >LG Art Lab invites art enthusiasts to collect and trade NFTs of
              digital artworks using blockchain transactions. </span
            ><br />
            <span
              >Integrated across multiple devices LG Art Lab enhances
              accessibility and allows art lovers to enjoy their collection on
              LG OLED, </span
            ><br />
            <span>
              the preferred digital canvas. This approach empowers art
              enthusiasts to securely collect and trade NFTs ensuring
              transparent verification of each artwork’s creation and ownership.
            </span>
          </div>

          <div class="board_sec_head">
            <p class="ttl">Our Approach</p>
            <p
              class=""
              style="color: #ff521d; font-size: 3.2rem; font-weight: bold"
            >
              "We display valuable arts that transform living rooms into digital
              art museums"
            </p>
          </div>
          <div class="board_sec_body" style="border: 0px">
            <span style="font-size: 20px; font-weight: bold; margin-left: 100px"
              >1. The Art Gallery You Can Fully Enjoy from Home</span
            >
            <br />
            <div
              style="
                margin-left: 150px;
                margin-right: 150px;
                line-height: 3.6rem;
              "
            >
              <span>We transform houses into a live digital art museum. </span
              ><br />
              <span
                >We provide an immersive experience to appreciate art work like
                a personal exhibition. </span
              ><br />
              <span
                >We continue to present the artworks as intended preserving the
                artist’s vision and quality of the piece on LG Smart TVs.
              </span>
            </div>

            <br /><br />
            <span style="font-size: 20px; font-weight: bold; margin-left: 100px"
              >2. Embrace the Ethos of Display Valuable Arts</span
            >
            <br />
            <div
              style="
                margin-left: 150px;
                margin-right: 150px;
                line-height: 3.6rem;
              "
            >
              <span
                >We prioritize a collaborative approach with artists, ensuring
                their voices are central. </span
              ><br />
              <span
                >We aim to provide an integrated experience that connects
                virtual to reality and linking artists and collectors through
                both online and offline events. We dream this will enhance the
                relationship between creators and collectors - artists can reach
                global audiences more easily, and our LG TV customers can
                experience art n more interactive ways.
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'AboutUsView',
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
@import '../../assets/css/footer.css';
.footer_page {
  padding-bottom: 6rem;
}
</style>
