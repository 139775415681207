// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.family_account_wrap[data-v-293675c7] {
  clear: both;
}
.family_account_head[data-v-293675c7] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.3rem;
}
.family_account_head .ttl[data-v-293675c7] {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
}
.family_account_head .add_btn[data-v-293675c7] {
  width: 10.3rem;
  padding-bottom: 0.6rem;
  border-bottom: 0.1rem solid #fff;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
}
.account_item[data-v-293675c7] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  background: #4a4a4a;
}
.account_item + .account_item[data-v-293675c7] {
  margin-top: 1rem;
}
.account_group .profile_info[data-v-293675c7] {
  margin-right: 2rem;
  vertical-align: middle;
}
.account_group .profile_info .profile[data-v-293675c7] {
  width: 5rem;
  height: 5rem;
  margin-right: 0;
}
.account_group .profile_info_area[data-v-293675c7] {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.account_group .profile_info_area .name[data-v-293675c7] {
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
}
.account_group .profile_info_area .date[data-v-293675c7] {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.account_group .profile_info_area .date.is_error[data-v-293675c7] {
  color: #e31f1f;
}
.account_group .profile_info_area .date.is_error[data-v-293675c7]:before {
  display: inline-block;
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.6rem;
  background: #e31f1f;
  border-radius: 50%;
  vertical-align: middle;
}
.account_group .profile_info_area .date.is_success[data-v-293675c7] {
  color: #00b981;
}
.account_group .profile_info_area .date.is_success[data-v-293675c7]:before {
  display: inline-block;
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.6rem;
  background: #00b981;
  border-radius: 50%;
  vertical-align: middle;
}
.account_item .ic_area[data-v-293675c7] {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.email_wrap[data-v-293675c7] {
  position: relative;
  margin-top: 1rem;
  padding: 2rem 3rem;
  border: 0.1rem solid #4a4a4a;
}
.email_input_group[data-v-293675c7] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email_input_group label[data-v-293675c7] {
  display: block;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  color: #fff;
}
.email_wrap .input_area.email[data-v-293675c7] {
  width: 34rem;
  text-align: left;
}
.email_wrap .input_area.email .input_box[data-v-293675c7] {
  width: 100%;
  padding: 1rem 1.4rem;
  background: #2e2e2e;
}
.email_wrap .input_area.email .input_box input[data-v-293675c7] {
  width: 100%;
  background: #2e2e2e;
  font-size: 1.6rem;
  color: #fff;
}
.email_wrap .input_area.email .input_box.is_error[data-v-293675c7] {
  border: 0.1rem solid #e31f1f;
}
.email_wrap .ic_area[data-v-293675c7] {
  position: absolute;
  top: 50%;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  margin-top: -2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.validation[data-v-293675c7] {
  margin-top: 0.5rem;
  font-size: 1.3rem;
  font-weight: 300;
  color: #e31f1f;
}
@media screen and (max-width: 767px) {
.family_account_head .ttl[data-v-293675c7] {
    font-size: 1.6rem;
}
.account_group .profile_info_area .name[data-v-293675c7] {
    font-size: 1.5rem;
}
.family_account_head .add_btn[data-v-293675c7],
  .account_group .profile_info_area .date[data-v-293675c7] {
    font-size: 1.3rem;
}
.family_account_head[data-v-293675c7] {
    margin-bottom: 1.8rem;
}
.family_account_head .add_btn[data-v-293675c7] {
    width: auto;
}
.account_item[data-v-293675c7] {
    padding: 2rem 1.6rem;
}
.account_item .ic_area[data-v-293675c7] {
    background: rgba(0, 0, 0, 0.2);
}
.account_item .ic_area .ic[data-v-293675c7] {
    width: 3.6rem !important;
    height: 3.6rem !important;
}
.account_group .profile_info .profile[data-v-293675c7] {
    width: 4rem !important;
    height: 4rem !important;
}
.account_group .profile_info_area .date[data-v-293675c7] {
    margin-top: 0.7rem;
}
.email_wrap[data-v-293675c7] {
    position: relative;
    padding: 1.6rem;
}
.email_wrap .input_area.email[data-v-293675c7] {
    width: 100%;
}
.email_wrap .input_area.email .input_box[data-v-293675c7] {
    position: relative;
    padding-right: 4rem;
}
.email_wrap .ic_area[data-v-293675c7] {
    right: 0;
    top: 0;
    margin-top: -0.2rem;
    background: transparent;
}
.email_wrap .ic_area .ic[data-v-293675c7] {
    width: 2.4rem !important;
    height: 2.4rem !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
