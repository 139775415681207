var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "arwPageLast",
        "icon-label": _vm.iconLabel,
        width: 11.043,
        height: 9.664,
        viewBox: "0 0 11.043 9.664",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("g", { attrs: { fill: "none", "data-name": "그룹 3748" } }, [
        _c("path", {
          attrs: { d: "m6.374.332 4 4.5-4 4.5", "data-name": "패스 3750" },
        }),
        _c("path", {
          attrs: { d: "m.374.332 4 4.5-4 4.5", "data-name": "패스 3752" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }