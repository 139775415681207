var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "viewer_page", attrs: { id: "contents" } }, [
    _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Player")]),
    _c(
      "article",
      {
        ref: "viewer",
        staticClass: "viewer_sec",
        class: { is_control: _vm.controlMode },
        on: { click: _vm.changeMode },
      },
      [
        _c(
          "div",
          { staticClass: "viewer_wrap" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.controlMode
                ? _c("div", { staticClass: "ttl_area" }, [
                    _vm.otherMarket
                      ? _c(
                          "div",
                          {
                            staticClass: "logo",
                            style: `background-image: url(${
                              _vm.collections[_vm.index].otherMaplLogoUrl
                            })`,
                          },
                          [
                            _c("h2", { staticClass: "ttl" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.collections[_vm.index].otherPlatform
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    this.dropInfo
                      ? _c("div", [
                          _c("h2", { staticClass: "ttl" }, [
                            _vm._v(_vm._s(_vm.dropInfo.artistName)),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(_vm._s(_vm.dropInfo.title)),
                          ]),
                        ])
                      : _vm._e(),
                    !this.dropInfo
                      ? _c("div", [
                          _c("h2", { staticClass: "ttl" }, [
                            _vm._v(_vm._s(_vm.collections[_vm.index].title)),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(
                              _vm._s(_vm.collections[_vm.index].artistName)
                            ),
                          ]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(
                              _vm._s(_vm.collections[_vm.index].description)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.collections[_vm.index].listType !== "D" &&
                    !this.dropInfo
                      ? _c("div", [
                          _vm.collections[_vm.index].otherMaplYn !== "Y"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn type_line_w w_l h_l",
                                  on: { click: _vm.handleClick },
                                },
                                [_c("span", [_vm._v("View Details")])]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "view_area" }, [
              _vm.collections[_vm.index].mimeType === "video/mp4" &&
              _vm.collections[_vm.index].videoInfos
                ? _c(
                    "video",
                    {
                      key: _vm.index,
                      ref: "videoRef",
                      attrs: {
                        loop: _vm.singleVideoLoop,
                        id: "videoId",
                        autoplay: "",
                        playsinline: "",
                      },
                    },
                    [
                      _c("source", {
                        attrs: {
                          src: _vm.collections[_vm.index].videoInfos[0].resUrl,
                          type: _vm.collections[_vm.index].videoInfos[0]
                            .mimeType,
                        },
                        on: { error: _vm.videoError },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.collections[_vm.index].mimeType === "image/jpeg" ||
              (_vm.collections[_vm.index].otherMaplYn == "Y" &&
                !_vm.collections[_vm.index].videoInfos)
                ? _c("img", {
                    key: _vm.collections[_vm.index].editionId,
                    attrs: {
                      srcset: `${
                        _vm.collections[_vm.index].imageInfos[2].resUrl
                      } 598w, 
                    ${_vm.collections[_vm.index].imageInfos[1].resUrl} 1920w,
                    ${_vm.collections[_vm.index].imageInfos[0].resUrl} 1920w`,
                      size: "(max-width: 598px) 592px, 1920px",
                      alt: _vm.collections[_vm.index].title
                        ? _vm.collections[_vm.index].title
                        : "",
                    },
                  })
                : _vm._e(),
            ]),
          ],
          1
        ),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.controlMode
            ? _c("div", { staticClass: "controller_wrap" }, [
                _c("div", { staticClass: "progress_area" }, [
                  _c("progress", {
                    attrs: { max: _vm.playTime },
                    domProps: { value: _vm.currentTime },
                    on: { click: _vm.moveProgress },
                  }),
                ]),
                _c("div", { staticClass: "btn_group" }, [
                  _c("div", { staticClass: "btn_area play_area" }, [
                    _c(
                      "button",
                      {
                        ref: "prev",
                        staticClass: "btn type_ic control_player btn_prev",
                        attrs: { "aria-label": "Play previous file" },
                        on: { click: _vm.prev },
                      },
                      [_c("IcPrevFile")],
                      1
                    ),
                    !_vm.isPlay
                      ? _c(
                          "button",
                          {
                            staticClass: "btn type_ic btn_play",
                            attrs: { "aria-label": "Play" },
                            on: { click: _vm.play },
                          },
                          [_c("IcPlayM", { staticClass: "ic_play" })],
                          1
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn type_ic btn_play",
                            attrs: { "aria-label": "Pause" },
                            on: { click: _vm.pause },
                          },
                          [_c("IcPauseM")],
                          1
                        ),
                    _c(
                      "button",
                      {
                        staticClass: "btn type_ic control_player btn_next",
                        attrs: { "aria-label": "Play next file" },
                        on: { click: _vm.next },
                      },
                      [_c("IcNextFile")],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "btn_area state_area" }, [
                    false
                      ? _c(
                          "button",
                          {
                            staticClass: "btn type_ic btn_download",
                            attrs: { "aria-label": "download" },
                            on: { click: _vm.download },
                          },
                          [_c("IcDownloadL")],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn type_ic open_playlist",
                        attrs: { "aria-label": "Open Playlist" },
                        on: {
                          click: function ($event) {
                            return _vm.onPlayList(true)
                          },
                        },
                      },
                      [
                        _c("IcItems"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "61px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.index + 1) +
                                " / " +
                                _vm._s(_vm.collections.length)
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    !_vm.fullscreen
                      ? _c(
                          "button",
                          {
                            staticClass: "btn type_ic btn_zoom",
                            attrs: { "aria-label": "Zoom in" },
                            on: { click: _vm.zoomIn },
                          },
                          [_c("IcZoomIn")],
                          1
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn type_ic btn_zoom",
                            attrs: { "aria-label": "Zoom out" },
                            on: { click: _vm.zoomOut },
                          },
                          [_c("IcZoomOut")],
                          1
                        ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm.controlMode
          ? _c(
              "button",
              { staticClass: "btn type_ic close_btn", on: { click: _vm.exit } },
              [_c("IcCloseM", { attrs: { "stroke-color": "#fff" } })],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.showPlaylist
      ? _c(
          "aside",
          { staticClass: "playlist_sec" },
          [
            _c("p", { staticClass: "count" }, [
              _c("span", { staticClass: "c_point" }, [
                _vm._v(_vm._s(_vm.collections.length)),
              ]),
              _vm._v(" play list "),
            ]),
            _c("PlaylistSlide", {
              attrs: { current: _vm.index, "item-data": _vm.collections },
              on: { changeCurrent: _vm.changeCurrent },
            }),
            _c(
              "button",
              {
                staticClass: "btn type_ic close_btn",
                on: {
                  click: function ($event) {
                    return _vm.onPlayList(false)
                  },
                },
              },
              [_c("IcCloseM", { attrs: { "stroke-color": "#fff" } })],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }