var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCheckMark",
        "icon-label": _vm.iconLabel,
        width: 60,
        height: 60,
        viewBox: "0 0 60 60",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", { attrs: { d: "m19.4 30.3 7.6 6.8 12.7-13.5" } }),
      _c("circle", { attrs: { cx: "30", cy: "30", r: "24.5" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }