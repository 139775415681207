// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile_wrap[data-v-42410a2c] {
  margin-bottom: 10px;
}
.modal_body .bg_edit_wrap[data-v-42410a2c] {
  position: relative;
  width: 100%;
  height: 13.6rem;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.modal_body .bg_edit_wrap .ic_area.type_camera[data-v-42410a2c] {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.modal_body .profile_area[data-v-42410a2c] {
  position: relative;
  display: block;
  cursor: auto;
}
.modal_body .profile_area .profile[data-v-42410a2c] {
  position: relative;
  display: inline-block;
  width: 11rem !important;
  height: 11rem !important;
  margin-top: -5.5rem !important;
  margin-right: 0.3rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 50%;
  background-size: cover;
}
.modal_body .profile_area.is_art .profile[data-v-42410a2c] {
  width: 9.4rem !important;
  height: 9.4rem !important;
}
.modal_body .profile_area .ic_ring[data-v-42410a2c] {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  margin-top: -6.3rem !important;
  margin-left: -5.4rem !important;
}
.modal_body .profile_area .profile .ic_area.type_camera[data-v-42410a2c] {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
}
.modal_body .profile_area .ttl[data-v-42410a2c] {
  display: block;
  margin-top: 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  color: #fff;
}
.modal_body .introduce_wrap[data-v-42410a2c] {
  margin-top: 3.3rem;
  margin-bottom: 1.3rem;
  text-align: left;
}
.modal_body .introduce_wrap .input_area.textarea textarea[data-v-42410a2c] {
  min-height: 10rem;
}
.modal_body .introduce_wrap .input_area.text label[data-v-42410a2c] {
  width: 9.5rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
  vertical-align: middle;
}
.modal_body .introduce_wrap .input_area.text .input_box[data-v-42410a2c] {
  width: calc(100% - 11rem);
  vertical-align: middle;
}
.modal_body .introduce_wrap .input_area.text .input_box input[data-v-42410a2c] {
  width: 100%;
  padding: 1.1rem 1.4rem;
  background: #2e2e2e;
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
}
.modal_body .introduce_wrap .input_area + .input_area[data-v-42410a2c] {
  margin-top: 1.4rem;
}
.modal_body + .modal_foot[data-v-42410a2c] {
  margin-top: 30px;
}
.tab_list[data-v-42410a2c] {
  margin-top: 3rem;
  margin-bottom: 0;
}
.tab_list[data-v-42410a2c]:before {
  background: #707070;
}
.tab_list .tab_node[data-v-42410a2c] {
  width: 100%;
}
.tab_list .tab_node .tab_item[data-v-42410a2c] {
  display: inline-block;
  width: 50%;
  margin-left: 0;
  padding: 1.4rem 0;
  font-size: 1.8rem;
  font-weight: 300;
}
.profile.none[data-v-42410a2c] {
  display: inline !important;
}
.modal_body .profile_area .ic_ring.none[data-v-42410a2c] {
  top: -17px !important;
}
.type_camera.none[data-v-42410a2c] {
  z-index: 2;
}
@media screen and (max-width: 767px) {
.modal_body .introduce_wrap .input_area.text .input_box[data-v-42410a2c] {
    width: 100%;
}
.modal_body .introduce_wrap .input_area label[data-v-42410a2c],
  .modal_body .introduce_wrap .input_area.text label[data-v-42410a2c] {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}
.modal_body .introduce_wrap .input_area.textarea textarea[data-v-42410a2c] {
    height: 6.6rem;
}
.modal_body .profile_area .ttl[data-v-42410a2c] {
    font-size: 1.8rem;
}
.modal_body .introduce_wrap[data-v-42410a2c] {
    margin-top: 1.6rem;
}
.modal_body .profile_area .profile[data-v-42410a2c] {
    width: 10rem !important;
    height: 10rem !important;
}
.modal_body .profile_area .ic_ring[data-v-42410a2c] {
    width: 10rem !important;
    height: 10rem !important;
    margin-top: -6rem !important;
    margin-left: -5rem !important;
}
.modal_body .profile_area.is_art .profile[data-v-42410a2c] {
    width: 9.4rem !important;
    height: 9.4rem !important;
    margin-top: -5.7rem !important;
    border: 0.2rem solid var(--box-bg) !important;
}
.modal_body .profile_area.is_art .profile.none[data-v-42410a2c] {
    border: none !important;
}
.ic_area.type_camera[data-v-42410a2c] {
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.6rem;
}
.ic_area.type_camera .ic[data-v-42410a2c] {
    width: 2.4rem !important;
    height: 2.4rem !important;
}
.modal_body .profile_area .profile .ic_area.type_camera[data-v-42410a2c] {
    right: -1rem;
    bottom: 0;
}
.modal_body .introduce_wrap[data-v-42410a2c] {
    max-height: calc(100vh - 60rem);
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.tab_list[data-v-42410a2c] {
    padding: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
