var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icAlarm",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 75",
          d: "M17.21 14.298V8.67a5.22 5.22 0 0 0-10.44 0v5.628l-1.847 2.008h14.134Z",
        },
      }),
      _c("path", {
        attrs: { "data-name": "Path 109", d: "M9.064 20.161h5.711" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }