// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/img_dim.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../img/ic/ic_thumb_check_m.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
	파일정의 : my_collection page 스타일
	작성날짜 : 2022-04-06 진재윤
	참고사항 : Detail page 스타일 정의
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/
.my_collection_visual_sec {
}
.my_collection_visual_sec .bg_el {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  min-height: 860px;
  height: calc(var(--nf) * 1px);
}
.bg_el:before,
.bg_el:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.bg_el:before {
  top: 0;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: cover;
}
.bg_el:after {
  height: 490px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
}
.bg_slide_wrap .mode {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 20rem;
  z-index: 14;
}
.show_item_info_wrap .mode i,
.bg_slide_wrap .mode i {
  vertical-align: -5px;
  margin-left: 7px;
}
.bg_slide_wrap .mode:hover i g {
  stroke: #1f1f1f;
}
.my_collection_visual_sec .set_inner {
  display: flex;
  position: relative;
  /* height: calc(var(--nf) * 1px); */
  min-height: 700px;
  flex-flow: column;
  justify-content: space-between;
  padding-bottom: 85px;
}
.show_item_info_wrap {
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: 23rem;
}
.show_item_info_wrap .ttl {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.625;
  color: #fff;
}
.show_item_info_wrap .desc {
  margin-top: 7px;
  font-size: 18px;
  font-weight: 200;
  line-height: 1.6;
  color: #fff;
}
.show_item_info_wrap .editions {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.5);
}
.show_item_info_wrap .btn_area {
  height: 50px;
  margin-top: 50px;
}
.swiper_container.list {
  position: relative;
}
.swiper_container.list .ttl_area {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.swiper_container.list .list_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.swiper_container.list .list_head .ttl_area {
  margin-bottom: 0;
}
.swiper_container.list .list_head .ttl_area .ttl {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray);
}
.swiper_container.list .list_head .ttl_area .ttl em {
  color: var(--main-color);
}
.swiper_container.list .dim_el {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  background: #000;
  opacity: 0.5;
  pointer-events: none;
}
.is_hover .swiper_container.list .list_body {
  z-index: 13;
  margin-top: -30rem;
  padding-top: 30rem;
}
.swiper_container.list .edit_item .ttl_area {
  margin-bottom: 0;
}
.swiper_container.list .list_body .edit_node .link,
.swiper_container.list .list_body .item_body {	
  /* NOTE: to es Lee -> remove comment for return to original state */	
  /* display: none; */	
  /* NOTE: to es Lee -> added by wsChoi */	
  display: flex;	
  position: absolute;	
  top: 0;	
  left: 0;	
  z-index: 1;	
  width: 100%;	
  height: 100%;	
  flex-flow: column;	
  justify-content: flex-end;	
  opacity: 0;	
  /* padding-right: 30px; */	
  padding: 0;	
  background: rgba(0, 0, 0, 0.7);	
  transition: ease-in-out 0.2s;
}
.swiper_container.list .list_body .item_body .basic_info_group {	
  padding: 17px;
}
.swiper_container.list .list_body .edit_node:hover {	
  /* NOTE: to es Lee -> remove comment for return to original state */	
  /* z-index: 100; */
}
.swiper_container.list .list_body .edit_node:hover .edit_item {	
  /* NOTE: to es Lee -> remove comment for return to original state */	
  /* position: absolute;	
  bottom: -194px;	
  left: 50%;	
  z-index: 100;	
  width: 498px;	
  margin-left: -249px;	
  opacity: 1 !important; */
}
.swiper_container.list .list_body .edit_node:hover img {	
  width: 100%;
}
.swiper_container.list .list_body .edit_node:hover .item_body {	
  /* NOTE: to es Lee -> remove comment for return to original state */	
  /* display: block; */	
  /* NOTE: to esLee -> added by wsChoi*/	
  opacity: 1;	
  transition: ease-in-out 0.4s;
}
.swiper_container.list .list_body .swiper-slide-active.edit_node:hover .edit_item {
  left: 0;
  margin-left: 0;
}
.swiper_container.list .list_body .swiper-slide-active + .edit_node + .edit_node + .edit_node:hover .edit_item {
  left: auto;
  right: 0;
  margin-left: auto;
  margin-right: 0;
}
.my_collection_visual_sec .list_wrap {
  position: relative;
  margin-top: 5rem;
}
.my_collection_visual_sec .list_wrap .slide_nav_area .btn {
  position: absolute;
  z-index: 1;
  bottom: 97px;
  margin-bottom: -2rem;
}
.my_collection_visual_sec .list_wrap .slide_nav_area .prev {
  left: -60px;
}
.my_collection_visual_sec .list_wrap .slide_nav_area .next {
  right: -60px;
}
.my_collection_visual_sec .list_wrap.is_hover .swiper_container.list .edit_node .edit_item {
  /* opacity: 0.6; */
}
.my_collection_visual_sec .list_wrap.is_hover .mode {
  z-index: 12;
}
.swiper_container.list .edit_item .item_body .profile_info {
  padding-right: 3.3rem;
}
.swiper_container.list .edit_item .item_body > .btn {
  position: absolute;
  /* right: 1.2rem; */
  left: 1.2rem;
  top: 1.2rem;
  /* bottom: 1.2rem; */
  z-index: 100;
}
/* mode_page */
.mode_page {
  padding-top: 6rem;
  padding-bottom: 15rem;
}
.collection_list_sec {
  position: relative;
}
.collection_list_sec .ttl_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid var(--line-color);
}
.collection_list_sec .ttl_group .ttl {
  margin-bottom: 1rem;
  font-size: 3.2rem;
  font-weight: 300;
}
.collection_list_sec .ttl_group p {
  font-size: 1.6rem;
  font-weight: 200;
  color: #fff;
}
.collection_list_sec .mode {
  display: flex;
  justify-content: center;
  align-items: center;
}
.collection_list_sec .collection_list_sec_body {
  margin-top: 3.5rem;
}
.collection_list_sec .collection_list_sec_body.is_mode {
  padding-top: 2rem;
  margin-top: 18rem;
  border-top: 0.1rem solid var(--line-color);
}
.collection_list_sec .collection_list_sec_body > .ttl_area {
  margin-bottom: 1.7rem;
}
.collection_list_sec .collection_list_sec_body > .ttl_area .ttl {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--txt-gray);
}
.collection_list_sec .collection_list_sec_body > .ttl_area .ttl em {
  color: var(--main-color);
}
.collection_list_sec_body + .collection_list_sec_body {
  margin-top: 7rem;
}
.collection_list_sec .collection_list_sec_body .view_btn_area {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid var(--line-color);
  text-align: center;
}
.collection_list_sec .collection_list_sec_body .view_btn_area button {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}
.collection_list_sec .btn_group {
  position: absolute;
  top: 13.5rem;
  left: 0;
}
.collection_list_sec .btn_group .input_area.check {
  position:absolute;
  top:80px;
  left:0;
}
.collection_list_sec .btn_group .input_area.check .input_box label {
  position: relative;
  padding-left: 2.8rem;
}
.collection_list_sec .btn_group .input_area.check .input_box label::after {
  content: "";
  display: block;
  position: absolute;
  top: .2rem;
  right: -1.5rem;
  width: .1rem;
  height: 1.5rem;
  background: #fff;
}
.collection_list_sec .btn_group .input_area.check .input_box input {
  cursor: pointer;
}
.collection_list_sec .btn_group .input_area.check .input_box .txt_selected {
  margin-left: 3rem;
  font-size: 1.6rem;
}
.collection_list_sec .profile_info .badge {
  margin-left: 2.5rem;
  transform: translateY(0);
}
.collection_list_sec .view_btn_area .view_btn i {
  vertical-align: middle;
  margin-left: 1.8rem;
}
.collection_list_sec .view_btn_area .view_btn.is_active i {
  vertical-align: -5px;
  transform: rotate(180deg);
}
.collection_wrap .input_area.check {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.2rem;
  z-index: 10;
}
.collection_wrap .input_area.check input + span:before {
  width: 3rem;
  height: 3rem;
  background: #000;
  opacity: 0.2;
}
.collection_wrap .input_area.check input + span:after {
  width: 3rem;
  height: 3rem;
  opacity: 1;
  background-size: cover;
}
.collection_wrap .input_area.check input:checked + span:before {
  background: var(--main-color);
  opacity: 1;
}
.collection_list_sec .guide_ui p {
  margin-top: 3.5rem;
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
}
.collection_list_sec .guide_ui .btn {
  margin-top: 7rem;
  min-width: 30rem;
}
@media screen and (max-width: 1560px) {
.my_collection_visual_sec .set_inner {
    max-width: 1280px;
}
}
@media screen and (max-width: 1024px) {
.my_collection_visual_sec .bg_el {
    top: 60px;
}
.my_collection_visual_sec .set_inner {
    height: auto;
    min-height: calc(var(--nf) * 1px);
    padding-top: 6rem;
    padding-bottom: 5rem;
    box-sizing: border-box;
}
.mode_page {
    padding-top: 3rem;
    padding-bottom: 8rem;
}
.collection_wrap .edit_item .item_head img {
    width: 100%;
}
.swiper_container.list .list_head {
    width: auto;
}
.my_collection_visual_sec .show_item_info_wrap {
    padding-top: 5rem;
}
.show_item_info_wrap .btn_area {
    display: inline-block;
    width: 20rem;
    height: 12rem;
    vertical-align: top;
}
.show_item_info_wrap .btn_area .btn {
    min-width: 20rem;
}
.show_item_info_wrap .btn_area .btn + .btn {
    margin-left: 0;
    margin-top: 2rem;
}
.show_item_info_wrap .mode i {
    margin-left: 0;
    margin-right: -0.5rem;
}
.swiper_container.list .list_body .edit_node:hover .edit_item {
    position: static !important;
    margin: 0 !important;
    width: auto;
    height: auto;
}
.swiper_container.list .list_body .item_body {
    display: block;
}
.my_collection_visual_sec .list_wrap .control_area.slide_nav_area {
    display: none;
}
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
.my_collection_page .show_item_info_wrap {
    padding-top: 3rem;
}
.show_item_info_wrap .btn_area {
    margin-top: 3rem;
}
}
@media screen and (max-width: 767px) {
.collection_list_sec_head .btn_group.mob_style {
    display: inline-block;
}
.collection_list_sec .btn_group.mob_style {
    top: 13.568rem;    
    width: 100%;
}
.collection_list_sec .btn_group.mob_style .input_area.check {
    top: inherit;
    left: inherit;
    right:0;
    bottom: 25%;
}
.collection_list_sec .btn_group.mob_style .btn_area {
    display: flex;
    width: 100%;
}
.collection_list_sec .btn_group.mob_style .btn_area .btn {
    display: none;
}
.collection_list_sec .btn_group.mob_style .btn_area button {
    position: relative;
    padding: 12px;
}
.collection_list_sec .btn_group.mob_style .btn_area button i {
    vertical-align: middle;
}
.collection_list_sec .btn_group.mob_style .btn_area button.play i {
    vertical-align: -10px;
}
.collection_list_sec .btn_group.mob_style .btn_area button + button:before {
    display: inline-block;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0.1rem;
    height: 2rem;
    margin-top: -1rem;
    background: var(--line-color);
    vertical-align: middle;
}
.collection_list_sec .btn_group .input_area.mob_none {
    display: none;
}
.collection_list_sec .collection_list_sec_body.is_mode {
    border: 0 none;
    margin-top: 5.1rem;
}
.collection_list_sec .view_btn_area .view_btn i {
    vertical-align: -5px;
}
.swiper_container.list .edit_item .item_body > .btn {
    margin-right: -4px;
}
.swiper_container.list .edit_item .item_body > .btn .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
}
.swiper_container.list .edit_item .item_body > .btn .ic g {
    stroke-width: 1.4;
}
}
@media screen and (max-width: 600px) {
.swiper-wrapper {
    width: 500px !important;
}
.my_collection_page .show_item_info_wrap {
    padding-top: 0;
}
.swiper_container.list .list_body {
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
}
.show_item_info_wrap .ttl {
    font-size: 2.4rem;
}
.show_item_info_wrap .desc {
    margin-top: 0.2rem;
    font-size: 1.6rem;
}
.show_item_info_wrap .editions {
    margin-top: 0.5rem;
}
.show_item_info_wrap .btn_area {
    width: 16rem;
}
.show_item_info_wrap .btn_area .btn {
    min-width: 16rem;
    font-size: 1.4rem;
}
.show_item_info_wrap .mode {
    display: flex;
    justify-content: center;
    align-items: center;
}
.collection_list_sec .ttl_group {
    position: relative;
    flex-direction: column;
    align-items: start;
    border-bottom: 0 none;
}
.collection_list_sec .ttl_group:before {
    display: block;
    content: '';
    position: absolute;
    left: -2rem;
    right: -2rem;
    bottom: -1px;
    height: 1px;
    z-index: 1;
    background: var(--line-color);
}
.collection_list_sec .ttl_group .ttl {
    margin-bottom: 0.7rem;
    font-size: 2.4rem;
    font-weight: 500;
}
.collection_list_sec .ttl_group p {
    margin-bottom: 2.3rem;
    font-size: 1.4rem;
    font-weight: 200;
}
.collection_list_sec .btn.mode {
    min-width: 14rem;
    height: 4rem;
    font-size: 1.4rem;
    font-weight: 300;
}
.collection_list_sec_body .edit_item.type_card .item_body {
    padding: 1.2rem 1rem;
}
.collection_list_sec .collection_list_sec_body {
    margin-top: 2.4rem;
}
.collection_list_sec .edit_list {
    margin-bottom: -2rem;
}
.collection_list_sec .edit_list .edit_node {
    margin-bottom: 2rem;
}
.collection_list_sec .collection_list_sec_body > .ttl_area .ttl {
    font-size: 1.2rem;
}
.collection_list_sec .is_art .profile {
    width: 2rem !important;
    height: 2rem !important;
}
.collection_list_sec .is_art .ic_ring {
    width: 2.4rem !important;
    height: 2.4rem !important;
}
.collection_list_sec .profile_info .name {
    font-size: 1.2rem;
}
.collection_list_sec .edit_item .ttl_area .ttl {
    font-size: 1.4rem;
}
.collection_list_sec .edit_item .ttl_area p {
    font-size: 1.2rem;
}
.collection_list_sec .btn_group.mob_style {
    flex: 1;
    /*margin-top: 2.3rem;*/
    top:16.44rem;
}
.collection_list_sec .btn_group .btn_area {
    width: calc(50% - 1rem);
    margin-right: 1rem;
}
.collection_list_sec .btn_group .input_area.check .input_box label::after {
    top: 0.3rem;
    right: -1.3rem;
    height: 1.3rem;
}
.collection_list_sec .btn_group .input_area.check .input_box .txt_selected {
    margin-left: 2.4rem;
    font-size: 1.4rem;
}
.collection_list_sec .btn_group .drop_ui.select .drop_btn {
    min-width: auto;
    width: 100%;
}
.collection_list_sec .collection_list_sec_body .view_btn_area {
    margin-top: 2rem;
    padding-top: 1.2rem;
}
.collection_list_sec .view_btn_area .view_btn.is_active i {
    vertical-align: top;
}
.collection_list_sec .collection_list_sec_body .view_btn_area button {
    font-size: 1.4rem;
}
.collection_list_sec .collection_list_sec_body + .collection_list_sec_body {
    margin-top: 3.5rem;
}
.collection_wrap .input_area.check {
    padding: 1.1rem;
}
.collection_wrap .input_area.check input + span:before {
    width: 2.4rem;
    height: 2.4rem;
}
.collection_wrap .input_area.check input + span:after {
    width: 2.4rem;
    height: 2.4rem;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.collection_list_sec .collection_list_sec_head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.collection_list_sec .ttl_group {
    width: 100%;
}
.collection_list_sec_head .input_area.check span {
    font-size: 1.4rem;
}
.collection_list_sec .collection_list_sec_head .input_area.check {
    display: inline-block;
    vertical-align: middle;
    margin-top: 2.5rem;
    margin-left: 5rem;
}
.collection_list_sec .guide_ui p {
    margin-top: 2.5rem;
    font-size: 1.4rem;
}
.collection_list_sec .guide_ui .btn {
    min-width: 21rem;
    margin-top: 5rem;
}
}
@media screen and (max-width: 360px) {
.collection_list_sec .collection_list_sec_head .input_area.check {
    display: block;
    margin-top: 2rem;
    margin-left: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
