var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCloseM",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", { attrs: { "data-name": "Line 3", d: "m5.5 5.5 20 20" } }),
      _c("path", { attrs: { "data-name": "Line 4", d: "m25.5 5.5-20 20" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }