var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "family_page", attrs: { id: "contents" } }, [
    _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Family Members")]),
    _c("div", { staticClass: "set_inner" }, [
      _c("div", { staticClass: "guide_ui" }, [
        _c("div", { staticClass: "img_area" }, [
          _c("picture", [
            _c("source", {
              attrs: {
                media: "(max-width: 600px)",
                type: "image/png",
                srcset: `${require("@/assets/img/img_mob_illust_family_l.png")} 4x, ${require("@/assets/img/img_mob_illust_family_m.png")} 3x, ${require("@/assets/img/img_mob_illust_family.png")}`,
              },
            }),
            _c("img", {
              attrs: {
                src: require("@/assets/img/img_illust_family.png"),
                alt: "img_illust_family",
              },
            }),
          ]),
        ]),
        _c("h2", { staticClass: "ttl" }, [_vm._v("Family Members!")]),
        _vm.isApproved === true
          ? _c("p", { staticClass: "desc" }, [
              _vm._v(" You approved Family Member request."),
              _c("br"),
              _vm._v(" Requester can now see the your collection. "),
            ])
          : _vm._e(),
        _vm.isApproved === false
          ? _c("p", { staticClass: "desc" }, [
              _vm._v(" You denied Family Member request. "),
            ])
          : _vm._e(),
        _vm.isApproved === null
          ? _c("p", [_vm._v("please wait...")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "btn_area" },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("span", { staticClass: "btn w_xl h_l strong" }, [
                _vm._v("Go Home"),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }