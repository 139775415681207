var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.openService
    ? _c(
        "div",
        {
          staticClass: "time_group clearFix",
          class: {
            is_Not_Solded: _vm.soldedCount == 0 && _vm.dropsDivision !== "live",
          },
        },
        [
          _vm.dropsDivision == "ended"
            ? _c("span", { staticStyle: { "font-size": "2rem" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("UsaFormat")(
                        _vm._f("GmtToTz")(_vm.endedDateTime),
                        "onlyDate"
                      )
                    )
                ),
              ])
            : [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass: "time_area",
                      attrs: { "aria-live": "days " + _vm.days },
                    },
                    [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.days)),
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("days")]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "time_area",
                      attrs: { "aria-live": "hours " + _vm.hours },
                    },
                    [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.hours)),
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("hours")]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "time_area",
                      attrs: { "aria-live": "minutes " + _vm.minutes },
                    },
                    [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.minutes)),
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("minutes")]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "time_area",
                      attrs: { "aria-live": "seconds " + _vm.seconds },
                    },
                    [
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(_vm.seconds)),
                      ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("seconds")]),
                    ]
                  ),
                ]),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }