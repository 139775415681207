// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
	파일정의 : Drops page 스타일
	작성날짜 : 2022-03-31 장병주
	참고사항 : Drops page 스타일 정의
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/
.drops_page .live_sec {
  /*padding-top: 60px;*/
}
.drops_page .live_sec .sec_body {
  display: flex;
}
.drops_page .live_sec .sec_body .img_wrap {
  flex-shrink: 0;
}
.drops_page .live_sec .sec_body .info_wrap {
  position: relative;
  flex: 1;
  padding-left: 40px;
  font-size: 1.6rem;
}
.drops_page .live_sec .info_wrap .time_group {
  margin-bottom: 27px;
}
.drops_page .live_sec .info_wrap .ttl_area .ttl {
  display: -webkit-box;
  flex: 1;
  overflow: hidden;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.drops_page .live_sec .ttl_area p {
  display: -webkit-box;
  overflow: hidden;
  color: #fff;
  line-height: 1.5;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.drops_page .live_sec .info_wrap .ttl_area.artist_info {
  font-size: 2rem;
}
.drops_page .live_sec .ttl_area.artist_info .ttl {
  font-size: 4.6rem;
}
.drops_page .live_sec .ttl_area.drops_info {
  position: relative;
  margin-top: 62px;
}
.drops_page .live_sec .ttl_area.drops_info:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: -30px;
  width: 40px;
  height: 2px;
  background: #fff;
}
.drops_page .live_sec .ttl_area.drops_info .ttl {
  font-size: 2rem;
}
.drops_page .live_sec .btn_area {
  position: absolute;
  left: 40px;
  bottom: 0;
}
.flow_list {
  position: relative;
}
.flow_list:before {
  display: block;
  content: '';
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 2px;
  background: #fff;
  opacity: 0.2;
}
.flow_list .flow_node {
  position: relative;
  text-align: right;
}
.flow_list .flow_node:not(:last-child) {
  margin-bottom: 30px;
}
.flow_list .flow_item {
  display: inline-flex;
  max-width: 1130px;
  width: 100%;
}
.flow_list .flow_item .date_info {
  position: absolute;
  left: 0;
  padding-left: 26px;
  font-size: 2rem;
  font-weight: 300;
}
.flow_list .flow_item .date_info:before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  border-radius: 50%;
  background: #fff;
}
.flow_list .flow_item .item_head {
  order: 1;
  flex-shrink: 0;
}
.flow_list .flow_item .item_body {
  position: relative;
  flex: 1;
  padding: 24px 30px;
  text-align: left;
  font-size: 1.8rem;
  background: var(--box-bg);
}
.flow_list .flow_item .item_body .ttl b,
.flow_list .flow_item .item_body .desc b {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.flow_list .flow_item .item_body .ttl b {
  -webkit-line-clamp: 1;
}
.flow_list .flow_item .item_body .desc b {
  -webkit-line-clamp: 2;
}
.flow_list .flow_item .item_body .ttl {
  margin-bottom: 13px;
  font-size: 2.8rem;
}
.flow_list .flow_item .item_body .desc {
  font-size: 2rem;
  color: #fff;
}
.flow_list .flow_item .item_body .btn_area {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.flow_list .flow_item .item_body .btn .ic {
  margin-right: 6px;
  vertical-align: -8px;
}
.drops_page .edition_slide_wrap .edit_node {
  margin-bottom: 0;
}
.drops_page .edition_slide_wrap.type_item .slide_nav_area {
  margin-bottom: 30px;
}

/* 큐레이션 페이지 */
.drops_page.curation_page .rep_sec {
  margin-bottom: 114px;
}
.drops_page.curation_page .rep_sec .img_wrap {
  margin-bottom: 40px;
}
.drops_page.curation_page .rep_sec .img_wrap .thumb_area {
  position: relative;
}
.drops_page.curation_page .rep_sec .img_wrap .thumb_area img {
  max-width: 100%;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .profile {
  width: 4.2rem !important;
  height: 4.2rem !important;
  margin-left: 0.4rem !important;
  margin-right: 2.4rem !important;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .ic_ring {
  top: 50%;
  margin-top: -2.5rem;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .name {
  font-size: 4.6rem;
  font-weight: 500;
}
.drops_page.curation_page .rep_sec .desc_info_group {
  position: relative;
  margin-top: 30px;
  font-size: 2rem;
}
.drops_page.curation_page .rep_sec .desc_info_group > div {
  float: left;
}
.drops_page.curation_page .rep_sec .desc_info_group .ttl {
  font-weight: 300;
}
.drops_page.curation_page .rep_sec .desc_info_group .desc {
  color: #cccccc;
}
.drops_page.curation_page .rep_sec .desc_info_group .drops_info:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: var(--line-color);
}
.drops_page.curation_page .rep_sec .desc_info_group .drops_info .desc {
  margin-top: 5px;
  font-size: 16px;
}
.drops_page.curation_page .live_sec .sec_body {
  display: block;
}
.live_node:not(:last-child) {
  margin-bottom: 30px;
}
.live_item {
  position: relative;
}
.live_item > .link {
  display: none;
}
.live_item > div {
  float: right;
}
.live_item .thumb_area {
  z-index: 2;
}
.live_item .item_body {
  width: 100%;
  margin-right: -598px;
  padding: 25px 678px 25px 30px;
  box-sizing: border-box;
}
.live_item .item_body:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid var(--line-color);
}
.live_item .ttl_area {
  position: relative;
  z-index: 3;
  font-size: 1.6rem;
}
.drops_page.curation_page .live_sec .live_item .ttl_area .ttl {
  overflow: hidden;
  font-size: 2.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.drops_page.curation_page .live_sec .live_item .ttl_area .edit {
  margin: 14px 0;
  color: var(--txt-gray-2);
}
.drops_page.curation_page .live_sec .live_item .ttl_area .desc {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.drops_page.curation_page .live_sec .live_item .time_group {
  position: absolute;
  left: 215px;
  bottom: 31px;
}
.drops_page.curation_page .live_sec .live_item .time_group .chip {
  margin: 5px 0;
}
.drops_page.curation_page .live_sec .btn_area {
  left: 30px;
  bottom: 30px;
}
@media screen and (max-width: 1024px) {
.drops_page .live_sec .sec_body {
    display: block;
}
.drops_page .live_sec .sec_body .info_wrap {
    padding-top: 30px;
    padding-left: 0;
    margin-left: -15px;
    margin-right: -15px;
}
.drops_page .live_sec .sec_body .info_wrap:after {
    display: block;
    content: '';
    clear: both;
}
.drops_page .live_sec .info_wrap > div {
    float: left;
    padding: 0 15px;
}
.drops_page .live_sec .info_wrap .time_group {
    width: 100%;
    margin-left: -2px;
}
.drops_page .live_sec .info_wrap .ttl_area {
    width: 50%;
}
.drops_page .live_sec .ttl_area.drops_info {
    padding-left: 30px;
}
.drops_page .live_sec .ttl_area.drops_info:before {
    left: 10px;
    top: 4px;
    bottom: 3px;
    width: 1px;
    height: auto;
    opacity: 0.2;
}
.drops_page .live_sec .btn_area {
    position: static;
    margin-top: 30px;
}
.flow_list .flow_item {
    display: block;
    padding-left: 250px;
}
.flow_list .flow_item .item_body .btn_area {
    position: static;
    margin-top: 40px;
}
.live_item > div {
    float: none;
}
.live_item .item_body {
    padding-right: 3rem;
    margin-right: 0;
}
.drops_page.curation_page .live_sec .live_item .time_group .chip {
    margin: 0;
}
.drops_page.curation_page .live_sec .live_item .time_group {
    bottom: 2.8rem;
}
}
@media screen and (max-width: 840px) {
.flow_list .flow_item {
    padding-left: 200px;
}
.flow_list .flow_item .date_info {
    font-size: 1.8rem;
}
.drops_page.curation_page .rep_sec .desc_info_group > div {
    width: 100%;
}
.drops_page.curation_page .rep_sec .desc_info_group .drops_info {
    position: relative;
    margin-top: 2.5rem;
    padding-top: 2.4rem;
}
.drops_page.curation_page .rep_sec .desc_info_group .drops_info:before {
    top: 0;
    width: auto;
    left: 3rem;
    right: 3rem;
    height: 1px;
}
}
@media screen and (max-width: 767px) {
.drops_page.curation_page .live_item .item_body {
    display: flex;
    flex-flow: column;
    padding: 2.3rem 2rem 3.5rem;
}
.drops_page.curation_page .live_sec .live_item .time_group {
    position: static;
    margin-bottom: 2rem;
}
.live_node:not(:last-child) {
    margin-bottom: 4rem;
}
.live_item > .link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
}
.drops_page.curation_page .live_item .ttl_area {
    order: 1;
}
.drops_page.curation_page .live_sec {
    margin-bottom: 8rem;
}
.drops_page.curation_page .live_sec .btn_area {
    display: none;
}
.drops_page.curation_page .rep_sec {
    margin-bottom: 7.8rem;
}
}
@media screen and (max-width: 600px) {
.drops_page .live_sec .sec_body .info_wrap {
    margin: 0;
}
.drops_page .live_sec .info_wrap .time_group {
    margin-left: -0.5rem;
    margin-bottom: 1.5rem;
}
.drops_page .live_sec .info_wrap .ttl_area {
    width: 100%;
    font-size: 1.4rem;
}
.drops_page .live_sec .ttl_area.artist_info .ttl {
    margin-bottom: 1.2rem;
    font-size: 2.4rem;
}
.drops_page .live_sec .ttl_area .ttl b {
    font-weight: 500;
}
.drops_page .live_sec .ttl_area p {
    line-height: 1.428;
    -webkit-line-clamp: 2;
}
.drops_page .live_sec .info_wrap .ttl_area.artist_info {
    font-size: 1.4rem;
}
.drops_page .live_sec .ttl_area.drops_info {
    padding-left: 1.9rem;
    margin-top: 2.9rem;
}
.drops_page .live_sec .ttl_area.drops_info .ttl {
    margin-bottom: 1.8rem;
    font-size: 1.8rem;
}
.drops_page .live_sec .ttl_area.drops_info:before {
    left: 0;
    width: 2px;
    opacity: 1;
}
.drops_page .live_sec .info_wrap > div {
    float: none;
    padding: 0;
}
.drops_page .live_sec .btn_area {
    margin-top: 4.5rem;
    text-align: center;
}
.flow_list:before {
    top: 0.5rem;
}
.flow_list .flow_item {
    padding-left: 2rem;
    padding-top: 3.9rem;
}
.flow_list .flow_item .date_info {
    top: 0;
    padding-left: 2rem;
    font-size: 1.4rem;
}
.flow_list .flow_item .date_info:before {
    left: 0.1rem;
    width: 0.6rem;
    height: 0.6rem;
}
.flow_list .flow_item .item_body {
    padding: 2rem;
}
.flow_list .flow_item .item_body .ttl {
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
}
.flow_list .flow_item .item_body .desc {
    font-size: 1.6rem;
}
.flow_list .flow_item .item_body .btn_area {
    margin-top: 2rem;
}
.flow_list .flow_item .item_body .btn .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
    margin-right: 0.4rem;
    vertical-align: -0.6rem;
}
.flow_list .flow_item .item_body .btn .ic g {
    stroke-width: 2.2;
}
.live_item .ttl_area,
  .drops_page.curation_page .rep_sec .desc_info_group,
  .drops_page.curation_page .rep_sec .desc_info_group .drops_info .desc {
    font-size: 1.4rem;
}
.drops_page.curation_page .rep_sec .img_wrap {
    margin-bottom: 2.4rem;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .profile {
    width: 3rem !important;
    height: 3rem !important;
    margin-left: 0.3rem !important;
    margin-right: 1.3rem !important;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .ic_ring {
    width: 3.6rem !important;
    height: 3.6rem !important;
    margin-top: -1.8rem !important;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .ic_ring circle:last-of-type {
    r: 14 !important;
}
.drops_page.curation_page .rep_sec .ttl_area.profile_info .name {
    font-size: 2.4rem;
}
.drops_page.curation_page .live_sec .live_item .time_group .chip {
    padding: 0.6rem 1.5rem;
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
}
.drops_page.curation_page .rep_sec .desc_info_group {
    margin-top: 1rem;
}
.drops_page.curation_page .rep_sec .desc_info_group .drops_info .ttl,
  .drops_page.curation_page .live_sec .live_item .ttl_area .ttl {
    font-size: 1.8rem;
}
.drops_page.curation_page .rep_sec .desc_info_group .drops_info .desc {
    margin-top: 0.8rem;
}
.drops_page.curation_page .live_sec .live_item .ttl_area .edit {
    margin: 0.7rem 0;
}
}
@media screen and (max-width: 320px) {
.drops_page.curation_page .live_sec .live_item .time_group {
    margin-left: -0.7rem;
    margin-right: -0.7rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
