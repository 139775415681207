var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "set_inner" }, [
    _c(
      "div",
      { staticClass: "search_wrap search_form" },
      [
        _c("div", { staticClass: "input_area search" }, [
          _c(
            "div",
            { staticClass: "input_box" },
            [
              _c("IcMobSearch", { staticClass: "ic_search" }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.keyword,
                    expression: "keyword",
                  },
                ],
                ref: "keywordRef",
                attrs: {
                  type: "search",
                  placeholder: "Search for your favorite art or artist…",
                  label: "Search",
                  title: "Search",
                },
                domProps: { value: _vm.keyword },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onSearch.apply(null, arguments)
                  },
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.keyword = $event.target.value
                    },
                    function ($event) {
                      return _vm.search($event)
                    },
                  ],
                },
              }),
              _vm.keyword.length > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn type_ic remove_word",
                      attrs: { type: "button" },
                      on: { click: _vm.reset },
                    },
                    [
                      _c("IcCloseS", {
                        attrs: {
                          iconLabel: "Clear search field",
                          "stroke-color": "#fff",
                          width: 20,
                          height: 20,
                          "stroke-width": 1,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.keyword.length > 0
            ? _c(
                "div",
                { staticClass: "auto_word_ui auto_word_frame has_scroll_y" },
                [
                  _c(
                    "ul",
                    { staticClass: "word_list" },
                    _vm._l(_vm.keywords, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "word_node",
                          on: {
                            click: function ($event) {
                              return _vm.onSearch(item)
                            },
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "word_item",
                              attrs: { type: "button" },
                            },
                            [
                              _c("span", { staticClass: "c_point" }, [
                                _vm._v(_vm._s(_vm.keyword)),
                              ]),
                              _vm._v(
                                _vm._s(item.substr(_vm.keyword.length)) + " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm.type === "web"
      ? _c(
          "button",
          { staticClass: "btn type_ic close_ui", on: { click: _vm.close } },
          [_c("IcCloseM", { attrs: { iconLabel: "Close search" } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }