var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icWrite",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        attrs: { "data-name": "Path 3650", d: "M22.5 17.474V23.5h-16v-16H15" },
      }),
      _c("path", {
        attrs: {
          "data-name": "Path 3649",
          d: "m23.243 5.151-8.486 8.486-1.414 4.242 4.243-1.414L26.07 7.98Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }