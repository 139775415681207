var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal_wrap",
      class: { is_show: _vm.show },
      attrs: { id: "mail_modal" },
    },
    [
      _c("div", { staticClass: "modal helpCenter" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal_body" }, [
          _c("div", { staticClass: "introduce_wrap" }, [
            _c("div", { staticClass: "input_area type_hoz text" }, [
              _c(
                "div",
                {
                  staticClass: "input_box",
                  class: _vm.reg.test(this.email) ? "" : "is_error",
                },
                [
                  _c("label", { attrs: { for: "txt-email" } }, [
                    _vm._v("* Your email address"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    attrs: {
                      id: "txt-email",
                      type: "text",
                      "aria-describedby": "text-email-error",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                        function ($event) {
                          return _vm.isValidText()
                        },
                      ],
                    },
                  }),
                ]
              ),
              _vm.errorMsg
                ? _c("p", { staticClass: "validation" }, [
                    _vm.isError
                      ? _c("span", { attrs: { id: "text-email-error" } }, [
                          _vm._v("This email is not valid. ex)abcd@kkk.com"),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "input_area type_hoz text drop_ui" }, [
              _c("label", { attrs: { for: "selectInquiry" } }, [
                _vm._v("* Choose your type of Inquiry "),
              ]),
              _c(
                "div",
                { staticClass: "input_box drop_box" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "drop_btn has_arw",
                      class: { is_active: _vm.showSelect },
                      attrs: {
                        role: "combobox",
                        "aria-haspopup": "listbox",
                        "aria-controls": "dropOpts",
                        "aria-labelledby": "selectInquiry",
                        "aria-expanded": _vm.showSelect ? "true" : "false",
                        "aria-activedescendant": "sort_op_" + _vm.focusOpt,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openDrop("dropOpts", $event)
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.selectedOpt.label) + " "),
                      _c("ArwSelect", { attrs: { "aria-hidden": "true" } }),
                    ],
                    1
                  ),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSelect,
                            expression: "showSelect",
                          },
                        ],
                        ref: "dropOpts",
                        staticClass: "drop_op_list",
                        attrs: { id: "dropOpts", role: "listbox" },
                      },
                      _vm._l(_vm.sortOptData, function (opt, i) {
                        return _c(
                          "li",
                          {
                            key: "sort_op_" + i,
                            class: [
                              { is_focus: _vm.focusOpt === i },
                              {
                                is_active: _vm.selectedOpt.value === opt.value,
                              },
                            ],
                            attrs: {
                              tabindex: "0",
                              id: "sort_op_" + i,
                              role: "option",
                              "aria-selected":
                                _vm.selectedOpt.value === opt.value,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selOtp(opt, i)
                              },
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.selOtp(opt, i)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(opt.label))])]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "input_area type_hoz text" }, [
              _c("label", { attrs: { for: "subject" } }, [_vm._v("* Subject")]),
              _c("div", { staticClass: "input_box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subject,
                      expression: "subject",
                    },
                  ],
                  attrs: { type: "text", label: "Subject", id: "subject" },
                  domProps: { value: _vm.subject },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.subject = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "input_area type_hoz textarea" }, [
              _c("label", { attrs: { for: "description" } }, [
                _vm._v("* Description"),
              ]),
              _c("div", { staticClass: "input_box" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  attrs: {
                    id: "description",
                    "aria-labelledby": "description",
                    title: "Description",
                  },
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "input_area type_hoz text" }, [
              _c("label", { attrs: { for: "attachments" } }, [
                _vm._v("Attachments(Optional)"),
              ]),
              _c("div", { staticClass: "input_box file-box" }, [
                _c("input", {
                  ref: "attachment",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    id: "attachment",
                    label: "Attachments(Optional)",
                  },
                  on: { change: _vm.uploadAttachment },
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.attachment.fileName,
                      expression: "attachment.fileName",
                    },
                  ],
                  staticClass: "file-input",
                  attrs: {
                    type: "text",
                    label: "Attachments(Optional)",
                    id: "attachments",
                    readonly: "",
                  },
                  domProps: { value: _vm.attachment.fileName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.attachment, "fileName", $event.target.value)
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn basic w_xs file-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.attachment.click()
                      },
                    },
                  },
                  [_vm._v(" Browse ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal_foot" }, [
          _c("div", { staticClass: "btn_area" }, [
            _c(
              "button",
              { staticClass: "btn basic w_xs h_m", on: { click: _vm.close } },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "btn strong w_xs h_m",
                on: { click: _vm.sendEmail },
              },
              [_vm._v("Send")]
            ),
          ]),
        ]),
        _c(
          "button",
          { staticClass: "btn type_ic close_modal", on: { click: _vm.close } },
          [_c("IcCloseM")],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl" }, [_vm._v("Submit a request")]),
      _c("span", [
        _vm._v(
          " Please enter the details of your request. A member of our support staff will respond as soon as possible. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }