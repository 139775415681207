var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAlarm
    ? _c(
        "div",
        {
          staticClass: "modal_wrap type_full",
          class: { is_show: _vm.showAlarm },
        },
        [
          _c(
            "div",
            {
              ref: "notiModal",
              staticClass: "modal",
              attrs: {
                id: "notiModal",
                role: "dialog",
                "aria-labelledby": "noti_title",
                "aria-describedby": "noti_desc",
              },
            },
            [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "modal_body", attrs: { id: "noti_desc" } },
                [
                  _vm.itemData && _vm.itemData.length > 0
                    ? _c(
                        "ul",
                        { staticClass: "alarm_list" },
                        _vm._l(_vm.itemData, function (item, i) {
                          return _c(
                            "li",
                            { key: "main_menu" + i, staticClass: "alarm_node" },
                            [
                              _c("div", { staticClass: "alarm_item" }, [
                                _c("p", { staticClass: "cate" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("h4", { staticClass: "msg" }, [
                                  _c("b", [_vm._v(_vm._s(item.content) + ".")]),
                                ]),
                                _c("span", { staticClass: "date" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("UsaFormat")(
                                        _vm._f("GmtToTz")(item.regDatetime)
                                      )
                                    ) + " "
                                  ),
                                ]),
                              ]),
                              _vm.itemData.length === 0 && _vm.isLoading
                                ? _c("ul", { staticClass: "alarm_list" }, [
                                    _c("div", { staticClass: "loader" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.itemData.length === 0 && !_vm.isLoading
                                ? _c("ul", { staticClass: "alarm_list" }, [
                                    _c(
                                      "div",
                                      { staticStyle: { padding: "10px 0px" } },
                                      [_vm._v("no notifications")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn type_ic close_modal",
                  on: { click: _vm.close },
                },
                [_c("IcCloseS")],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl", attrs: { id: "noti_title" } }, [
        _vm._v("Notifications"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }