var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_list_page my_artist_page", attrs: { id: "contents" } },
    [
      _c(
        "div",
        { staticClass: "set_inner clearFix" },
        [
          _c("h1", { staticClass: "page_ttl" }, [_vm._v("My Account")]),
          _c("AccountMenu"),
          _c("div", { staticClass: "min_inner" }, [
            _c("section", { staticClass: "artist_sec" }, [
              _c("div", { staticClass: "artist_sec_head" }, [
                _vm.profileCoverUrls
                  ? _c("div", {
                      staticClass: "visual_wrap",
                      style: `background-image: url('${_vm.profileCoverUrls}')`,
                    })
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            height: "200px",
                            "justify-content": "center",
                          },
                          attrs: {
                            src: require("@/assets/img/img_illust_nodata.png"),
                            alt: "nodata",
                          },
                        }),
                      ]
                    ),
                _c("div", { staticClass: "profile_group" }, [
                  _c(
                    "div",
                    { staticClass: "profile_info is_art" },
                    [
                      _vm.profileInfos && _vm.profileInfos.length
                        ? _c("span", {
                            staticClass: "profile",
                            style: `background-image: url('${_vm.profileInfos[0].resUrl}')`,
                          })
                        : _c("IcProfile", {
                            staticClass: "profile",
                            attrs: { width: 110, height: 110 },
                          }),
                      _c("IcRing", {
                        staticClass: "ic_ring",
                        attrs: { width: 110, height: 110, "small-ring": 14.8 },
                      }),
                      _c("h2", { staticClass: "ttl" }, [
                        _vm._v(_vm._s(_vm.artistInfo.name)),
                      ]),
                      _c(
                        "button",
                        {
                          ref: "edit_btn",
                          staticClass: "edit_btn",
                          on: {
                            click: function ($event) {
                              _vm.showProfile = true
                            },
                          },
                        },
                        [_c("IcWrite")],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.zipCodeFullStr
                    ? _c("div", { staticClass: "ttl_area zpcd" }, [
                        _c("span", { staticClass: "zpcd_info" }, [
                          _vm._v(_vm._s(_vm.zipCodeFullStr)),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "follow_group" }, [
                  _c("ul", { staticClass: "follow_area" }, [
                    _c("li", { staticClass: "following" }, [
                      _c(
                        "button",
                        {
                          ref: "following",
                          on: {
                            click: function ($event) {
                              return _vm.openModal("FollowModal", false)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("numberFormatter")(_vm.followingCount, 2)
                              ) +
                              " "
                          ),
                          _c("span", [_vm._v("Following")]),
                        ]
                      ),
                    ]),
                    _c("li", { staticClass: "followers" }, [
                      _c(
                        "button",
                        {
                          ref: "followers",
                          on: {
                            click: function ($event) {
                              return _vm.openModal("FollowModal", true)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("numberFormatter")(_vm.followerCount, 2)
                              ) +
                              " "
                          ),
                          _c("span", [_vm._v("Followers")]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm.familyInfos && _vm.familyInfos.length > 0
                  ? _c("div", { staticClass: "family_account_group" }, [
                      _c("div", { staticClass: "account_length_area" }, [
                        _c("p", [
                          _vm._v(" Family Account "),
                          _c("em", [_vm._v(_vm._s(_vm.familyInfos.length))]),
                        ]),
                        _c("p"),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "profile_info" },
                        _vm._l(_vm.familyInfos, function (item) {
                          return _c("li", { key: item.id }, [
                            item.avatarRsrcUrl
                              ? _c("span", {
                                  staticClass: "profile",
                                  style: `background-image: url('${item.avatarRsrcUrl}')`,
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToProfile(item)
                                    },
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToProfile(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("IcProfile", {
                                      staticClass: "profile",
                                      attrs: { width: "40", height: "40" },
                                    }),
                                  ],
                                  1
                                ),
                            _c("div", { staticClass: "profile_layer" }, [
                              _c("div", { staticClass: "profile_info_group" }, [
                                _c(
                                  "div",
                                  { staticClass: "profile_info" },
                                  [
                                    item.avatarRsrcUrl
                                      ? _c("span", {
                                          staticClass: "profile",
                                          style: `background-image: url('${item.avatarRsrcUrl}')`,
                                        })
                                      : _c("IcProfile", {
                                          staticClass: "profile",
                                        }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "ttl_area" }, [
                                  _c("h2", { staticClass: "ttl" }, [
                                    _vm._v(_vm._s(item.userName)),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "follow_group" }, [
                                _c("div", { staticClass: "follow_area" }, [
                                  _c("div", { staticClass: "followers" }, [
                                    _c("dl", [
                                      _c("dt", { attrs: { role: "status" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("numberFormatter")(
                                                item.followers,
                                                2
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("dd", [_vm._v("Followers")]),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "following" }, [
                                    _c("dl", [
                                      _c("dt", { attrs: { role: "status" } }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("numberFormatter")(
                                                item.following,
                                                2
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("dd", [_vm._v("Following")]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "btn_area" }, [
                                _c(
                                  "button",
                                  {
                                    ref: "follow",
                                    refInFor: true,
                                    staticClass: "btn basic w_f h_l",
                                    class: { is_active: item.followYn === "Y" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.follow(item)
                                      },
                                    },
                                  },
                                  [
                                    item.followYn === "N"
                                      ? _c("IcMember", {
                                          attrs: { "stroke-color": "#000000" },
                                        })
                                      : _vm._e(),
                                    item.followYn === "Y"
                                      ? _c("IcCheck")
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.followYn === "Y"
                                            ? "Following"
                                            : "Follow"
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "desc_area",
                    class: { is_active: _vm.expand },
                  },
                  [
                    _vm.profileDetails
                      ? _c("pre", { staticClass: "desc" }, [
                          _vm._v(
                            "              " +
                              _vm._s(
                                _vm.profileDetails.artistDescription || null
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                !_vm.expand
                  ? _c(
                      "button",
                      {
                        staticClass: "expand_btn dv_mob",
                        on: { click: _vm.expandBtn },
                      },
                      [_vm._v(" Expand "), _c("IcExpand")],
                      1
                    )
                  : _vm._e(),
                _vm.profileDetails
                  ? _c("div", { staticClass: "sns_area" }, [
                      _vm.profileDetails.twitterUrl
                        ? _c(
                            "a",
                            {
                              staticClass: "sns",
                              attrs: {
                                href: _vm.profileDetails.twitterUrl,
                                target: "noopener noreferrer",
                              },
                            },
                            [_c("IcTwitter")],
                            1
                          )
                        : _vm._e(),
                      _vm.profileDetails.instagramUrl
                        ? _c(
                            "a",
                            {
                              staticClass: "sns",
                              attrs: {
                                href: _vm.profileDetails.instagramUrl,
                                target: "noopener noreferrer",
                              },
                            },
                            [_c("IcInstagram")],
                            1
                          )
                        : _vm._e(),
                      _vm.profileDetails.webSiteUrl
                        ? _c(
                            "a",
                            {
                              staticClass: "sns",
                              attrs: {
                                href: _vm.profileDetails.webSiteUrl,
                                target: "noopener noreferrer",
                              },
                            },
                            [_c("IcWeb")],
                            1
                          )
                        : _vm._e(),
                      _vm.profileDetails.facebookUrl
                        ? _c(
                            "a",
                            {
                              staticClass: "sns",
                              attrs: {
                                href: _vm.profileDetails.facebookUrl,
                                target: "noopener noreferrer",
                              },
                            },
                            [_c("IcFaceBook")],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "artist_sec_body" }, [
                _c("div", { staticClass: "tab_wrap" }, [
                  _c("div", { staticClass: "tab_list type_stack" }, [
                    _c("div", { staticClass: "tab_node" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tab_item",
                          class: { is_active: _vm.tabSelected === "CREATED" },
                          on: {
                            click: function ($event) {
                              _vm.tabSelected = "CREATED"
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v("Created "),
                            _c("em", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.artistInfo.crtrCount)),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "tab_node" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tab_item",
                          class: { is_active: _vm.tabSelected === "OWNED" },
                          on: {
                            click: function ($event) {
                              _vm.tabSelected = "OWNED"
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v("Owned "),
                            _c("em", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.artistInfo.ownrCount)),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "ul",
                  { staticClass: "grid grid_15 ib edit_list" },
                  _vm._l(_vm.filterTab, function (item, i) {
                    return _c(
                      "li",
                      { key: item + i, staticClass: "col col_4 edit_node" },
                      [
                        _c("ItemCardEdit", {
                          key: _vm.componentListKey + i,
                          attrs: {
                            mbrNo: _vm.mbrNo,
                            userType: _vm.userType,
                            itemData: item,
                            viewType: "profile",
                            hideOwner: true,
                          },
                          on: {
                            open: _vm.open,
                            onAppChangeSelling: _vm.onAppChangeSelling,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm.showProfile
        ? _c("EditProfile", {
            ref: "EditModal",
            attrs: { showProfile: _vm.showProfile },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm.mbrNo
        ? _c("FollowList", {
            key: _vm.componentKey,
            ref: "FollowModal",
            attrs: { mbrNo: _vm.mbrNo },
            on: {
              follow: _vm.handleFollow,
              onSetCloseFocus: _vm.onSetCloseFocus,
            },
          })
        : _vm._e(),
      _vm.showQrModal
        ? _c("QrModal", {
            attrs: {
              showQrModal: _vm.showQrModal,
              "modal-data": _vm.changeSelling,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm.showPriceModal
        ? _c("SetPriceModal", {
            attrs: {
              showPriceModal: _vm.showPriceModal,
              editionId: _vm.editionId,
              artworkId: _vm.artworkId,
              "modal-size": "m",
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }