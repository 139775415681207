// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#Tutorial.modal {
  min-width: 150rem;
  padding-top: 27rem;
}
#Tutorial.modal .modal_head {
  right: 1.7rem;
  width: auto;
  padding: 12rem 1rem 7rem;
}
#Tutorial.modal .modal_head:before {
  display: none;
}
#Tutorial.modal .modal_ttl {
  font-size: 7rem;
  font-weight: 200;
}
#Tutorial.modal .modal_ttl .ic_logo {
  vertical-align: -0.2rem;
}
#Tutorial.modal .modal_body {
  text-align: left;
}
#Tutorial.modal .info_sec {
  padding: 8rem 6rem;
  background: var(--box-bg);
}
#Tutorial.modal .info_sec + .info_sec {
  margin-top: 4rem;
}
#Tutorial.modal .info_sec .sec_ttl {
  margin-bottom: 2rem;
  font-size: 3.6rem;
  font-weight: 200;
}
#Tutorial.modal .info_sec .sec_ttl b {
  font-weight: 500;
}
#Tutorial.modal .info_sec .img_group {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}
#Tutorial.modal .info_sec .img_area {
  margin-top: 5rem;
}
#Tutorial.modal .info_sec p {
  margin-bottom: 2rem;
  color: var(--txt-gray);
  line-height: 1.5;
}
#Tutorial.modal .info_sec .img_group figcaption {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--txt-gray);
  text-transform: uppercase;
}
#Tutorial.modal .info_sec hr {
  display: block;
  height: 0.1rem;
  margin: 7rem 0 6rem;
  border: 0 none;
  background: var(--line-color);
}
#Tutorial.modal .info_sec .info_group {
  display: flex;
}
#Tutorial.modal .info_sec .info_group .img_area {
  flex-shrink: 0;
  margin-top: 0;
  margin-left: 6rem;
}
#Tutorial.modal .info_sec.sign_info .img_group .img_area {
  flex: 1;
  width: calc(50% - 2rem);
  padding: 0 2rem;
}
#Tutorial.modal .info_sec.sign_info .img_group {
  margin-left: -2rem;
  margin-right: -2rem;
}
#Tutorial.modal .info_sec.sign_info .img_area:first-of-type figure {
  margin-right: -6px;
}
#Tutorial.modal .info_sec.wallet_info .sec_ttl {
  margin-bottom: 6rem;
}
#Tutorial.modal .info_sec.wallet_info .img_area {
  text-align: center;
}
#Tutorial .modal_foot {
  padding: 6rem 0 15rem;
}
#Tutorial .modal_foot .btn {
  min-width: 30.3rem;
}
#Tutorial .close_modal,
#Tutorial .close_modal .ic_close {
  width: 5.6rem !important;
  height: 5.6rem !important;
}
#Tutorial .close_modal {
  right: 2.7rem;
}
#Tutorial .close_modal .ic_close g {
  stroke-width: 1;
}
@media screen and (max-width: 1024px) {
#Tutorial.modal {
    min-width: auto;
    padding-top: 25rem;
}
#Tutorial.modal .modal_head {
    right: 0;
}
#Tutorial.modal .modal_ttl {
    font-size: 4.5rem;
}
#Tutorial.modal .modal_ttl .ic_logo {
    width: 24rem !important;
    height: 4rem !important;
}
#Tutorial.modal .info_sec .sec_ttl {
    font-size: 2.6rem;
}
#Tutorial.modal .info_sec p:not(.c_point) br {
    display: none;
}
#Tutorial.modal .info_sec img {
    height: auto;
}
#Tutorial.modal .info_sec .info_group p {
    flex: 1.5;
}
#Tutorial.modal .info_sec .info_group .img_area {
    flex: 1;
}
#Tutorial.modal .info_sec .info_group .img_area figure {
    margin: 0 -4rem;
}
#Tutorial .close_modal {
    right: 1rem;
}
}
@media screen and (max-width: 600px) {
#Tutorial.modal {
    padding-top: 2rem;
}
#Tutorial.modal .modal_head {
    padding: 6rem 1rem 5rem;
}
#Tutorial.modal .modal_ttl {
    font-size: 3.2rem;
}
#Tutorial.modal .modal_ttl .ic_logo {
    display: block;
    width: 18rem !important;
    margin: 1rem auto 0;
}
#Tutorial.modal .info_sec {
    margin-top: 15rem !important;
    padding: 4rem 2rem;
}
#Tutorial.modal .info_sec .img_area {
    margin-top: 4rem;
}
#Tutorial.modal .info_sec .img_group figcaption,
  #Tutorial.modal .info_sec p {
    font-size: 1.4rem;
}
#Tutorial.modal .info_sec.sign_info .img_group,
  #Tutorial.modal .info_sec .info_group {
    flex-flow: column;
}
#Tutorial.modal .info_sec.sign_info .img_group .img_area {
    width: 100%;
}
#Tutorial.modal .info_sec .sec_ttl {
    margin-bottom: 5rem;
    font-size: 2rem;
    text-align: center;
    line-height: 1.5;
}
#Tutorial.modal .info_sec .sec_ttl b {
    display: block;
}
#Tutorial .close_modal,
  #Tutorial .close_modal .ic_close {
    width: 3rem !important;
    height: 3rem !important;
}
#Tutorial .close_modal .ic_close g {
    stroke-width: 1.2;
}
#Tutorial.modal .info_sec .info_group .img_area,
  #Tutorial.modal .info_sec .info_group .img_area figure {
    margin: 0;
}
#Tutorial.modal .info_sec.wallet_info .info_group .img_area {
    margin-top: 3rem;
}
#Tutorial.modal .info_sec hr {
    margin: 4.3rem 0 4rem;
}
#Tutorial .modal_foot {
    padding: 4rem 0 7rem;
}
#Tutorial .modal_foot .btn {
    min-width: 28rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
