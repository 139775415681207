var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal_wrap" }, [
    _c("div", { staticClass: "modal s type_dialog" }, [
      _c("div", { staticClass: "modal_body" }, [_c("IcWarn"), _vm._m(0)], 1),
      _vm._m(1),
      _c(
        "button",
        { staticClass: "btn type_ic close_modal" },
        [_c("IcCloseM")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "txt" }, [
      _vm._v(" You cannot download 5 or"),
      _c("br"),
      _vm._v(" more Artworks at a time. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_foot" }, [
      _c("div", { staticClass: "btn_area" }, [
        _c(
          "button",
          { staticClass: "btn h_m strong", attrs: { type: "button" } },
          [_vm._v("OK")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }