var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popup" }, [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
    _c("button", { on: { click: _vm.closePopup } }, [_vm._v("닫기")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }