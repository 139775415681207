// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ttl_area.zpcd[data-v-345b0610] {
  word-break: break-all;
}
.zpcd_title[data-v-345b0610] {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.zpcd_info[data-v-345b0610] {
  font-weight: 600;
  font-size: 1.4rem;
  /* font-size: 15px;
  letter-spacing: -2px; */
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
