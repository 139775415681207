var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.faqDetails
    ? _c(
        "main",
        {
          staticClass: "footer_page help_detail_page",
          attrs: { id: "contents" },
        },
        [
          _c("div", { staticClass: "set_inner" }, [
            _vm._m(0),
            _c("section", { staticClass: "board_sec" }, [
              _c("div", { staticClass: "board_sec_head" }, [
                _c("span", [_vm._v(_vm._s(_vm.faqDetails.cateName))]),
                _c("p", { staticClass: "ttl" }, [
                  _vm._v(_vm._s(_vm.faqDetails.title)),
                ]),
              ]),
              _c("div", { staticClass: "board_sec_body" }, [
                _c("pre", {
                  ref: "contentFaqRef",
                  staticClass: "detail",
                  domProps: { innerHTML: _vm._s(_vm.faqDetails.content) },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "board_sec_foot",
                  on: {
                    click: function ($event) {
                      return _vm.$router.back()
                    },
                  },
                },
                [
                  _c("button", { staticClass: "btn strong w_s h_l" }, [
                    _vm._v("Back to List"),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("MailModal", {
            ref: "MailModal",
            on: { closeModal: _vm.closeModal },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page_ttl_area" }, [
      _c("h1", { staticClass: "page_ttl" }, [_vm._v("Help Center")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }