// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.result_sec[data-v-78f7c938] {
  padding-top: 6rem;
  min-height: calc(var(--min) * 1px);
  box-sizing: border-box;
}
.result_sec .sec_head .ttl_area[data-v-78f7c938] {
  margin-bottom: 25px;
}
.result_sec .sec_head .ttl[data-v-78f7c938] {
  font-size: 3.2rem;
  font-weight: 200;
}
.result_sec .sec_body[data-v-78f7c938] {
  padding: 50px 0 150px;
}
.result_sec .guide_ui[data-v-78f7c938] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: calc(var(--min) * 1px - 39rem);
}
.result_sec .guide_ui .img_area[data-v-78f7c938] {
  margin-bottom: -1rem;
}
@media screen and (max-width: 600px) {
.result_sec[data-v-78f7c938] {
    padding-top: 4rem;
}
.result_sec .sec_head .ttl[data-v-78f7c938] {
    font-size: 2rem;
}
.result_sec .sec_body[data-v-78f7c938] {
    padding-top: 2rem;
    padding-bottom: 8rem;
}
.grid.grid_15 > .col[data-v-78f7c938] {
    width: 100%;
}
.result_sec .guide_ui[data-v-78f7c938] {
    flex-flow: column !important;
}
.result_sec .guide_ui .img_area[data-v-78f7c938] {
    margin-bottom: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
