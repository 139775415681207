var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icFootInstagram",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
      },
    },
    [
      _c("rect", {
        attrs: {
          id: "사각형_4177",
          "data-name": "사각형 4177",
          width: "30",
          height: "30",
          fill: "none",
        },
      }),
      _c("path", {
        attrs: {
          id: "Instagram",
          d: "M19.846,5.594a1.44,1.44,0,1,1-1.44-1.44A1.44,1.44,0,0,1,19.846,5.594ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM12,5.838A6.162,6.162,0,1,0,18.162,12,6.162,6.162,0,0,0,12,5.838Zm0-3.676c3.2,0,3.584.012,4.849.07a6.638,6.638,0,0,1,2.228.413,3.718,3.718,0,0,1,1.38.9,3.715,3.715,0,0,1,.9,1.38,6.643,6.643,0,0,1,.413,2.228c.058,1.265.07,1.645.07,4.849s-.012,3.584-.07,4.849a6.638,6.638,0,0,1-.413,2.228,3.975,3.975,0,0,1-2.278,2.278,6.643,6.643,0,0,1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.638,6.638,0,0,1-2.228-.413,3.719,3.719,0,0,1-1.38-.9,3.718,3.718,0,0,1-.9-1.38,6.643,6.643,0,0,1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.638,6.638,0,0,1,.413-2.228,3.719,3.719,0,0,1,.9-1.38,3.715,3.715,0,0,1,1.38-.9,6.643,6.643,0,0,1,2.228-.413C8.416,2.174,8.8,2.162,12,2.162ZM12,0C8.741,0,8.332.014,7.052.072A8.808,8.808,0,0,0,4.14.63,5.88,5.88,0,0,0,2.014,2.014,5.88,5.88,0,0,0,.63,4.14,8.809,8.809,0,0,0,.072,7.052C.014,8.332,0,8.741,0,12s.014,3.668.072,4.948A8.809,8.809,0,0,0,.63,19.86a5.88,5.88,0,0,0,1.384,2.126A5.883,5.883,0,0,0,4.14,23.37a8.809,8.809,0,0,0,2.913.558C8.332,23.986,8.741,24,12,24s3.668-.014,4.948-.072a8.809,8.809,0,0,0,2.913-.558,6.136,6.136,0,0,0,3.51-3.51,8.809,8.809,0,0,0,.558-2.913C23.986,15.668,24,15.259,24,12s-.014-3.668-.072-4.948A8.809,8.809,0,0,0,23.37,4.14a5.883,5.883,0,0,0-1.384-2.125A5.883,5.883,0,0,0,19.86.63,8.809,8.809,0,0,0,16.948.072C15.668.014,15.259,0,12,0Z",
          transform: "translate(3 3)",
          fill: "#aaa",
          "fill-rule": "evenodd",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }