var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "purchase_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("My List")]),
      _c("article", { staticClass: "purchase_sec" }, [
        _c("div", { staticClass: "set_inner" }, [
          _c("div", { staticClass: "guide_ui" }, [
            _c("div", { staticClass: "img_area" }, [
              _c("picture", [
                _c("source", {
                  attrs: {
                    media: "(max-width: 600px)",
                    type: "image/png",
                    srcset: `${require("@/assets/img/img_mob_illust_changeaccount_l.png")} 4x, ${require("@/assets/img/img_mob_illust_changeaccount_m.png")} 3x, ${require("@/assets/img/img_mob_illust_changeaccount.png")}`,
                  },
                }),
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/img_illust_changeaccount.png"),
                    alt: "img_illust_changeaccount",
                  },
                }),
              ]),
            ]),
            _vm._m(0),
            _c("p", { staticClass: "desc" }, [
              _vm._v("You have just changed an account successfully."),
            ]),
            _c(
              "div",
              { staticClass: "btn_area" },
              [
                _c(
                  "a",
                  { staticClass: "btn basic w_l h_l", attrs: { href: "/" } },
                  [_vm._v("Go Home")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn strong w_l h_l",
                    attrs: { to: "/collections" },
                  },
                  [_vm._v("Shop Marketplace")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "ttl" }, [
      _vm._v("You’ve Changed "),
      _c("br", { staticClass: "dv_mob" }),
      _vm._v("An Account."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }