var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icCloseS",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": _vm.strokeWidth,
      },
    },
    [
      _c("path", { attrs: { "data-name": "Line 1", d: "m5.5 5.5 13 13" } }),
      _c("path", { attrs: { "data-name": "Line 2", d: "m18.5 5.5-13 13" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }