var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user_item type_card" }, [
    _c("div", { staticClass: "item_body" }, [
      _c(
        "div",
        {
          staticClass: "basic_info_group",
          on: {
            click: function ($event) {
              return _vm.goOtherProfile(_vm.itemData)
            },
          },
        },
        [
          _c("div", { staticClass: "profile_info", attrs: { tabindex: "0" } }, [
            _vm._v(" " + _vm._s(_vm.itemData.avatartInfo) + " "),
            _vm.itemData.avatarInfo && _vm.itemData.avatarInfo.length
              ? _c(
                  "div",
                  {
                    staticClass: "profile l",
                    style: `background-image: url(${_vm.itemData.avatarInfo[0].resUrl})`,
                  },
                  [
                    _c("span", { staticClass: "is_blind" }, [
                      _vm._v("Artist profile picture"),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  [
                    _c("IcProfile", {
                      staticClass: "profile l",
                      attrs: { width: 50, height: 50, "stroke-width": 1 },
                    }),
                  ],
                  1
                ),
          ]),
          _c("div", { staticClass: "ttl_area" }, [
            _c("h3", { staticClass: "ttl name" }, [
              _c("b", [_vm._v(_vm._s(_vm.itemData.userName))]),
            ]),
            _c("p", { staticClass: "own" }, [
              _vm._v(" Owned "),
              _c("b", [_vm._v(_vm._s(_vm.itemData.ownedCount))]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }