var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icRefreshS",
        "icon-label": _vm.iconLabel,
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("g", { attrs: { "stroke-miterlimit": "10" } }, [
        _c("path", { attrs: { d: "m11.5 2.5 4 4-4 4" } }),
        _c("path", {
          attrs: { d: "M15.158 6.501H12a7.5 7.5 0 1 0 7.5 7.515" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }