var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icEye",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 1.2,
      },
    },
    [
      _c("path", {
        attrs: {
          "data-name": "Path 2594",
          d: "M3 15s3.83 9 12 9 12-9 12-9-3.83-9-12-9-12 9-12 9Z",
        },
      }),
      _c("circle", {
        attrs: {
          "data-name": "Ellipse 361",
          cx: "4.5",
          cy: "4.5",
          r: "4.5",
          transform: "translate(10.5 10.5)",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }