var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "my_account_menu" },
    [
      _vm.userType === "1"
        ? _c("div", { staticClass: "my_account_head" }, [
            _c("div", { staticClass: "profile_group" }, [
              _c("div", { staticClass: "profile_info" }, [
                _vm.profileUrl
                  ? _c(
                      "span",
                      {
                        staticClass: "profile",
                        style: `background-image: url('${_vm.profileUrl}')`,
                      },
                      [
                        _c("span", { staticClass: "is_blind" }, [
                          _vm._v("My profile picture"),
                        ]),
                      ]
                    )
                  : _c(
                      "span",
                      { staticClass: "profile none" },
                      [
                        _c("IcProfile", {
                          staticClass: "ic_profile",
                          attrs: { width: "113", height: "113" },
                        }),
                      ],
                      1
                    ),
              ]),
              _c("div", { staticClass: "ttl_area" }, [
                _c("h2", { staticClass: "ttl" }, [
                  _vm._v(_vm._s(_vm.userName)),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "edit_btn",
                    on: {
                      click: function ($event) {
                        return _vm.openModal("UserEditModal")
                      },
                    },
                  },
                  [_c("IcWrite")],
                  1
                ),
              ]),
              _vm.zipCodeFullStr
                ? _c("div", { staticClass: "ttl_area zpcd" }, [
                    _c("span", { staticClass: "zpcd_info" }, [
                      _vm._v(" " + _vm._s(_vm.zipCodeFullStr) + " "),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "follow_group" }, [
              _c("ul", { staticClass: "follow_area" }, [
                _c("li", { staticClass: "followers" }, [
                  _c(
                    "button",
                    {
                      ref: "followers",
                      attrs: { role: "status" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("FollowModal", true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("numberFormatter")(_vm.followers, 2)) +
                          " "
                      ),
                      _c("span", [_vm._v("Followers")]),
                    ]
                  ),
                ]),
                _c("li", { staticClass: "following" }, [
                  _c(
                    "button",
                    {
                      ref: "following",
                      attrs: { role: "status" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("FollowModal", false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("numberFormatter")(_vm.following, 2)) +
                          " "
                      ),
                      _c("span", [_vm._v("Following")]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm.familyInfos && _vm.familyInfos.length > 0
              ? _c("div", { staticClass: "family_account_group" }, [
                  _c("div", { staticClass: "account_length_area" }, [
                    _c("p", [
                      _vm._v(" Family Account "),
                      _c("em", [_vm._v(_vm._s(_vm.familyInfos.length))]),
                    ]),
                    _c("p"),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "profile_info" },
                    _vm._l(_vm.familyInfos, function (item) {
                      return _c(
                        "li",
                        { key: item.id, staticStyle: { cursor: "pointer" } },
                        [
                          item.avatarRsrcUrl
                            ? _c("span", {
                                staticClass: "profile",
                                style: `background-image: url('${item.avatarRsrcUrl}')`,
                                on: {
                                  click: function ($event) {
                                    return _vm.goToProfile(item)
                                  },
                                },
                              })
                            : _c("IcProfile", {
                                staticClass: "profile",
                                attrs: { width: "32", height: "32" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToProfile(item)
                                  },
                                },
                              }),
                          _c("div", { staticClass: "profile_layer" }, [
                            _c("div", { staticClass: "profile_info_group" }, [
                              _c(
                                "div",
                                { staticClass: "profile_info" },
                                [
                                  item.avatarRsrcUrl
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "profile",
                                          style: `background-image: url('${item.avatarRsrcUrl}')`,
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "is_blind" },
                                            [_vm._v("My profile picture")]
                                          ),
                                        ]
                                      )
                                    : _c("IcProfile", {
                                        staticClass: "profile",
                                      }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ttl_area" }, [
                                _c("h2", { staticClass: "ttl" }, [
                                  _vm._v(_vm._s(item.userName)),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "follow_group" }, [
                              _c("div", { staticClass: "follow_area" }, [
                                _c("div", { staticClass: "followers" }, [
                                  _c("dl", [
                                    _c("dt", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("numberFormatter")(
                                              item.followers,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("dd", [_vm._v("Followers")]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "following" }, [
                                  _c("dl", [
                                    _c("dt", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("numberFormatter")(
                                              item.following,
                                              2
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("dd", [_vm._v("Following")]),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "btn_area" }, [
                              _c(
                                "button",
                                {
                                  ref: "follow",
                                  refInFor: true,
                                  staticClass: "btn basic w_f h_l",
                                  class: { is_active: item.followYn === "Y" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.follow(item)
                                    },
                                  },
                                },
                                [
                                  item.followYn === "N"
                                    ? _c("IcMember", {
                                        attrs: { "stroke-color": "#000000" },
                                      })
                                    : _vm._e(),
                                  item.followYn === "Y"
                                    ? _c("IcCheck")
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.followYn === "Y"
                                          ? "Following"
                                          : "Follow"
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "my_account_body",
          class: { is_blind: _vm.window <= 1024 },
        },
        [
          _c("ul", [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: `/mylist/${
                        _vm.userType === "2" ? "myartist" : "user"
                      }`,
                    },
                  },
                  [_vm._v("Profile")]
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/mylist/favorites" } }, [
                  _vm._v("Favorites"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/mylist/wallet" } }, [
                  _vm._v("My Wallet"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/mylist/activities" } }, [
                  _vm._v("Activities"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/mylist/settings" } }, [
                  _vm._v("Settings"),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("UserEditProfile", { ref: "UserEditModal" }),
      _vm.mbrNo
        ? _c("FollowList", {
            key: _vm.componentKey,
            ref: "FollowModal",
            attrs: { mbrNo: _vm.mbrNo },
            on: {
              follow: _vm.handleFollow,
              onSetCloseFocus: _vm.onSetCloseFocus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }