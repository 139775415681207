var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icPlayS",
        "icon-label": _vm.iconLabel,
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("path", {
        attrs: { d: "M7.695 5.07V19l10.447-6.965Z", "data-name": "패스 195" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }