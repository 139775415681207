var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal_wrap",
      class: { is_show: _vm.show },
      on: { click: _vm.dimEvt },
    },
    [
      _c("div", { staticClass: "modal" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal_body" }, [
          _c("div", { staticClass: "img_wrap" }, [
            _c("img", {
              attrs: {
                srcset: `${_vm.editionContentsUrl} 2x, ${_vm.editionContentsUrl}`,
                src: _vm.editionContentsUrl,
                width: 560,
                alt: "editionContentsUrl",
              },
            }),
            _c("div", { staticClass: "btn_area" }, [
              _c(
                "button",
                {
                  staticClass: "btn strong w_xl h_m",
                  on: { click: _vm.download },
                },
                [_c("IcDownload"), _vm._v(" Download ")],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "input_area type_hoz textarea" }, [
            _c(
              "label",
              { staticClass: "is_blind", attrs: { for: "editionDescription" } },
              [_vm._v("Description")]
            ),
            _c("div", { staticClass: "input_box" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editionDescription,
                    expression: "editionDescription",
                  },
                ],
                attrs: { id: "editionDescription" },
                domProps: { value: _vm.editionDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.editionDescription = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal_foot" }, [
          _c(
            "button",
            { staticClass: "btn basic w_xl h_m", on: { click: _vm.copy } },
            [
              _c("IcCopy", {
                attrs: { "stroke-color": "#000000", "fill-color": "#000000" },
              }),
              _vm._v(" Copy Description "),
            ],
            1
          ),
        ]),
        _c(
          "button",
          { staticClass: "btn type_ic close_modal", on: { click: _vm.close } },
          [_c("IcCloseM")],
          1
        ),
      ]),
      _c("LoadingModal", {
        attrs: { isLoading: _vm.isLoading, message: "Wait for Downloads..." },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal_head" }, [
      _c("h3", { staticClass: "modal_ttl" }, [
        _vm._v("Share This Gorgeous Artwork!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }