var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "header" } },
    [
      _c(
        "article",
        { staticClass: "gnb clearFix" },
        [
          _c(
            "div",
            {
              staticClass: "logo_wrap",
              on: {
                click: function ($event) {
                  ;(_vm.showMobMenu = false), (_vm.showSearch = false)
                },
              },
            },
            [
              _c(
                "router-link",
                { staticClass: "logo_area", attrs: { to: "/" } },
                [_c("h1", [_c("Logo")], 1)]
              ),
            ],
            1
          ),
          !_vm.$parent.isMob && _vm.openService
            ? [
                _c("nav", { staticClass: "menu_wrap main_menu_wrap" }, [
                  _c(
                    "ul",
                    { staticClass: "menu_list" },
                    _vm._l(_vm.mainMenuItems, function (item, i) {
                      return _c(
                        "li",
                        {
                          key: "main_menu" + i,
                          staticClass: "menu_node",
                          on: {
                            click: function ($event) {
                              return _vm.pageMove(item.link)
                            },
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "menu_item",
                              attrs: { to: item.link },
                            },
                            [_c("span", [_vm._v(_vm._s(item.name))])]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "menu_wrap sub_menu_wrap" }, [
                  _c(
                    "ul",
                    { staticClass: "menu_list" },
                    [
                      _c("li", { staticClass: "menu_node" }, [
                        _c(
                          "button",
                          {
                            ref: "searchBtn",
                            staticClass: "menu_item",
                            on: {
                              click: function ($event) {
                                return _vm.showDropMenu("showSearch")
                              },
                            },
                          },
                          [_c("IcSearch")],
                          1
                        ),
                      ]),
                      !_vm.accessToken
                        ? _c("li", { staticClass: "menu_node" }, [
                            _c(
                              "a",
                              {
                                ref: "signIn",
                                staticClass: "menu_item type_line",
                                attrs: {
                                  href: "javascript:;",
                                  title: "Open in new Sign in popup",
                                },
                                on: { click: _vm.openPortal },
                              },
                              [_c("span", [_vm._v("Sign In")])]
                            ),
                          ])
                        : [
                            _c("li", { staticClass: "menu_node" }, [
                              _c(
                                "button",
                                {
                                  ref: "showAlarm",
                                  staticClass: "menu_item type_ic btn_alarm",
                                  class: { is_active: _vm.redDotYn === "Y" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDropMenu("showAlarm")
                                    },
                                  },
                                },
                                [
                                  _c("IcAlarm"),
                                  _c("span", { staticClass: "is_blind" }, [
                                    _vm._v("Alarm"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.showAlarm
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dep2_menu_wrap alarm_menu_wrap notification-wrapper",
                                    },
                                    [
                                      _c("div", { staticClass: "ttl_area" }, [
                                        _c(
                                          "h4",
                                          { staticClass: "ttl c_point" },
                                          [
                                            _vm._v(
                                              "Hello, " +
                                                _vm._s(_vm.userName) +
                                                "!"
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "has_scroll_y" },
                                        [
                                          _vm.notificationInfos &&
                                          _vm.notificationInfos.length > 0
                                            ? _c(
                                                "ul",
                                                { staticClass: "alarm_list" },
                                                _vm._l(
                                                  _vm.notificationInfos,
                                                  function (item, i) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: "main_menu" + i,
                                                        staticClass:
                                                          "alarm_node",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "alarm_item",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "cate",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "h4",
                                                              {
                                                                staticClass:
                                                                  "msg",
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.content
                                                                    ) + "."
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "date",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "UsaFormat"
                                                                    )(
                                                                      _vm._f(
                                                                        "GmtToTz"
                                                                      )(
                                                                        item.regDatetime
                                                                      )
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.notificationInfos.length === 0 &&
                                          _vm.isLoading
                                            ? _c(
                                                "ul",
                                                { staticClass: "alarm_list" },
                                                [_c("LoadingSpinner")],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.notificationInfos.length === 0 &&
                                          !_vm.isLoading
                                            ? _c(
                                                "ul",
                                                { staticClass: "alarm_list" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        padding: "10px 0px",
                                                      },
                                                    },
                                                    [_vm._v("no notifications")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          ref: "closeAlarm",
                                          staticClass: "btn type_ic close_menu",
                                          attrs: {
                                            "aria-label": "Close my list menu",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showAlarm = false
                                            },
                                          },
                                        },
                                        [_c("IcCloseS")],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("li", { staticClass: "menu_node" }, [
                              _c(
                                "button",
                                {
                                  ref: "member",
                                  staticClass: "menu_item member_btn type_ic",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDropMenu("showMyListMenu")
                                    },
                                  },
                                },
                                [
                                  _vm.profileInfos && _vm.profileInfos.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pc-profile profile_info",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "profile",
                                            style: `background-image: url(${_vm.profileInfos[0].resUrl})`,
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pc-profile profile_info",
                                        },
                                        [_c("IcMember")],
                                        1
                                      ),
                                  _c("span", { staticClass: "is_blind" }, [
                                    _vm._v("My list Menu"),
                                  ]),
                                ]
                              ),
                              _vm.showMyListMenu
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dep2_menu_wrap my_account_menu_wrap",
                                    },
                                    [
                                      _c("div", { staticClass: "ttl_area" }, [
                                        _c(
                                          "h4",
                                          { staticClass: "ttl c_point" },
                                          [
                                            _vm._v(
                                              "Hello, " +
                                                _vm._s(_vm.userName) +
                                                "!"
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "menu_list dep2_menu_list",
                                        },
                                        [
                                          _vm._l(
                                            _vm.myMenuItems,
                                            function (item, i) {
                                              return _c(
                                                "li",
                                                {
                                                  key: "main_menu" + i,
                                                  staticClass: "menu_node",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showMyListMenu =
                                                        !_vm.showMyListMenu
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "menu_item pc_item",
                                                      attrs: { to: item.link },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "li",
                                            { staticClass: "menu_node" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "menu_item",
                                                  on: { click: _vm.logout },
                                                },
                                                [_vm._v(" Sign Out ")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn type_ic close_menu",
                                          attrs: {
                                            "aria-label": "Close my list menu",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showMyListMenu = false
                                            },
                                          },
                                        },
                                        [_c("IcCloseS")],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm.$parent.isMob && _vm.openService
            ? [
                _c("div", { staticClass: "menu_wrap mob_menu_wrap" }, [
                  _c("ul", { staticClass: "menu_list" }, [
                    !_vm.showMobMenu
                      ? _c("li", { staticClass: "menu_node" }, [
                          _c(
                            "button",
                            {
                              staticClass: "menu_item",
                              on: {
                                click: function ($event) {
                                  ;[_vm.openModal("SearchModal")]
                                },
                              },
                            },
                            [_c("IcMobSearch")],
                            1
                          ),
                        ])
                      : _vm.accessToken
                      ? _c("li", { staticClass: "menu_node" }, [
                          _c(
                            "button",
                            {
                              ref: "member",
                              staticClass: "menu_item type_ic btn_alarm",
                              class: { is_active: _vm.redDotYn === "Y" },
                              on: { click: _vm.getNotificationList },
                            },
                            [
                              _c("IcMobAlarm"),
                              _c("span", { staticClass: "is_blind" }, [
                                _vm._v("Alarm"),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("li", { staticClass: "menu_node" }, [
                      _c(
                        "button",
                        {
                          staticClass: "menu_item btn_control_menu toggle_menu",
                          class: { is_active: _vm.showMobMenu },
                          attrs: {
                            "aria-label": "menu",
                            "aria-expanded": _vm.showMobMenu ? "true" : "false",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showDropMenu("showMobMenu")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "ic ic_menu" }),
                          _c("span", { staticClass: "is_blind" }, [
                            _vm._v("menu"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("transition", { attrs: { name: "drawer" } }, [
        _vm.$parent.isMob && _vm.showMobMenu
          ? _c("aside", { staticClass: "anb", attrs: { role: "menubar" } }, [
              _c("nav", { staticClass: "menu_wrap mob_menu_wrap mob_nav" }, [
                !_vm.accessToken
                  ? _c(
                      "button",
                      {
                        staticClass: "menu_item btn_signin",
                        attrs: { title: "Open in new Sign in popup" },
                        on: { click: _vm.openPortal },
                      },
                      [_c("span", [_vm._v("Sign In")])]
                    )
                  : _c(
                      "div",
                      { staticClass: "profile_info" },
                      [
                        _vm.profileInfos && _vm.profileInfos.length
                          ? _c("span", {
                              staticClass: "profile",
                              style: `background-image: url(${_vm.profileInfos[0].resUrl})`,
                            })
                          : _c("IcProfile", { staticClass: "profile" }),
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.userName)),
                        ]),
                      ],
                      1
                    ),
                _vm.openService
                  ? _c(
                      "ul",
                      { staticClass: "menu_list", attrs: { role: "menu" } },
                      [
                        _vm._l(_vm.mainMenuItems, function (item, i) {
                          return _c(
                            "li",
                            {
                              key: "mob_menu" + i,
                              staticClass: "menu_node",
                              attrs: { role: "menuitem" },
                              on: {
                                click: function ($event) {
                                  return _vm.pageMove(item.link)
                                },
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "menu_item",
                                  attrs: { to: item.link },
                                },
                                [_c("span", [_vm._v(_vm._s(item.name))])]
                              ),
                            ],
                            1
                          )
                        }),
                        _c(
                          "li",
                          {
                            staticClass: "menu_node",
                            attrs: {
                              role: "menuitem",
                              "aria-expanded": _vm.showDep2
                                ? "unfold"
                                : "collapsed",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "menu_item has_sub",
                                class: { is_active: _vm.showDep2 },
                                attrs: { href: "javascript:;" },
                                on: { click: _vm.showDropMenuMobile },
                              },
                              [
                                _c("span", { staticClass: "is_blind" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.showDep2 ? "unfold" : "collapsed"
                                    ) + ","
                                  ),
                                ]),
                                _c("span", [_vm._v("My Account")]),
                                _c("ArwSelect", {
                                  attrs: {
                                    "stroke-color": "var(--txt-gray)",
                                    iconLabel: _vm.showDep2
                                      ? "unfold"
                                      : "collapsed",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("transition", { attrs: { name: "drop" } }, [
                              _vm.showDep2
                                ? _c(
                                    "ul",
                                    { staticClass: "menu_list dep2_menu_list" },
                                    _vm._l(_vm.myMenuItems, function (item, i) {
                                      return _c(
                                        "li",
                                        {
                                          key: "main_menu" + i,
                                          staticClass: "menu_node",
                                          attrs: { role: "menuitem" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showMobMenu = false
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "menu_item",
                                              attrs: { to: item.link },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "ul",
                  { staticClass: "menu_list foot_menu_list" },
                  _vm._l(_vm.fnbMenuItems, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: "main_menu" + i,
                        staticClass: "menu_node",
                        on: {
                          click: function ($event) {
                            _vm.showMobMenu = false
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "menu_item",
                            attrs: { to: item.link },
                          },
                          [_c("span", [_vm._v(_vm._s(item.name))])]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.accessToken
                  ? _c(
                      "button",
                      {
                        staticClass: "menu_item btn_signout",
                        on: { click: _vm.logout },
                      },
                      [
                        _c("IcMobSignOut"),
                        _vm._v(" "),
                        _c("span", [_vm._v("Sign Out")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showSearch
          ? _c(
              "aside",
              { staticClass: "search_sec search_ui" },
              [
                _c("SearchInner", {
                  attrs: { type: "web" },
                  on: {
                    searchClose: function ($event) {
                      return _vm.searchClose()
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.showAlarm && _vm.$isMobile()
        ? _c("NotificationModal", {
            ref: "NotificationModal",
            attrs: {
              isLoading: _vm.isLoading,
              showAlarm: _vm.showAlarm,
              itemData: _vm.notificationInfos,
            },
            on: {
              "update:itemData": function ($event) {
                _vm.notificationInfos = $event
              },
              "update:item-data": function ($event) {
                _vm.notificationInfos = $event
              },
              alarmClose: function ($event) {
                return _vm.alarmClose()
              },
            },
          })
        : _vm._e(),
      _c("SearchModal", { ref: "SearchModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }