// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.popup {
  padding-top: -6px !important;
  background-color: white;
}
.popup .notice_body a {
  text-decoration: underline !important;
}
.popup .notice_body table.gridtable {
  border: 1px solid #666666;
  width: 100%;
  color: #333333;
  border-collapse: separate;
  border-spacing: 0;
}
.popup .notice_body table.gridtable th {
  border-bottom: 1px solid #666666;
  padding: 8px;
  height: 25px;
  font-size: 12px;
  background-color: #dedede;
}
.popup .notice_body table.gridtable td {
  padding: 8px;
  font-size: 12px;
  background-color: #ffffff;
}
.popup .notice_body table.gridtable td p,
.popup .notice_body table.gridtable td b {
  padding: 0;
  font-size: 12px;
}
.popup .notice_body table.texttable {
  color: #333333;
  border-width: 0px;
}
.popup .notice_body table.texttable td {
  border-width: 0px;
  padding: 8px;
}

/**/
.popup .notice_body,
.popup .notice_body * {
  margin: 0;
  font-family: arial, sans-serif;
  font-size: 12px;
  color: #333 !important;
}
/*body * {white-space: nowrap;}*/
.popup .notice_body b {
  font-size: 1.25em;
  color: #000;
}
.popup .notice_body p {
  margin: 0;
  padding: 10px;
}
.popup .notice_body h2.notice_title {
  position: relative;
  margin: 0;
  padding: 1.25em 1em 1em;
  overflow: hidden;
  font-size: 1.25em;
  color: #fff;
  background: #980432;
}
.popup .notice_body h2.notice_title *,
.popup .notice_body h2.notice_title sup {
  font-size: 12px;
  color: #fff !important;
}
.popup .left {
  float: left;
}
.popup .right {
  float: right;
}
.popup .notice_body .bdTop {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #888;
}
.popup caption {
  display: none;
}
.popup th:not(:last-child),
.popup td:not(:last-child) {
  border-right: 1px solid #666666;
}
.popup tr:not(:last-child) td {
  border-bottom: 1px solid #666666;
}
/*.bdTop b {font-style: italic;}*/
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
