var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "i",
    {
      staticClass: "ic",
      style: `width: ${_vm.width / 10}rem; height: ${_vm.height / 10}rem`,
    },
    [
      _c("span", { staticClass: "is_blind" }, [_vm._v(_vm._s(_vm.iconLabel))]),
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: _vm.width,
            height: _vm.height,
            viewBox: _vm.viewBox,
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                fill: _vm.iconColor,
                "stroke-width": _vm.strokeWidth,
                stroke: _vm.strokeColor,
              },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }