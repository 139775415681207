// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.move_el[data-v-41bb4389] {
  transition: ease-out 0.3s;
}
.move_el.is_moving[data-v-41bb4389] {
  pointer-events: none;
  transition: 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
