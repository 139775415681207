// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal_head .tab_list[data-v-c09e8dd8] {
  margin-top: 2rem;
  margin-bottom: 0;
}
.modal_head .tab_list[data-v-c09e8dd8]:before {
  background: #707070;
}
.modal_head .tab_list .tab_node[data-v-c09e8dd8] {
  width: 100%;
}
.modal_head .tab_list .tab_node .tab_item[data-v-c09e8dd8] {
  display: inline-block;
  width: 50%;
  margin-left: 0;
  padding: 1.4rem 0;
  font-size: 1.8rem;
  font-weight: 300;
}
.modal .modal_head + .modal_body[data-v-c09e8dd8] {
  margin-top: 0;
  margin-bottom: -5rem;
}
.modal_body .has_scroll_y[data-v-c09e8dd8] {
  max-height: 65.4rem;
}
.modal_body .has_scroll_y[data-v-c09e8dd8]::-webkit-scrollbar-thumb {
  border-top: 4rem solid #393939;
  border-bottom: 5rem solid #393939;
}
.modal_body .follow_list[data-v-c09e8dd8] {
  padding: 4rem 0 5rem;
}
.modal_body .follow_node[data-v-c09e8dd8] {
  padding: 1.5rem 2.4rem 1.5rem 2rem;
}
.modal_body .follow_node[data-v-c09e8dd8]:first-child {
  padding-top: 0;
}
.modal_body .follow_node[data-v-c09e8dd8]:last-child {
  padding-bottom: 0;
}
.follow_item[data-v-c09e8dd8] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile_info_area .profile[data-v-c09e8dd8] {
  display: inline-block;
  width: 5rem !important;
  height: 5rem !important;
  margin-right: 1.6rem;
  background-size: cover;
  vertical-align: middle;
}
.profile_info_area .ttl[data-v-c09e8dd8] {
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
  vertical-align: middle;
}
@media screen and (max-width: 600px) {
.modal_head .tab_list[data-v-c09e8dd8] {
    padding: 0;
}
.modal_body .follow_node[data-v-c09e8dd8] {
    padding-left: 0;
    padding-right: 0;
}
.profile_info_area[data-v-c09e8dd8] {
}
.profile_info_area .profile[data-v-c09e8dd8] {
    width: 3.6rem !important;
    height: 3.6rem !important;
}
.modal_body .follow_item .btn[data-v-c09e8dd8] {
    min-width: 0;
    width: 9rem;
}
.modal .modal_head + .modal_body[data-v-c09e8dd8] {
    margin-bottom: -3rem;
}
.modal_body .follow_list[data-v-c09e8dd8] {
    padding: 3rem 0;
}
.modal_body .has_scroll_y[data-v-c09e8dd8] {
    max-height: 50rem;
}
.modal_body .has_scroll_y[data-v-c09e8dd8]::-webkit-scrollbar-thumb {
    border-top: 3rem solid #393939;
    border-bottom: 3rem solid #393939;
}
.profile_info_area .ttl[data-v-c09e8dd8] {
    font-size: 1.5rem;
    padding-right: 1rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
