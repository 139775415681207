var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icLike",
        "icon-label": _vm.status ? "Not Selected, Like" : "Selected, Like",
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": _vm.iconColor,
        "stroke-width": 0,
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M24.8 6.602c-2.5-2.5-6.5-2.5-8.9 0l-.9.9-.8-.8c-2.5-2.5-6.5-2.5-8.9 0s-2.5 6.5 0 8.9l9.8 9.7 9.8-9.7c2.4-2.5 2.4-6.5-.1-9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }