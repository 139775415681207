// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal_body .time_area[data-v-08808a50] {
  display: inline-block;
  min-width: 170px;
  padding: 9px 18px;
  margin: 20px 0 0;
  border: 1px solid #707070;
  font-size: 1.6rem;
  font-weight: 300;
  border-radius: 20px;
}
.modal_foot p.txt[data-v-08808a50] {
  font-size: 1.4rem;
  color: var(--txt-gray-2);
}
.modal_foot p.txt span[data-v-08808a50] {
  color: #fff;
}
.modal_foot p.txt .ic[data-v-08808a50] {
  vertical-align: -7px;
  margin: 0 -3px 0 -4px;
}
.modal_foot .btn_area[data-v-08808a50] {
  margin-top: 30px;
}
.qr_group[data-v-08808a50] {
  position: relative;
}
.qr_group.is_disabled .img_area[data-v-08808a50] {
  opacity: 0.2;
}
.qr_group .img_area[data-v-08808a50] {
  display: flex;
  justify-content: center;
}
.qr_group .btn[data-v-08808a50] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  border-radius: 50%;
}
.qr-code[data-v-08808a50] {
  background-color: #fff;
  padding: 10px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
