// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
	파일정의 : market page 스타일
	작성날짜 : 2022-03-31 장병주
	참고사항 : Drops page 스타일 정의
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/
.market_page .list_sec .sec_head {
  margin-bottom: 0;
}
.market_page .sort_group {
  position: relative;
}
.market_page .filter_ui {
  position: static;
}
.market_page .filter_ui .filter_opt_group {
  margin-top: 17px;
}
.market_page .filter_ui .filter_opt_group .input_area > label{
  font-size: 1.8rem;
  font-weight: 400;
}
.market_page .filter_ui .filter_opt_group .input_area.check label{
  display: flex;
  align-items: center;
}
.market_page .filter_ui .filter_opt_group .input_area.check label span{
  display: flex;
  align-items: center;
}
.market_page .filter_ui .filter_opt_group .input_area.check label span:before,
.market_page .filter_ui .filter_opt_group .input_area.check label span:after{
  top:50%;
  transform: translateY(-50%);
}
.market_page .filter_ui .filter_opt_group .input_area.check label span i {
  margin-right: 0.6rem;
}
.market_page .guide_ui {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: calc(var(--min) * 1px - 310px);
  margin-bottom: -10px;
  padding-bottom: 0;
}
@media screen and (max-width: 600px) {
.market_list_sec .list_head.has_elem {
    display: block;
    margin-top: 1.5rem;
}
.market_list_sec .list_head .count {
    margin-bottom: 1.2rem;
}
.market_page .sort_group {
    margin-left: -2rem;
    margin-right: -2rem;
    background: var(--box-bg);
}
.market_page .sort_group > div {
    width: 50%;
}
.market_list_sec .list_head .sort_group .select .label {
    display: none;
}
.market_page .list_head .sort_group > div:not(:first-child) {
    margin-left: 0;
}
.market_page .drop_ui.select .input_box,
  .market_page .drop_ui.select .drop_btn {
    width: 100%;
    min-width: 0;
}
.market_page .filter_ui {
    position: relative;
}
.market_page .filter_ui:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: var(--line-color);
}
.market_page .filter_ui > .btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem 0 1.4rem;
    height: 4rem;
    font-size: 1.4rem;
}
.market_page .filter_ui > .btn:before {
    display: inline-block;
    content: 'Filter';
    font-size: inherit;
}
.market_page .filter_ui > .btn .ic {
    width: 2.2rem !important;
    height: 2.2rem !important;
}
.market_page .filter_ui > .btn .ic g {
    stroke-width: 1 !important;
    stroke: #fff !important;
}
.market_list_sec .edit_list .edit_node {
    width: 100%;
}
.market_page .filter_ui .filter_opt_group {
    margin-top: 0;
}
.filter_opt_group .filter_opt_body .input_area {
}
.market_page .edit_item.type_card .item_body {
    min-height: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
