var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      attrs: {
        "icon-name": "icSearch",
        "icon-label": _vm.iconLabel,
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        "stroke-color": _vm.strokeColor,
        "stroke-width": 2,
      },
    },
    [
      _c("circle", {
        staticClass: "st1",
        attrs: { cx: "10.8", cy: "11", r: "7" },
      }),
      _c("path", {
        staticClass: "st1",
        attrs: { d: "m16.2 16.5 5 5", fill: "none" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }