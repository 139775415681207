// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.guide_ui .img_area[data-v-5b5e45e0] {
  margin-bottom: -1rem;
}
.request-ic[data-v-5b5e45e0] {
  width: 2rem;
  height: 2rem;
}
.btn-mail[data-v-5b5e45e0] {
  color: #fff;
  border: 1px solid var(--disabled-colr);
}
.mail-form[data-v-5b5e45e0] {
  display: flex;
  align-items: center;
  font-size: 1.8rem !important;
}
input[data-v-5b5e45e0]:focus::-webkit-input-placeholder,
textarea[data-v-5b5e45e0]:focus::-webkit-input-placeholder {
  color: transparent;
}
input[data-v-5b5e45e0]:focus::-moz-placeholder,
textarea[data-v-5b5e45e0]:focus::-moz-placeholder {
  color: transparent;
}
input[data-v-5b5e45e0]:focus:-ms-input-placeholder,
textarea[data-v-5b5e45e0]:focus:-ms-input-placeholder {
  color: transparent;
}
@media screen and (max-width: 600px) {
.guide_ui .img_area[data-v-5b5e45e0] {
    margin-bottom: 0;
}
}
.license_area[data-v-5b5e45e0] {
  cursor: pointer;
  margin-top: 4px;
  position: absolute;
  z-index: 5;
}
.license[data-v-5b5e45e0] {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--txt-gray);
}
@media screen and (max-width: 767px) {
.license[data-v-5b5e45e0] {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--txt-gray);
}
.license_area[data-v-5b5e45e0] {
    margin-top: 4px;
    position: absolute;
    text-align: left;
}
.pagination_wrap[data-v-5b5e45e0] {
    margin-top: 2rem;
}
.license_area[data-v-5b5e45e0] {
    margin-top: 50px;
}
}
textarea[data-v-5b5e45e0]:focus,
input[data-v-5b5e45e0]:focus {
  outline: none;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
