// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.user_item.type_card .item_body[data-v-0ea921c5] {
  padding: 2rem 2rem;
  cursor: pointer;
}
/*Dev: 220503 수정 */
.user_item.type_card .profile_info .profile[data-v-0ea921c5] {
  width: 5rem;
  height: 5rem;
  margin: 0 2rem 0 0;
}
@media screen and (max-width: 600px) {
.user_item.type_card .profile_info .profile[data-v-0ea921c5] {
    width: 3.6rem !important;
    height: 3.6rem !important;
    margin: 0 2rem 0 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
