var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_collection_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [
        _vm._v("MY Collections"),
      ]),
      _vm.currentCollection
        ? _c("section", { staticClass: "my_collection_visual_sec" }, [
            _vm.currentCollection.mimeType === "image/jpeg" ||
            _vm.currentCollection.otherMaplYn == "Y"
              ? _c("div", {
                  staticClass: "bg_el",
                  style: `background-image: url(${_vm.currentCollection.videoThumbInfos[0].resUrl})`,
                })
              : _vm._e(),
            _vm.currentCollection.mimeType === "video/mp4"
              ? _c(
                  "video",
                  {
                    key: _vm.currentCollection.priority,
                    ref: "videoRef",
                    staticClass: "bg_el",
                    attrs: {
                      id: "videoId",
                      autoplay: "",
                      playsinline: "",
                      muted: "",
                    },
                    domProps: { muted: true },
                    on: { pause: _vm.onVideoPaused },
                  },
                  [
                    _vm.currentCollection.videoInfos
                      ? _c("source", {
                          attrs: {
                            src:
                              _vm.currentCollection.videoInfos[0].resUrl +
                              "#t=,15",
                            type: _vm.currentCollection.mimeType,
                          },
                        })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "set_inner" },
              [
                _c("div", { staticClass: "show_item_info_wrap" }, [
                  _c("div", { staticClass: "show_item" }, [
                    _c("div", { staticClass: "txt_area" }, [
                      _c("h2", { staticClass: "ttl" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.currentCollection.artistName
                                ? _vm.currentCollection.artistName
                                : _vm.currentCollection.otherPlatform
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "desc" }, [
                        _vm._v(_vm._s(_vm.currentCollection.title)),
                      ]),
                      _c(
                        "p",
                        { staticClass: "editions", attrs: { role: "status" } },
                        [
                          _vm.currentCollection.listType !== "D"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.currentCollection.editionId) +
                                    " of"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(_vm.currentCollection.totalCount) +
                              " " +
                              _vm._s(
                                _vm.currentCollection.totalEdition > 1
                                  ? "Editions"
                                  : "Edition"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "btn_area" },
                      [
                        _vm.currentCollection.otherMaplYn !== "Y"
                          ? _c(
                              "p",
                              {
                                staticClass: "btn strong h_l w_l view",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goMarketPlace(
                                      _vm.currentCollection
                                    )
                                  },
                                },
                              },
                              [_vm._v(" View Details ")]
                            )
                          : _vm._e(),
                        _vm.winW <= 1024
                          ? _c(
                              "router-link",
                              {
                                staticClass: "btn type_line_w h_l w_l mode",
                                attrs: { to: "/collections/mode" },
                              },
                              [_vm._v(" Collection Mode"), _c("ArwNextS")],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("BgSlide", {
                  attrs: {
                    setTitle: "Collections",
                    itemData: _vm.ownedCollections,
                    num: _vm.ownedCollections.length,
                    currentIndex: _vm.currentIndex,
                    dimmable: false,
                  },
                  on: {
                    setCurrent: _vm.setCurrent,
                    swiperClick: _vm.swiperClick,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isShowDetailView
        ? _c("DetailViewer", {
            attrs: {
              collections: _vm.ownedCollections,
              fixedIndex: _vm.fixedIndex,
              isFullScreen: true,
              currentIndex: _vm.currentCollection.priority,
              singleVideoLoop: _vm.ownedCollections.length == 1,
            },
            on: {
              "update:collections": function ($event) {
                _vm.ownedCollections = $event
              },
              hide: _vm.hide,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }