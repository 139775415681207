var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconBase",
    {
      staticStyle: { "stroke-miterlimit": "10" },
      attrs: {
        "icon-name": "IcWeb",
        "icon-label": _vm.iconLabel,
        width: 30,
        height: 30,
        viewBox: "0 0 30 30",
        "icon-color": "none",
        "stroke-color": _vm.strokeColor,
        "stroke-width": "1.2",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M25.3 15.3c0 5.7-4.6 10.3-10.3 10.3-5.7 0-10.3-4.6-10.3-10.3S9.3 5 15 5c5.7 0 10.3 4.6 10.3 10.3z",
        },
      }),
      _c("path", {
        attrs: { d: "M14.5 5c3.1 2.5 4.9 6.3 4.8 10.3 0 4-1.8 7.8-4.8 10.3" },
      }),
      _c("path", {
        attrs: {
          d: "M15.5 5c-3.1 2.5-4.9 6.3-4.8 10.3 0 4 1.8 7.8 4.8 10.3M5.5 11.8h19.1M5.5 18.7h19.1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }