// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "utf-8";
/*-------------------------------------------------------------------
	파일정의 : Main page 스타일
	작성날짜 : 2022-03-31 장병주
	참고사항 : Main page 스타일 정의
	속성순서 : 표시 > 위치 > 넘침/흐름 > 크기/간격 > 박스모양 > 폰트/정렬 > 기타
-------------------------------------------------------------------*/
.main_visual_sec {
  margin-bottom: 92px;
}
.main_visual_wrap .mv_banner_list {
  position: relative;
}
.main_visual_wrap .banner_item:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 337px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  pointer-events: none;
}
.main_visual_wrap .banner_item .set_inner {
  display: flex;
  position: relative;
  z-index: 3;
  height: 100%;
  flex-flow: column;
  justify-content: flex-end;
}
.main_visual_wrap .banner_item .time_group {
  margin-bottom: 10px;
}
.main_visual_wrap .banner_item .time_group > dl:not(:first-child):before {
  opacity: 0.25;
}
.main_visual_wrap .banner_item .time_group dd {
  opacity: 0.5;
}
.main_visual_wrap .controller_group {
  display: flex;
  align-items: center;
}
.main_visual_wrap .controller_group > .btn {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}
.main_visual_wrap .controller_group .slide_nav_area {
  transform: translateY(-1px);
}
.main_page .drops_sec {
  padding-top: 0;
}
.drops_sec .sec_body {
  position: relative;
}
.drops_sec .sec_body > div {
  float: left;
}
.drops_sec .info_wrap {
  z-index: 1;
  width: 450px;
  padding-right: 30px;
  box-sizing: border-box;
}
.drops_sec .info_wrap .profile_info {
  margin-bottom: 18px;
}
.drops_sec .info_wrap .btn_area {
  position: absolute;
  left: 0;
  bottom: 0;
}
.drops_sec .info_wrap .ttl_area .ttl {
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 8px;
  font-size: 2.2rem;
  font-weight: 300;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.drops_sec .info_wrap .ttl_area .desc {
  display: -webkit-box;
  overflow: hidden;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.drops_sec .edition_slide_wrap {
  width: 100%;
  margin-left: -450px;
  padding-left: 450px;
  padding-top: 10px;
  box-sizing: border-box;
  pointer-events: none;
}
.drops_sec .edition_slide_wrap > div,
.drops_sec .edition_slide_wrap > nav {
  pointer-events: auto;
}
.activity_item_wrap .edit_item .item_body {
  display: flex;
}
.activity_item_wrap .edit_item .thumb_area {
  position: relative;
  overflow: hidden;
}
.activity_item_wrap .edit_item .thumb_area img {
  position: absolute;
  top: 0;
  left: 0;
}
.activity_item_wrap .edit_node:first-child {
  width: 628px;
}
.activity_item_wrap .edit_node:first-child .thumb_area {
  padding-bottom: 336px;
}
.activity_item_wrap .edit_node:first-child .thumb_area img {
  width: 100%;
}
.activity_item_wrap .edit_node:first-child .item_body {
  padding: 16px 20px 17px;
  justify-content: space-between;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info .price b {
  font-size: 2rem;
  font-weight: 300;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info .price b .unit{
  padding:0 0.3rem 0 0.1rem;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info .price b .dollars{
  font-size: 1.6rem;
  font-weight: 200;
}
.activity_item_wrap .edit_node:first-child .profile_info + .ttl_area {
  margin-top: 17px;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info {
  display: flex;
  position: relative;
  width: 148px;
  flex-flow: column;
  flex-shrink: 0;
  justify-content: center;
  padding: 0 0 0 20px;
  margin-top: 0;
  border: 0 none;
  white-space: nowrap;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info:before {
  display: block;
  content: '';
  position: absolute;
  top: -16px;
  bottom: -17px;
  left: 0;
  width: 1px;
  background: var(--line-color);
}
.activity_item_wrap .edit_node:first-child .item_body .state_info .chip {
  margin: 0;
}
.activity_item_wrap .edit_node:first-child .basic_info_group {
  flex: 1;
  padding-right: 2rem;
}
.activity_item_wrap .edit_node:not(:first-child) {
  width: 421px;
}
.activity_item_wrap .edit_node:not(:first-child) .thumb_area {
  padding-bottom: 219px;
}
.activity_item_wrap .edit_node:not(:first-child) .item_body {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: center;
  opacity: 0;
  padding-right: 30px;
  background: rgba(0, 0, 0, 0.7);
  transition: ease-in-out 0.2s;
}
.activity_item_wrap .edit_node:not(:first-child) .item_body.activate {
  opacity: 1;
  transition: ease-in-out 0.4s;
}
.activity_item_wrap .edit_node:not(:first-child) .edit_item:hover .item_body {
  opacity: 1;
  transition: ease-in-out 0.4s;
}
.activity_item_wrap .edit_node:not(:first-child) .state_info {
  border-top-color: rgba(108, 108, 108, 0.4);
}
.activity_item_wrap .edit_node:not(:first-child) .profile_info {
  padding-right: 25px;
}
.keyword_sec {
  background: rgba(255, 255, 255, 0.08);
  padding: 90px 0;
}
@media screen and (max-width: 1024px) {
.drops_sec .sec_body .edition_slide_wrap {
    float: right;
    width: 450px;
    margin-left: 0;
    padding-left: 0;
}
.drops_sec .info_wrap {
    width: 100%;
    margin-right: -450px;
    padding-right: 480px;
}
.activity_item_wrap .edit_node:first-child {
    width: 100%;
}
.activity_item_wrap .edit_node:first-child .edit_item {
    display: flex;
}
.activity_item_wrap .edit_node:first-child .edit_item .item_head {
    width: calc(50% + 1.5rem);
    flex-shrink: 0;
}
.activity_item_wrap .edit_node:first-child .edit_item .item_body {
    flex: 1;
    flex-flow: column;
}
.activity_item_wrap .edit_node:first-child .ttl_area .ttl {
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info {
    width: 100%;
    padding-left: 0;
    margin-bottom: -0.5rem;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info:before {
    left: 0;
    right: 0;
    top: -0.8rem;
    bottom: auto;
    width: auto;
    height: 1px;
}
.activity_item_wrap .edit_node:not(:first-child) {
    width: 50%;
}
}
@media screen and (max-width: 840px) {
.drops_sec .sec_body {
    padding-bottom: 9rem;
}
.drops_sec .sec_body > div {
    float: none !important;
}
.drops_sec .info_wrap {
    margin-right: 0px;
    padding-right: 0px;
}
.drops_sec .info_wrap .ttl_area .desc {
    -webkit-line-clamp: 2;
}
.drops_sec .info_wrap .btn_area {
    position: absolute;
    width: 100%;
    text-align: center;
}
.drops_sec .sec_body .edition_slide_wrap {
    position: relative;
    width: 100%;
    margin-top: 2rem;
}
.edition_slide_wrap .slide_nav_area {
    display: none;
}
.edition_slide_wrap .pagination_area {
    display: block;
    position: relative;
    margin-top: 1rem;
    text-align: center;
}
}
@media screen and (max-width: 767px) {
.activity_item_wrap .edit_list {
    font-size: 0;
}
.activity_item_wrap .edit_list .edit_node {
    display: inline-block;
    width: 50%;
    float: none;
}
.activity_item_wrap .edit_list .edit_node .edit_item {
    display: block;
}
.activity_item_wrap .edit_list .edit_node .edit_item .item_head {
    width: 100%;
}
.activity_item_wrap .edit_list .edit_node .thumb_area {
    padding-bottom: 0;
}
.activity_item_wrap .edit_item .thumb_area img {
    position: static;
}
.activity_item_wrap .edit_list .edit_node .profile_info + .ttl_area {
    margin-top: 1.5rem;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info {
    padding-top: 11px;
    margin-top: 15px;
    border-top: 1px solid var(--line-color);
}
.activity_item_wrap .edit_node:first-child .item_body .state_info {
    margin-bottom: -0.6rem;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info:before {
    display: none;
}
.activity_item_wrap .edit_node:not(:first-child) .item_body {
    position: static;
    background: var(--box-bg);
    opacity: 1;
}
}
@media screen and (max-width: 600px) {
.main_visual_sec {
    margin-bottom: 3.8rem;
}
.main_visual_wrap .mv_banner_list {
    padding-bottom: 14rem;
}
.main_visual_wrap .banner_item .set_inner {
    position: absolute;
    bottom: 0;
    height: auto;
    align-items: flex-start;
    margin-bottom: -11.5rem;
}
.main_visual_wrap .banner_item .time_group {
    margin-bottom: 1.4rem;
}
.main_visual_wrap .controller_group .slide_nav_area {
    margin-left: 0.2rem;
}
.main_page .drops_sec {
    position: relative;
    padding-top: 4.8rem;
}
.main_page .drops_sec:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -2rem;
    right: -2rem;
    height: 1rem;
    background: var(--divider-bg);
}
.activity_item_wrap .edit_node:first-child .item_body .state_info .price b {
    font-size: 1.6rem;
}
.activity_item_wrap .edit_node:first-child .item_body .state_info .price b .dollars{
    font-size: 1.2rem;
}
.drops_sec .sec_body {
    padding-bottom: 8rem;
}
.drops_sec .info_wrap .profile_info {
    margin-bottom: 0.8rem;
}
.drops_sec .info_wrap .profile_info .profile {
    display: none;
}
.drops_sec .info_wrap .profile_info .name {
    font-size: 1.6rem;
}
.slide_nav_area .btn + .btn {
    margin-left: 0.7rem;
}
.activity_item_wrap .edit_list .edit_node,
  .market_item_wrap .edit_list .edit_node {
    width: 100%;
}
.edit_item .ttl_area .ttl {
    font-size: 1.8rem;
    font-weight: 300;
}
.main_page .edit_list .edit_node .profile_info .name {
    font-size: 1.2rem;
}
.edit_item.type_card .state_info .price_area,
  .edit_item.type_card .state_info .time_area {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
}
.edit_item.type_card .state_info .time_area {
    margin-top: 0.7rem;
}
.keyword_sec {
    padding: 3.7rem 0 4.1rem;
}
.main_visual_wrap .banner_item:before {
    height: 10rem;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
