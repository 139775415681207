<template>
  <footer ref="footer" id="footer">
    <article class="fnb">
      <div class="fnb_head clearFix">
        <div class="logo_wrap">
          <p class="logo_area">
            <Logo />
          </p>
        </div>
        <div v-if="$parent.isMob" class="info_wrap">
          <p>
            Create, sell and collect truly rare digital artworks. <br />Powered
            by blockchain technology.
          </p>
        </div>
        <div class="menu_wrap">
          <ul class="menu_list clearFix">
            <li
              v-for="(item, i) in fnbMenuItems"
              :key="'main_menu' + i"
              class="menu_node"
            >
              <router-link :to="item.link" class="menu_item">
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
          <ul class="menu_list sns_menu clearFix">
            <li class="menu_node">
              <a
                href="https://twitter.com/LGArtLab"
                target="_blank"
                title="Open in new window"
                class="menu_item"
                aria-label="Twitter"
              >
                <IcFootTwitter />
              </a>
            </li>
            <li class="menu_node">
              <a
                href="https://www.instagram.com/lg_oledart/ "
                target="_blank"
                title="Open in new window"
                class="menu_item"
                aria-label="instagram"
              >
                <IcFootInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="fnb_body">
        <div class="info_wrap">
          <p v-if="!$parent.isMob">
            Create, sell and collect truly rare digital artworks. <br />Powered
            by blockchain technology.
          </p>
          <p class="copy">
            <label @click="testLink">&copy;</label> 2022 LG Electronics. All
            rights reserved.
          </p>
        </div>
      </div>
    </article>
    <div
      ref="floatEl"
      class="float_btn_area"
      :class="[
        { is_active: showFixEl },
        { fix_bottom: fixBottom },
        { dev_mob: $parent.isMob },
      ]"
    >
      <button class="btn basic btn_gotop" @click="goToTop">top</button>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/ic/Logo';
import IcFootTwitter from '@/components/ic/IcFootTwitter';
import IcFootInstagram from '@/components/ic/IcFootInstagram';

export default {
  name: 'FooterBase',
  components: { IcFootInstagram, IcFootTwitter, Logo },
  data() {
    return {
      footerTop: 0,
      winHeight: null,
      fixBottom: false,
      showFixEl: false,
      fnbMenuItems: [
        {
          name: 'Privacy Policy',
          link: '/privacy',
        },
        {
          name: 'Terms of  Service',
          link: '/terms',
        },
        {
          name: 'Notice Board',
          link: '/notice',
        },
        {
          name: 'Help Center',
          link: '/help',
        },
        {
          name: 'About Us',
          link: '/aboutUs',
        },
      ],
      swiperInit: false,
    };
  },
  methods: {
    setInit() {
      this.winHeight = window.innerHeight;
    },
    testLink() {
      return false;
      //this.$EventBus.$emit('empLogin', 'emptest');
    },
    setFixBtn() {
      if (this.winHeight < 1) this.setInit();
      const st = window.pageYOffset;
      const footer = this.$refs.footer;
      this.footerTop = footer.getBoundingClientRect().top + st;
      // 버튼 활성화
      if (this.winHeight / 2 < st) this.showFixEl = true;
      else this.showFixEl = false;
      // 버튼 푸터위 고정
      if (this.footerTop < this.winHeight + st) this.fixBottom = true;
      else this.fixBottom = false;
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.setFixBtn);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setFixBtn);
  },
};
</script>

<style scoped></style>
