// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_page {
  padding-top: 6rem;
  padding-bottom: 15rem;
}
.footer_page .page_ttl_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid var(--line-color);
}
.footer_page .page_ttl_area .page_ttl {
  font-size: 3.2rem;
  font-weight: 300;
  color: #fff;
}
.footer_page .page_ttl_area .date {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray-2);
}
.footer_page .footer_info_sec_head {
  margin-top: 5rem;
}
.footer_page p {
  font-size: 1.6rem;
  font-weight: 200;
  line-height: 1.4375;
  color: var(--txt-gray-2);
}
.footer_page .footer_info_sec_body {
  margin-top: 4.8rem;
}
.footer_page .footer_info_sec_body ol li p {
  margin-top: 2.5rem;
}
.footer_page .footer_info_sec_body ol li {
  font-size: 2.4rem;
  font-weight: 300;
  color: #fff;
}
.footer_page .footer_info_sec_body ol li + li {
  margin-top: 4.8rem;
}
.footer_page .board_sec {
  margin-top: 5rem;
}
.footer_page .board_sec li + li {
  margin-top: 2rem;
}
.footer_page .board_sec .board_item {
  position: relative;
  padding: 2.8rem 3rem;
  background: var(--box-bg);
  border: 0.1rem solid var(--box-bg);
}
.footer_page .board_sec .board_item .link {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.footer_page .board_sec .board_item:hover {
  border: 0.1rem solid var(--main-color);
}
.footer_page .board_sec .board_item .item_head {
  margin-bottom: 2rem;
}
.footer_page .board_sec .board_item .item_head span {
  font-size: 1.4rem;
  font-weight: 200;
  vertical-align: text-top;
  color: var(--txt-gray-2);
}
.footer_page .board_sec .board_item .item_head span + span:before {
  display: inline-block;
  content: '';
  width: 0.1rem;
  height: 1.6rem;
  margin: -0.2rem 1.5rem 0 2.1rem;
  background: #414141;
  vertical-align: middle;
}
.footer_page .board_sec .board_item .item_body .ttl {
  margin-bottom: 1.3rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #ffffff;
}
.footer_page .board_sec .board_item .item_body .desc {
  overflow: hidden;
  width: 115rem;
  font-size: 1.4rem;
  font-weight: 200;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.footer_page .board_sec .board_item .btn_area {
  position: absolute;
  top: 50%;
  right: 2.8rem;
  transform: translateY(-50%);
}
.footer_page .board_sec .board_sec_head {
  margin-top: 3.5rem;
}
.footer_page .board_sec .board_sec_head .ttl {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
}
.footer_page .board_sec .board_sec_head .date {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.footer_page .board_sec .board_sec_body {
  margin-top: 2.2rem;
  padding-top: 2.7rem;
  padding-bottom: 11rem;
  border-bottom: 0.1rem solid var(--line-color);
  border-top: 0.1rem solid #ffffff;
}
.footer_page .board_sec .board_sec_body p {
  font-size: 1.4rem;
  font-weight: 200;
  line-height: 1.6;
  color: #fff;
}
.footer_page .board_sec .board_sec_foot {
  margin-top: 5rem;
  text-align: center;
}
.footer_page.help_detail_page .board_sec {
  margin-top: 4rem;
}
.footer_page.help_detail_page .board_sec .board_sec_head span {
  color: var(--txt-gray-2);
}
.footer_page.help_detail_page .board_sec .board_sec_head .ttl {
  margin-bottom: 0;
  margin-top: 1rem;
}
.footer_page.help_page .tab_wrap {
  margin-bottom: 5rem;
}
.footer_page.help_page .tab_list .tab_node {
  min-width: auto;
}
.footer_page.help_page .tab_list .tab_item + .tab_item {
  margin-left: 0;
}
.footer_page.help_page .board_sec .board_item.basic .item_body .ttl {
  margin-bottom: 0;
}
.footer_page.help_page .board_sec .board_item.result .item_head span i {
  vertical-align: -6px;
  margin-right: 1px;
}
.footer_page.help_page .board_sec .board_item.result .item_head span i g {
  stroke-width: 1.5;
}
.footer_page.help_page .board_sec .board_item.result .item_head span + span:before {
  display: none;
}
.footer_page.help_page .board_sec .result_num {
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray-2);
}
.footer_page.help_page .board_sec .result_num em {
  color: var(--main-color);
}
.footer_page.help_page .board_sec .search_wrap {
  margin: 9rem auto 6rem;
}
.footer_page.help_page .board_sec .search_wrap .input_area .input_box {}
.footer_page.help_page .board_sec .search_wrap .input_area .input_box input {
  width: 3rem;
  height: 3rem;
  padding-left: 3rem;
}
.footer_page.help_page .board_sec .search_wrap .input_area .input_box .btn_search  {
  position: absolute;
  top:0;
  left:0;
}
.footer_page.help_page .board_sec .guide_ui .img_area {
  max-width: 24rem;
}
.footer_page.help_page .board_sec .guide_ui p {
  color: #fff;
  line-height: 1.6;
}
.footer_page.help_page .board_sec .guide_ui .btn {
  margin-top: 7rem;
}
@media screen and (max-width: 1024px) {
.footer_page .board_sec .board_item {
    padding: 2.1rem 2rem;
}
.footer_page .board_sec .board_item .item_body .desc {
    width: 100%;
}
.footer_page.notice_detail_page .page_ttl_area,
  .footer_page.help_detail_page .page_ttl_area {
    display: none;
}
.footer_page.notice_detail_page .board_sec,
  .footer_page.help_detail_page .board_sec {
    margin-top: 0;
}
.footer_page.notice_detail_page .board_sec .board_sec_head,
  .footer_page.help_detail_page .board_sec .board_sec_head {
    margin-top: 0;
}
.footer_page.help_page .board_sec .search_wrap {
    margin: 3.6rem 0 4rem;
}
.footer_page.help_page .tab_wrap {
    margin-left: -2rem;
    margin-right: -2rem;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.footer_page.help_page .tab_wrap::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
.footer_page.help_page .tab_list {
    display: inline-flex;
    padding: 0 2rem;
    white-space: nowrap;
}
.footer_page.help_page .tab_list .tab_node:not(:first-child) {
    margin-left: 3rem;
}
.footer_page .board_sec .board_item.result .item_body .ttl {
    overflow: hidden;
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset;
}
}
@media screen and (max-width: 767px) {
.footer_page {
    padding-top: 3rem;
    padding-bottom: 8rem;
}
.footer_page .page_ttl_area {
    flex-direction: column;
    align-items: flex-start;
}
.footer_page .page_ttl_area .date {
    margin-top: 1rem;
}
.footer_page .page_ttl_area .page_ttl {
    font-size: 2.4rem;
    font-weight: 500;
}
.footer_page .footer_info_sec_head {
    margin-top: 1.4rem;
}
.footer_page p {
    font-size: 1.4rem;
}
.footer_page.help_page .tab_wrap {
    margin-bottom: 2rem;
}
.footer_page .footer_info_sec_body {
    margin-top: 4rem;
}
.footer_page .footer_info_sec_body ol li {
    font-size: 1.6rem;
    font-weight: 500;
}
.footer_page .footer_info_sec_body ol li p {
    margin-top: 2rem;
}
.footer_page .footer_info_sec_body ol li + li {
    margin-top: 4rem;
}
.footer_page .board_sec {
    margin-top: 2rem;
}
.footer_page .board_sec .board_item .item_body .ttl {
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
}
.footer_page .board_sec .board_sec_head .ttl {
    margin-bottom: 1.7rem;
    font-size: 1.6rem;
    font-weight: 300;
}
.footer_page .board_sec .board_sec_head span {
    font-size: 1.4rem;
}
.footer_page .board_sec .board_sec_body {
    padding-bottom: 2rem;
}
.footer_page.notice_page .board_sec .board_item .item_body .ttl {
    overflow: hidden;
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset;
}
.footer_page .board_sec .board_item .link {
    display: block;
}
.footer_page.help_page .board_sec .board_item .item_body .ttl {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.footer_page .board_sec .board_item.result .item_body .ttl {
    overflow: hidden;
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset;
}
.footer_page.help_page .board_sec .result_num {
    margin-bottom: 4rem;
    font-size: 2rem;
    font-weight: 200;
}
.footer_page .board_sec .board_item .btn_area {
    display: none;
}
}
@media screen and (max-width: 500px) {
.footer_page .pagination .btn_num.list {
    margin: 0 0.8rem;
}
.pagination .btn_num.first {
    margin-left: 0;
}
.pagination .btn_num.last {
    margin-right: 0;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
