var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal_wrap",
      class: { is_show: _vm.showNotice /*is_home: showHome */ },
    },
    [
      _c("div", { staticClass: "modal", attrs: { role: "dialog" } }, [
        _c("div", { staticClass: "modal_head" }, [
          _c("h3", { staticClass: "modal_ttl" }, [_vm._v(_vm._s(_vm.subject))]),
        ]),
        _c("div", { staticClass: "modal_body" }, [
          _c("p", { staticClass: "desc" }, [
            _vm._v(" " + _vm._s(_vm.content) + " "),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }