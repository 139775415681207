// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.error_sec[data-v-83ce1664] {
  display: flex;
  min-height: calc(var(--nf) * 1px);
  width: 100%;
  align-items: center;
  justify-content: center;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
