var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "my_list_page artist_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("artist_page")]),
      _c("section", {
        staticClass: "main_visual_sec",
        style: `background-image: url('${_vm.profileCoverUrl}')`,
      }),
      _c("div", { staticClass: "set_inner" }, [
        _c("section", { staticClass: "artist_sec" }, [
          _c("div", { staticClass: "artist_sec_head" }, [
            _c("div", { staticClass: "profile_group" }, [
              _c(
                "div",
                { staticClass: "profile_info is_art" },
                [
                  _vm.profileUrl
                    ? _c("span", {
                        staticClass: "profile",
                        style: `background-image: url('${_vm.profileUrl}')`,
                      })
                    : _c("IcProfile", {
                        staticClass: "profile",
                        attrs: { width: 110, height: 110, "stroke-width": 0.8 },
                      }),
                  _c("IcRing", {
                    staticClass: "ic_ring",
                    attrs: { width: 110, height: 110, "small-ring": 14.8 },
                  }),
                  _c("h2", { staticClass: "ttl" }, [
                    _vm._v(_vm._s(_vm.userName)),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "follow_group" }, [
              _c("ul", { staticClass: "follow_area" }, [
                _c("li", { staticClass: "following" }, [
                  _c(
                    "button",
                    {
                      ref: "following",
                      attrs: { role: "status" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("FollowModal", false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("numberFormatter")(_vm.following, 2)) +
                          " "
                      ),
                      _c("span", [_vm._v("Following")]),
                    ]
                  ),
                ]),
                _c("li", { staticClass: "followers" }, [
                  _c(
                    "button",
                    {
                      ref: "followers",
                      attrs: { role: "status" },
                      on: {
                        click: function ($event) {
                          return _vm.openModal("FollowModal", true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("numberFormatter")(_vm.followers, 2)) +
                          " "
                      ),
                      _c("span", [_vm._v("Followers")]),
                    ]
                  ),
                ]),
              ]),
              _vm.mbrNo !== _vm.$route.params.mbrNo
                ? _c("div", { staticClass: "btn_area" }, [
                    _c(
                      "button",
                      {
                        ref: "follow",
                        staticClass: "btn basic w_m h_l",
                        class: { is_active: _vm.followYn === "Y" },
                        on: {
                          click: function ($event) {
                            return _vm.follow()
                          },
                        },
                      },
                      [
                        _vm.followYn === "N"
                          ? _c("IcMember", {
                              attrs: { "stroke-color": "#000000" },
                            })
                          : _vm._e(),
                        _vm.followYn === "Y" ? _c("IcCheck") : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.followYn === "Y" ? "Following" : "Follow"
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "desc_area", class: { is_active: _vm.expand } },
              [
                _c("p", { staticClass: "desc" }, [
                  _vm._v(" " + _vm._s(_vm.artistDesc) + " "),
                ]),
              ]
            ),
            !_vm.expand
              ? _c(
                  "button",
                  {
                    staticClass: "expand_btn dv_mob",
                    on: { click: _vm.expandBtn },
                  },
                  [_vm._v(" Expand "), _c("IcExpand")],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "sns_area" }, [
              _vm.facebookUrl
                ? _c(
                    "a",
                    {
                      staticClass: "sns",
                      attrs: {
                        href: _vm.facebookUrl,
                        target: "noopener noreferrer",
                      },
                    },
                    [_c("IcFaceBook")],
                    1
                  )
                : _vm._e(),
              _vm.instagramUrl
                ? _c(
                    "a",
                    {
                      staticClass: "sns",
                      attrs: {
                        href: _vm.instagramUrl,
                        target: "noopener noreferrer",
                      },
                    },
                    [_c("IcInstagram")],
                    1
                  )
                : _vm._e(),
              _vm.twitterUrl
                ? _c(
                    "a",
                    {
                      staticClass: "sns",
                      attrs: {
                        href: _vm.twitterUrl,
                        target: "noopener noreferrer",
                      },
                    },
                    [_c("IcTwitter")],
                    1
                  )
                : _vm._e(),
              _vm.webSiteUrl
                ? _c(
                    "a",
                    {
                      staticClass: "sns",
                      attrs: {
                        href: _vm.webSiteUrl,
                        target: "noopener noreferrer",
                      },
                    },
                    [_c("IcWeb")],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "artist_sec_body" }, [
            _c("div", { staticClass: "tab_wrap" }, [
              _c(
                "div",
                {
                  staticClass: "tab_list type_stack",
                  attrs: { role: "tablist" },
                },
                [
                  _c("div", { staticClass: "tab_node" }, [
                    _c(
                      "button",
                      {
                        staticClass: "tab_item",
                        class: { is_active: _vm.tabSelected === "CREATED" },
                        attrs: { role: "tab" },
                        on: {
                          click: function ($event) {
                            _vm.tabSelected = "CREATED"
                          },
                        },
                      },
                      [
                        _vm.tabSelected === "CREATED"
                          ? _c("span", { staticClass: "is_blind" }, [
                              _vm._v("Selected, "),
                            ])
                          : _vm._e(),
                        _c("span", [
                          _vm._v("Created "),
                          _c("em", { staticClass: "count" }, [
                            _vm._v(_vm._s(_vm.createdInfos.length)),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "tab_node" }, [
                    _c(
                      "button",
                      {
                        staticClass: "tab_item",
                        class: { is_active: _vm.tabSelected === "OWNED" },
                        attrs: { role: "tab" },
                        on: {
                          click: function ($event) {
                            _vm.tabSelected = "OWNED"
                          },
                        },
                      },
                      [
                        _vm.tabSelected === "OWNED"
                          ? _c("span", { staticClass: "is_blind" }, [
                              _vm._v("Selected, "),
                            ])
                          : _vm._e(),
                        _c("span", [
                          _vm._v("Owned "),
                          _c("em", { staticClass: "count" }, [
                            _vm._v(_vm._s(_vm.ownedEditions.length)),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _c(
              "ul",
              { staticClass: "grid grid_15 ib edit_list" },
              _vm._l(_vm.filterTab, function (item) {
                return _c(
                  "li",
                  { key: item.rsrcId, staticClass: "col col_3 edit_node" },
                  [
                    _c("ItemCardEdit", {
                      attrs: {
                        itemData: item,
                        userType: _vm.userType,
                        viewType: "other",
                        mbrNo: _vm.mbrNo,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("FollowList", {
        key: _vm.componentKey,
        ref: "FollowModal",
        attrs: { mbrNo: _vm.$route.params.mbrNo },
        on: { follow: _vm.handleFollow, onSetCloseFocus: _vm.onSetCloseFocus },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }